import React, { Component } from "react";

import { API, Auth, Storage } from "aws-amplify";
import "./Exports.css";
import LoaderButton from "../../components/LoaderButton";
import {ListGroup, ListGroupItem } from "react-bootstrap";

var fileDownload = require('js-file-download');

export default class Myob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            files: ""
        };
    }

    async componentDidMount(){
        try {
            let session = await Auth.currentSession();

            let files = await this.getMyobFiles(session);

            console.log(files);
            let array = files.sort((a, b) => {
                return new Date(a.upload_date).getTime() -
                    new Date(b.upload_date).getTime()
            }).reverse();

            this.setState({files: array});

        } catch (e) {
            console.log(e);
        }
    }

    handleFileDownload(name,e){
        Storage.get(name, {download: true})
            .then(result => fileDownload(result.Body, name))
            .catch(err => console.log(err));
    };

    handleProcess = async (file, e) => {
        try {
            let session = await Auth.currentSession();

            await API.post("users", "users/uploads/status/change", {
                body: {
                    file: file,
                    file_status: "processed",
                    file_category: "myob",
                    token: session.accessToken.jwtToken
                }
            });



        } catch (e) {
            console.log(e);
        }
    };

    /**
     * Calls the API to get all active stores.
     *
     * @returns {Promise<any>}
     */
    getMyobFiles(session) {
        return API.post("users", "uploads/generated", {
            body: {
                token: session.accessToken.jwtToken,
                file_type: 'myob'
            }
        });
    };

    convertDate(date) {
        let dateArray = date.split('-');
        return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
    }

    renderFileList() {
        return ((this.state.files.length > 0)
                ? <div className={"file-list"}>
                    <div>Pending Files:</div>
                    <ListGroup>
                    {this.state.files.map((file, i) =>
                        (file.file_status === 'pending')
                        ? <ListGroupItem key={i }>
                            <div className={'heading-text third-width'}>{file.file.split('-')[0]} - {this.convertDate(file.upload_date)}</div>
                            <div className={"download-wrapper third-width"} onClick = {(e)=>this.handleFileDownload(file.file, e)}>
                                <img src={require("../../images/download-icon.svg")}/>
                                <span>Download File</span>
                            </div>
                            <LoaderButton
                                block
                                text="Mark as Processed"
                                onClick={(e) => this.handleProcess(file.file, e)}
                                className={"approve-button third-width"}
                            />
                        </ListGroupItem>
                        : null
                    )}
                    </ListGroup>

                    <div>Processed Files:</div>
                    {this.state.files.map((file, i) =>
                        (file.file_status === 'processed')
                        ? <ListGroupItem key={i}>
                                <div className={'heading-text third-width'}>{this.convertDate(file.upload_date)}</div>
                                <div className={"download-wrapper third-width"} onClick = {(e)=>this.handleFileDownload(file.file, e)}>
                                    <img src={require("../../images/download-icon.svg")}/>
                                    <span>Download File</span>
                                </div>
                                <div className={"download-wrapper third-width"} onClick = {(e)=>this.handleFileDownload(file.file, e)}>
                                    <img src={require("../../images/tick-icon.png")}/>
                                    <span>Processed</span>
                                </div>
                            </ListGroupItem>
                        : null
                    )}
                </div>

                : <div>Files are loading...</div>
        )
    }

    render() {
        return (
            <div className={"wrapper"}>
                <div className={"heading"}>
                    <div className={"left-heading"}>
                        <div className={"heading-text"}>MYOB Files</div>
                        <div className={"small-heading-text"}>Here are all the MYOB files:</div>
                    </div>
                </div>
                {this.renderFileList()}
            </div>
        )
    }
}