import React, { Component } from "react";
import ViewExport from "./ViewExport";
export default class PayrollManagement extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
    }
    handleUserSearch(){
    }

    render() {
        return (
            <div className="payroll-menu wrapper">
                <div className="payroll-export-wrapper">
                    <ViewExport />
                </div>
            </div>

        );
    }
}