/*SHIP FROM STORES CONSTANTS*/
export const SET_CURRENT_STORE= "SET_CURRENT_STORE";
export const LOG_IN="LOG_IN";
export const FETCH_SHIPMENTS = "FETCH_SHIPMENTS";
export const LOADING_SHIPMENTS = "LOADING_SHIPMENTS";
export const CLEAR_SHIPMENTS = "CLEAR_SHIPMENTS";
export const SET_FOCUSED_SHIPMENT = "SET_FOCUSED_SHIPMENT";
export const ADD_SHIPMENTS = "ADD_SHIPMENTS";
export const ADD_NEW_SHIPMENT = "ADD_NEW_SHIPMENT";
export const GET_AUTHENTICATION_TOKEN = "GET_AUTHENTICATION_TOKEN";
export const UPDATE_FULFILLER = "UPDATE_FULFILLER";
export const UPDATE_SHIPMENTS_WITH_FULFILLER = "UPDATE_SHIPMENTS_WITH_FULFILLER";
export const SET_AUSPOST_LABEL = "SET_AUSPOST_LABEL";
export const SET_REJECTED_IBT = "SET_REJECTED_IBT";
export const SET_AUSPOST_DETAILS = "SET_AUSPOST_DETAILS";
export const SET_MANIFEST = "SET_MANIFEST";
export const UPDATE_SHIPMENT_WITH_ORDER = "UPDATE_SHIPMENT_WITH_ORDER"; // updates shipments with order info
export const UPDATE_ITEM_AVAILABILITY = "UPDATE_ITEM_AVAILABILITY";
export const UPDATE_ITEM_IMAGES = "UPDATE_ITEM_IMAGES";
export const SET_SEARCHED_SHIPMENT = "SET_SEARCHED_SHIPMENT";
export const CLEAR_SEARCHED_SHIPMENT = "CLEAR_SEARCHED_SHIPMENT";
export const UPDATE_SHIPMENT_STATUS = "UPDATE_SHIPMENT_STATUS";
export const PENDING_OVERVIEW = "PENDING_OVERVIEW";
export const UPDATE_SHIPMENT_STAGE = "UPDATE_SHIPMENT_STAGE";
export const UPDATE_SHIPMENT = "UPDATE_SHIPMENT";
export const REMOVE_SHIPMENT = 'REMOVE_SHIPMENT';