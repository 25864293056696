import * as type from "../constants/actions/ActionTypes";
const initialState = {
    errors: [],
    isLoading: false
};

export default function adminReducer (state = initialState , action)  {
    switch (action.type) {
        case type.CREATE_ROLE:
            return {...state};
        case type.EDIT_ROLE:
            return {...state};

        case type.ADMIN_IS_LOADING:
            return {...state, isLoading:action.loading};
        default:
            return state;
    }
}