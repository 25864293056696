import React, { Component } from "react";

import { API, Auth } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton";


export default class ApplicantSuccessfulTrial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // keyProp: this.props.keyProp,
            end_date: this.props.trial_date,
            uploadInfo: "",
            resendIsLoading: false
        };
    }

    handleResendSubmit = async event => {
        event.preventDefault();

        this.setState({ resendIsLoading: true });
        try{
            await API.post("users", `users/applicants/status/update/successful/${true}`, {
                body: {
                    userId: this.props.userId
                }
            });

            this.setState({resendIsLoading:false});
        } catch (e) {
            console.log(e);
        }
        this.setState({ resendIsLoading: false });
    };

    render() {
        return (
            <div className={"submenu submenu-success"}>
                <div className={"submenu-heading"}>We are waiting to hear from the applicant. Do you want to resend the letter of offer?</div>
                <form className={"full-width"} onSubmit={this.handleResendSubmit}>
                    <LoaderButton
                        block
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.resendIsLoading}
                        className={"approve-button"}
                        text="Resend Letter of Offer"
                        loadingText="Resending…"
                    />
                </form>
            </div>
        )
    }
}