import * as type from "../constants/actions/ActionTypes";

const initialState = {
    reload: true, // this will be false if a new store has been added to the state or if any changes have been made to the storeState, to make sure re-renders do not occur
    store: [], // current store that is being focused on
    stores: [],
    loading: false, // loading indicators for frontend use
    members: [],

};
export default function storeReducer (state = initialState , action)  {
    switch (action.type) {
        case type.GET_STORE_STATE:
            return {...state};
        case type.LOADING_STORES:
            return {...state, loading:action.loading};
        case type.FETCH_STORES:
            return {...state, stores:[],reload:false};
        case type.ADD_STORE:
            return { ...state, stores: state.stores.concat(action.payload) };
        case type.SET_CURRENT_STORE:
            return {...state, store:action.payload};
        default:
            return state;
    }
};

