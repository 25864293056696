import React, { Component } from "react";
import * as EmailValidator from 'email-validator';
import {changePassword} from "../../actions/login/LoginActions"
import "./Login.css";


import ForgottenForm from "../../components/login/user-forgot-password"

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: null,
            email_confirmed: false
        };
    }

    handleEmail = async (email) => {
        await changePassword(email);
        this.setState({email:email,email_confirmed:true})
    }

    handlePasswordChange = async (code,password) => {
        await changePassword(this.state.email,code,password);
        this.setState({email_confirmed:true})
        this.props.history.push('/login')
    }

    handleChange = event => {
        this.setState({[event.target.id]: event.target.value});
    };


    render() {
        return (
            <div className="ChangePassword">
               <ForgottenForm
                   email_confirmed={this.state.email_confirmed}
                   handleEmailSubmit={this.handleEmail}
                   handlePasswordSubmit = {this.handlePasswordChange}
               />
            </div>
        );
    }

}
