import React, {Component} from "react";
import {connect} from "react-redux";
import {Row,Col} from "react-bootstrap";
import './reportshipping.css'
import {} from "../../../actions/ReportingActions";
import {generateRejectedItems} from "../../../actions/ReportingActions";
import {errorNotification} from "../../../actions/NotificationActions";
import Filters from "./Filters";
import {getStoresByUser} from "../../../actions/StoreActions";

const mapStateToProps = state => {
    return {userId:state.authState.username, permissions: state.authState.userPermissions,store:state.authState.store}

};

const mapDispatchToProps = dispatch =>{
    return {
        generateRejectedItems: (storeId,date)=>{dispatch(generateRejectedItems(storeId,date))},
        errorNotification : (message) => {dispatch(errorNotification(message))}
    }
};

class RejectedView extends Component {
    constructor(props) {
        super(props);
        this.state={
            dateRange: null,
            store:null,
            stores: [],
            state:null,
            method: null,
        }
    };

    async componentDidMount() {
        if(this.props.store){
            this.setState({store:this.props.store})
        }
        let stores = await getStoresByUser(this.props.userId);
        stores.unshift( {value: null,label: "All"});
        this.setState({stores})
    }

    generateRejectedItems = () =>{
        let store = this.state.store ? this.state.store : null;
        let date = this.state.dateRange ? this.state.dateRange : null;
        if(date){
            this.props.generateRejectedItems(store,date)
        }else {
            this.props.errorNotification('Please select a date range before generating');
        }

    };

    handleMethod =(method) => {
        this.setState({method});
    };
    handleState = (state) => {
        this.setState({state})
    };
    handleStore = (store) => {
        this.setState({store})
    };
    handleDate = (dateRange) => {
        this.setState({dateRange})
    };

    render() {
        return (
            <div>
                <Row className={"shipping-select-wrapper"}>
                    <Col sm={12}>
                        <Filters handleMethod={this.handleMethod}
                                 handleState={this.handleState}
                                 handleStore={this.handleStore}
                                 handleDate={this.handleDate}
                                 role = {this.props.role}
                                 permissions = {this.props.permissions}
                                 stores = {this.state.stores}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={8}>
                        <h2 className={"small-heading-text"}>
                            Rejected Items
                        </h2>
                    </Col>
                    <Col sm={4}>
                        <button onClick={()=>this.generateRejectedItems()}>Generate</button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>
                            To View rejected items from a store, please select a store and date/time range for your query. once selected please click the generate button which will
                            then download a Excel file with rejected items.
                        </p>
                    </Col>
                </Row>
            </div>
        )
    }
}

const rejectedItems = connect(mapStateToProps, mapDispatchToProps)(RejectedView);
export default rejectedItems;