import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import { Row,Col } from "react-bootstrap";
import "../Home.css";
import "./Applicants.css";
import SearchFilter from "./ApplicantSearchFilters"
import NewApplicantList from "./NewApplicantsList"
import {
    addToVisibleUsers,
    getResponsibleApplicants,
    setFocusedApplicant,
    replaceVisibleUsers,
    clearApplicants,
    getFocusedApplicant,
    fetchElasticSearch
} from "../../actions/ApplicantActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";



const mapStateToProps = state => {
    if(state.applicantsState){
        return ({users: state.applicantsState.users, visibleUsers:state.applicantsState.visibleUsers, searchedUsers: state.applicantsState.searchedUsers, username:state.authState.username })
    }else {
        return ({users:null})
    }

};

const mapDispatchToProps = dispatch => {
    return {
        getResponsibleApplicants : (user,query) => dispatch(getResponsibleApplicants(user,query)),
        setFocusedApplicant : applicant => dispatch(setFocusedApplicant(applicant)),
        addToVisibleUsers : users => dispatch(addToVisibleUsers(users)),
        replaceVisibleUsers : users => dispatch(replaceVisibleUsers(users)),
        clearApplicants : () => dispatch(clearApplicants()),
        getFocusedApplicant : user => dispatch(getFocusedApplicant(user)),
        fetchElasticSearch : query => dispatch(fetchElasticSearch(query))
    };
};

class Applicants extends Component {
    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            queryOptions : {

            },
            add_user_redirect: false,
            active_panels: [],
            successful: null,
            user_role: "",
            user_stores: "",
            visible_users: "",
            users_loaded: false,
            session: [],
            adminId:"",
            page: 1,
            pageSize:10,
            disableBackButton:true,
            disableNextButton:false
        };
    }
    async componentDidMount(){
        //authenticate current user, and load there responsibleApplicants
        let role = await this.getRole(this.props.username);
        this.setState({
            adminId: role.userId,
            user_stores: role.stores,
            user_role: role.user_role
        });
        try {
            this.props.getResponsibleApplicants(this.props.username);
        } catch (e) {
            console.log(e);
        }


    }


    getRole(username) {
        return API.get("users", `users/roles/get/${username}`);
    }

    setAddUserRedirect = () => {
        this.setState({
            add_user_redirect: true
        });
    };

    addRedirects = () => {
        if (this.state.add_user_redirect === true) {
            this.props.history.push('/add');
        }
    };

    render() {
        return (
            <div className={"wrapper"}>
                {this.addRedirects()}
                <div className={"heading"}>
                    <div className={"left-heading"}>
                        <h1 className={"heading-text"}>Your Applicants</h1>
                        <h2 className={"small-heading-text"}>Here are all the applicants you are responsible for:</h2>
                    </div>
                    <div className={"right-heading"}>
                        <button className={"create-user-button"} onClick={() => this.props.history.push('/add')}>
                            <img src={require("../../images/add-contact.png")}/>Create New Applicant
                        </button>
                    </div>
                </div>
                <div>
                    <SearchFilter session = {this.state.session} page = {this.state.page} pageSize = {this.state.pageSize}/>
                    <NewApplicantList
                        page ={this.state.page}
                        pageSize = {this.state.pageSize}
                        getFocusedApplicant = {this.props.getFocusedApplicant}
                        user_role = {this.state.user_role}
                        user_stores = {this.state.user_stores}/>

                    </div>
            </div>
        )
    }
}

const ApplicantsPage = connect( mapStateToProps,mapDispatchToProps)(Applicants);

export default withRouter(ApplicantsPage);
