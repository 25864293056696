// Perfect Stranger Stacked  Logo
import React, {Component} from 'react'

class PerfectStrangerStackedLogo extends Component{
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50.327" viewBox="0 0 99.978 50.327" style={{backgroundColor:'#F2EFE6'}}>
                <g id="Group_2" data-name="Group 2" transform="translate(-179.447 -94.006)">
                    <path id="Path_2606" data-name="Path 2606" d="M21.59,37.561V16.23h4.585a6.848,6.848,0,0,1,2.217.329,4.272,4.272,0,0,1,1.738,1.17,4.574,4.574,0,0,1,1.081,1.962,12.316,12.316,0,0,1,.3,3.041,15.145,15.145,0,0,1-.165,2.427,5.085,5.085,0,0,1-.7,1.859,4.473,4.473,0,0,1-1.678,1.629,5.608,5.608,0,0,1-2.759.591H24.646v8.325Zm3.056-18.455v7.25h1.469a3.123,3.123,0,0,0,1.437-.269,1.7,1.7,0,0,0,.75-.749,2.745,2.745,0,0,0,.287-1.108q.046-.658.045-1.469,0-.747-.03-1.423a3.1,3.1,0,0,0-.269-1.183,1.71,1.71,0,0,0-.72-.779,2.848,2.848,0,0,0-1.378-.27Z" transform="translate(157.857 77.956)"/>
                    <path id="Path_2607" data-name="Path 2607" d="M155.13,37.571V16.24h9.109v2.874h-6.055v6.261h5.27v2.874h-5.27v6.265h6.055v3.056Z" transform="translate(37.112 77.946)"/>
                    <path id="Path_2608" data-name="Path 2608" d="M277.09,37.571V16.24H282q5.394,0,5.393,6.261a7.89,7.89,0,0,1-.584,3.206,4.529,4.529,0,0,1-2.052,2.127l3.3,9.736H284.82l-2.846-9.108h-1.827v9.108Zm3.057-18.455v6.651h1.738a3.067,3.067,0,0,0,1.288-.225,1.75,1.75,0,0,0,.75-.644,2.939,2.939,0,0,0,.329-1.033,11.225,11.225,0,0,0,0-2.845,2.835,2.835,0,0,0-.359-1.067,2.43,2.43,0,0,0-2.157-.839Z" transform="translate(-73.163 77.946)"/>
                    <path id="Path_2609" data-name="Path 2609" d="M416.57,37.571V16.24h9.108v2.874h-6.052v6.472h5.27V28.46h-5.27v9.11Z" transform="translate(-199.284 77.946)"/>
                    <path id="Path_2610" data-name="Path 2610" d="M538.53,37.571V16.24h9.109v2.874h-6.052v6.261h5.274v2.874h-5.274v6.265h6.052v3.056Z" transform="translate(-309.56 77.946)"/>
                    <path id="Path_2611" data-name="Path 2611" d="M668.488,29.786V31.1a4.733,4.733,0,0,1-.375,1.872,5.181,5.181,0,0,1-1.034,1.573,5,5,0,0,1-1.545,1.094,4.513,4.513,0,0,1-1.9.4,6.836,6.836,0,0,1-1.768-.24,4.161,4.161,0,0,1-1.618-.839,4.64,4.64,0,0,1-1.184-1.542,5.45,5.45,0,0,1-.464-2.412V19.27a5.191,5.191,0,0,1,.359-1.947,4.592,4.592,0,0,1,1.02-1.559,4.754,4.754,0,0,1,1.573-1.034,5.3,5.3,0,0,1,2.023-.375,4.635,4.635,0,0,1,3.5,1.408,5.027,5.027,0,0,1,1.034,1.629,5.507,5.507,0,0,1,.375,2.053v1.2h-3.056V19.63a2.477,2.477,0,0,0-.509-1.559,1.653,1.653,0,0,0-1.379-.659,1.55,1.55,0,0,0-1.513.7,3.756,3.756,0,0,0-.375,1.783V30.8a2.821,2.821,0,0,0,.4,1.557,1.6,1.6,0,0,0,1.453.63,2.223,2.223,0,0,0,.644-.105,1.907,1.907,0,0,0,.645-.345,1.828,1.828,0,0,0,.448-.658,2.676,2.676,0,0,0,.18-1.049V29.781Z" transform="translate(-418.126 79.65)"/>
                    <path id="Path_2612" data-name="Path 2612" d="M781.635,37.589V19.134H778.1V16.26h10.127v2.874h-3.536V37.589Z" transform="translate(-526.18 77.929)"/>
                    <path id="Path_2613" data-name="Path 2613" d="M32.388,272.319H29.331v-.69a3.267,3.267,0,0,0-.494-1.813,1.83,1.83,0,0,0-1.663-.766,1.916,1.916,0,0,0-1.017.24,2,2,0,0,0-.629.6,2.536,2.536,0,0,0-.33.883,5.823,5.823,0,0,0-.09,1.034,9.92,9.92,0,0,0,.045,1.048,1.985,1.985,0,0,0,.225.749,1.64,1.64,0,0,0,.524.569,4.791,4.791,0,0,0,.944.479l2.337.929a5.791,5.791,0,0,1,1.648.913,3.99,3.99,0,0,1,.99,1.213,5.739,5.739,0,0,1,.448,1.633,16.227,16.227,0,0,1,.12,2.083,11.008,11.008,0,0,1-.269,2.5,5.269,5.269,0,0,1-.869,1.963A4.3,4.3,0,0,1,29.6,287.2a5.831,5.831,0,0,1-2.491.479,5.436,5.436,0,0,1-2.067-.389,4.9,4.9,0,0,1-1.649-1.079,5.231,5.231,0,0,1-1.093-1.6,4.854,4.854,0,0,1-.4-1.992v-1.137h3.059v.958a2.5,2.5,0,0,0,.5,1.515,1.932,1.932,0,0,0,1.663.671,2.68,2.68,0,0,0,1.214-.225,1.616,1.616,0,0,0,.671-.644,2.373,2.373,0,0,0,.285-1q.045-.585.044-1.3a12.835,12.835,0,0,0-.057-1.379,2.374,2.374,0,0,0-.24-.869,1.7,1.7,0,0,0-.555-.54,7.326,7.326,0,0,0-.913-.449l-2.187-.9a4.839,4.839,0,0,1-2.652-2.142,7.363,7.363,0,0,1-.674-3.338,7.736,7.736,0,0,1,.33-2.277,5.173,5.173,0,0,1,.989-1.857,4.521,4.521,0,0,1,1.6-1.245A5.351,5.351,0,0,1,27.294,266a5.084,5.084,0,0,1,2.086.418,5.4,5.4,0,0,1,1.629,1.108,4.628,4.628,0,0,1,1.378,3.3Z" transform="translate(157.55 -143.353)"/>
                    <path id="Path_2614" data-name="Path 2614" d="M149.005,289.219V270.764H145.47V267.89H155.6v2.874h-3.535v18.455Z" transform="translate(45.816 -145.067)"/>
                    <path id="Path_2615" data-name="Path 2615" d="M271.49,289.192V267.86H276.4q5.394,0,5.392,6.262a7.9,7.9,0,0,1-.584,3.205,4.523,4.523,0,0,1-2.052,2.128l3.3,9.736H279.22l-2.845-9.108h-1.828v9.108Zm3.056-18.455v6.651h1.738a3.077,3.077,0,0,0,1.289-.225,1.75,1.75,0,0,0,.748-.644,2.937,2.937,0,0,0,.331-1.034,11.412,11.412,0,0,0,0-2.846,2.846,2.846,0,0,0-.36-1.063,2.431,2.431,0,0,0-2.158-.839Z" transform="translate(-68.132 -145.039)"/>
                    <path id="Path_2616" data-name="Path 2616" d="M396.58,289.192l4.764-21.332h2.548l4.765,21.332H405.6l-.9-4.584h-4.164l-.9,4.584Zm7.551-7.459-1.5-7.728h-.059l-1.5,7.73Z" transform="translate(-181.239 -145.039)"/>
                    <path id="Path_2617" data-name="Path 2617" d="M543.25,289.192V267.86h2.936l4.614,12.85h.06V267.86h3.057v21.332h-2.874l-4.677-12.823h-.059v12.823Z" transform="translate(-313.858 -145.039)"/>
                    <path id="Path_2618" data-name="Path 2618" d="M687.72,271.281a5.442,5.442,0,0,1,.449-2.277,4.827,4.827,0,0,1,1.2-1.649A5.063,5.063,0,0,1,691,266.4a5.443,5.443,0,0,1,5.348.958A5.018,5.018,0,0,1,697.518,269a5.441,5.441,0,0,1,.449,2.277v1.079h-3.056v-1.079a2.034,2.034,0,0,0-.614-1.633,2.278,2.278,0,0,0-2.907,0,2.032,2.032,0,0,0-.614,1.633v11.265a2.032,2.032,0,0,0,.614,1.633,2.278,2.278,0,0,0,2.907,0,2.037,2.037,0,0,0,.614-1.633V278.53h-2.428v-2.7h5.483v6.712a5.4,5.4,0,0,1-.449,2.307,4.761,4.761,0,0,1-1.168,1.588,5.174,5.174,0,0,1-1.663.991,5.33,5.33,0,0,1-3.683,0,4.82,4.82,0,0,1-1.634-.989,4.585,4.585,0,0,1-1.2-1.588,5.38,5.38,0,0,1-.449-2.307Z" transform="translate(-444.489 -143.427)"/>
                    <path id="Path_2619" data-name="Path 2619" d="M829.07,289.192V267.86h9.109v2.874h-6.049V277h5.27v2.874h-5.27v6.262h6.052v3.055Z" transform="translate(-572.298 -145.039)"/>
                    <path id="Path_2620" data-name="Path 2620" d="M951,289.192V267.86h4.915q5.392,0,5.392,6.262a7.891,7.891,0,0,1-.584,3.205,4.532,4.532,0,0,1-2.048,2.128l3.3,9.736h-3.236l-2.848-9.108H954.06v9.108Zm3.056-18.455v6.651H955.8a3.071,3.071,0,0,0,1.288-.225,1.745,1.745,0,0,0,.748-.644,2.92,2.92,0,0,0,.331-1.034,11.285,11.285,0,0,0,0-2.846,2.815,2.815,0,0,0-.36-1.063,2.428,2.428,0,0,0-2.158-.839Z" transform="translate(-682.546 -145.039)"/>
                </g>
            </svg>

        )
    }
}

export default PerfectStrangerStackedLogo;