import React, { Component } from "react";
import Dropdown from "../../../components/form/inputs/Dropdown"
import PropTypes from "prop-types";

/**
 * Takes in an array of sizes which has the simple_sku and size label
 * {simple:12345,label:'Small}
 * Returns a callback for a function and passes through the simple sku with the size label.
 */
export default class SizeSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_size: "SIZE"
        };
    }

    componentDidMount() {
        if(this.props.defaultSelection != null){
            this.setState({selected_size: this.props.defaultSelection.label})
        }
    }
    handleSizeSelect = (size) => {
        let parsed_size = JSON.parse(size)
        let updated_size = {
            simple: parsed_size.simple,
            label: parsed_size.label,
        }
        this.setState({selected_size: updated_size.label});
        this.props.updateSize(updated_size)
    }

    render(){
        let sizes = this.props.sizes
        return(
            <Dropdown
                className = {"btn-block small"}
                list={sizes}
                onSelect={this.handleSizeSelect}
                placeholder={this.state.selected_size}
                variant={"outline-dark"}
            />
        )
    }
}


SizeSelector.propTypes = {
    defaultSelection: PropTypes.object,
    sizes: PropTypes.array.isRequired,
    updateSize: PropTypes.func.isRequired
};
