import React, { Component } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik, getIn,ErrorMessage } from 'formik';
import Input from "../../../components/form/inputs/Input";
import Select from "../../../components/form/inputs/NewSelect"
import LoaderButton from "../../../components/LoaderButton";
import DownloadIcon from "../../../images/download-icon.svg"
import formValidation from "./personal-form-schema"
import DateInput from "../../../components/form/inputs/DateInput";
import UploadFile from "../../../components/form/UploadFile";
import Checkbox from "../../../components/form/inputs/Checkbox";

import {
    submitChanges,
    uploadParentalConsent,
    handlePersonalDetailsChange
} from "../../../actions/OnboardingActions";
import {updateUser} from "../../../actions/LambdaCalls"
import {connect} from "react-redux";
import Alert from "react-bootstrap/Alert";


const states = [
    {value:null,label:"Please Select a State"},
    {value:"VIC",label:"Victoria"},
    {value:"NSW",label:"New South Wales"},
    {value:"WA",label:"Western Australia"},
    {value:"QLD",label:"Queensland"},
    {value:"SA",label:"South Australia"},
    {value:"NT",label:"Nothern Territory"},
    {value:"ACT",label:"Australian Capital Territory"},
    {value:"TAS",label:"Tasmania"},
]

const gender = [
    {value:null,label:"Select a Gender"},
    {value:"m",label:"Male"},
    {value:"f",label:"Female"},
    {value:"other",label:"Other"},
    {value:"na",label:"Rather not say "},
]

const citizenship_options = [
    {value:null,label:"Please Select an option"},
    {value: 'au-citizen',label: "I'm an Australian citizen"},
    {value: 'nz-citizen',label: "I'm a New Zealand citizen"},
    {value: 'not-citizen',label: "I don't have Australian or New Zealand citizenship"}
]

const workright_options = [
    {value:null,label:"Please Select an option"},
    {value: 'family/partner',label: "I have a family/partner visa with no restriction on working hours"},
    {value: 'graduate-work-visa',label: "I have a graduate temporary work visa"},
    {value: 'temp-work-visa',label: "I have a holiday temporary work visa"},
    {value: 'regional-work-visa',label: "I have a skilled regional temporary work visa (e.g. 489)"},
    {value: 'protection-safe-work-visa',label: "I have a temporary protection or safe haven enterprise work visa"},
    {value: 'student',label: "I only have the right to work restricted hours (e.g. student visa)"},
    {value: 'sponsorship',label: " I require sponsorship to work for a new employer (e.g. 457 visa)"}
]

const file = [];

const mapDispatchToProps = dispatch => {
    return {
        submitChanges : (token, type, data) => dispatch(submitChanges(token, type, data)),
        handlePersonalDetailsChange : (personal_details) => dispatch(handlePersonalDetailsChange(personal_details)),
    };
};
const mapStateToProps = state => {
    return {
        username: state.authState.username,
        cogId: state.authState.identityId,
        personal_details: state.onboardingState.personalDetails,
        role_details: state.onboardingState.roleDetails,
        };
};
/**
 * Component for a customer form submission which returns a object
 * with customers personal information and an object with customers
 * address/delivery details
 */
class PersonalForm extends Component {
    constructor(props) {
        super(props);
        this.addressRef = React.createRef();
        this.state = {
            previously_employed: false,
            availability: [],
            overseas_reference_one: false,
            overseas_reference_two: false,
            loading:false
        }
    }


    handleCheckbox = (e) => {
        this.setState({[e.target.id]: !this.state[e.target.id]});
    }

    /**
     * Upload personal information, upload parental consent file if there is one, update availability
     * @param personal_details
     */
    submitDetails = async (personal_details) =>{
        this.setState({loading:true})
        //upload file if there is one
        if(ageChecker(personal_details.dob.data,personal_details.address.data.state)){
            await uploadParentalConsent(file,this.props.username,this.props.cogId)
        }

        this.props.handlePersonalDetailsChange(personal_details);
        await this.props.submitChanges("personal",personal_details);
        await updateUser("status","STAGE_2_ONBOARDING");
        this.props.changeStage(2);
    }



    render(){
        if(this.props.personal_details.address){
            return (
                <Row>
                    <Formik
                        validateOnChange={true}
                        initialValues={{...this.props.personal_details}}
                        onSubmit={(values,{setStatus}) => {
                            this.submitDetails(values).then(() => this.setState({loading:false})).catch(e=>this.setState({loading:false}));
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              handleSubmit,
                          }) => (

                            <Form noValidate onSubmit= {handleSubmit} className = "customer-details-view">
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Input
                                            controlId={"street_one"}
                                            className = {"text-capitalize"}
                                            name={"address.data.street_one"}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value = {values.address.data.street_one}
                                            type="text"
                                            placeholder="Address Line 1"
                                            isInvalid = {!!getIn(touched,"address.data.street_one") && !!getIn(errors,"address.data.street_one")}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Input
                                            controlId={"street_two"}
                                            className = {"text-capitalize"}
                                            name={"address.data.street_two"}
                                            onChange={handleChange}
                                            value = {values.address.data.street_two}
                                            type="text"
                                            onBlur={handleBlur}
                                            placeholder="Address Line 2"
                                            isInvalid = {!!getIn(touched,"address.data.street_one") && !!getIn(errors,"address.data.street_two")}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={8} md={4}>
                                        <Input
                                            controlId={"suburb"}
                                            className = {"text-capitalize"}
                                            name={"address.data.suburb"}
                                            onChange={handleChange}
                                            value = {values.address.data.suburb}
                                            type="text"
                                            onBlur={handleBlur}
                                            placeholder="Suburb"
                                            isInvalid = {!!getIn(touched,"address.data.suburb") && !!getIn(errors,"address.data.suburb")}
                                            required
                                        />
                                    </Col>
                                    <Col sm={4} md={2}>
                                        <Input
                                            controlId={"postcode"}
                                            name={"address.data.postcode"}
                                            onChange={handleChange}
                                            value = {values.address.data.postcode}
                                            type="number"
                                            placeholder="postcode"
                                            onBlur={handleBlur}
                                            isInvalid = {!!getIn(touched,"address.data.postcode") && !!getIn(errors,"address.data.postcode")}
                                            required
                                         />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Select
                                            name={"address.data.state"}
                                            id={"state"}
                                            list={states}
                                            value={values.address.data.state}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid = {!!getIn(touched,"address.data.state") && !!getIn(errors,"address.data.state")}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={7} md={4}>
                                        <DateInput
                                            className={!!getIn(touched,"dob.data") && !!getIn(errors,"dob.data") ? "is-invalid": null}
                                            name={"dob.data"}
                                            id={"dob"}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.dob.data}
                                            label={"Date of Birth"}
                                        />

                                    </Col>
                                    <Col sm={5} md={2}>
                                        <Select
                                            label={"Gender"}
                                            name={"gender.data"}
                                            id={"gender"}
                                            list={gender}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid = {!!getIn(touched,"gender.data") && !!getIn(errors,"gender.data")}
                                            required
                                        />
                                    </Col>
                                </Row>
                                {ageChecker(values.dob.data,values.address.data.state) ?<div>
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <Form.Label>
                                                    A parent or guardian needs to confirm you're eligible to work by completing this form.
                                                    You can log back in to upload the signed form later.
                                                </Form.Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6} className = "upload-file-icon text-start">
                                                <div className=""><a href={"https://s3-ap-southeast-2.amazonaws.com/us-public-files/forms/ParentConsentForm.pdf"} target={"_blank"}><img src={DownloadIcon}/><span>Download Parental Consent Form</span></a></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6} className={"text-start"}>
                                                <UploadFile file = {file}/>
                                            </Col>

                                        </Row>
                                    </div>
                                    : null}
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Select
                                            label={"citizenship"}
                                            name={"citizenship.data"}
                                            id={"citizenship"}
                                            list={citizenship_options}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid = {!!getIn(touched,"citizenship.data") && !!getIn(errors,"citizenship.data")}
                                            required
                                        />
                                    </Col>
                                </Row>

                                {values.citizenship.data === "not-citizen" ?
                                    <Row>
                                            <Row>
                                                <Col sm={12} md={6} className={"text-left"}>
                                                    <strong>Alert! VEVO Check required. </strong>Use the <a href="https://online.immi.gov.au/evo/firstParty?actionType=query" target="_blank">VEVO website</a> {' '}
                                                    to send your visa entitlement details to <a href="mailto:hr@universalstore.com.au">hr@universalstore.com.au</a>. VEVO Checks can
                                                    take up to 48 hours to process so we recommend you do this immediately. We’ll provide you with step-by-step instructions once you finish this form.
                                                    <p><br /></p>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col sm ={12} md ={6} className={"text-left"}>
                                                <Select
                                                    label={"workrights"}
                                                    name={"workrights.data"}
                                                    id={"workrights"}
                                                    list={workright_options}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    isInvalid = {!!getIn(touched,"workrights.data") && !!getIn(errors,"workrights.data")}
                                                    required
                                                />
                                            </Col>
                                            </Row>
                                    </Row>
                                :null}
                                <Row>
                                    <Col sm ={12} md ={6} className={"text-left"}>
                                        <Checkbox
                                            onChange = {(e)=>this.handleCheckbox(e)}
                                            value={this.state.previously_employed}
                                            id={"previously_employed"}
                                            onValidate={""}
                                            text={"I have worked at Universal Store before in the past."}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm ={12} md ={6}>
                                        <Form.Label>When are you available to work Mondays to Sundays?<br/>
                                            Please also list any dates you cannot work between December 1st to January 20th. Christmas trade is the busiest time of the year in retail so, like many retailers, our team members don't normally take time off in December/January.
                                            Include any upcoming events like holidays, medical appointments or events like weddings etc. and your Manager will discuss this with you.</Form.Label>
                                        <Input
                                            controlId={"availability_notes"}
                                            name={"availability_notes.data"}
                                            onChange={handleChange}
                                            value = {values.availability_notes.data}
                                            as={"textarea"}
                                            type="textarea"
                                            placeholder="Mandatory"
                                            isInvalid = {!!getIn(touched,"availability_notes.data") && !!getIn(errors,"availability_notes.data")}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={5}>
                                        <h3 className="sub-heading-text">Reference Contacts</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={3}>
                                        <Row>
                                            <Col sm={12} className={"text-left"}>
                                                <Checkbox
                                                    checked={this.state.overseas_reference_one}
                                                    id={"overseas_reference_one"}
                                                    onChange={ (e)=>this.handleCheckbox(e)}
                                                    text={"My reference is overseas "}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"user_references.data.contactOne.fName"}
                                                    onChange={handleChange}
                                                    value = {values.user_references.data.contactOne.fName}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="First Name"
                                                    isInvalid = {!!getIn(touched,"user_references.data.contactOne.fName") && !!getIn(errors,"user_references.data.contactOne.fName")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"user_references.data.contactOne.lName"}
                                                    onChange={handleChange}
                                                    value = {values.user_references.data.contactOne.lName}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Last Name"
                                                    isInvalid = {!!getIn(touched,"user_references.data.contactOne.lName") && !!getIn(errors,"user_references.data.contactOne.lName")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"user_references.data.contactOne.organisation"}
                                                    onChange={handleChange}
                                                    value = {values.user_references.data.contactOne.organisation}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Relationship"
                                                    isInvalid = {!!getIn(touched,"user_references.data.contactOne.organisation") && !!getIn(errors,"user_references.data.contactOne.organisation")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"user_references.data.contactOne.relationship"}
                                                    onChange={handleChange}
                                                    value = {values.user_references.data.contactOne.relationship}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Organisation"
                                                    isInvalid = {!!getIn(touched,"user_references.data.contactOne.relationship") && !!getIn(errors,"user_references.data.contactOne.relationship")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                {this.state.overseas_reference_one ?
                                                    <Input
                                                        controlId={"email"}
                                                        className = {"text-capitalize"}
                                                        name={"user_references.data.contactOne.number"}
                                                        onChange={handleChange}
                                                        value = {values.user_references.data.contactOne.number}
                                                        type="text"
                                                        onBlur={handleBlur}
                                                        placeholder="Email"
                                                        isInvalid = {!!getIn(touched,"user_references.data.contactOne.number") && !!getIn(errors,"user_references.data.contactOne.number")}
                                                        required
                                                    />

                                                    :  <Input
                                                        name={"user_references.data.contactOne.number"}
                                                        controlId={"number"}
                                                        type={"number"}
                                                        onChange={handleChange}
                                                        value={values.user_references.data.contactOne.number}
                                                        placeholder={"Phone Number"}
                                                        isInvalid = {!!getIn(touched,"user_references.data.contactOne.number") &&  !!getIn(errors,"user_references.data.contactOne.number")}
                                                        required
                                                        onBlur={handleBlur}
                                                        id={"number"}
                                                        error={getIn(errors,"user_references.data.contactOne.number")}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Row>
                                            <Col sm={12} className={"text-left"}>
                                                <Checkbox
                                                    checked={this.state.overseas_reference_two}
                                                    id={"overseas_reference_two"}
                                                    onChange={ (e)=>this.handleCheckbox(e)}
                                                    text={"My reference is overseas "}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"user_references.data.contactTwo.fName"}
                                                    onChange={handleChange}
                                                    value = {values.user_references.data.contactTwo.fName}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="First Name"
                                                    isInvalid = {getIn(touched,"user_references.data.contactTwo.fName") &&  !!getIn(errors,"user_references.data.contactTwo.fName")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"user_references.data.contactTwo.lName"}
                                                    onChange={handleChange}
                                                    value = {values.user_references.data.contactTwo.lName}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Last Name"
                                                    isInvalid = {getIn(touched,"user_references.data.contactTwo.lName") &&  !!getIn(errors,"user_references.data.contactTwo.lName")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"user_references.data.contactTwo.organisation"}
                                                    onChange={handleChange}
                                                    value = {values.user_references.data.contactTwo.organisation}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Relationship"
                                                    isInvalid = {getIn(touched,"user_references.data.contactTwo.organisation") && !!getIn(errors,"user_references.data.contactTwo.organisation")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"user_references.data.contactTwo.relationship"}
                                                    onChange={handleChange}
                                                    value = {values.user_references.data.contactTwo.relationship}
                                                    type="text"
                                                    placeholder="Organisation"
                                                    onBlur={handleBlur}
                                                    isInvalid = {getIn(touched,"user_references.data.contactTwo.relationship") && !!getIn(errors,"user_references.data.contactTwo.relationship")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                {this.state.overseas_reference_two ?
                                                    <Input
                                                        controlId={"email"}
                                                        name={"user_references.data.contactTwo.number"}
                                                        onChange={handleChange}
                                                        value = {values.user_references.data.contactTwo.number}
                                                        type="text"
                                                        placeholder="Email"
                                                        onBlur={handleBlur}
                                                        isInvalid = {getIn(touched,"user_references.data.contactTwo.number") && !!getIn(errors,"user_references.data.contactTwo.number")}
                                                        required
                                                    />
                                                    :
                                                            <Input
                                                                name={"user_references.data.contactTwo.number"}
                                                                controlId={"number"}
                                                                type={"number"}
                                                                onChange={handleChange}
                                                                value={values.user_references.data.contactTwo.number}
                                                                placeholder={"Phone Number"}
                                                                isInvalid = {getIn(touched,"user_references.data.contactTwo.number") && !!getIn(errors,"user_references.data.contactTwo.number")}
                                                                required
                                                                onBlur={handleBlur}
                                                                id={"number"}
                                                                error={getIn(errors,"user_references.data.contactTwo.number")}
                                                            />

                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={7} md={7}>
                                        <h3 className="sub-heading-text">Emergency Contacts</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={3}>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"emergency.data.contactOne.fName"}
                                                    onChange={handleChange}
                                                    value = {values.emergency.data.contactOne.fName}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="First Name"
                                                    isInvalid = {getIn(touched,"emergency.data.contactOne.fName") && !!getIn(errors,"emergency.data.contactOne.fName")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"emergency.data.contactOne.lName"}
                                                    onChange={handleChange}
                                                    value = {values.emergency.data.contactOne.lName}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Last Name"
                                                    isInvalid = {getIn(touched,"emergency.data.contactOne.lName") && !!getIn(errors,"emergency.data.contactOne.lName")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"emergency.data.contactOne.relationship"}
                                                    onChange={handleChange}
                                                    value = {values.emergency.data.contactOne.relationship}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Relationship"
                                                    isInvalid = {getIn(touched,"emergency.data.contactOne.relationship") && !!getIn(errors,"emergency.data.contactOne.relationship")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    name={"emergency.data.contactOne.number"}
                                                    controlId={"number"}
                                                    type={"number"}
                                                    onChange={handleChange}
                                                    value={values.emergency.data.contactOne.number}
                                                    placeholder={"Phone Number"}
                                                    isInvalid = {getIn(touched,"emergency.data.contactOne.number") && !!getIn(errors,"emergency.data.contactOne.number")}
                                                    required
                                                    onBlur={handleBlur}
                                                    id={"number"}
                                                    error={getIn(errors,"emergency.data.contactOne.number")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col sm={12} md={3}>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"emergency.data.contactTwo.fName"}
                                                    onChange={handleChange}
                                                    value = {values.emergency.data.contactTwo.fName}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="First Name"
                                                    isInvalid = {getIn(touched,"emergency.data.contactTwo.fName") && !!getIn(errors,"emergency.data.contactTwo.fName")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"emergency.data.contactTwo.lName"}
                                                    onChange={handleChange}
                                                    value = {values.emergency.data.contactTwo.lName}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Last Name"
                                                    isInvalid = {getIn(touched,"emergency.data.contactTwo.lName") && !!getIn(errors,"emergency.data.contactTwo.lName")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    controlId={"fName"}
                                                    className = {"text-capitalize"}
                                                    name={"emergency.data.contactTwo.relationship"}
                                                    onChange={handleChange}
                                                    value = {values.emergency.data.contactTwo.relationship}
                                                    type="text"
                                                    onBlur={handleBlur}
                                                    placeholder="Relationship"
                                                    isInvalid = {getIn(touched,"emergency.data.contactTwo.relationship") && !!getIn(errors,"emergency.data.contactTwo.relationship")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm ={12}>
                                                <Input
                                                    name={"emergency.data.contactTwo.number"}
                                                    controlId={"number"}
                                                    type={"number"}
                                                    onChange={handleChange}
                                                    value={values.emergency.data.contactTwo.number}
                                                    placeholder={"Phone Number"}
                                                    isInvalid = {getIn(touched,"emergency.data.contactTwo.number") && !!getIn(errors,"emergency.data.contactTwo.number")}
                                                    required
                                                    onBlur={handleBlur}
                                                    id={"number"}
                                                    error={getIn(errors,"emergency.data.contactTwo.number")}
                                                />

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    {Object.keys(errors).length > 0 ?
                                        <Col sm={12} md={3}>
                                            <Alert variant="danger">
                                                {"Errors in the form, please check over and try again"}!
                                            </Alert>
                                        </Col>
                                        :null
                                    }
                                </Row>
                                <Row style={{marginTop:'1rem'}}>
                                    <Col sm={12} md={3}>
                                        <LoaderButton
                                            className={"btn-block"}
                                            variant="dark"
                                            type="submit"
                                            disabled = {this.props.disabled}
                                            isLoading={this.state.loading}
                                            text={"NEXT"}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Row>
            )
        }else {
            return null
        }

    }
}


const ageChecker = (date,state = "no state") =>{
    if(date){
        if(date.length>9){
            let dateOneArray = date.split("/");
            let startDay = dateOneArray[0];
            let startMonth = dateOneArray[1];
            let startYear = dateOneArray[2];
            let date1 = new Date(startMonth +"/"+startDay+"/"+startYear);
            let date2=  new Date();
            let timeDiff = Math.abs(date2.getTime() - date1.getTime());
            let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            let years = parseInt(diffDays/365);
            if (state === "WA"){
                if(years < 18){
                    return true;
                }
            }else if (state === "QLD"){
                if(years < 16){
                    return true;
                }
            }else {
                if(years < 17){
                    return true;
                }
            }
        }else return false;
    }
    else return false;
}

export default connect(mapStateToProps,mapDispatchToProps)(PersonalForm);