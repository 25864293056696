
import PropTypes from 'prop-types'
import React, {Component} from "react";
import {connect} from "react-redux";

import {updateSize, removeFromCart} from "../../../../actions/checkout/CheckoutActions"

import CheckoutProductCard from "../../../../components/ordering/checkout/checkout-product-card"
const mapStateToProps = state => {
    return {
    stage: state.checkoutState.stage
    };
};

const mapDispatchToProps = dispatch =>{
    return {
        updateSize: (item,size) => {
            dispatch(updateSize(item,size))
        },
        removeFromCart: (sku) => {
            dispatch(removeFromCart(sku))
        }
    }
};
class CartItemView extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    };
    componentDidMount() {

    }

    handleSizeSelect = (size) => {
        this.props.updateSize(this.props.cart_item,size)
    }

    removeFromCart = (item) => {
        this.props.removeFromCart(item)
    }

    render() {
        let item = this.props.cart_item;
        return(
            <div className={"cart-item-view"}>
                <CheckoutProductCard disabled = {this.props.stage === 4} updateSize={this.handleSizeSelect} cart_item={item} removeFromCart={this.removeFromCart} />
            </div>
        )
    }

}

CartItemView.propTypes = {
    cart_item: PropTypes.object.isRequired
};

const cartItemView = connect(mapStateToProps, mapDispatchToProps)(CartItemView);
export default cartItemView;