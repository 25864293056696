// Icon
import React, {Component} from 'react'


class addIcon extends Component{
    render() {
        return (
            <svg id="621e3200-829d-438c-ab67-b145cd816c75" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><title>icons</title><path d="M40.57,45H14.26a4.39,4.39,0,0,1-4.44-4.33v-.1H9.43A4.39,4.39,0,0,1,5,36.24V9.33A4.39,4.39,0,0,1,9.43,5H36a4.39,4.39,0,0,1,4.44,4.33v.38h.1A4.39,4.39,0,0,1,45,14V40.67A4.39,4.39,0,0,1,40.57,45ZM38.25,9.33A2.19,2.19,0,0,0,36,7.17H9.43A2.19,2.19,0,0,0,7.22,9.33V36.24a2.19,2.19,0,0,0,2.21,2.17H36a2.19,2.19,0,0,0,2.22-2.17ZM42.78,14a2.19,2.19,0,0,0-2.21-2.16h-.1V36.24A4.39,4.39,0,0,1,36,40.57H12v.1a2.19,2.19,0,0,0,2.22,2.16H40.57a2.19,2.19,0,0,0,2.21-2.16ZM13.87,18H31.6v2.17H13.87Zm0-4.33H31.6v2.17H13.87Zm8.86,10.83H13.87V22.32h8.86Z"/></svg>        )
    }
};

export default addIcon