import React, { Component } from "react";
import { option, Row, Col} from "react-bootstrap";
import Select from '../../components/form/inputs/Select'
import Input from '../../components/form/inputs/Input'
import DateInput from '../../components/form/inputs/DateInput'
import TimeInput from '../../components/form/inputs/TimeInput'
import Checkbox from '../../components/form/inputs/Checkbox'
import {API} from "aws-amplify/lib/index";
import LoaderButton from "../../components/LoaderButton";
import moment from "moment/moment";
import {updateApplicant} from "../../actions/ApplicantActions";
import {connect} from "react-redux";


const mapDispatchToProps = dispatch =>{
    return {
        updateApplicant : userId => {dispatch(updateApplicant(userId))}
    }
}

class ApplicantChangeTrial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newTrial:false,
            selectedStore: null,
            stores_dropdown: [],
            trial_date: "",
            trial_time:"",
            isLoading:false
        };
    }

   async componentDidMount(){

        await this.setDropdowns();
    }



    validateForm() {
        const trialDate = this.state.trial_date;
        var date = moment(trialDate, 'DD/MM/YYYY',true);
        var today = moment(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1));
        var startDate = moment(new Date(today));
        var endDate   = moment(new Date(2023, 1, 15));

        if (date.isValid()) {
            if (date.isBetween(startDate, endDate)) {
                return true
            } else if (!date.isBetween(startDate, endDate)) {
                return false
            }
        } else if (trialDate.length > 3 && trialDate.length < 10) {
            return false
        } else return false


    }


        handleButtonChange = event => {
        let newTrial = this.state.newTrial;
        newTrial = !this.state.newTrial;
        this.setState({
            newTrial
        });
    };

    getValidationState(type) {
        if (type === "trial_date") {
            const trialDate = this.state.trial_date;
            var date = moment(trialDate, 'DD/MM/YYYY',true);
            var today = moment(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1));
            var startDate = moment(new Date(today));
            var endDate   = moment(new Date(2022, 1, 15));

            if (date.isValid()) {
                if (date.isBetween(startDate, endDate)) {
                    return 'success'
                } else if (!date.isBetween(startDate, endDate)) {
                    return 'error'
                }
            } else if (trialDate.length > 3 && trialDate.length < 10) {

                return 'error'
            } else return null
        }

    }
    setDropdowns = async() =>{
        API.get("users","stores/get/all").then(response => {
            let allowed_stores = [];
            let user_stores = this.props.user_stores;
            for (var i in user_stores) {
                allowed_stores.push(user_stores[i].store);
            }

            let store_dropdown = [];

            for (var j in response) {
                let storeId = response[j].value;
                if (allowed_stores.indexOf(storeId) !== -1) {
                    store_dropdown.push(response[j]);
                }
            }

            this.setState({stores_dropdown: store_dropdown});
        })
    };

    handleDateChange = (value) =>{
        let trial_date = this.state.trial_date;
        trial_date = value;
        this.setState({trial_date})
    }
    handleTimeChange = (value) => {
        let trial_time = this.state.trial_time;
        trial_time = value;
        this.setState({trial_time})
    }

    handleStoreChange = (e) => {
        let selectedStore = e
        this.setState({selectedStore})
    };

    getSingleStore=async(store) =>{
        return await API.get("users",`stores/get/${store}`)

    };
    updateStore = async(store) =>{
        await API.post("users",`stores/update/${store.storeId}`,{
            body:store
        }).then(response =>{
            console.log(response);
        })
    };
    getUserRole = username =>{
        return API.get("users", `users/roles/get/${username}`);
    };
    handleChangeTrial = async event =>{
        event.preventDefault();
        this.setState({isLoading:true})
        try{

            let userRole = await this.getUserRole(this.props.userId);

            if(this.props.applicant_status === "PENDING_OFFER" || this.props.applicant_status === "DID_NOT_FINISH_ONBOARDING"){
                userRole.applicant_status = userRole.trial_type
            }

            if(userRole.home_store !== this.state.selectedStore.value){
                this.getSingleStore(this.state.selectedStore.value).then(response =>{
                    let store = response;
                    let members = store.responsibilities[0];
                    if (this.props.user.role === "TEAM_MEMBER"){

                        members.TEAM_MEMBER.push(this.props.userId);

                    }
                    store.responsibilities[0] = members;

                    this.updateStore(store)

                });
                this.getSingleStore(userRole.home_store).then(response =>{
                    console.log(response);
                    let store = response;
                    let members = store.responsibilities[0];
                    if (this.props.user.role === "TEAM_MEMBER"){
                        console.log("deleting from ");
                        console.log(userRole.home_store);
                        var index = members.TEAM_MEMBER.indexOf(this.props.userId)
                        if (index !== -1) {
                            members.TEAM_MEMBER.splice(index, 1);
                            console.log("deleted")
                            console.log(members)
                        }
                        store.responsibilities[0] = members;
                        this.updateStore(store)
                    }

            });




        }
        userRole.trial_date = this.state.trial_date;
        userRole.home_store = this.state.selectedStore.value;
        userRole.trial_time = this.state.trial_time;
        userRole.stores[0].store = this.state.selectedStore.value;

        await API.put("users",`roles/update/${this.props.userId}`,{
            body: userRole
        }).then(response => {
            this.setState({isLoading:false});
            console.log(response)});
            this.props.updateApplicant(this.props.userId);
        } catch (e) {
            console.log(e);
        }


    };
    render(){

        return(
            <div>
                {this.state.newTrial

                ?
                    <form onSubmit={this.handleChangeTrial} className="form-container add-form">
                        <span style={{paddingBottom:'1%'}} className={"submenu-heading"}>Change Trial Details</span>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Select
                                    label = "Store"
                                    field="Select a Store"
                                    id="store"
                                    selectedOption={this.state.selectedStore}
                                    onChange={(e) => this.handleStoreChange(e)}
                                    options={this.state.stores_dropdown}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <DateInput
                                    required
                                    label = {this.props.user.trial_type === "INDUCTION" ? "Induction Date" :"Trial Date"}
                                    id="date"
                                    value={this.state.trial_date}
                                    onChange={(e) => this.handleDateChange( e.target.value)}
                                    onValidate={this.getValidationState("trial_date")}
                                />
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <TimeInput
                                    label = {this.props.user.trial_type === "INDUCTION" ? "Induction Time" :"Trial Time"}
                                    required
                                    value = {this.state.trial_time}
                                    onChange={(e,m) => this.handleTimeChange(m)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <LoaderButton
                                    className="offer-button "
                                    block
                                    bsSize="large"
                                    type="submit"
                                    disabled = {!this.validateForm()}
                                    isLoading={this.state.isLoading}
                                    text="UPDATE"
                                    loadingText="Rescheduling…"
                                />
                            </Col>
                        </Row>
                    </form>

                : <Row>
                        <Col sm={6} style={{float:'right',margin:'3%'}}>
                            <button className={"change-trial-button"} onClick={this.handleButtonChange}>
                                {this.props.user.trial_type === "INDUCTION" ? "Change Induction Details" :"Change Trial Details"}</button>
                        </Col>
                    </Row>}


            </div>
        )
    }
}

export default connect(null,mapDispatchToProps)(ApplicantChangeTrial)