import * as type from "../constants/actions/ActionTypes";
import * as lambda from "../actions/LambdaCalls";
import {s3CustomUpload, s3Upload} from "../libs/awsLib";
import * as user from "../actions/UsersActions";
import * as storeActions from "./StoreActions";
import {CHANGE_PERSONAL_DETAILS} from "../constants/actions/ActionTypes";
let JSZip = require('jszip');

/**
 * DISPATCH FUNCTIONS
 * */


export const loadPersonal = (personalInfo) => {
    return(
        {
            type: type.LOAD_PERSONAL,
            payload: personalInfo
        }
    )
};

export const loadRole = (roleDetails) => {
    return(
        {
            type: type.LOAD_ROLE_DETAILS,
            payload: roleDetails
        }
    )
};
export const loadBank = (bankDetails) => {
    return(
        {
            type: type.LOAD_BANK_DETAILS,
            payload: bankDetails
        }
    )
};

export const loadSuper = (superDetails) => {
    return(
        {
            type: type.LOAD_SUPER_DETAILS,
            payload: superDetails
        }
    )
};

export const loadWageInfo = (wageDetails) => {
    return (
        {
            type:type.LOAD_WAGE_DETAILS,
            payload : wageDetails
        }
    )
}

export const loadPersonalAttributes = (atts) => {
    return(
        {
            type: type.LOAD_PERSONAL_ATTRIBUTES,
            payload: atts
        }
    )
};
export const loadApprovalAttributes = (atts) => {
    return(
        {
            type: type.LOAD_APPROVAL_ATTRIBUTES,
            payload: atts
        }
    )
};

export const handleStage = (stage) => {

    return(
        {
            type: type.HANDLE_PAGE,
            payload: stage
        }
    )
};


export const handleAddress = (name,value) => {
    return(
        {
            type: type.CHANGE_ADDRESS,
            name:name,
            value:value
        }
    )
};
export const handleDOB = (name,value) => {
    return(
        {
            type: type.CHANGE_DOB,
            name:name,
            value:value
        }
    )
};

export const handleGender = (name,value) => {
    return(
        {
            type: type.CHANGE_GENDER,
            name:name,
            value:value
        }
    )
}

export const handleCitizenship = (name,value) => {
    return(
        {
            type: type.CHANGE_CITIZENSHIP,
            name:name,
            value:value
        }
    )
};

export const handleRoleDetails = (name,value) => {
    return (
        {
            type: type.CHANGE_ROLE_DETAILS,
            name: name,
            value: value
        }
    )
};



export const handleAvailability = (name,value) => {
    return (
        {
            type: type.CHANGE_AVAILABILITY,
            name: name,
            value: value
        }
    )
};

export const handleReferences = (name, value, field) => {
    return (
        {
            type: type.CHANGE_REFERENCES,
            name: name,
            value: value,
            field: field
        }
    )
};

export const handleEmergency = (name, value, field) => {
    return (
        {
            type: type.CHANGE_EMERGENCY,
            name: name,
            value: value,
            field: field
        }
    )
};

export const updateUser = (payload) => {
    return (
        {
            type: type.UPDATE_USER,
            value: payload
        }
    )
};

export const handleError = (error) => {
    return (
        {
            type:type.HANDLE_ERROR,
            value:error
        }
    )
};

export const handlePersonalDetailsChange = (value) => {
    return (
        {
            type: type.CHANGE_PERSONAL_DETAILS,
            value: value
        }
    )
};

export const handleBankDetailsChange = (value) => {
    return (
        {
            type: type.CHANGE_BANK_DETAILS,
            value: value
        }
    )
};

export const handleSuperDetailsChange = (superDetails) => {
    return (
        {
            type: type.CHANGE_SUPER_DETAILS,
            value: superDetails
        }
    )
};

export const handleWageDetails = (name,value) => {
    return (
        {
            type: type.CHANGE_WAGE_DETAILS,
            object: ['sup_fund','data'],
            name: name,
            value: value
        }
    )
};


export const handleGlobalChange = (path,name,value) => {
    return (
        {
            type: type.CHANGE_FORM_DETAILS,
            path: path,
            name: name,
            value: value
        }
    )
};

export const handleFocusInput = (path,name) => {
    return (
        {
            type:type.SET_FOCUSED_INPUT,
            path: path,
            name:name,
        }
    )
};




/**
 * GET FETCH LOGIC FUNCTIONS
 * */

export const getPersonalInfo = (userId) => {
    return (dispatch) => {
        lambda.getUserInfo(userId).then(async function(personalInfo){

            dispatch(loadPersonal(personalInfo))
        })
    }
};

export const getRoleInfo = (userId) => {
    return (dispatch) => {
        lambda.getUserRole(userId).then(async function(roleInfo){
            let stage = roleInfo.applicant_status;
            if(stage === "STAGE_1_ONBOARDING"){
                dispatch(handleStage(1))
            }else if(stage === "STAGE_2_ONBOARDING"){
                dispatch(handleStage(2))
            }else if(stage === "STAGE_3_ONBOARDING"){
                dispatch(handleStage(3))
            }else  dispatch(handleStage(4));

            dispatch(loadRole(roleInfo))
        })
    }
};


export const getBankInfo = (userId) => {
    return (dispatch) => {
        lambda.getBank(userId).then(async function(bankInfo){
            dispatch(loadBank(bankInfo))
        })
    }
};

export const getSuperInfo = (userId) => {
    return (dispatch) => {
        lambda.getSuperInfo(userId).then(async function (superInfo){
            dispatch(loadSuper(superInfo))
        })
    }
};

export const getWageInfo = (userId) => {
    return (dispatch) => {
        lambda.getWageInfo(userId).then(async function (wageInfo){
            dispatch(loadWageInfo(wageInfo))
        })
    }
};


export const getApprovalAtts = (userId) => {
  return (dispatch) => {
      lambda.getApprovalAttributes(userId).then(async function (atts){
          dispatch(loadApprovalAttributes(atts))
      })
  }
};

export const getPersonalAtts = (userId) => {

    return (dispatch) => {
        lambda.getPersonalAttributes(userId).then(async function (atts){
            dispatch(loadPersonalAttributes(atts))
        })
    }
};

export const handlePersonalAttributes = (userId) => {

};


export const updateApplicantStatus = (userId) =>{



};

export const submitChanges = (type, data) => {
    return async (dispatch) => {
        let response = await lambda.updateUser(type,data).catch(e=>{dispatch(handleError(true))});
            if(response.status === false){
                dispatch(handleError(true))
            } else {
                //dispatch(handleError(false))
                console.log('backend updated')}

    }
};


export const uploadParentalConsent = async (files, userId,cogId) => {
        var zip = new JSZip();
        let attachmentPC;
        let uploadPC = {
            filename: attachmentPC ? attachmentPC : null,
            attachment:attachmentPC ? attachmentPC : null,
            attribute_type:"APP_PC",
            file_status: "pending",
            type: "PC",
            userId: userId
        };
        if(files.length > 1){
            for (let i in files){
                zip.file(files[i].name,files[i],{base64: true})
            }
            zip.generateAsync({type:"blob"}).then(async function(content) {
                attachmentPC = new Date()+"-PC.zip";
                uploadPC.filename = attachmentPC;
                uploadPC.attachment = attachmentPC;
                await s3CustomUpload(content,attachmentPC,cogId);
                await lambda.uploadFile(uploadPC);
            })
        }
        //single
        else if(files.length){
            //result is returned from the upload to ensure both database and s3 buckets have the same filename as they are timestamps.
            let result = await s3CustomUpload(files[0],null,cogId);
            attachmentPC = result.fileName;
            uploadPC.filename = attachmentPC;
            uploadPC.attachment = attachmentPC;
           await lambda.uploadFile(uploadPC);
        }
        //runs if no file is attached so that we can set attribute to false and give user a task.
        else{
            await lambda.uploadFile(uploadPC);
        }
};
/**
 *
 * @param cognitoParams
 * @param role
 * @param stores stores is a singular store object or an array of store objects
 * @returns {function(*): *}
 */
export const createUser = (cognitoParams, role, stores) => {

    return dispatch => {
        return dispatch(user.createCognitoUser(cognitoParams)).then(response =>{
            console.log(response);
            let userId = response.User.Username;
            return dispatch(storeActions.updateStoreMembers(stores,userId,role));
        })
    }
};