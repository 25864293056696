import React, { Component } from "react";
import Input from "../../components/form/inputs/Input";
import {getFulfillerData,getCCShipments,updateShipment} from "../../actions/ShippingActions";
import {errorNotification,removeNotification} from "../../actions/NotificationActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import TillLogin from "../../components/login/staff-number-login"
import LoaderButton from "../../components/LoaderButton";
import RealTimeAgent from "../../components/mqtt/MqttAgent";

const mapStateToProps = state => {
    return {
        user: state.authState, loading: state.shippingState.isLoading, fulfiller: state.shippingState.fulfiller,
        shipments: state.shippingState.shipments,
        auspost: state.shippingState.auspost, loggedIn: state.shippingState.loggedIn,
        errors: state.notificationState.errors
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getFulfillerData: (futuraId) => {
            dispatch(getFulfillerData(futuraId))
        },
        getCCShipments: ()=>{
            dispatch(getCCShipments())
        },
        updateShipment: (shipmentId,status,reason,collection) => {
            dispatch(updateShipment(shipmentId,status,reason,collection));
        },
        errorNotification : (message) => {
            dispatch(errorNotification(message));
        },
        removeNotification : (message) => {
            dispatch(removeNotification(message));
        }
    }
};

class Collect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fulfiller: '',
            loggedIn: false,
            disabledManifest:false,
            labels : 0,
            barcodeOne: '',
            barcodes: [],
            bags: 1,
            inputs: [],
            selectedShipment:null,
            notFound : false
        };
    }

    componentDidMount() {
        this.props.getCCShipments();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.notFound){
            let errors = this.props.errors;
            if(!errors.includes('Shipment Not Found')){
                //this.createNotification(this.props.personalDetails.userId,"error",err)
                this.props.errorNotification('Shipment Not Found');
            }
        }else if(prevState.notFound && this.state.notFound === false) {
            let errors = this.props.errors;
            this.props.removeNotification('Shipment Not Found');
        }
    }

    createInputs = (bags) =>{
        let inputs= [];
        for (let i = 0; i < bags; i++) {
            inputs.push(
                <Input id={"barcode-"+i}
                       placeholder={"SCAN OR TYPE IN BARCODE FROM SHOP BAG "+(i+1)}
                       onChange={(e)=>this.handleScan(e.target.value,i)}
                       required={true}
                       value = {this.state.barcodes[i]}
                />
            );
        }
        this.setState({inputs});
    };

    handleLogin= (fulfiller) =>{
        event.preventDefault();
        this.props.getFulfillerData(fulfiller);
        this.setState({loggedIn:true})
    };

    handleScan = (scan,type) => {
        let parent = this;
        console.log(scan,type);
        if (type === "barcode-one") {
            this.setState({barcodeOne: scan});
            let barcode = scan.split("-");
            if (barcode.length > 0) {
                if(barcode[0].length >5 && Array.isArray(barcode) ){
                    let shipmentId = barcode[0];
                    this.props.shipments.filter(s=>{
                        if(s.shipmentId === shipmentId) {
                            if(s.bagCount){
                                parent.setState({bags:s.bagCount});
                                parent.createInputs(s.bagCount);
                                parent.setState({selectedShipment:s});
                                //TODO revisit error
                                //parent.setState({notFound:false,selectedShipment:s});

                            }
                        }else {
                            //parent.setState({selectedShipment:null});
                            //parent.setState({notFound:true,selectedShipment:null});
                        }
                        if(barcode[1] !== "1" && parseInt(barcode[1])){
                            let barcodes = parent.state.barcodes;
                            barcodes[parseInt(barcode[1])-1] = scan;
                            parent.setState({barcodes});
                            parent.setState({barcodeOne: ''});
                            console.log(barcodes)
                        }
                    })
                }
            }
        }else {
            let barcodes = this.state.barcodes;
            barcodes[type] = scan;
            this.setState({barcodes});
        }
    };

    resetScan=(event)=>{
        event.preventDefault();
        this.setState({barcodeOne:'',barcodes:[],inputs:[],selectedShipment:null})
    };

    validateBarcode = () => {
        let one = this.state.barcodeOne;
        let barcodes = this.state.barcodes;
        let bags = this.state.bags;
        let validated = 0;
        one = one.split("-");
        for(let b of barcodes){
            if(b){
                let scanned = b.split("-");
                if(scanned[0].length > 5){
                    if(scanned[0] === one[0]){
                        if(scanned[1] !== one[1]){
                            if(parseInt(scanned[1]) <= bags+1){
                                validated ++;
                            }
                        }

                    }
                }
            }
        }
        return validated !== bags;
    };

    fulfillShipment=(event) => {
        event.preventDefault();
        let barcode = this.state.barcodeOne;
        barcode = barcode.split("-");
        this.props.updateShipment(barcode[0],"COMPLETE",false,true)
    };

    render(){
        if(this.props.loggedIn){
            return (
                <div className={"wrapper"}>
                    <div className={"heading"}>
                        <div className={"left-heading"}>
                            <h1 className={"heading-text"}>Your Click and Collect shipments</h1>
                            {this.props.loading === true ?
                                <h2 className={"small-heading-text"}>
                                    Hey {this.props.fulfiller.fName}! hang tight while we load your click & collect shipments.
                                </h2>
                                :
                                <div>
                                    {
                                        this.props.auspost ?
                                            <h2 className={"medium-heading-text"}>
                                                Scan the barcode on the customers confirmation email and shop bag and confirm customer has collected the order.
                                            </h2>
                                            :null
                                    }
                                </div>
                            }
                        </div>

                    </div>
                    <Row style={{alignItems:"center",display:"flex",flexDirection:"column"}}>
                        <Col sm={4}>
                            <Input id={"barcode-one"}
                                   placeholder={"SCAN OR TYPE IN BARCODE FROM CUSTOMERS EMAIL/SMS"}
                                   onChange={(e)=>this.handleScan(e.target.value,'barcode-one')}
                                   required={true}
                                   value = {this.state.barcodeOne}
                                   />
                        </Col>
                        <Col sm={4}>
                            {this.state.inputs}
                        </Col>
                    </Row>
                    <Row style={{justifyContent:"center",display:"flex", paddingTop:'1rem'}}>
                        <Col sm={4}>
                            <form onSubmit={this.fulfillShipment} >
                                <LoaderButton
                                    type="submit"
                                    isLoading={this.props.loading}
                                    disabled={this.validateBarcode() !== false}
                                    text={"CONFIRM CUSTOMER COLLECTED"}>
                                </LoaderButton>
                            </form>
                            <p>
                                Clicking confirm sends the customer an email confirmation.
                            </p>
                            <a onClick={this.resetScan} >
                                RESET
                            </a>
                        </Col>
                    </Row>
                    <Row style={{justifyContent:"center",display:"flex"}}>
                        <Col sm={6}>
                            {this.state.selectedShipment ?
                                <p style={{fontWeight:'700'}}>Items in order:</p>
                                :null}
                            {this.state.selectedShipment ?
                                this.state.selectedShipment.items.map((item,i)=>
                                    item.availability === true ?
                                    <p>{item.product_name} {" ("+item.size + ")"}</p>
                                        : null)
                                :null}
                        </Col>
                    </Row>
                </div>
            )

        }else {
            return (
                <div className={"wrapper shipping-wrapper"}>
                    <Row style={{marginBottom:'10px'}}>
                        <Col sm={12}>
                            <span style={{fontSize:'18px',fontWeight:'700',textTransform: 'uppercase'}}>
                                Login in to view todays collections
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <TillLogin handleLogin={this.handleLogin}/>
                    </Row>
                    <RealTimeAgent />
                </div>
            )
        }

    }


}

const collect = connect(mapStateToProps, mapDispatchToProps)(Collect);
export default withRouter(collect);