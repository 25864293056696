import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import {Row,Col, option} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import Checkbox from "../../components/form/inputs/Checkbox"
import {updateApplicant} from "../../actions/ApplicantActions";
import {connect} from "react-redux";


const mapDispatchToProps = dispatch =>{
    return {
        updateApplicant : userId => {dispatch(updateApplicant(userId))}
    }
}

class ApplicantUnsuccessfulTrial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // keyProp: this.props.keyProp,
            userId: this.props.userId,
            removals: {
                removeWfs: false,
                removeMyob: false
            },
            isLoading: false,
            termination_reason: ""
        };
    }

    async componentDidMount(){
       await this.getTerminationReason()
    }
    handleWfsChange = event => {
        let removals = this.state.removals;
        removals.removeWfs = !this.state.removals.removeWfs;

        this.setState({
            removals
        });
    };

    handleMyobChange = event => {
        let removals = this.state.removals;
        removals.removeMyob = !this.state.removals.removeMyob;

        this.setState({
            removals
        });
    };
    adminCreateUser= async (params) =>{
        return await API.post("users","users/admin/create",{body:params})

    };

    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });
        // Adding store to the dynamoDB
        try{
            await API.post("users", "users/applicants/status/update", {
                body: {
                    userId: this.props.userId,
                    applicant_status: "TERMINATED",
                    store:this.props.storeId
                }
            });
            var params = {
                Username: this.props.userId,
                type: "disable"
            };
            this.adminCreateUser(params).then(response =>{
                console.log(response)
            });
            this.setState({isLoading:false});
            this.props.updateApplicant(this.props.userId)
        } catch (e) {
            console.log(e);
        }
        this.setState({ isLoading: false });
    };

    // form validation, checks storeId is not empty
    validateForm() {
        return (
            this.state.removals.removeWfs !== false &&
            this.state.removals.removeMyob !== false
        );
    }

    getTerminationReason= async() =>{
        let role = await API.get("users", `users/roles/get/${this.props.userId}`);
        if(role.termination_reason.comment){
            this.setState({termination_reason:role.termination_reason})
        }else  this.setState({termination_reason:role.termination_reason})


    };

    render() {
        return (
            <Row className={"submenu"}>
                <Row>
                    <span className = {"submenu-heading"}> Termination Reason</span>
                    <span style={{float:"left",textAlign:"left",marginBottom:"10px",marginTop:"5px"}}>{this.state.termination_reason.reason}<br/> {this.state.termination_reason.comment}</span>
                </Row>
                <Row>
                   <div className={"submenu-heading"}>Confirm you have removed the user from the following:</div>
                   <form className={"pending-form"} onSubmit={this.handleSubmit}>
                       <Row>
                           <Col sm={4}>
                               <Checkbox
                                   id={"wfs-remove"}
                                   key={"removeWfs"}
                                   checked={this.state.removals.removeWfs}
                                   onChange={this.handleWfsChange}
                                   text={"WFS"}
                               />
                           </Col>
                       </Row>
                       <Row>
                           <Col sm={4}>
                               <Checkbox
                                   id={"myobremove"}
                                   key={"removeMyob"}
                                   checked={this.state.removals.removeMyob}
                                   onChange={this.handleMyobChange}
                                   text={"MYOB"}
                               />
                           </Col>
                       </Row>
                       <Row>
                           <Col sm={3}>
                               <LoaderButton
                                   block
                                   bsSize="large"
                                   disabled={!this.validateForm()}
                                   type="submit"
                                   isLoading={this.state.isLoading}
                                   className={"btn-block offer-button terminate-button"}
                                   text="Terminate User"
                                   loadingText="Terminating…"
                               />
                           </Col>
                       </Row>
                   </form>
                </Row>
            </Row>
        )
    }
}
export default connect(null,mapDispatchToProps)(ApplicantUnsuccessfulTrial)