import React, { Component } from 'react'
import { Grid, Menu, Segment } from 'semantic-ui-react'
import PropTypes from "prop-types";
import DateOfBirth from "../../components/form/DateOfBirth";
import './AdminMenu.css'

export default class MenuOnLeft extends Component {
    constructor(props) {
        super(props);
        this.handleItemClick = this.props.handleItemClick;
        this.state = { activeItem: 'userManagement' };
    }

    handleCSS = (id) => {
        this.setState({ activeItem: id })
    };

    render() {
        return (
            <Grid>
                <Grid.Column>
                    <Menu className="side-menu admin" fluid vertical tabular>
                        <Menu.Item name='User management'
                                   id='userManagement'
                                   active={this.state.activeItem === 'userManagement'}
                                   onClick={e=>{this.handleItemClick(e.target.id);
                                   this.handleCSS(e.target.id)}
                                   } />
                        <Menu.Item name='Store Management'
                                   id ='storeManagement'
                                   active={this.state.activeItem === 'storeManagement'}
                                   onClick={e=>{this.handleItemClick(e.target.id);
                                    this.handleCSS(e.target.id)}
                                    } />
                        <Menu.Item name='Role Management'
                                   id ='roleManagement'
                                   active={this.state.activeItem === 'roleManagement'}
                                   onClick={e=>{this.handleItemClick(e.target.id);
                                       this.handleCSS(e.target.id)}
                                   } />
                        <Menu.Item name='Payroll Management'
                                   id ='payrollManagement'
                                   active={this.state.activeItem === 'payrollManagement'}
                                   onClick={e=>{this.handleItemClick(e.target.id);
                                       this.handleCSS(e.target.id)}
                                   } />
                    </Menu>
                </Grid.Column>
            </Grid>
        )
    }
}
MenuOnLeft.propTypes = {
    /** HandleChange is the parent logic to handle input and what to do with the input in terms of storing the data */
    handleItemClick: PropTypes.func.isRequired,
    /** if data is preloaded from backend pass in the following props with the corresponding data*/
};
