import * as type from "../constants/actions/ActionTypes";
import * as _ from "lodash";

const initialState = {
    reload: true, // this will be false if a new store has been added to the state or if any changes have been made to the state, fixes re renders
    loading:false,

    personalDetails:  {
        userId:"",

        email:{
            data:"",
            p_type: "2"
        },
        fName: {
            data:"",
            p_type: "2"
        },
        lName: {
            data:"",
            p_type: "2"
        },
        number: {
            data:"",
            p_type: "2"
        },

        address:{
            data: {
                street_one:"",
                street_two:"",
                suburb:"",
                state:""
            },
            p_type: "1"
        },

        gender: {
            data:"",
            p_type: "2"
        },

        dob: {
            data:"",
            p_type: "1"
        },

        citizenship:{
            data:"",
            p_type: "1"
        },

        workrights:{
            data:"",
            p_type: "1"
        },

        availability:{
            data:{
                sunday: [{start:"12:00am",end:"12:00am"}],
                monday: [{start:"12:00am",end:"12:00am"}],
                tuesday: [{start:"12:00am",end:"12:00am"}],
                wednesday: [{start:"12:00am",end:"12:00am"}],
                thursday: [{start:"12:00am",end:"12:00am"}],
                friday: [{start:"12:00am",end:"12:00am"}],
                saturday: [{start:"12:00am",end:"12:00am"}]
            },
            p_type: "1"
        },
        availability_notes:{
            data:"",
            p_type: "1"
        },
        emergency:{
            data:{
                contactOne:{
                    fName: '',
                    lName: '',
                    relationship: '',
                    number: ''
                },
                contactTwo: {
                    fName: '',
                    lName: '',
                    relationship: '',
                    number: ''
                }
            },
            p_type: "2"
        },
        user_references:{
            data:{
                contactOne:{
                    fName: '',
                    lName: '',
                    relationship: '',
                    organisation: '',
                    number: ''
                },
                contactTwo: {
                    fName: '',
                    lName: '',
                    relationship: '',
                    organisation: '',
                    number: ''
                }
            },
            p_type: "2"
        }
    },
    bankingDetails: {
        userId: "",
        tfn: {
            data:"",
            p_type:"0"
        },
        tax_res:{
            data:"",
            p_type:"0"
        },
        tax_free:{
            data:"",
            p_type:"0"
        },
        tax_name:{
            data:"",
            p_type:"0"
        },
        help_loan:{
            data:"",
            p_type:"0"
        },
        fin_debt:{
            data:"",
            p_type:"0"
        },
        tfn_dec:{
            data:'',
            p_type: "0"
        },
        bank_name:{
            data:"",
            p_type:"0"
        },
        account_name:{
            data:"",
            p_type:"0"
        },
        bsb:{
            data:"",
            p_type:"0"
        },
        account_number:{
            data:"",
            p_type:"0"
        }
    },
    superDetails:{
        userId:"",
        existing_fund:{
            data:false,
            p_type:"0"
        },
        sup_fund:{
            data:"",
            p_type:"0"
        },
        account_name:{
            data:"",
            p_type:"0"
        },
        account_number:{
            data:"",
            p_type:"0"
        }
    },
    roleDetails:{
        userId: "na",
        futuraId: "na",

        role:"na",

        home_store:"na",

        stores: [{
            role: "na",
            store: "na"
        }],
        trial_type: "na",
        trial_date: "na",

        trial_time: "na",

        start_date:"na",

        end_date:"na",

        second_start_date:"na",

        termination_reason: "na",

        references: "na",

        ten_hour_rule:false,

        christmas_casual: false,

    },
    wageDetails:{
        userId:"na",
        employment_classification:"C REL1",
        wage:"na",
        pay_type:"na"
    },
    personalAttributes: {
        userId: "na",
        tfn: "na",
        pc: "na",
        offer:"na"

    },
    approvalAttributes:{
        userId:"na",
        hr_pc:"na",
        hr_tfn:"na",
        hr_vevo:"na",
        hr_removal:"na",
        man_pendingtrial:"na",
        hr_idcheck:"na",
        hr_resume:"na",
        pc:"na",
        tfn:"na"
    },
    error: false,
    stage: 0,
    focusedPath: []
};

export default function onboardingReducer (state = initialState , action) {
    switch (action.type) {
        case type.CREATE_USER:
            return null;
        case type.HANDLE_ERROR:
            return {...state,error:action.value};

        case type.HANDLE_PAGE:
            return {...state,stage:action.payload};
        /**PERSONAL DETAILS
         *
         * */
        case type.LOAD_PERSONAL:
            return {...state, personalDetails:action.payload};

        /**ROLE DETAILS
         *
         * */
        case type.LOAD_ROLE_DETAILS:
            return { ...state,roleDetails: {...action.payload}};

        case type.CHANGE_ROLE_DETAILS:
            let newRole = {...state.roleDetails};
            if(action.name === "stores"){
                let stores = {...newRole.stores};
                stores.concat(action.value);
                return {...state, roleDetails : {...state.roleDetails,stores: {stores}}}
            }else if (action.name === "references"){
                //TODO refernces logic
                //let references = {...newRole.user_references}
            }else {
                newRole[action.name] = action.value;
                return {...state, roleDetails: {...newRole}}
            }

        case type.LOAD_WAGE_DETAILS:
            return {...state,wageDetails: {...action.payload}};

        case type.CHANGE_WAGE_DETAILS:
            return {...state,wageDetails: {...action.value}};


        case type.LOAD_APPROVAL_ATTRIBUTES:
            return { ...state,approvalAttributes: {...action.payload}};

        case type.LOAD_PERSONAL_ATTRIBUTES:
            return { ...state,personalAttributes: {...action.payload}};


        case type.CHANGE_FORM_DETAILS:
            let tableName = state.focusedPath[0];

            if(tableName){
                let details = {...state[tableName]};
                let path = [...state.focusedPath];
                let test = setRealvalue(details,path,action.name, action.value);
                //////console.log(test);
                return {...state, [tableName] : test};
            }else {
                return state
            }


        case type.SET_FOCUSED_INPUT:
            let object = action.path[0];
            if(object){
                let details = {...state[object]};
                let path = setValue(details,action.path,action.name);
                return {...state, focusedPath : path}
            }else {
                return state
            }



        /**BANK DETAILS
         *
         * */

        case type.LOAD_BANK_DETAILS:
            return {...state, bankingDetails:{...action.payload}};

        case type.CHANGE_BANK_DETAILS:
            return {...state,bankingDetails: {...action.value}};

        case type.CHANGE_PERSONAL_DETAILS:
            return {...state,personalDetails: {...action.value}};
        case type.CHANGE_SUPER_DETAILS:
            return {...state,superDetails: {...action.value}};

        /** SUPER DETAILS
         *
         *
         */

        case type.LOAD_SUPER_DETAILS:
            return {...state,superDetails:{...action.payload}};
        default:
            return state;

    }
};

/**
 * a nice little function that helps update the store data dynamically.
 * @param nestedObj is the state object that will be updated,
 * @param pathArr - contains an array that leads us to the key to be updated
 * @param name - name of the key to be updated (optional) if null the last string in the path array will be updated
 * @param value - value to be updated with
 * @param force -  if you'd like to update even if the key does not exist, forcing will create that key.
 * @returns {*}
 */

const setRealvalue = (nestedObj, pathArr, name, value, force = false) =>{
    let path = pathArr.slice(0);
    path.shift();

    //include the field name into the path if it already doesnt include it.
    if(!path.includes(name)){
        path.push(name);
    }
    assign(nestedObj, path, value);
    return nestedObj;
}

const setValue = (nestedObj, pathArr, name) => {
    let finalPath = [];

    var testpath = pathArr.slice(0);
    var copyOfPathArr = pathArr.slice(0);
    let result = [];

    /*if given path array is only lenght of 1, meaning table name it should be a root object, so we can check
    * and run this, to assign the focused path without searching for the key*/

    if(pathArr.length < 2 && nestedObj.hasOwnProperty(name)){
        finalPath = pathArr.slice(0);
        //console.log('key is property of root object');
        finalPath.push(name);
        let temp = nestedObj[name];
        if(!isPrimitive(temp)){
            //console.log('key is an object, should be including data');
            finalPath.push('data')
        }
        return finalPath;

    }else if(pathArr.includes('data')){
        //console.log('path already has data inside of it, no need to look for it')
        finalPath = pathArr;
        return pathArr;
    }else {
        testpath.shift();
        let newPath = [];
        let level =0;
        testpath.reduce((obj,key) => {
            //console.log('---------object we are looking at, and its key---------')
            //console.log(obj,key);
            if(obj !== 'undefined' && obj[key] !== 'undefined') {
                newPath.push(key);
                //console.log(newPath);
                level ++;
                if(level === testpath.length){
                    //console.log('---------at the end of the path and have ended at this object ---------');
                    let temp = obj[key];
                    //console.log(temp)
                    //if the object contains the field we want to edit, or the key in path = name of field, assign the final path with the following logic
                    if(temp.hasOwnProperty(name) || key === name){
                        //console.log('given path already had key');
                        if(isPrimitive(temp)){
                            finalPath = pathArr;
                        }else {
                            //console.log('key is an object, should be including data');
                            finalPath = pathArr;
                            finalPath.push('data')
                        }
                        return finalPath;
                        // if path does not have the field we want to edit in the path name, check if the last object given in path has data, if not add data to path.
                    }else if(temp.hasOwnProperty('p_type') && !temp.hasOwnProperty('data') ){
                        //console.log('---------path found has p_type but no data, meaning newly initialized user, need to add data---------')
                        finalPath = pathArr.slice(0);
                        newPath.push('data');
                        finalPath.push('data');
                        return finalPath;
                    }
                    // last part of key is an object, we should search this object to find the correct field name, and append the path to full path
                    else{
                        //console.log('---------need to search deeper to make sure correct path to field: '+name+'is found---------');
                        //console.log('looking for field : '+name, 'in object: '+JSON.stringify(temp), 'starting in key: '+key);
                        let path = searchForKey(temp, result, name, key,copyOfPathArr);
                        if(path.length> 0){
                            finalPath = path;
                        }else {
                           // console.log('-------- must be a new field, didnt find it, might have to create it---------');
                            pathArr.push('data');
                           // console.log(pathArr);
                            finalPath = pathArr;
                        }
                        return finalPath;
                    }
                }else {
                    return obj[key];
                }
            }
        },nestedObj);

        if (finalPath.length <1){
            //console.log('there was no object to create data in, should create one ourselves');

            finalPath = pathArr;
            finalPath.push(name);
            finalPath.push('data');
        }
    return finalPath;

    }
    ////console.log('------------------------------------------');
    ////console.log('seraching for key: '+name);
    //let t0 = performance.now();
     //let path = searchForKey(nestedObj, result, name, testpath[0]);
    // let t1 = performance.now();
    ////console.log("Call to find key " + (t1 - t0) + " milliseconds.");



};

function searchForKey(object, result = [], key, testKey, path =[]){
    if(!path.includes(testKey)){
        path.push(testKey)
    }
    //console.log('recursive call made')
    if(typeof object === "object" && object !== null && object !== undefined) {
        for (let i = 0; i < Object.keys(object).length; i++) {
            if (Object.keys(object)[i] === key) {
               // console.log('key found in: '+testKey)
               // console.log(path);
                result = path;
                return result;
            }
            else if (typeof object[Object.keys(object)[i]] === "object") {
                result = searchForKey(object[Object.keys(object)[i]], result, key, Object.keys(object)[i], path);
                if (result.length > 0) {
                    return result;
                }
            }
        }

    }

    path.pop();
    return result;
}



function isPrimitive (val) {
    // typeof null returns object but for this null is a primitive
    return val === null || ["object","function"].indexOf(typeof val) === -1;
}


function createObject(obj,keyPath){
    let lastKeyIndex = keyPath.length-1;
    for (var i = 0; i < lastKeyIndex; ++ i) {
        //console.log('---object to assign to-----')
        //console.log(obj);
        let key = keyPath[i];
        //console.log(key);
        //console.log(obj);
        if (!(key in obj)){
            //console.log(' we going to initialise p_type and data here');
            obj[key] = {'data': {}, 'p_type': 1}
        }
        obj = obj[key];
    }
}

function assign(obj, keyPath, value) {
    let lastKeyIndex = keyPath.length-1;
    for (var i = 0; i < lastKeyIndex; ++ i) {
        //console.log('---object to assign to-----')
        //console.log(obj);
        let key = keyPath[i];
        //console.log(key);
        //console.log(obj);
        if (!(key in obj)){
            //console.log('this key not in object, so we gonna add it: '+key);
            //console.log(' we also going to initialise p_type and data here')
            obj[key] = {}
        }

        obj = obj[key];
    }
    obj[keyPath[lastKeyIndex]] = value;
}

function assignKey(obj, path, name, value){
    let parentIndex = path.length - 1;
    let lastIndex = path.length;
    //console.log(path)
    for (let i =0; i<parentIndex ; ++i)
    {
        //console.log('---object to assign to-----');
        //console.log(obj);
        let keyy = path[i];
        //console.log('---key-----');
        //console.log(keyy);
        if (!(keyy in obj)){
            //console.log('this key not in object, so we gonna add it as a empty');
            //console.log(keyy)
            obj[keyy]={}

        }
        obj = obj[keyy];
    }

    let temp = obj[path[lastIndex]];
    //console.log(temp);
    /*
    if(isPrimitive(temp)){
        //console.log('------object to modify is a primitive with no permissions------');
        obj[key] = value;
        //console.log('updated key: '+key+' with value: '+value);
        return value;

    }else if (key === "data"){
        //console.log('------path included data so updated this------');
        temp[name]=value;
        obj[key]=temp;
        //console.log('updated objects field: '+name+' with value: '+value);
        return temp
    } else if (temp.hasOwnProperty('data')){
        temp['data'] = value;
        obj[key]=temp;
        //console.log('updated data field with value: '+value);
    }else if (!temp.hasOwnProperty('data') && temp.hasOwnProperty('p_type') ){
        temp['data'] = value;
        obj[key]=temp;
        //console.log('updated data field with value: '+value);
    }

    else {
        temp[name]=value;
        obj[key]=temp;
        //console.log('updated objects field: '+name+' with value: '+value);
        return temp;
    }
    */

}


