import * as type from "../constants/actions/FeedActionTypes";

const initialState = {
    messages: [],
    campaigns:[]
};
export default function FeedReducer (state = initialState , action)  {
    switch (action.type) {
        case type.GET_MESSAGE:
            return{...state,messages:action.messages};
        case type.GET_CAMPAIGN:
                return{...state,campaigns:action.campaign};
        default:
            return state;
    }
}