import React, { Component } from "react";
import { updateCognitoAttributes,initialiseUserRole,verifyUsernameUpdate} from "../../actions/login/LoginActions"
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import UpdateForm from "../../components/login/user-update-details"
import ConfirmEmailForm from "../../components/login/user-confirm-email"
import {updateRoleStatus} from "../../actions/AuthActions";
const applicants = ["TEAM_MEMBER","DC_TEAM_MEMBER"];
const mapStateToProps = state => {
    return {
        user: state.authState,status:
        state.authState.roleStatus,
        role: state.authState.role,
        roleStatus: state.authState.roleStatus,
        cogInfo : state.authState.cognitoInfo,
        verified:state.authState.verified,
        cognitoInfo : state.authState.cognitoInfo
    };
};

const mapDispatchToProps = dispatch =>{
    return {
        updateRoleStatus:(status) => {
            dispatch(updateRoleStatus(status))
        }
    }
}

class Welcome extends Component {
    constructor(props){
        super(props);
        this.applicants = ["TEAM_MEMBER","DC_TEAM_MEMBER"];
        this.state = {
            confirmation : false,
            isLoading : false,
            submitted:false,
            confirmation_needed: false,
            cognito_details: null,
            confirm_error:false
        }
    }

    loadPropsToState = () =>{
        let info = this.props.cogInfo;
        this.setState({email:info.email})
    };

    handleSubmit = async (user_attributes,updated_username) => {
        console.log(user_attributes);
        if(updated_username){
            this.setState({confirmation_needed:true})
            await updateCognitoAttributes(user_attributes,updated_username)
        }else{
            await updateCognitoAttributes(user_attributes)
            if(applicants.includes(this.props.role)){
                await this.props.updateRoleStatus("STAGE_1_ONBOARDING");
            }else {
                await this.props.updateRoleStatus("TEAM_MEMBER");
            }
            if(this.props.roleStatus.includes("NOT")){
                await initialiseUserRole(this.props.role)
            }
            this.props.history.push("feed");
        }
    };

    handleCodeSubmit = async(confirmation_code) => {
        let confirmed = await verifyUsernameUpdate(confirmation_code)
        if(confirmed)
            this.props.history.push("feed");
        else{
            console.log("did not confirm")
            this.setState({confirm_error:true})
        }

    }

    renderConfirmationForm() {
        return (
            <ConfirmEmailForm confirm_error = {this.state.confirm_error} submitDetails={this.handleCodeSubmit}/>
        )
    }

    render(){
        return (
            <div className="welcome">
                {this.state.confirmation_needed ? this.renderConfirmationForm() :null}
                <div className={"update-details-wrapper"}>
                    <h1 className="heading-text"> CONFIRM CONTACT DETAILS </h1>
                    <h2 style={{textTransform:'capitalize'}} className="sub-heading-text">Hey {this.props.cognitoInfo ? this.props.cognitoInfo['given_name']: 'there'}!</h2>
                    <UpdateForm  submitDetails = {this.handleSubmit} cognito_details={this.props.cognitoInfo} />
                </div>
            </div>
        )

    }
}

const WelcomePage =  connect(mapStateToProps,mapDispatchToProps)(Welcome);
export default withRouter(WelcomePage);