import React from "react";
import {Route, Switch, Redirect, withRouter} from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/login/Login";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

// import Settings from "./containers/Settings.js";
import Add from "./containers/add/AddNew";
import AddManagers from "./containers/add/AddManager"
import ChangePassword from "./containers/login/ChangePassword";
import UpdateDetails from "./containers/login/UpdateDetails"
import Tasks from "./containers/Tasks"
import NewForm from "./containers/form/FormContainer"
import Applicants from "./containers/applicants/Applicants"
import Offer from "./containers/offer/Offer"
import Myob from "./containers/exports/Myob"
import ChristmasCasuals from "./containers/christmas/ChristmasCasuals"
import PrivacyPolicy from "./containers/policy/PrivacyPolicy"
import withAuth from "./components/WithAuth"
import AdminPanel from "./containers/admin/AdminPanel";
import Feed from "./containers/feed/Feed"
import Shipping from "./containers/shipping/ShippingTest";
import Orders from "./containers/stores/StoreOrderView";
import Collect from "./containers/shipping/CollectVerification"
import Reporting from "./containers/reporting/Reporting";
import ShippingReport from "./containers/reporting/shipping/shippingReport";

import TestPrinter from "./containers/TestPrinter"
/**
 * Routes take in a componenet that is wrapped in a HOC (withAuth), withAuth takes in three parameters (Component,state,allowedPermissions)
 * where state is state to be injected into the store.
 * Both state and allowedPermissions are optional
 * @param childProps
 * @returns {*}
 */
export default ({ childProps }) =>

    <Switch>

        <AuthenticatedRoute path="/" exact component={withAuth(Home)} props={childProps}/>

        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />

        <Route path="/feed" exact component = {withAuth(Feed,"feedState")} />

        <Route path="/forgotten" exact component={ChangePassword} props={childProps} />

        <Route path="/update" exact component={withAuth(UpdateDetails)} />

        <Route path="/add" exact component={withAuth(Add,"storesState",["onboarding::add"])} />

        <Route path="/add/managers" exact component={withAuth(AddManagers,null,["onboarding::add::manager"])} />

        <Route path="/onboarding" exact component={withAuth(NewForm,"onboardingState")} />

        <Route path="/tasks" exact component = {withAuth(Tasks)} />

        <Route path="/ship" exact component = {withAuth(Shipping,'shippingState',["shipping"], ['HR','STORE'])} />

        <Route path="/order" exact component = {withAuth(Orders,['checkoutState','catalogState'],["shipping"], ['STORE'])} />

        <Route path="/printer" exact component = {withAuth(TestPrinter,null,["shipping"], ['STORE'])} />

        <Route path="/collect" exact component = {withAuth(Collect,'shippingState',["shipping"], ['HR','STORE'])} />

        <Route path = "/privacy" exact component ={PrivacyPolicy} />

        <Route path = "/reporting" exact component ={withAuth(Reporting,'reportingState',["reporting::full","reporting::store"])} />

        <Route path = "/reporting/shipping" exact component ={withAuth(ShippingReport,'reportingState',["reporting::full","reporting::store"])} />

        <Route path = "/christmas" exact component = {ChristmasCasuals} />

        <Route path="/applicants" exact component = {withAuth(Applicants,"applicantsState",["onboarding::applicants"])} />

        <Route path="/offer" exact component = {withAuth(Offer,null)} />

        <Route path="/admin" exact component={withAuth(AdminPanel,['adminState','storesState'],["admin"],['HR','SUPERUSER'])} />

        <Route path="/myob" exact component={withAuth(Myob,null,["view:myob"])} />

        <Route component={NotFound} />
    </Switch>

;




