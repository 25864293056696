import React, { Component } from "react";
import { API } from "aws-amplify";
import "./Home.css";
import 'react-toastify/dist/ReactToastify.css';
import {withRouter} from "react-router-dom"
import {connect} from "react-redux";


/*mapping current users basic information and permissions to Redux*/
const mapStateToProps = state => {
    return { user: state.authState, role: state.authState.role, cogInfo:state.authState.cognitoInfo };
};


class Home extends Component {
    constructor(props) {
        super(props);
        this.applicants = ["TEAM_MEMBER","DC_TEAM_MEMBER"];
        this.stores = ["STORE"];
        this.members = ["JUNIOR","MEMBER"];
        this.onboarding = ["STAGE_1_ONBOARDING","STAGE_2_ONBOARDING","STAGE_3_ONBOARDING"]
       // NotificationClass = new Notification();
        this.handler = this.handler.bind(this)
        this.state = {
            isLoading: true,
            currUser:{},
            notification:'',
            awsCreds : [],
            userRole:{}
        };
    }
    handler(e) {
        e.preventDefault();
        this.setState({
            notification: true
        })
    }

    async componentDidMount() {
        if (( this.applicants.includes(this.props.role) && this.onboarding.includes(this.props.user.roleStatus)
            && this.props.user.cognitoInfo.email_verified)) {
            this.props.history.push('/onboarding')
        } else if (this.stores.includes(this.props.role)){
            this.props.history.push('/ship')
        } else if (this.props.role !== "STORE" &&(this.props.user.verified === false  || this.props.user.roleStatus === "NOT_LOGGED_IN")){
            this.props.history.push('/update')
        } else if(this.props.user.status === "LOGGED_OUT"){
            this.props.history.push('/login')
        }else {
            this.props.history.push('/feed')
        }
    }
    getUserRole = username =>{
        return API.get("users", `users/roles/get/${username}`);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    renderLoading(){
        return(
            <div>
                loading your view... If page does not load, please refresh window.
            </div>
        )
    }

    render() {

        return (
            <div className="Home">
                {this.renderLoading()}
            </div>
        );
    }
}


const HomePage =  connect(mapStateToProps,null)(Home);
export default withRouter(HomePage);