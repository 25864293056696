import React, { Component } from "react";
import {
    Col,
    Row
} from "react-bootstrap";
import './AdminPanel.css'
import MenuOnLeft from './AdminMenu'
import UserManagement from "./containers/users/UserManagement";
import StoreManagement from "./containers/stores/StoreManagement";
import RoleManagement from "./containers/roles/RoleManagement";
import PayrollManagement from "./containers/payroll/PayrollManagement";

export default class AdminPanel extends Component {
    constructor(props){
        //const classes = useStyles();
        super(props);
        this.file = null;
        this.state = {
            menu:'userManagement'
        }
    }

    componentDidMount() {
        //TODO check if user is 100% admin, cause this page is veryy important
    }
    handleItemClick =(id) =>{
        this.setState({menu:id});
        console.log(id)
    };

    render(){
        return(
            <div className="wrapper">
                <Row>
                    <div >
                        {(() => {
                            switch (this.state.menu) {
                                case 'userManagement':
                                    return  <h1 className="heading-text"> User Management</h1>;
                                case 'storeManagement':
                                    return <h1 className="heading-text"> Store Management</h1>;
                                case 'roleManagement':
                                    return <h1 className="heading-text">  Role Management</h1>;
                                case 'payrollManagement':
                                    return <h1 className="heading-text">  Payroll Management</h1>;
                                default :
                                    return <h1 className="heading-text"> Admin Panel</h1>;
                            }
                        })()}
                    </div>
                </Row>
                <Row>
                    <Col sm={4}>
                        <div className="admin-sidenav">
                            <MenuOnLeft handleItemClick = {this.handleItemClick} />
                        </div>
                    </Col>
                    <Col sm={8}>
                        <div className="main">
                            {(() => {
                                switch (this.state.menu) {
                                    case 'userManagement':
                                        return <UserManagement />;
                                    case 'storeManagement':
                                        return <StoreManagement />;
                                    case 'roleManagement':
                                        return <RoleManagement />;
                                    case 'payrollManagement':
                                        return <PayrollManagement />;
                                    default :
                                        return null
                                }
                            })()}
                        </div>
                    </Col>

                </Row>

            </div>

            )

    }
}
