import React, { useEffect, useState, memo } from 'react'
import ShipmentItem from './ShipmentItem'
const styles = {
    display: 'flex',
    transform: 'rotate(-0deg)',
    WebkitTransform: 'rotate(-0deg)',
}
const BoxDragPreview = memo(({ item }) => {
    const [tickTock, setTickTock] = useState(false)
    useEffect(
        function subscribeToIntervalTick() {
            const interval = setInterval(() => setTickTock(!tickTock), 500)
            return () => clearInterval(interval)
        },
        [tickTock],
    )
    return (
        <div style={styles}>
            <ShipmentItem item={{item}}/>
        </div>
    )
})
export default BoxDragPreview
