import React from 'react'
import Form from "react-bootstrap/Form";
import PropTypes from 'prop-types'

const Checkbox = (props) => (
    <Form.Group  className="mb-3" controlId={props.id}>
        <Form.Check
            name={props.name}
            type="checkbox"
            label ={props.text}
            className= {props.className}
            onClick = {props.onFocus}
            value={props.value}
            defaultChecked={props.defaultChecked}
            required={props.required}
            onChange={props.onChange} />
    </Form.Group>
);

Checkbox.propTypes = {
    id:PropTypes.string.isRequired,
    required: PropTypes.bool,
    onValidate:PropTypes.func,
    onChange:PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    checked:PropTypes.bool,
    defaultChecked:PropTypes.bool
};

export default Checkbox;