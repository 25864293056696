// Click Collect Icon
import React, {Component} from 'react'

class ClickCollectIcon extends Component{
    render() {
        const style = {
            fill:'none',
            stroke:'#fff',
            strokeLinecap:'round',
            strokeLinejoin:'round',
            strokeWidth:'2.5px'
        }
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="25.221" height="32.498" viewBox="0 0 25.221 32.498">
                <g transform="translate(1.25 1.25)">
                    <path style={style}
                          d="M30.841,207.65H9.426a.672.672,0,0,1-.651-.724l1.231-22.771a.667.667,0,0,1,.651-.647H29.611a.668.668,0,0,1,.652.647l1.23,22.771A.672.672,0,0,1,30.841,207.65Z"
                          transform="translate(-8.773 -177.652)"/>
                    <path style={style}
                          d="M18.258,185.131v-9.184a.672.672,0,0,1,.655-.689h7.823a.672.672,0,0,1,.655.689v9.184"
                          transform="translate(-11.571 -175.259)"/>
                </g>
            </svg>
        )
    }
}

export default ClickCollectIcon
