import { gql } from "@apollo/client";

export const CREATE_NEW_CART = () => {
    return gql`
        mutation {
          createEmptyCart
        }
    `;
}

export const ADD_TO_CART = (cart_id, parent_sku, uid, quantity) => {
    return gql`
        mutation {
            addProductsToCart(
                cartId: "${cart_id}"
                cartItems: [
                    {
                        quantity: ${quantity}
                        sku: "${parent_sku}"
                        selected_options: ["${uid}"]
                    }
                ]
            ) {
                cart {
                    items {
                        uid
                        ... on ConfigurableCartItem {
                            configurable_options {
                                option_label
                                value_label
                            }
                            simple_sku
                            supplier
                            serialized_discount_amounts
                        }
                        product {
                            name
                            sku
                            special_price
                            media_gallery {
                              url
                            }
                        }
                        prices {
                            row_total {
                                value
                            }
                            total_item_discount {
                                value
                            }
                        }
                        quantity
                    }
                    serialized_discount_amounts
                    prices {
                        grand_total {
                            value
                        }
                    }
                }
            }
        }
    `;
}

export const REMOVE_FROM_CART = (cart_id, uid) => {
    return gql`
        mutation {
            removeItemFromCart (
                input: {
                    cart_id: "${cart_id}"
                    cart_item_uid: "${uid}"
                }
            ) {
                cart {
                    items {
                        uid
                        ... on ConfigurableCartItem {
                            configurable_options {
                                option_label
                                value_label
                            }
                            simple_sku
                            supplier
                            serialized_discount_amounts
                        }
                        product {
                            name
                            sku
                            special_price
                            media_gallery {
                              url
                            }
                        }
                        prices {
                            row_total {
                                value
                            }
                            total_item_discount {
                                value
                            }
                        }
                        quantity
                    }
                    serialized_discount_amounts
                    prices {
                        grand_total {
                            value
                        }
                    }
                }
            }
        }
    `;
}

export const SET_ADDRESS = (cart_id, customer, address) => {
    return gql`
        mutation {
            setShippingAddressesOnCart(
                input: {
                    cart_id: "${cart_id}"
                    shipping_addresses: [
                        {
                            address: {
                                firstname: "${customer.fullname.split(" ")[0]}"
                                lastname: "${customer.fullname.split(" ")[1]}"
                                street: ["${address.street[0]}"]
                                city: "${address.city}"
                                region: "${address.region_code}"
                                postcode: "${address.postcode}"
                                country_code: "${address.country_id}"
                                telephone: "${customer.telephone}"
                                save_in_address_book: false
                            }
                        }
                    ]
                }
            ) {
                cart {
                    shipping_addresses {
                        available_shipping_methods{
                            carrier_code
                            carrier_title
                            method_code
                            method_title
                            amount {
                                value
                            }
                            error_message
                            available
                        }
                    }
                }
            }
            setBillingAddressOnCart(
                input: {
                    cart_id: "${cart_id}"
                    billing_address: {
                        address: {
                            firstname: "${customer.fullname.split(" ")[0]}"
                            lastname: "${customer.fullname.split(" ")[1]}"
                            street: ["${address.street[0]}"]
                            city: "${address.city}"
                            region: "${address.region_code}"
                            postcode: "${address.postcode}"
                            country_code: "${address.country_id}"
                            telephone: "${customer.telephone}"
                            save_in_address_book: false
                        }
                    }
                }
            ) {
                cart {
                    shipping_addresses {
                        available_shipping_methods{
                            carrier_code
                            carrier_title
                            method_code
                            method_title
                            amount {
                                value
                            }
                            error_message
                            available
                        }
                    }
                }
            }
            setGuestEmailOnCart(
                input: {
                    cart_id: "${cart_id}"
                    email: "${customer.email}"
                }
            ) {
                cart {
                    email
                }
            }
        }
    `;
}

export const SET_SHIPPING_METHOD = (cart_id, carrier_code, method_code) => {
    return gql`
        mutation {
            setShippingMethodsOnCart(
                input: {
                    cart_id: "${cart_id}"
                    shipping_methods: [
                        {
                            carrier_code: "${carrier_code}"
                            method_code: "${method_code}"
                        }
                    ]
                }
            ) {
                cart {
                    shipping_addresses {
                        selected_shipping_method {
                            carrier_code
                            method_code
                            carrier_title
                            method_title
                        }
                    }
                }
            }
        }
    `;
}

export const SET_PAYMENT_METHOD = (cart_id) => {
    return gql`
        mutation {
            setPaymentMethodOnCart(input: {
                cart_id: "${cart_id}"
                payment_method: {
                    code: "instorepayment"
                }
            }) {
                cart {
                    selected_payment_method {
                          code
                    }
                }
            }
        }
    `;
}

export const PLACE_ORDER = (cart_id) => {
    return gql`
        mutation {
            placeOrder(
                input: {
                    cart_id: "${cart_id}"
                }
            ) {
                order {
                  order_number
                }
            }
        }
    `;
}