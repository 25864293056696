import React, {Component} from "react";
import {connect} from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import CheckoutProgress from "../../../components/ordering/checkout/checkout-progress"
import CartItemView from "./items/CartItemView"
import CustomerDetailsView from "./customer/CustomerDetailsView"
import DeliveryDetailsView from "./delivery/DeliveryDetailsView"
import ConfirmDetailsView from "./confirmation/ConfirmDetailsView"
import CheckoutSuccessView from "./success/CheckoutSuccessView"
import WebsocketPrinter from "../../../components/ordering/external/websocket-printer";
import {printReceipt} from "../../../actions/checkout/CheckoutActions";
import {errorNotification} from "../../../actions/NotificationActions";
import Card from "react-bootstrap/Card";
import { Placeholder } from "react-bootstrap";
import CartRules from "../../../components/ordering/checkout/checkout-cart-rule";

const mapStateToProps = state => {
    return {
        loading: state.checkoutState.loading,
        cart_items: state.checkoutState.cart_items,
        discounts: state.checkoutState.discounts,
        stage:state.checkoutState.stage,
        total:state.checkoutState.total,
        confirmation:state.checkoutState.confirmed,
        cart_id: state.checkoutState.cart_id,
        hardware: state.authState.hardware,
        store_view_code: state.authState.store_view_code,
        receipt_id: state.checkoutState.receipt_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        errorNotification: (message,position,error) => {
            dispatch(errorNotification(message,position,error))
        }
    }
}

class CheckoutView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
            thermal_printer_one:null,
            thermal_printer_two:null
        }
    };

    /**
     * Check if printer is active when checkout is active
     */
    componentDidMount() {

    }

    close = () => {
        this.setState({ showModal: false});
    }

    printReceipt = () => {
        let store_name = this.props.store_view_code === "perfect_stranger" ? "PERFECT STRANGER" : "UNIVERSAL STORE";
        let builder = printReceipt(this.props.receipt_id,this.props.cart_items,this.props.total, store_name);
        let address = `http://${this.props.hardware['thermal_one']}/cgi-bin/epos/service.cgi?devid=local_printer&timeout=10000`;
        let ePosDev = new window.epson.ePOSPrint(address);
        ePosDev.onreceive = function (res) {
            //When the printing is not successful, display a message
            if (!res.success) {alert('A print error occurred');}
        }
        ePosDev.send(builder.toString());
    }

    getCurrentOrderDetailsLabel(stage){
        switch (stage) {
            case 1:
                return "CUSTOMER DETAILS"
            case 2:
                return "DELIVERY DETAILS"
        }
        return null;
    }

    getCurrentOrderDetailsView(stage){
        switch (stage) {
            case 1:
                return <CustomerDetailsView />
            case 2:
                return <DeliveryDetailsView />
            case 3:
                return <ConfirmDetailsView />
            case 4:
                return <CheckoutSuccessView reprintReceipt = {this.printReceipt} />
        }
        return null;
    }

    renderOrderDetails(stage){
        let loading_spinner = <div className = "checkout-spinner"><i className="fas fa-spinner fa-spin" /></div>
        return(
                <Accordion flush={true} defaultActiveKey="0" className={"customer-details-accordion"}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{this.getCurrentOrderDetailsLabel(stage)}</Accordion.Header>
                        <Accordion.Body>
                            {this.props.loading ? loading_spinner : this.getCurrentOrderDetailsView(stage) }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
        )
    }

    render() {
        let stage = this.props.stage;
        return(
            <div className = "checkout-view">
                <Row className = "overflow-auto">
                    <Col sm={9}>
                        <CheckoutProgress stage = {stage} />
                    </Col>
                        <Col sm={2} className={"cart-total center"}>
                            {
                                this.props.total
                                ? <span><b>TOTAL:</b>${this.props.total.toFixed(2)}</span>
                                : <Placeholder as={Card.Title} animation="glow"> <Placeholder xs={12} /> </Placeholder>
                            }
                        </Col>
                    <Row className = "overflow-auto">
                        {Object.keys(this.props.cart_items).map(keyName => <CartItemView key ={keyName} cart_item={this.props.cart_items[keyName]}/>)}
                    </Row>
                    {
                        this.props.total
                        ? <CartRules rules={this.props.discounts} />
                        : null
                    }
                </Row>
                <Row>
                    {this.renderOrderDetails(stage)}
                </Row>
            </div>
        )
    }



}
const checkoutView = connect(mapStateToProps,mapDispatchToProps)(CheckoutView);
export default checkoutView;