import * as type from "../constants/actions/ActionTypes";
import * as lambda from "../actions/LambdaCalls";
import * as cogLib from "../libs/cognitoLib";


export const fetchAuthorization = () => {
    return () => {
        return lambda.authoriseUser().then(data => {
            let username = data.username;
            let userPermissions = data.userPermissions;
            let role = data.role;
            if(role === "STORE"){
                return lambda.getSingleStore(data.store).then(store => {
                    return  {
                        username: username,
                        userPermissions: userPermissions,
                        role: role,
                        status: data.status,
                        store:data.store,
                        store_view_code: store.store_view_code,
                        hardware:store.hardware ?? null
                    };
                })
            }else {
                return {
                    username: username,
                    userPermissions: userPermissions,
                    role: role,
                    status: data.status,
                };
            }
        })
    }
};


export const loadCognitoInfo = (cogInfo) => {
   return (
       {
           type: type.LOAD_COGNITO_INFO,
           identityId:cogInfo.id,
           cognitoInfo : cogInfo.attributes
       }
   )
};


export const authorizeUser = (user) => {
    return(
        {
            type: type.AUTHORISE_USER,
            user:user
        }
    )
};
export const authorizeStore = (store) => {
    return(
        {
            type: type.AUTHORISE_STORE,
            store:store
        }
    )
};

export const loginUser = (verifyEmail = true) => {
    return (
        {
            type:type.LOGIN_USER,
            status: "LOGGED_IN",
            email : verifyEmail
        }
    )
};

export const updateRoleStatus = (status) => {
    return (
        {
            type:type.UPDATE_ROLE_USER,
            payload: status
        }
    )
};

export const idleUser = () => {
    return (
        {
            type:type.IDLE_USER,
            status: "IDLE"
        }
    )
};

/**
 * retrieves token and makes a backend call that returns the users permissions and username to store in store state.
 * @returns {Function}
 */
export function authorize (){
    return (dispatch,getState) =>{
        dispatch(fetchAuthorization()).then(response => {
            if(response.role === "STORE"){
                dispatch(authorizeStore(response));
            }else {
                dispatch(authorizeUser(response));
            }
            let userId = getState().authState.username;
            cogLib.fetchCognitoInfo(userId).then(response => {
                dispatch(loadCognitoInfo(response));
            });
        })
    }
}