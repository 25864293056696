import * as type from "../constants/actions/FeedActionTypes";
import * as lambda from "../actions/LambdaCalls";


const loadMessage = (messages) => {
    return (
        {
            type:type.GET_MESSAGE,
            messages:messages
        }
    )
};

const loadCampaign = (campaign) => {
    return (
        {
            type:type.GET_CAMPAIGN,
            campaign:campaign
        }
    )
};

const fetchContent = (type) => {
return () => {
    return lambda.getMessages(type).then(response=>{
        return response;
    })
}
};

export function getMessage () {
    return (dispatch,state) =>{
        dispatch(fetchContent("message")).then(response =>{
            console.log(response);
            dispatch(loadMessage(response.message));
            })
    }
}

export function getCampaign () {
    return (dispatch,state) =>{
        dispatch(fetchContent("campaign")).then(response =>{
            console.log(response);
            dispatch(loadCampaign(response.message));
        })
    }
}