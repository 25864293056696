// Universal Store Stacked Logo
import React, {Component} from 'react'

class UniversalStoreStackedLogo extends Component{
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="42.675" viewBox="0 0 111.124 42.675" style={{backgroundColor: '#000'}}>
                <g id="Group_1" data-name="Group 1" transform="translate(0 0)">
                    <path id="Path_1" data-name="Path 1" d="M115.85,105.1h5.262v10.452a8.564,8.564,0,0,1-.478,2.846,6.21,6.21,0,0,1-1.483,2.344,5.725,5.725,0,0,1-2.1,1.411,10.544,10.544,0,0,1-3.683.574,22.106,22.106,0,0,1-2.7-.167,6.969,6.969,0,0,1-2.464-.694,5.746,5.746,0,0,1-1.794-1.459,5.677,5.677,0,0,1-1.124-1.961,9.7,9.7,0,0,1-.478-2.87V105.124h5.238v10.691a3.016,3.016,0,0,0,.765,2.177,2.9,2.9,0,0,0,5.047-2.153Z" transform="translate(-104.8 -105.1)" fill="#fff"/>
                    <path id="Path_2" data-name="Path 2" d="M180.2,125h4.4v2.3a6.724,6.724,0,0,1,2.009-1.77,5.142,5.142,0,0,1,2.464-.526,3.932,3.932,0,0,1,3.062,1.172,5.1,5.1,0,0,1,1.1,3.612v8.108h-4.76v-7.056a2.528,2.528,0,0,0-.431-1.65,1.566,1.566,0,0,0-1.2-.478,1.712,1.712,0,0,0-1.387.646,3.8,3.8,0,0,0-.526,2.344v6.195H180.2Z" transform="translate(-162.166 -120.24)" fill="#fff"/>
                    <path id="Path_3" data-name="Path 3" d="M241.7,105.1h4.736v3.205H241.7Zm0,0h4.736v3.205H241.7Zm0,4.736h4.736v12.892H241.7Z" transform="translate(-208.956 -105.1)" fill="#fff"/>
                    <path id="Path_4" data-name="Path 4" d="M265.6,124.9h4.975l2.464,8.084,2.583-8.084h4.808l-5.358,12.892h-4.186Z" transform="translate(-227.14 -120.164)" fill="#fff"/>
                    <path id="Path_5" data-name="Path 5" d="M338.255,132.454h-9.472a3.088,3.088,0,0,0,.622,1.7,2.275,2.275,0,0,0,1.794.813,2.592,2.592,0,0,0,1.315-.359,3.29,3.29,0,0,0,.861-.813h4.688a8.458,8.458,0,0,1-.789,1.626,5,5,0,0,1-1.842,1.483,9.472,9.472,0,0,1-4.329.813,8.94,8.94,0,0,1-3.851-.694,5.526,5.526,0,0,1-2.32-2.2,6.569,6.569,0,0,1-.933-3.54,6.269,6.269,0,0,1,1.842-4.688,7.064,7.064,0,0,1,5.118-1.794,8.918,8.918,0,0,1,4.186.813,5.486,5.486,0,0,1,2.344,2.32,8.6,8.6,0,0,1,.813,3.97v.55Zm-4.807-2.272a3.108,3.108,0,0,0-.742-1.961,2.314,2.314,0,0,0-3.372.287,3.275,3.275,0,0,0-.55,1.65h4.664Z" transform="translate(-271.572 -120.088)" fill="#fff"/>
                    <path id="Path_6" data-name="Path 6" d="M388,124.824h4.425v2.3a4.874,4.874,0,0,1,1.315-1.818,2.728,2.728,0,0,1,1.674-.5,5.088,5.088,0,0,1,2.3.646l-1.459,3.372a3.629,3.629,0,0,0-1.315-.359,1.633,1.633,0,0,0-1.435.765,7.834,7.834,0,0,0-.741,4.042v4.425H388Z" transform="translate(-320.264 -120.088)" fill="#fff"/>
                    <path id="Path_7" data-name="Path 7" d="M427.6,134.021h4.76a1.75,1.75,0,0,0,.742,1,2.885,2.885,0,0,0,2.87-.048.912.912,0,0,0,.407-.741.862.862,0,0,0-.526-.789,10.892,10.892,0,0,0-2.033-.478,21.919,21.919,0,0,1-3.42-.789,3.379,3.379,0,0,1-1.6-1.244,3.411,3.411,0,0,1,.072-4.09,4.138,4.138,0,0,1,1.961-1.315,10.476,10.476,0,0,1,3.325-.431,11.893,11.893,0,0,1,3.253.335,4.035,4.035,0,0,1,1.746,1.052,7.874,7.874,0,0,1,1.244,2.368h-4.568a1.6,1.6,0,0,0-.574-.885,2.448,2.448,0,0,0-1.339-.383,1.919,1.919,0,0,0-1.172.287.873.873,0,0,0-.359.694.739.739,0,0,0,.454.694,7.932,7.932,0,0,0,2.009.407,13.668,13.668,0,0,1,3.516.741,3.807,3.807,0,0,1,1.77,1.363,3.366,3.366,0,0,1,.6,1.937,3.8,3.8,0,0,1-.646,2.081,4.394,4.394,0,0,1-2.033,1.6,9.6,9.6,0,0,1-3.779.6c-2.248,0-3.851-.311-4.808-.957A5.02,5.02,0,0,1,427.6,134.021Z" transform="translate(-350.392 -120.316)" fill="#fff"/>
                    <path id="Path_8" data-name="Path 8" d="M549.6,105.1h4.736v17.652H549.6Z" transform="translate(-443.212 -105.1)" fill="#fff"/>
                    <path id="Path_9" data-name="Path 9" d="M604.1,117.283h4.975a3.3,3.3,0,0,0,.646,1.244,2.773,2.773,0,0,0,2.32,1.028,2.583,2.583,0,0,0,1.746-.526,1.584,1.584,0,0,0,.6-1.22,1.626,1.626,0,0,0-.574-1.2,6.617,6.617,0,0,0-2.7-.981,11.846,11.846,0,0,1-4.927-2.057A4.193,4.193,0,0,1,604.7,110.3a4.537,4.537,0,0,1,.766-2.487,4.989,4.989,0,0,1,2.3-1.842,10.549,10.549,0,0,1,4.186-.67,8.534,8.534,0,0,1,4.975,1.22,4.282,4.282,0,0,1,1.937,3.444h-4.9a2.944,2.944,0,0,0-.789-1,2.575,2.575,0,0,0-1.746-.478,2.088,2.088,0,0,0-1.387.383,1.188,1.188,0,0,0-.454.957.951.951,0,0,0,.383.741,4.39,4.39,0,0,0,1.77.622,23.056,23.056,0,0,1,4.951,1.507,5.2,5.2,0,0,1,2.177,1.89,4.7,4.7,0,0,1,.67,2.511,5.38,5.38,0,0,1-.909,3.014,5.646,5.646,0,0,1-2.535,2.1,10.109,10.109,0,0,1-4.09.718c-2.894,0-4.9-.55-6-1.674A5.9,5.9,0,0,1,604.1,117.283Z" transform="translate(-604.1 -80.252)" fill="#fff"/>
                    <path id="Path_10" data-name="Path 10" d="M718,131.458a6.149,6.149,0,0,1,1.913-4.64,8.271,8.271,0,0,1,10.739.335,6.281,6.281,0,0,1,1.531,4.257,6.182,6.182,0,0,1-1.89,4.664,7.174,7.174,0,0,1-5.214,1.818,7.321,7.321,0,0,1-4.808-1.507A6.057,6.057,0,0,1,718,131.458Zm4.736,0a3.669,3.669,0,0,0,.67,2.44,2.118,2.118,0,0,0,1.674.789,2.084,2.084,0,0,0,1.674-.789,3.874,3.874,0,0,0,.646-2.487,3.57,3.57,0,0,0-.67-2.392,2.026,2.026,0,0,0-1.626-.789,2.1,2.1,0,0,0-1.7.789A3.9,3.9,0,0,0,722.736,131.458Z" transform="translate(-690.758 -95.24)" fill="#fff"/>
                    <path id="Path_11" data-name="Path 11" d="M783.1,124.824h4.425v2.3a4.873,4.873,0,0,1,1.316-1.818,2.728,2.728,0,0,1,1.674-.5,5.088,5.088,0,0,1,2.3.646l-1.459,3.372a3.631,3.631,0,0,0-1.316-.359,1.633,1.633,0,0,0-1.435.765,7.834,7.834,0,0,0-.741,4.042v4.425H783.1Z" transform="translate(-740.287 -95.088)" fill="#fff"/>
                    <path id="Path_12" data-name="Path 12" d="M835.755,132.606h-9.472a3.088,3.088,0,0,0,.622,1.7,2.274,2.274,0,0,0,1.794.813,2.592,2.592,0,0,0,1.316-.359,3.538,3.538,0,0,0,.837-.789h4.592a12.836,12.836,0,0,1-.813,1.507,5.182,5.182,0,0,1-1.7,1.626,9.471,9.471,0,0,1-4.329.813,8.94,8.94,0,0,1-3.851-.694,5.526,5.526,0,0,1-2.32-2.2,6.568,6.568,0,0,1-.933-3.54,6.269,6.269,0,0,1,1.842-4.688A7.064,7.064,0,0,1,828.46,125a8.918,8.918,0,0,1,4.186.813,5.487,5.487,0,0,1,2.344,2.32,8.617,8.617,0,0,1,.789,3.97v.5Zm-4.807-2.272a3.108,3.108,0,0,0-.741-1.961,2.314,2.314,0,0,0-3.372.287,3.274,3.274,0,0,0-.55,1.65h4.664Z" transform="translate(-769.503 -95.24)" fill="#fff"/>
                    <path id="Path_13" data-name="Path 13" d="M492.071,128.831h-4.688a4.964,4.964,0,0,1,.718-1.818,4.012,4.012,0,0,1,1.387-1.172,5.491,5.491,0,0,1,1.794-.55,13.376,13.376,0,0,1,2.464-.191,18.855,18.855,0,0,1,3.42.239,4.292,4.292,0,0,1,2.153,1,3.546,3.546,0,0,1,.957,1.483,5.213,5.213,0,0,1,.359,1.818V135.1c0,.574.024,2.248,0,2.894H496.3v-1.818a6.456,6.456,0,0,1-1.889,1.316,7.851,7.851,0,0,1-2.918.5,4.8,4.8,0,0,1-3.349-1.028A3.234,3.234,0,0,1,487,134.452a3.352,3.352,0,0,1,.813-2.32,5.539,5.539,0,0,1,3.038-1.339c1.77-.359,2.918-.6,3.444-.741a15.519,15.519,0,0,0,1.674-.574,1.69,1.69,0,0,0-.359-1.22,1.785,1.785,0,0,0-1.268-.359,2.959,2.959,0,0,0-1.746.383A1.3,1.3,0,0,0,492.071,128.831Zm3.9,3.014c-.646.239-1.339.431-2.033.622a4.422,4.422,0,0,0-1.818.765,1.215,1.215,0,0,0-.407.861,1.142,1.142,0,0,0,.383.909,1.651,1.651,0,0,0,1.148.359,2.832,2.832,0,0,0,1.459-.383,2.4,2.4,0,0,0,.957-.933,3.059,3.059,0,0,0,.287-1.435v-.765Z" transform="translate(-395.585 -120.316)" fill="#fff"/>
                    <path id="Path_14" data-name="Path 14" d="M682.4,116.2v-3.8h-2.655v-3.9h-4.736v3.9H672.4v3.8h2.655v9.137h4.76c-.024-.191-.024-4.521-.024-5.047V116.2Z" transform="translate(-656.064 -82.687)" fill="#fff"/>
                </g>
            </svg>
        )
    }
}

export default UniversalStoreStackedLogo;