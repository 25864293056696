import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Formik, getIn } from 'formik';
import Input from "../../../components/form/inputs/Input";
import Select from "../../../components/form/inputs/NewSelect"
import LoaderButton from "../../../components/LoaderButton";

import formValidation from "./banking-form-schema"

import Checkbox from "../../../components/form/inputs/Checkbox";
import IntegerInput from "../../../components/form/inputs/IntegerInput";


import {
    handleBankDetailsChange,
    handleSuperDetailsChange,
    submitChanges
} from "../../../actions/OnboardingActions";
import {updateUser} from "../../../actions/LambdaCalls"
import {connect} from "react-redux";
import * as api from "../../../actions/LambdaCalls";
import {FormLabel} from "react-bootstrap";
import {API} from "aws-amplify/lib";

const file = [];

const noTFNReasonOptions = [
    {value:null,label:"Please Select an option"},
    {value: '111111111',label: "I have made a separate application / enquiry to the ATO for a new or existing TFN"},
    {value: '333333333',label: "I am claiming an exemption because I am under 18 and do not earn enough to pay tax"},
    {value: '444444444',label: "I am claiming an exemption because I am in receipt of a pension, benefit or allowance"}
]
const taxPurposeOptions = [
    {value:null,label:"Please Select an option"},
        {value: 'au-resident',label: "An Australian resident for tax purposes"},
        {value: 'foreign-resident',label: "A foreign resident for tax purposes"},
        {value: 'holiday-work',label: "A working holiday maker"},
    ]

const mapDispatchToProps = dispatch => {
    return {
        submitChanges : (token, type, data) => dispatch(submitChanges(token, type, data)),
        handleBankDetailsChange : (banking_details) => dispatch(handleBankDetailsChange(banking_details)),
        handleSuperDetailsChange : (super_details) => dispatch(handleSuperDetailsChange(super_details)),
    };
};
const mapStateToProps = state => {
    return {
        username: state.authState.username,
        cogId: state.authState.identityId,
        personal_details: state.onboardingState.personalDetails,
        role_details: state.onboardingState.roleDetails,
        super_details:state.onboardingState.superDetails,
        banking_details:state.onboardingState.bankingDetails,
    };
};
/**
 * Component for a customer form submission which returns a object
 * with customers personal information and an object with customers
 * address/delivery details
 */
class BankingForm extends Component {
    constructor(props) {
        super(props);
        this.addressRef = React.createRef();
        this.state = {
            loading:false,
            existing_fund: false,
            no_tfn:false,
            no_tax_free: false,
            superTable: [ {value:null,label:"Please Select a Super Fund"}],
            superTypes : [{value:null,label:"Please Select a Fund Type"}]
        }
    }

    /**
     * Load in the super table list
     * @return {Promise<void>}
     */
    async componentDidMount () {
        await API.post("users","functions/list").then(response=>{
            this.setState({superTable: this.state.superTable.concat(response)})
        });

        if(this.props.superFund){
            let superFund = this.props.superFund;
            await this.loadSuperTypes(superFund.id)
            this.setState({loading:false});
        }
    }

    loadSuperTypes(fund){
        this.setState({loading:true});
        API.get("users",`functions/get/${fund}`).then(response =>{
            this.setState({superTypes:this.state.superTypes.concat(response),loading:false})
        })
    }

    handleCheckbox = (e) => {
        this.setState({[e.target.id]: !this.state[e.target.id]});
    }


    /**
     * Upload banking details and super details
     * @param personal_details
     */
    submitDetails = async (banking_details,super_details) =>{
        try{
            this.setState({loading:true})
            this.props.handleBankDetailsChange(banking_details);
            this.props.handleSuperDetailsChange(super_details);
            await this.props.submitChanges("bank", banking_details);
            await this.props.submitChanges("super", super_details);
            await updateUser("status","STAGE_3_ONBOARDING");
            this.props.changeStage(3);
        }catch (e) {
            this.setState({loading:false})
        }

    }


    render(){
        if(this.props.personal_details.address){
            return (
                <Row>
                    <Formik
                        validateOnChange={false}
                        initialValues={{
                            banking:this.props.banking_details,
                            super:this.props.super_details
                        }}
                        validationSchema={()=>formValidation(this.state.no_tax_free,this.state.existing_fund)}
                        onSubmit={(values,{setStatus}) => {
                            this.submitDetails(values.banking,values.super).then(() => this.setState({loading:false}));
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              setFieldValue,
                              handleSubmit,
                          }) => (
                            <Form noValidate onSubmit= {handleSubmit} className = "customer-details-view">
                                <h3 className="sub-heading-text">Tax File Number Declaration</h3>
                                {console.log(errors)}
                                {console.log(values)}
                                {!this.state.no_tfn ?

                                    <Row>
                                        <Col sm={12} md={6}>
                                            <IntegerInput
                                                className={getIn(touched,"banking.tfn.data")  &&!!getIn(errors,"banking.tfn.data") ? "is-invalid": null}
                                                name={"banking.tfn.data"}
                                                id={"tfn"}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.banking.tfn.data}
                                                placeholder="111 111 111"
                                                mask={[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    :null
                                }
                                <Row>
                                    <Col sm={12} md={6} className={"text-left"}>
                                        <Checkbox
                                            onChange = {(e)=>this.handleCheckbox(e)}
                                            value={this.state.no_tfn}
                                            id={"no_tfn"}
                                            text={"I don't have a TFN"}
                                        />
                                    </Col>
                                </Row>
                                {this.state.no_tfn ?
                                    <Row>
                                        <Col sm={12} md={6}>
                                            <Select
                                                name={"banking.tfn.data"}
                                                id={"no_tfn"}
                                                value={values.banking.tfn.data}
                                                list={noTFNReasonOptions}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                isInvalid = {!!getIn(errors,"banking.tfn.data")}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    : null}
                                <Row>
                                    <Col sm={12}  md={6}>
                                        <Select
                                            label={"For tax purposes, I am: "}
                                            name={"banking.tax_res.data"}
                                            id={"no_tfn"}
                                            value={values.banking.tax_res.data}
                                            list={taxPurposeOptions}
                                            onBlur={handleBlur}
                                            onChange={(e) =>{handleChange(e);
                                                e.target.value === "holiday-work" ?
                                                    this.setState({no_tax_free:true})
                                                    : null}
                                            }
                                            isInvalid = {!!getIn(errors,"banking.tax_res.data")}
                                            required
                                        />
                                    </Col>
                                </Row>
                                {values.banking.tax_res.data !== "holiday-work"
                                ?
                                    <div>
                                        <Row>
                                            <Col sm={12} md={6} className={"text-left"} style={{marginBottom: "10px"}}>
                                                <div style={{fontSize: "15px", fontWeight:"700"}}>
                                                    Do you want to claim the tax-free threshold from Universal Store?
                                                </div>
                                                <div>
                                                    Only claim the tax‑free threshold from one payer at a time, unless your total
                                                    income from all sources for the financial year will be less
                                                    than <a target="_blank" href={"https://www.ato.gov.au/individuals/working/working-as-an-employee/claiming-the-tax-free-threshold/"}>the tax-free threshold.</a>
                                                    &nbsp;Answer <span style={{fontWeight:"700"}}>no</span> if you are a foreign resident or working holiday maker, except if you
                                                    are a foreign resident in receipt of an Australian Government pension or allowance.
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row  className={"mb-4"}>
                                            <Col sm={12} md={6} className={"text-left"}>
                                                <Form.Check
                                                    inline
                                                    label="No"
                                                    name="banking.tax_free.data"
                                                    type={"radio"}
                                                    onChange={() => {setFieldValue("banking.tax_free.data",false)}}
                                                    id={`inline-radio-1`}
                                                />
                                                <Form.Check
                                                    inline
                                                    label="Yes"
                                                    name="banking.tax_free.data"
                                                    type={"radio"}
                                                    onChange={() => {setFieldValue("banking.tax_free.data",true)}}
                                                    id={`inline-radio-2`}
                                                />

                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className={"text-left uni-invalid-feedback"}>
                                                {getIn(errors,"banking.tax_free.data")}
                                            </div>
                                        </Row>
                                    </div>
                                :null}
                                <Row>
                                    <Col sm={12} md={6} style={{marginBottom: "10px"}} className={"text-left"}>
                                        <div style={{fontSize: "15px", fontWeight:"700"}}>
                                            Do you have a Higher Education Loan Program (HELP), VET Student Loan (VSL), Financial
                                            Supplement (FS), Student Start-up Loan (SSL) or Trade Support Loan (TSL) debt?
                                        </div>
                                        <div>
                                            Answer <span style={{fontWeight:"700"}}>yes</span> will withhold additional amounts to cover any compulsory repayment
                                            that may be raised on your notice of assessment.
                                        </div>
                                    </Col>
                                </Row>
                                <Row className={"mb-4"}>
                                    <Col sm={12} md={6} className={"text-left"}>
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="banking.help_loan.data"
                                            type={"radio"}
                                            onChange={() => {setFieldValue("banking.help_loan.data",false)}}
                                            id={`inline-radio-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="banking.help_loan.data"
                                            type={"radio"}
                                            onChange={() => {setFieldValue("banking.help_loan.data",true)}}
                                            id={`inline-radio-2`}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <div className={"text-left uni-invalid-feedback"}>
                                        {getIn(errors,"banking.help_loan.data")}
                                    </div>
                                </Row>
                                <Row >
                                    <Col sm={12} md={6}>
                                        <Input
                                            controlId={"family_name"}
                                            label = {"If you have changed your name since you last dealt with the ATO, provide your previous family name"}
                                            name={"banking.tax_name.data"}
                                            onChange={handleChange}
                                            value = {values.banking.tax_name.data}
                                            type="text"
                                            placeholder="Family Name"
                                        />
                                    </Col>
                                </Row>
                                <Row className={"mb-3"}>
                                    <Col sm={12}>
                                        <h3 className={'sub-heading-text'}>
                                            Declaration by Payee
                                        </h3>
                                    </Col>
                                    <Col sm={12} className={"text-left"}>
                                        <Checkbox
                                            name={"banking.tfn_dec.data"}
                                            value={values.banking.tfn_dec.data}
                                            id={"tfn_dec"}
                                            onChange={(e) => {setFieldValue("banking.tfn_dec.data",e.target.checked)}}
                                            text={"I Declare that the information I have given above is true and correct."}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <div className={"text-left uni-invalid-feedback"}>
                                        {getIn(errors,"banking.tfn_dec.data")}
                                    </div>
                                </Row>
                                <Row >
                                    <Col sm={12} md={6}>
                                        <h3 className="sub-heading-text">Bank Details</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Input
                                            controlId={"bank_name"}
                                            className = {"text-capitalize"}
                                            name={"banking.bank_name.data"}
                                            onChange={handleChange}
                                            value = {values.banking.bank_name.data}
                                            type="text"
                                            onBlur={handleBlur}
                                            placeholder="Bank Name"
                                            isInvalid = {getIn(touched,"banking.bank_name.data")  && !!getIn(errors,"banking.bank_name.data")}
                                            required
                                        />

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={6}>
                                        <Input
                                            controlId={"bank_name"}
                                            className = {"text-capitalize"}
                                            name={"banking.account_name.data"}
                                            onChange={handleChange}
                                            value = {values.banking.account_name.data}
                                            type="text"
                                            onBlur={handleBlur}
                                            placeholder="Account Holder Name"
                                            isInvalid = {getIn(touched,"banking.account_name.data")  && !!getIn(errors,"banking.account_name.data")}
                                            required
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} md={3}>
                                        <IntegerInput
                                            className={getIn(touched,"banking.bsb.data")  && !!getIn(errors,"banking.bsb.data") ? "is-invalid": null}
                                            label={"BSB"}
                                            name={"banking.bsb.data"}
                                            controlId={"number"}
                                            onChange={handleChange}
                                            value={values.banking.bsb.data}
                                            placeholder={"063 751"}
                                            isInvalid = {!!getIn(errors,"banking.bsb.data")}
                                            mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                            required
                                            id={"bsb"}
                                        />
                                    </Col>
                                    <Col sm={6} md={3}>
                                        <IntegerInput
                                            className={getIn(touched,"banking.account_number.data")  && !!getIn(errors,"banking.account_number.data") ? "is-invalid": null}
                                            label={"Account Number"}
                                            name={"banking.account_number.data"}
                                            controlId={"number"}
                                            onChange={handleChange}
                                            value={values.banking.account_number.data}
                                            placeholder={"976556826"}

                                            isInvalid = {!!getIn(errors,"banking.account_number.data")}
                                            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/ , /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ , /\d/, /\d/, /\d/]}
                                            required
                                            id={"account_number"}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col sm={12} md={6} className={"text-left"}>
                                        <h3 className="sub-heading-text">Superannuation Fund</h3>
                                        {!this.state.existing_fund ?
                                            <FormLabel>If you do not have a superfund, Universal Store will create you an account with our preferred superfund provider Sunsuper. Learn about Sunsuper at
                                                <a  target="_blank"  href={"https://www.sunsuper.com.au/"}> www.sunsuper.com.au </a>
                                                or call 13 11 84.
                                            </FormLabel>:null}
                                    </Col>
                                    <Col sm={12} className={"text-left"}>
                                        <Checkbox
                                            name={"super.existing_fund.data"}
                                            value={values.super.existing_fund.data}
                                            id={"existing_fund"}
                                            onChange={(e) => {setFieldValue("super.existing_fund.data",e.target.checked);this.setState({existing_fund:e.target.checked})}}
                                            text={"I already have a Superannuation Fund."}/>
                                    </Col>
                                </Row>
                                {
                                    this.state.existing_fund ?
                                        <Row>
                                            <Col sm={12} md={6}>
                                                <Select
                                                    className={"selectpicker"}
                                                    name={"super.sup_fund.data"}
                                                    id={"state"}
                                                    list={this.state.superTable}
                                                    onChange={e=>{setFieldValue("super.sup_fund.data",
                                                        {id:e.target.value,name:e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text});
                                                        this.loadSuperTypes(e.target.value)}}
                                                    isInvalid = {!!getIn(errors,"super.sup_fund.data")}
                                                    required
                                                />
                                            </Col>
                                        </Row>
                                        :null
                                }
                                {
                                    this.state.superTypes.length > 1 ?
                                        <div>
                                            <Row>
                                                <Col sm={12} md={6}>
                                                    <Select
                                                        className={"selectpicker"}
                                                        name={"super.sup_id.data"}
                                                        id={"state"}
                                                        list={this.state.superTypes}
                                                        onChange={e=>{setFieldValue("super.sup_id.data",
                                                            {id:e.target.value,name:e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text})
                                                        }}
                                                        isInvalid = {!!getIn(errors,"super.sup_id.data")}
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Row>
                                                    <Col sm={12} md={6}>
                                                        <Input
                                                            controlId={"bank_name"}
                                                            className = {"text-capitalize"}
                                                            name={"super.account_name.data"}
                                                            onChange={handleChange}
                                                            value = {values.super.account_name.data}
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            placeholder="Super Account Name"
                                                            isInvalid = {getIn(touched,"super.account_name.data")  && !!getIn(errors,"super.account_name.data")}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={12} md={6}>
                                                        <Input
                                                            controlId={"bank_name"}
                                                            name={"super.account_number.data"}
                                                            onChange={handleChange}
                                                            value = {values.super.account_number.data}
                                                            type="text"
                                                            onBlur={handleBlur}
                                                            placeholder="Super Account Number"
                                                            isInvalid = {getIn(touched,"super.account_number.data")  && !!getIn(errors,"super.account_number.data")}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                            </Row>
                                        </div>
                                        :null
                                }

                                <Row style={{marginTop:'1rem'}}>
                                    <Col sm={3}>
                                        <LoaderButton
                                            className={"btn-block"}
                                            variant="dark"
                                            type="submit"
                                            disabled = {this.props.disabled}
                                            isLoading={this.state.loading}
                                            text={"NEXT"}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Row>
            )
        }else {
            return null
        }

    }
}



export default connect(mapStateToProps,mapDispatchToProps)(BankingForm);