import { Storage } from "aws-amplify";
var options = {partSize: 10 * 1024 * 1024, queueSize: 1};
const customPrefix = {
    public: 'protected/',
    protected: 'protected/',
    private: 'private/'
};
var fileDownload = require('js-file-download');
export async function s3CustomUpload(file,zipName,cogId) {
    let filename="";
    if(zipName){
        filename = zipName
    }else {
        filename = `${Date.now()}-${file.name}`;
    }
    const stored = await Storage.put(cogId+"/"+filename, file,{customPrefix: customPrefix,level:'public'});
    return {key:stored.key,fileName:filename}
}

export async function s3Init(){
    Storage.put('config.txt', 'config', {
        level: 'protected',
        contentType: 'text/plain'
    })
        .then (result => console.log(result))
        .catch(err => console.log(err));
}
export async function s3Upload(file,zipName) {
    let filename="";
    if(zipName){
        filename = zipName
    }else {
        filename = `${Date.now()}-${file.name}`;
    }
    const stored = await Storage.put(filename, file,{level: 'public'});
    return {key:stored.key,fileName:filename}
}

export async function s3Delete(file) {
    const toDelete = await Storage.remove(file);
    return toDelete

}

export async function s3UploadPDF(file) {
    const filename = `${Date.now()}-offer.pdf`;
    const stored = await Storage.put(filename, file,options);
    return stored.key;
}

export async function protectedDownload(cogId,name){
    Storage.get(name, {level: 'protected',identityId:cogId,download: true})
        .then(result => fileDownload(result.Body, name))
        .catch(err => console.log(err));
}

export async function publicDownload(name){
    Storage.get(name, {download: true})
        .then(result => fileDownload(result.Body, name))
        .catch(err => console.log(err));
}