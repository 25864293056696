import React from 'react';
import {Formik, Form, getIn} from 'formik';
import * as yup from 'yup';
import Input from "../form/inputs/Input";
import LoaderButton from "../LoaderButton";
import PropTypes from "prop-types";

import PasswordForm from "../../components/login/user-new-password"

class ForgottenForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    };

    renderConfirmEmail() {
        return (
            <Formik
                validateOnChange={false}
                initialValues={{
                    email:''
                }}
                validationSchema={this.buildEmailSchema}
                onSubmit={(values) => {
                    this.props.handleEmailSubmit(values.email)
                }}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} className={"user-login-form"} >
                        <Input
                            controlId={"email"}
                            name={"email"}
                            onChange={handleChange}
                            value={values.email}
                            placeholder={"Email"}
                            isInvalid = {!!getIn(errors,"email")}
                            required
                            help = {"We’ll email you a code to verify this is your account " +
                            "before you can change your password."}
                        />
                        <LoaderButton
                            className={"btn-block"}
                            variant="dark"
                            type="submit"
                            text={"Request password change"}
                        />
                    </Form>
                )}
            </Formik>
        )
    }

    renderConfirmPassword(){
        return (
            <Formik
                validateOnChange={true}
                initialValues={{
                    code: '',
                    password:'',
                    confirm_password:''
                }}
                validationSchema={this.buildPasswordSchema}
                onSubmit={(values) => {
                    this.props.handlePasswordSubmit(values.code,values.password)
                }}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit
                  }) => (
                    <Form noValidate onSubmit={handleSubmit} className={"user-login-form"} autocomplete="off">
                        <Input
                            controlId={"email"}
                            name={"email"}
                            placeholder={"Email"}
                            onChange={()=>{}}
                            value={'test'}
                            type="hidden"/>
                        <Input
                            controlId={"confirmation-code"}
                            autocomplete="off"
                            name={"code"}
                            onChange={handleChange}
                            value={values.code}
                            placeholder={"Confirmation Code"}
                            isInvalid = {!!getIn(errors,"code")}
                            required
                        />
                        <a>
                            Please ensure password has a minimum of 8 characters, at least 1 number, 1 capital letter & 1 symbol
                        </a>
                        <Input
                            controlId={"password"}
                            name={"password"}
                            type={"password"}
                            onChange={handleChange}
                            value={values.password}
                            placeholder={"New password"}
                            isInvalid = {!!getIn(errors,"password")}
                            required
                        />
                        <Input
                            controlId={"password_confirm"}
                            name={"confirm_password"}
                            type={"password"}
                            onChange={handleChange}
                            value={values.confirm_password}
                            placeholder={"Confirm new passowrd"}
                            isInvalid = {!!getIn(errors,"confirm_password")}
                            error = {errors.confirm_password}
                            required
                        />

                        <LoaderButton
                            className={"btn-block"}
                            variant="dark"
                            type="submit"
                            text={"CONFIRM NEW PASSWORD"}
                        />
                    </Form>
                )}
            </Formik>
        )
    }
    render() {
        return (
            (!this.props.email_confirmed ?
            this.renderConfirmEmail()
            :this.renderConfirmPassword()
            )
        )
    }
    buildEmailSchema = () => {
        return yup.object().shape({
            email: yup.string().required("Please provide an email")
        })
    }
    buildPasswordSchema = () => {
        let password_validation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
        return yup.object().shape({
            password: yup.string().matches(password_validation, "Please ensure password rules are followed").
            required("Please enter a valid password"),
            confirm_password: yup.string().when("password", {
                is: val => (val && val.length > 0) ,
                then: yup.string().oneOf(
                    [yup.ref("password")],
                    "Please make sure both passwords are the same"
                ),

            }).matches(password_validation, "Please ensure password rules are followed")
        })
    }
}

ForgottenForm.propTypes = {
    email_confirmed: PropTypes.bool.isRequired,
    handleEmailSubmit: PropTypes.func.isRequired,
    handlePasswordSubmit: PropTypes.func.isRequired,
};

export default ForgottenForm ;