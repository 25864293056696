import React, { Component } from "react";
import {Col, Row} from "react-bootstrap";
import "./feed.css";
import values from "./assets/values.svg";
import valuesMobile from "./assets/values-mobile.svg";
import NewspaperIcon from "../../components/icons/icon-newspaper";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Button from "../../components/LoaderButton";
import {getMessage,getCampaign} from "../../actions/FeedActions";

const mql = window.matchMedia(`(min-width: 1000px)`);



const mapStateToProps = state => {
    if(state.authState){
        return ({user: state.authState.cognitoInfo, messages:state.feedState.messages,campaigns:state.feedState.campaigns})
    }
};

const mapDispatchToProps = dispatch =>{
    return {
        getMessage: () => {dispatch(getMessage())},
        getCampaign: () => {dispatch(getCampaign())}
    }
};

class NewsFeed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false
        }
    }

    componentDidMount(){
        // listening for changes in screen change
        mql.addListener(this.mediaQueryChanged);
        this.setState({ isMobile:!mql.matches});

        this.props.getMessage();
        this.props.getCampaign();
    }
    render(){

         let messages = this.props.messages.map((message,i) =>
            <Col sm={12}>
                <Col sm = {2}>
                    <NewspaperIcon className="icon"/>
                </Col>
                <Col sm={10}>
                    <p className={"message"}>{message.content.message}<span className={"from"}>
                                            - {message.content.author}
                                        </span></p>
                </Col>
            </Col>
        );

         let campaigns = this.props.campaigns.map((campaign,i) =>
             <div>
                 <Row>
                     <Col sm={12}>
                         <img src={campaign.content.image}/>
                     </Col>
                 </Row>
                 <Row>
                     <Col sm={12}>
                         <p className={"message"}>
                             {campaign.content.message}
                         </p>
                     </Col>
                 </Row>
             </div>
         );
        return(
            <div className={"wrapper"}>
                <div className={"heading"}>
                    <div className={"left-heading"}>
                        <h1 className={"heading-text"}>Hey {this.props.user.given_name}, Welcome back!</h1>
                    </div>
                </div>
                <div className={"feed"}>
                    <Row className={"banner"}>
                        <Col sm={12}>
                            <div className={"black-box"}>
                                <Row>
                                    <Col  sm={12}>
                                        <h2 className={"heading-text"}>WHAT VALUES HAVE YOU USED TODAY?</h2>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col  sm={12}>
                                        {this.state.isMobile ?
                                            <img className={"values"} src={valuesMobile}/>
                                        :<img className={"values"} src={values}/> }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className={"content"}>
                        <Col sm={6} xs={12}>
                            <div className={"left-heading"}>
                                <h3 className={"heading-text"}>News feed</h3>
                            </div>
                            <Row className ={"news-feed"}>
                                {messages}
                                <Col sm={12}>
                                    <Col sm = {2}>
                                        <NewspaperIcon className="icon"/>
                                    </Col>
                                    <Col sm={10}>
                                        <p className={"message"}><a target="_blank" href="https://universal-s3-production-attachmentsbucket-1jjcvn7f7vtyu.s3-ap-southeast-2.amazonaws.com/public/contentthe+wrap+WK37-1.pdf">
                                            Here is this week's Wrap  </a>- Keeping you in the know with all things Universal Store. <span className={"from"}>
                                        - Marketing team
                                        </span>
                                        </p>
                                    </Col>
                                </Col>

                            </Row>
                        </Col>
                        <Col sm={6} xs={12} className ={"campaign"}>
                            <div className={"left-heading"}>
                                <h3 className={"heading-text"}>Current Campaign</h3>
                            </div>
                            {campaigns}
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

const newsFeed = connect( mapStateToProps,mapDispatchToProps)(NewsFeed);
export default withRouter(newsFeed);