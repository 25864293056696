import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {searchShipments} from "../../actions/ShippingActions"
import {connect} from "react-redux";
import Checkbox from "../../components/form/inputs/Checkbox";

const mapStateToProps = state => {
    return{
        searchedShipments: state.shippingState.searchedShipments
    }
};
const mapDispatchToProps = dispatch => {
    return{
        searchShipments: (text,fulfilled) => dispatch(searchShipments(text,fulfilled))
    }
};

class ShipmentSearchFilter extends Component {
    constructor(props) {
        super(props);
        this.debouncedSearch = AwesomeDebouncePromise(props.searchShipments,500);
        this.state = {
            fulfilled: false,
            search:''
        }
    }

    setFulfilled = () => {
        let fulfilled = !this.state.fulfilled;
        this.setState({fulfilled:fulfilled});
        this.debouncedSearch(this.state.search,fulfilled);
    };

    handleSearch= async(event)=>{
        let searchQuery = event.target.value.toLowerCase();
        if(searchQuery.length < 1){
            this.setState({fulfilled:false})
        }
        this.debouncedSearch(searchQuery,this.state.fulfilled);
        this.setState({search:searchQuery})
    };
    render(){
        return(
            <div className={"accordion-node search-filter-wrapper"}>
                <Row >
                    <Col sm={5}>
                        <input type="text" className="shipping-search" placeholder="Search by shipment number" onChange={(e)=>this.handleSearch(e)}/>
                    </Col>
                    {this.props.searchedShipments.length <1 && this.state.search.length > 0 ?
                    <Col sm={7}>
                        <Checkbox id={'shipment-search-checkbox'} onChange={()=>{this.setFulfilled()}} text={'No Shipments found, tick to search for Fulfilled Shipments'} />
                        </Col>
                        :null
                    }

                </Row>
            </div>
        )
    }
}

const shipmentSearchFilter = connect( mapStateToProps,mapDispatchToProps)(ShipmentSearchFilter);

export default shipmentSearchFilter;