import React, {Component} from "react";
import {connect} from "react-redux";

import CheckoutCustomerForm from "../../../../components/ordering/checkout/checkout-customer-form"
import {
    updateCustomerDetails,
    updateAddressDetails,
    updateStage,
    updateMageDetails
} from "../../../../actions/checkout/CheckoutActions"

const mapStateToProps = state => {
    return {
        cart_items: state.checkoutState.cart_items,
        stage:state.checkoutState.stage,
        customer_details: state.checkoutState.order_details.customer,
        delivery_details: state.checkoutState.order_details.shipping
    };
};

const mapDispatchToProps = dispatch =>{
    return {
        updateStage: (stage) =>{
            dispatch(updateStage(stage))
        },
        updateDetails: (customer_details, address_details) =>{
            dispatch(updateCustomerDetails(customer_details))
            dispatch(updateAddressDetails(address_details))
            dispatch(updateMageDetails(customer_details, address_details))
        }
    }
};

class CustomerDetailsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullname: "",
            email: "",
            telephone: "",
            address: "",
        }
    };

    submitDetails = (customer,address) =>{
        event.preventDefault();
        this.props.updateDetails(customer, address);
    }

    render(){
        let disabled = this.props.cart_items.length === 0;
        return(
            <CheckoutCustomerForm customer_details = {this.props.customer_details} disabled={disabled} submitDetails = {this.submitDetails} />
            )
    }
}
const customerDetailsView = connect(mapStateToProps,mapDispatchToProps)(CustomerDetailsView);
export default customerDetailsView;