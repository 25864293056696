import React, {Component, useState,useCallback} from "react";
const items = [{
    title: 'Small Bag',
    index: 1
}, {
    title: 'Large Bag',
    index: 9
}];
const distItems = [{
    title: 'Small Bag',
    index: 1
}, {
    title: 'Large Bag',
    index: 9
},{
    title: 'Box',
    index: 6
}];
class ParcelToggleItem extends React.Component {
    onSelect = (index) => {
        this.props.onSelect(index);
    };
    render() {
        let className = this.props.selected ? "parcel-toggle-item parcel-toggle-item--selected" : "parcel-toggle-item";
        return <span className={ className } onClick={ ()=>this.onSelect(this.props.item.index) }>{ this.props.item.title }</span>;
    }
}
export function Toggle(props) {
    if(props.collection){
        return <div/>;
    }else {
        if(props.store === '490' || props.store === '422' || props.store ==='370'){
            return <div className="parcel-toggle">
                {distItems.map((item)=>
                    <ParcelToggleItem
                        item={item}
                        key={item.index}
                        selected={props.selectedItem === item.index}
                        onSelect={(e)=>props.onSelect(e)} />)}
            </div>
        }else {
            return <div className="parcel-toggle">
                {items.map((item)=>
                    <ParcelToggleItem
                        item={item}
                        key={item.index}
                        selected={props.selectedItem === item.index}
                        onSelect={(e)=>props.onSelect(e)} />)}
            </div>
        }
    }


}
