import React, { Component } from "react";
import { option, Row, Col} from "react-bootstrap";
import LoaderButton from "../../../../components/LoaderButton";
import "../../../Add.css";
import * as _ from "lodash";
import moment from "moment/moment";
import * as EmailValidator from "email-validator/index";
import Select from '../../../../components/form/inputs/Select'
import Input from '../../../../components/form/inputs/Input'
import CheckBox from '../../../../components/form/inputs/Checkbox'

import {API} from "aws-amplify/lib/index";
import {connect} from "react-redux";
import {loadRoles} from "../../../../actions/RolesActions";
import {createUser} from "../../../../actions/OnboardingActions"
import {createUserTwo} from "../../../../actions/UsersActions"
import {setCurrentStore,updateStoreMembers} from "../../../../actions/StoreActions";

const mapDispatchToProps = dispatch => {
    return {
        createUserTwo :(cognitoParams, role, store) => dispatch(createUserTwo(cognitoParams, role, store)),
        updateStoreMembers : (store,userId,role)=> dispatch(updateStoreMembers(store,userId,role))
    }
};

 class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fName:"",
            lName: "",
            mobile:"",
            email:"",
            role:"",
            stores:"",
            selectedRole:{},
            selectedStores:[],
            roleOptions:[],
            storeOptions:[]
        };
    }
    async componentDidMount (){
        await this.getStores();
        let testRoles = await loadRoles();
        this.setState({roleOptions: testRoles});
    }
    getStores = async() =>{
        API.get("users","stores/get/all").then(response => {
            let store_dropdown = [];
            for (var j in response) {
                    store_dropdown.push(response[j]);
            }
            this.setState({storeOptions: store_dropdown});
        })
    };
    getSingleStore=async(store) =>{
        return await API.get("users",`stores/get/${store}`)

    };
    updateStore = async(store) =>{
        await API.post("users",`stores/update/${store.storeId}`,{
            body:store
        }).then(response =>{

        })
    };
    adminCreateUser= async (params) =>{
        return await API.post("users","users/admin/create",{body:params})

    };
    createUser= async event =>{
            event.preventDefault();

            let newUser= this.state.newUser;
            // initialize
            let personal = {
                userId: "",

                email: {
                    data: "",
                    p_type: "2"
                },
                fName: {
                    data: "",
                    p_type: "2"
                },
                lName: {
                    data: "",
                    p_type: "2"
                },
                number: {
                    data: "",
                    p_type: "2"
                },

                address: {

                    p_type: "1"
                },

                gender: {

                    p_type: "2"
                },

                dob: {

                    p_type: "1"
                },

                citizenship: {

                    p_type: "1"
                },

                workrights: {

                    p_type: "1"
                },

                availability: {
                    data: {
                        sunday: [{start: "12:00am", end: "12:00am"}],
                        monday: [{start: "12:00am", end: "12:00am"}],
                        tuesday: [{start: "12:00am", end: "12:00am"}],
                        wednesday: [{start: "12:00am", end: "12:00am"}],
                        thursday: [{start: "12:00am", end: "12:00am"}],
                        friday: [{start: "12:00am", end: "12:00am"}],
                        saturday: [{start: "12:00am", end: "12:00am"}]
                    },
                    p_type: "1"
                },
                availability_notes: {

                    p_type: "1"
                },
                emergency: {

                    p_type: "2"
                },
                user_references: {
                    p_type: "2"
                }
            }
            let wages = {
                userId: "na",
                employment_classification: "na",
                wage: "na",
                pay_type: "Y"
            }
            let userRole = {
                userId: "na",
                futuraId: "na",

                role: "na",

                applicant_status: "TEAM_MEMBER",

                home_store: "na",

                stores: [],
                trial_type: "na",
                trial_date: "na",

                trial_time: "na",

                start_date: "na",

                end_date: "na",

                second_start_date: "na",

                termination_reason: "na",

                references: "na",

                ten_hour_rule: false,

                christmas_casual: false,

            }

            personal.fName.data = this.state.fName;
            personal.lName.data = this.state.lName;
            personal.number.data = this.state.mobile;
            personal.email.data = this.state.email;
            userRole.role =  this.state.role.toUpperCase();
            let stores = [];

            if(this.state.role === "HR"){
                stores = this.state.storeOptions;

            }else {
                stores = this.state.selectedStores;
            }

            if(stores.length> 1){
                for(let i in stores){
                    userRole.stores.push({role:this.state.role,store:stores[i].value})
                }
            }else {
                userRole.home_store = stores[0].value;
                userRole.stores.push({role:this.state.role,store:stores[0].value})
            }
            /*converts number for aws*/
            let number = this.state.mobile;

            if(number.startsWith('04')){
                number = number.replace('04', '614');
            }else if(number.startsWith('61 4')){
                number = number.replace('61 4', '614');
            }else if(number.startsWith('+614')){
                number = number.replace('+614', '614');
            }else if(number.startsWith('+61 4')){
                number = number.replace('+61 4', '614');
            }
            number = number.replace(/\s/g, '');
            let cognitoParams = {
                Username: this.state.email,
                UserAttributes: [
                    {Name: "given_name", Value: this.state.fName},
                    {Name: "family_name", Value: this.state.lName},
                    {Name: "name", Value: this.state.fName +" "+this.state.lName},
                    {Name: "email", Value: this.state.email},
                    {Name: "phone_number", Value: "+" + number},

                ],
                user: personal,
                wages: wages,
                userRole: userRole,
                type: "create",
                existing_user:true
            };
                let storesArray = [];
                for(let i in stores){
                    storesArray.push(await this.getSingleStore(stores[i].value))
                }

                this.props.createUserTwo(cognitoParams,this.state.selectedRole.value,storesArray)


    }
    handleChange = event => {
        //make email lowercase on input// reduces errors with email
        let target = event.target.id;


        this.setState({[target]:event.target.value})

    };
    handleRoleChange = async(event) => {

        let role = this.state.role;
        role = event.value;

        this.setState({
            role,
            selectedRole:event
        });
    };
    handleStoreChange = async(event) =>{
        let stores = this.state.selectedStores;
        stores=event;

        this.setState({
            selectedStores:stores
        });
    };
    getValidationState(type){

        if(type==="email"){
            const email =this.state.email;
            if(EmailValidator.validate(email)){
                return 'success'
            }else if(email.length > 5){

                return 'error'
            }
            else return null
        }

        if(type ==="name"){
            const fName=this.state.fName;
            const lName = this.state.lName;

            if((fName.length> 0 && fName.length< 30) && (lName.length >0 && lName.length <40)){
                return 'success'
            }else if(fName.length> 30 || lName.length >30){
                return 'error'
            }else return null;
        }
        if(type=== "number"){
            const number = this.state.mobile;
            const patter = /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
            const pattern = /^(?:\+?(61))? ?(?:\((?= \)))?(0?[4-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/
            if(number) {
                if ((number.length > 6 && number.match(pattern)) && number.charAt(0) !== '4') {

                    return 'success'
                } else if (number.length >= 9 && (number.charAt(0) !== '0' || number.charAt(0) !== '+' || number.charAt(0) !== '6' || number.length > 10)) {
                    // this.handleTool('error');
                    return 'error'
                } else return null
            }

        }

    }
    render(){
        return(
            <div className="page-div">
            <form onSubmit={this.createUser} className="form-container add-form">
                <h1 className="heading-text">Create a Member</h1>
                <div>
                    <Row>
                        <Col sm={12} md={3} lg={2}>
                            <Select id={"role"}
                                    label={"Role"}
                                    options={this.state.roleOptions}
                                    selectedOption={this.state.selectedRole}
                                    onChange={(e)=>this.handleRoleChange(e)}/>
                        </Col>
                        {this.state.selectedRole.value === "HR" ?
                            <Col sm={12} md={9} lg={4}><p>All stores all selected for HR</p></Col>
                            :
                            <Col sm={12} md={9} lg={4}>
                                <Select id={"role"}
                                        label={"Stores"}
                                        multi={true}
                                        options={this.state.storeOptions}
                                        onChange={(e)=>this.handleStoreChange(e)}/>
                            </Col>



                        }
                    </Row>
                    <h3 className = "form-heading">Contact Details</h3>
                    <Row>
                        <Col sm={12} md={8} lg={6}>
                            <input
                                id="email"
                                placeholder = "Email Address"
                                value={this.state.email}
                                onChange={this.handleChange}
                                required
                            />
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={12} md={8} lg={6}>
                            <input
                                id="fName"
                                placeholder = "First Name"
                                value={this.state.fName}
                                onChange={this.handleChange}
                                type="text"
                                required
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={8} lg={6}>
                            <input
                                id="lName"
                                placeholder = "Last Name"
                                style={{ textTransform: 'capitalize'}}
                                value={this.state.lName}
                                onChange={this.handleChange}
                                type="text"
                                required
                            />
                        </Col>
                    </Row>
                    <Row >

                        <Col sm={12} md={8} lg={6}>
                            <input
                                pattern="(?:\+?61)?(?:\(0\)[23478]|\(?0?[23478]\)?)\d{8}"
                                placeholder="Phone Number"
                                value={this.state.mobile}
                                onChange={e => this.handleChange(e)}
                                required
                                id={"mobile"}
                            />
                        </Col>
                    </Row>
                    <Row>

                    </Row>

                    <Row>
                        <LoaderButton
                            className="add-user-button large-button"
                            block
                            bsSize="large"
                            disabled={false}
                            type="submit"
                            isLoading={this.state.isLoading}
                            text="SEND EMAIL & SMS"
                            loadingText="Sending…"
                        />
                    </Row>
                </div>
            </form>
            </div>
        )
    }
}

export default connect( null,mapDispatchToProps)(AddUser);