import PropTypes from 'prop-types'
import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {selectSize} from "../../../actions/catalog/CatalogActions"
import {addToCart} from "../../../actions/checkout/CheckoutActions"

import ProductCard from "../../../components/ordering/catalog/catalog-product-card"
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch =>{
    return {
        addToCart: (product)=>{
            dispatch(addToCart(product))
        },
        selectSize: (id,size) => {
            dispatch(selectSize(id,size))
        },
    }
};
class ProductView extends Component {
    /**
     * add selected size to the product for checkout
     * @param sku
     * @param size
     */
    handleSizeSelect = (size) => {
        this.props.selectSize(this.props.product.sku,size)
    }

    addToCart = (product) => {
        this.props.addToCart(product);
    }

    render() {
        let product = this.props.product;
        return(
            <div className={"product-card-view"}>
                <ProductCard product={product} selectProduct={this.addToCart} selectSize={this.handleSizeSelect} isLoading={this.props.isLoading}/>
            </div>
        )
    }
}

ProductView.propTypes = {
    product: PropTypes.object.isRequired
};

const productView = connect(mapStateToProps, mapDispatchToProps)(ProductView);
export default withRouter(productView);