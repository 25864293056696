import React, { Component } from "react";

import { API, Auth, Storage } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton";
import {Col,Row} from "react-bootstrap";
import InputMask from 'react-input-mask';

import "./Applicants.css";
import {protectedDownload, publicDownload} from "../../libs/awsLib";

var fileDownload = require('js-file-download');


export default class ApplicantDownloads extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: this.props.userId,
            uploadInfo: ""
        };
    }

    async componentDidMount(){
        try {
            if (this.props.userId !== null) {
                const uploadInfo = await this.getUploadInfo();
                this.setState({
                    uploadInfo
                });
            }
        } catch (e) {
            console.log(e);
        }

    }
    getUploadInfo() {
        return API.post("users", "users/uploads/applicants", {
            body: {
                userId: this.props.userId
            }
        });
    };

    handleFileDownload = async(name, e) => {
        if(this.props.cogId){
            await protectedDownload(this.props.cogId,name);
        }else {
            await publicDownload(name);
        }
    };

    render() {
        return (
            <div className={"submenu"}>
                {this.state.uploadInfo.Count > 0
                    ? <Row className={"applicant-uploads"}>
                        <span className={"submenu-heading"}>Contract & Position Description:</span>
                        {this.state.uploadInfo.Items.map((upload, i) =>
                            (upload.file_type === 'TAC' || upload.file_type === 'PD')
                                ?
                                <Col xs={12} sm={6}>
                                    <div key={i}>
                                        <div className={"left-menu"}>
                                            <span
                                                className={"file-type"}>{upload.file_type === 'TAC' ? "Contract" : "Position Description"}</span>
                                            <div className={"download-wrapper"}
                                                 onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                <img src={require("../../images/download-icon.svg")}/>
                                                <span>Download File</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                : null
                        )}
                    </Row>
                    : null
                }

            </div>
        )
    }
}