import React from 'react';
import {Formik, Form, getIn} from 'formik';
import * as yup from 'yup';
import Input from "../form/inputs/Input";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoaderButton from "../LoaderButton";
import PropTypes from "prop-types";

class PasswordForm extends React.Component {
    render() {
        return (
            <Formik
                validateOnChange={true}
                initialValues={{
                    password:'',
                    confirm_password:''
                }}
                validationSchema={this.buildLoginSchema}
                onSubmit={(values) => {
                    this.props.submitDetails(values.password)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit
                      /* and other goodies */
                  }) => (
                    <Col sm={12} md={3} lg={3} xl={3}>
                    <Form noValidate onSubmit={handleSubmit} className={"user-login-form"}>
                        <Row>
                            <Col sm={12}>
                                <Input
                                    controlId={"password"}
                                    name={"password"}
                                    type={"password"}
                                    onChange={handleChange}
                                    value={values.password}
                                    placeholder={"New password"}
                                    isInvalid = {!!getIn(errors,"password")}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input
                                    controlId={"password_confirm"}
                                    name={"confirm_password"}
                                    type={"password"}
                                    onChange={handleChange}
                                    value={values.confirm_password}
                                    placeholder={"Confirm new passowrd"}
                                    isInvalid = {!!getIn(errors,"confirm_password")}
                                    error = {errors.confirm_password}
                                    help = {"please ensure password has at least 1 number, 1 capital letter & 1 symbol"}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <LoaderButton
                                    className={"btn-block"}
                                    variant="dark"
                                    type="submit"
                                    text={"CONFIRM NEW PASSWORD"}
                                />
                            </Col>
                        </Row>
                    </Form>
                    </Col>
                )}
            </Formik>
        )
    }

    buildLoginSchema = () => {
        let password_validation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/

        return yup.object().shape({
            password: yup.string().matches(password_validation, "Please ensure password rules are followed").
            required("Please enter a valid password"),
            confirm_password: yup.string().when("password", {
                is: val => (val && val.length > 0),
                then: yup.string().oneOf(
                    [yup.ref("password")],
                    "Please make sure both passwords are the same"
                )
            })
        })
    }
}

PasswordForm.propTypes = {
    submitDetails: PropTypes.func.isRequired,
};

export default PasswordForm ;