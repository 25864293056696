export const GET_DEFAULT_DATA="GET_DEFAULT_DATA";
export const GET_REJECTED_DATA="GET_REJECTED_DATA";
export const GET_MANIFEST_DATA="GET_MANIFEST_DATA";
export const GET_ALL_STORE_VIEW="GET_ALL_STORE_VIEW";
export const GET_STATE_VIEW = "GET_STATE_VIEW";
export const GET_PENDING_OVERVIEW="GET_PENDING_OVERVIEW";
export const RESET_SHIPMENT_VIEW = "RESET_SHIPMENT_VIEW";
export const GET_PENDING_DETAILS = "GET_PENDING_DETAILS";
export const GET_COMPLETE_OVERVIEW = "GET_COMPLETE_OVERVIEW";
export const GET_COMPLETE_DETAILS = "GET_COMPLETE_DETAILS";
export const GET_REJECTED_OVERVIEW= "GET_REJECTED_OVERVIEW";
export const GET_REJECTED_DETAILS= "GET_REJECTED_DETAILS";
export const LOADING_REPORTING_DATA = "LOADING_REPORTING_DATA";