import {Auth} from "aws-amplify";

export const fetchCognitoInfo = () =>{
        return Auth.currentUserInfo().then((userInfo) => {
            const { attributes = {} } = userInfo;
            return userInfo
        })

};

export const checkActiveSession = () => {
        return Auth.currentSession().then(data => console.log(data))
            .catch(err => console.log(err));
}