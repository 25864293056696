import * as yup from "yup";
const buildFormSchema = (no_tax_free = false,
                         existing_superfund = false) => {
    return yup.object().shape({
        banking:yup.object({
            tfn: yup.object({
                data: yup.string().required('required')
                    .min(8, 'Please ensure the TFN entered is correct')
                    .max(11, 'Please ensure the TFN entered is correct')
            }),
            tax_res: yup.object({
                data: yup.string().required('required')
            }),
            tax_free: yup.object({
                data: !no_tax_free ?
                    yup.boolean().required('Please select an option for the question above')
                    :''
            }),
            help_loan: yup.object({
                data: yup.boolean().required('Please select an option for the question above')
            }),
            tfn_dec: yup.object({
                data: yup.boolean().required("Must check this box to continue.")
                    .oneOf([true], "Must check this box to continue.")
            }),
            bank_name: yup.object({
                data: yup.string().required('required')
            }),
            account_name: yup.object({
                data: yup.string().required('required')
            }),
            bsb: yup.object({
                data: yup.string().required('required')
                    .min(4, 'Please ensure the BSB entered is correct')
                    .max(8, 'Please ensure the BSB entered is correct')
            }),
            account_number: yup.object({
                data: yup.string().required('required')
                    .min(4, 'Please ensure the Account Number entered is correct')
                    .max(15, 'Please ensure the Account Number entered is correct')
            }),
        }),
        super:yup.object({
            existing_fund: yup.object({
                data: yup.boolean()
            }),
            sup_fund: yup.object({
                data: existing_superfund ? yup.object().required('required') : yup.object()
            }),
            sup_id: yup.object({
                data: existing_superfund ? yup.object().required('required') : yup.object()
            }),
            account_name: yup.object({
                data: existing_superfund ? yup.string().required('required') : yup.string()
            }),
            account_number: yup.object({
                data: existing_superfund ? yup.string().required('required') : yup.string()
            }),
        })
    })

}

export default buildFormSchema