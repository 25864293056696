import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import methods from "../../../../constants/shipping/MethodTypes"
import {
    updateShippingMethod,
    updateStage,
    clearOrder,
    copyBarcode
} from "../../../../actions/checkout/CheckoutActions";
import LoaderButton from "../../../../components/LoaderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faCopy, faPrint, faTrash } from "@fortawesome/free-solid-svg-icons";


const mapStateToProps = state => {
    return {
        receipt_id: state.checkoutState.receipt_id,
        order_total: state.checkoutState.total,
        shipping_cost:state.checkoutState.shipping_method.amount.value
    };
};

const mapDispatchToProps = dispatch =>{
    return {
        updateStage: (stage) =>{
            dispatch(updateStage(stage))
        },
        updateShippingMethod: (shipping_method) =>{
            dispatch(updateShippingMethod(shipping_method))
        },
        copyBarcode: (barcode) => {
            navigator.clipboard.writeText(barcode + "\x0D")
            dispatch(copyBarcode(barcode));
        },
        clearOrder : ()=> {
            dispatch(clearOrder())
        }
    }
};

class CheckoutSuccessView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer_disabled: true
        }
    };

    componentDidMount() {
        //disable Clear search button for 10 seconds after a sale is made
        setTimeout(() => this.setState({ timer_disabled: false }), 10000);
    }

    handleClearOrder = () => {

    }

    render(){
        return(
                <Row className = "checkout-success-view">
                    <Card.Body>
                        <Card.Title>
                            <h3 style={{ textTransform: 'uppercase'}} >Order Success!</h3>
                        </Card.Title>
                        <Card.Text>
                            <span style={{fontSize:'1.1rem'}}>The online order will be processed once you complete the sale on TouchPOS with the following barcode</span>
                        </Card.Text>
                        <Card.Text>
                            <span onClick={() => { this.props.copyBarcode(this.props.receipt_id) }}
                                style={{marginRight: "16px", fontSize:'1.8rem', cursor: "copy"}}>{this.props.receipt_id} - ${(this.props.order_total).toFixed(2)}</span>
                        </Card.Text>
                        <Row>
                            <Col sm={4}>
                                <LoaderButton
                                    icon={faCopy}
                                    className="btn-block"
                                    variant="success"
                                    onClick={() => { this.props.copyBarcode(this.props.receipt_id) }}
                                    text={"COPY BARCODE"}
                                />
                            </Col>
                            <Col sm={4}>
                                <LoaderButton
                                    icon={faPrint}
                                    className="btn-block"
                                    variant="outline-dark"
                                    onClick={(e)=>this.props.reprintReceipt()}
                                    text={"REPRINT BARCODES"}
                                />
                            </Col>
                            <Col sm={4}>
                                <LoaderButton
                                    icon={faTrash}
                                    disabled = {this.state.timer_disabled}
                                    className="btn-block"
                                    variant="dark"
                                    onClick={this.props.clearOrder}
                                    text={"NEW SEARCH"}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Row>
        )
    }
}
const checkoutSuccessView = connect(mapStateToProps,mapDispatchToProps)(CheckoutSuccessView);
export default checkoutSuccessView;