import React, { Component } from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import { Formik, getIn } from 'formik';
import * as yup from 'yup';
import LoaderButton from "../../LoaderButton";
import ManualAddressForm from "./checkout-manual-address-form"
const addressResultsStyle = {
    ul: {
        top:'-20px !important'
    }
}
 /**
 * Component for a customer form submission which returns a object
 * with customers personal information and an object with customers
 * address/delivery details
 */
export default class CheckoutCustomerForm extends Component {
    constructor(props) {
        super(props);
        this.addressRef = React.createRef();
        this.state = {
            address_details : {
                street: '',
                city: '',
                postcode: '',
                region_code: '',
                country_id:"AU"
            },
            showModal: false,
            disable_auto_address: false,
        }
    }

     /**
      * Created a jquery UI autocomplete function for handling mastersoft address validation
      */
    updateAutocomplete(formikSetFieldValue){
    let parent = this
    let addressNode = this.addressRef.current
    $(addressNode).autocomplete(
        {
            // minimum number of entered characters before trying to search
            minLength:3,
            // miliseconds to wait before trying to search
            delay:500,
            appendTo: "#address-results",
            position: { collision: "flip" },
            source: function(request, response) {
                Harmony.address({ fullAddress : request.term }, Harmony.AUPAF,
                    function(data) {
                        var array = [];
                        if(data.status == Harmony.SUCCESS) {
                            array = $.map(data.payload, function(p) {
                                return {
                                    label: p.fullAddress,
                                };
                            });
                            response(array);
                        }
                });
            },
            focus: function(event, ui) {
                event.preventDefault();
            },
            select: function(event, ui) {
                event.preventDefault();
                formikSetFieldValue("address",ui.item.label)
                Harmony.address({ fullAddress : ui.item.label }, Harmony.AUPAF,
                    function(data) {
                        if(data.status === Harmony.SUCCESS) {
                            if('payload' in data){
                                // since full address is sent, grab first available match
                                const address_details = parent.buildAddressDetails(data.payload[0]);
                                console.log(address_details)
                                parent.setState({address_details})
                            }
                        }
                    });
            }
        })
}

    buildAddressDetails(harmony_address_object){
        let addr = harmony_address_object;
        let po_box = addr['postal']
        let street = po_box ? po_box: addr['streetNumber'] +" " + addr['street']
        let streetWithSubdwelling = [addr['subdwelling'], street, addr['street2']].join(' ').trim()
        console.log(street);
        return {
            street: [streetWithSubdwelling],
            city: addr['locality'],
            postcode: addr['postcode'],
            region_code:addr['state'],
            country_id: "AU"
        }
    }

     /**
      * Submit form details and ensure if address object is manually entered to
      * create an array of streets before sending it across
      * @param customer_details
      */
    submitDetails = (customer_details) =>{
        if(this.state.address_details) {
            let address = {...this.state.address_details};
            if(!(address.street instanceof Array)){
                address.street = [address.street]
            }
            console.log(address)
            this.props.submitDetails(customer_details,address);
        }else {
            throw new Error('Error finding address! Please try again')
        }
    }

     handleCloseModal = () => {
         this.setState({showModal: false})
     }

     /**
      * Change street to array of string instead of string
      * @param address_details
      */
     buildManualAddress = (address_details) => {
         let address = address_details;
         address.street = [address.street]
         this.setState({address_details:address,disable_auto_address:true})
         this.setState({showModal: false})
     }

    render(){
        return(
            <Row>
                <Modal className = {"customer-manual-address-view"} animation={false} show={this.state.showModal} onHide={this.handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title><span>CUSTOMER ADDRESS</span></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ManualAddressForm submitDetails={this.buildManualAddress}  address_details={this.state.address_details}/>
                    </Modal.Body>
                </Modal>
                <Formik
                    validateOnChange={false}
                    initialValues={{...this.props.customer_details}}
                    validationSchema={this.buildFormSchema}
                    onSubmit={(values,{setStatus}) => {
                        try{
                            //capitalize first and last name
                            values['fullname'] = capitalizeName(values['fullname'])
                            this.submitDetails(values)
                            setStatus(null)
                        }catch (e) {
                            console.error(e);
                            console.log(e.message);
                            setStatus(e.message)
                        }
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          setFieldValue,
                          status
                          /* and other goodies */
                      }) => (
                    <Form noValidate onSubmit= {handleSubmit} className = "customer-details-view">
                        {status?
                            <Row>
                                <Col sm={11}>
                                    <Alert variant="danger">
                                        {status}!
                                    </Alert>
                                </Col>
                            </Row>
                            : null}
                        <Row>
                            <Col sm={6}>
                                <Form.Group className="mb-3" controlId="fullname">
                                    <Form.Control
                                        className = {"text-capitalize"}
                                        name={"fullname"}
                                        onChange={handleChange}
                                        value = {values.fullname}
                                        type="text"
                                        placeholder="Full Name"
                                        autoComplete="off"
                                        isValid={touched.fullname && !getIn(errors,"fullname")}
                                        isInvalid = {!!getIn(errors,"fullname")}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.fullname}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col sm={5}>
                                <Form.Group className="mb-3" controlId="telephone">
                                    <Form.Control
                                        name={"telephone"}
                                        onChange={handleChange}
                                        value = {values.telephone}
                                        type="phone"
                                        placeholder="Mobile"
                                        autoComplete="off"
                                        isValid={touched.fullname && !getIn(errors,"telephone")}
                                        isInvalid = {!!getIn(errors,"telephone")}/>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={11}>
                                <Form.Group className="mb-3" id={"address"}>
                                    <Form.Control
                                        disabled={this.state.disable_auto_address}
                                        ref={this.addressRef}
                                        name={"address"}
                                        onChange={(e)=>{handleChange(e);this.updateAutocomplete(setFieldValue)}}
                                        value={values.address}
                                        type="text"
                                        autoComplete="off"
                                        placeholder={this.state.disable_auto_address ? "Manual Address Entered" : "Address"}
                                        isInvalid = {!!getIn(errors,"address")}/>
                                    <div style={addressResultsStyle} id="address-results"> </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={11}>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Control
                                        name={"email"}
                                        onChange={handleChange}
                                        value = {values.email}
                                        type="email"
                                        placeholder="Email"
                                        autoComplete="off"
                                        isValid={touched.email && !getIn(errors,"email")}
                                        isInvalid = {!!getIn(errors,"email")}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{marginTop:'1rem'}}>
                            <Col lg={{span:5, offset:0}} xl={{span:4, offset:0}}>
                            <LoaderButton
                                className={"btn-block"}
                                variant="outline-dark"
                                text={"Manually Enter Address"}
                                onClick={()=>this.setState({showModal:true})}
                            />
                        </Col>
                            <Col lg={{span:3, offset:3}} xl={{span:2, offset:5}}>
                                <LoaderButton
                                    className={"btn-block"}
                                    variant="success"
                                    type="submit"
                                    disabled = {typeof(this.state.address_details.street) === 'string'}
                                    text={"NEXT"}
                                />
                            </Col>
                        </Row>
                    </Form>
                    )}
                </Formik>
            </Row>
        )
    }

     buildFormSchema = () => {
         const mobile_pattern = /^(?:\+?(61))? ?(?:\((?= \)))?(0?[4-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
         const full_name_pattern = /[a-z]* [a-z]*/

         let customer_validation = yup.object().shape({
             fullname: yup.string().matches(full_name_pattern,"Please enter first & last name").
             required("Customers full name is required"),
             email: yup.string().email('Please enter a valid email')
                 .required('Customers email required'),
             telephone: yup.string()
                 .matches(mobile_pattern, 'Invalid Phone Number')
                 .required("Telephone required"),
         })
         let address_validation;
         address_validation = !this.state.disable_auto_address ?
             yup.object().shape({address:yup.string().required()}) :
             yup.object().shape({address:yup.string().notRequired()})

         return customer_validation.concat(address_validation);
     }

}

function capitalizeName(name) {
    return name.replace(/\b(\w)/g, s => s.toUpperCase());
}

CheckoutCustomerForm.propTypes = {
    submitDetails: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

CheckoutCustomerForm.defaultProps = {
    disabled:false,
    customer_details : {
        fullname: '',
        email: '',
        telephone: '',
        address: '',
    }
};
