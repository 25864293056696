// Icon
import React, {Component} from 'react'


class storeIcon extends Component{
    render() {
        return (
            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="search"
                 className="svg-inline--fa fa-search fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 500 512">
                <path d="M199.725,0v36.025H85.211v421.66l114.514,0.094v36.459l209.085-37.555l0.216-418.867L199.725,0z M234.404,230.574
		c7.022,0,12.715,7.408,12.715,16.545c0,9.139-5.692,16.545-12.715,16.545s-12.715-7.406-12.715-16.545
		C221.688,237.982,227.382,230.574,234.404,230.574z M119.211,423.713V70.025h80.514v353.753L119.211,423.713z"/>
            </svg>
        )
    }
};

export default storeIcon