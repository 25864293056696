import React,{Component} from "react";
import {Col} from "react-bootstrap";
export default class StockChecker extends Component {
    constructor (props) {
        super(props);
        this.state = { isButtonActive: 'na'};
        this.onClick = this.onClick.bind(this);
    }

    onClick (check) {
        // if there is a change in status then run the code, otherwise leave it be
        if(check !== this.state.isButtonActive){
            this.setState({
                isButtonActive: check
            });

            if(this.props.runFunction){
                this.props.runFunction(check);
            }
        }
    }

    render () {
        return (
            <Col sm={12}>
                <i style={{marginRight:"10px"}} onClick={()=>this.onClick(true)} className={`fa${this.state.isButtonActive === true ? 's' : 'l'} fa-check-circle`} />
                <i style={{marginLeft:"10px"}} onClick={()=>this.onClick(false)} className={`fa${this.state.isButtonActive === false ? 's' : 'l'} fa-times-circle`} />
            </Col>
            )
    }

}