import React, { Component } from "react";
import * as dynamolib from "../../../../libs/dynamoLib"
import Input from "../../../../components/form/inputs/Input";
import AddRole from "./AddRole";



export default class RoleManagement extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    handleUserSearch(){

    }

    render() {
        return (
            <div className="roles-menu wrapper">
                <div className="role-add-wrapper">
                    <AddRole />
                </div>
            </div>

        );
    }
}