import * as api from "./CatalogCalls"
import * as type from "../../constants/actions/CatalogActionTypes"
import { MageQL } from "../../graphql/MagentoClient";
import { GET_CONFIGURABLE_PRODUCTS } from "../../graphql/MagentoQueries";
import { DISABLED, IN_STOCK, OUT_OF_STOCK } from "../../constants/actions/CatalogAvailabilityTypes";

export const loading = (loading) => {
    return (
        {
            type:type.LOADING_PRODUCTS,
            loading:loading
        }
    )
};

export const clearProducts = () => {
    return (
        {
            type:type.CLEAR_PRODUCTS,
        }
    )
};

const addProducts = (products) => {
    return (
        {
            type:type.ADD_PRODUCTS,
            products:products
        }
    )
}

const addProductInfo = (products) => {
    return (
        {
            type:type.ADD_PRODUCT_INFO,
            products:products
        }
    )
}

export const selectSize = (sku,size) => {
    return (
        {
            type:type.SELECT_SIZE,
            sku,
            size
        }
    )
}

const fetchSearchedProducts = (query, store_view_code = 'default') => {
    return () => {
        return api.getSearchedProducts(query, store_view_code).then(response => {
            return response;
        })
    }
}

const fetchProductInfo = (products, store_view_code = 'default') => {
    return () => {
        return api.getProductInfo(products, store_view_code).then(response => {
            return response;
        })
    }
}

/**
 * Get the product's extra fulfilment information from Magento using GraphQL.
 *
 * @param skuList
 * @param store_view_code
 * @returns {(function())|*}
 */
function fetchExtraInfo(skuList, store_view_code = 'default') {
    return () => {
        return MageQL.query({
            query: GET_CONFIGURABLE_PRODUCTS(skuList),
            context: {
                headers: {
                    'store': store_view_code
                }
            }
        })
    };
}

/**
 * calls lambda functions required to build out a catalog product for the team portal.
 * @param query
 * @return {(function(*): void)|*}
 */
export function searchProducts(query) {
    return(dispatch,getState) => {
        let store_view_code = getState().authState?.store_view_code;
        let loading_status = getState().catalogState.isLoading;

        if(loading_status){
            return false;
        }else {
            dispatch(loading(true))
            dispatch(clearProducts())
            // Get products from Elasticsearch.
            dispatch(fetchSearchedProducts(query, store_view_code)).then(response => {
                if('products' in response){
                    dispatch(addProducts(response.products));

                    let config_products_skus = response['products'].map((product) => '"' + product['sku'] + '"');

                    dispatch(fetchExtraInfo(config_products_skus, store_view_code)).then(graphQLResponse => {
                        dispatch(addProductInfo(getFinalProductsInfoList(graphQLResponse.data.products.items, response['products'])));
                    }).catch(
                        error => {
                            console.log(error)
                        }
                    );

                    dispatch(loading(false));
                }else {
                    dispatch(loading(false))
                }
            })
        }
    }
}

/**
 * Provides the list of final product info to be displayed.
 * It uses the magento products and the dynamo products to get the price and stock availability of each product.
 * For each product, if the magento product has a special price, it assigns that as the final price or uses the regular price value.
 *
 * @param magento_products
 * @param original_products The product info fetched from Elasticsearch.
 * @returns {[]}
 */
function getFinalProductsInfoList(magento_products, original_products) {
    let final_products = [];

    for (let productInfo of original_products) {
        // Get the magento product that matches the current Elasticsearch product.
        // As the SKU of the item is always unique, we can always get a single identifiable product.
        let magento_product = magento_products.find(item => item.sku === productInfo['sku']);

        // If the config product is not in stock on M2 then disable the product.
        if (!magento_product || magento_product['stock_status'] !== IN_STOCK) {
            final_products.push({
                ...productInfo,
                status: DISABLED,
                final_price: 0
            });

            continue;
        }

        const price_info = magento_product['price_range']['minimum_price'];
        const regular_price = price_info['regular_price']['value'];
        const final_price = price_info['final_price']['value'];

        let product = {
            ...productInfo,
            regular_price: regular_price,
            final_price: final_price,
            on_sale: regular_price !== final_price,
            sizes: []
        };

        let product_availability = OUT_OF_STOCK;

        // To display the correct available size, loop through simple products provided by Magento.
        for (let simple_product of magento_product['variants']) {
            let size = simple_product['attributes'].find(item => item.code === 'erp_size');

            simple_product = simple_product['product'];
            // Add only the sizes available and `in stock` on M2.
            if (simple_product['stock_status'] === IN_STOCK && simple_product.hasOwnProperty('sku') && size.hasOwnProperty('label')) {
                product['sizes'].push({
                    simple: simple_product['sku'],
                    label: size['label'],
                    uid: simple_product['uid']
                })

                product_availability = IN_STOCK;
            }
        }

        product['status'] = product_availability;

        final_products.push(product);
    }

    return final_products;
}