import React, {Component} from 'react';
import {
    Col,
    Row,

    FormGroup
} from "react-bootstrap";

import Checkbox from '../../../components/form/inputs/Checkbox'
import {connect} from "react-redux";
import {
    handleWageDetails,
    handleRoleDetails,
    handleStage,
    submitChanges,
    handleError
} from '../../../actions/OnboardingActions'
import {updateRoleStatus} from '../../../actions/AuthActions'

import LoaderButton from "../../../components/LoaderButton";
import * as dynamo from "../../../libs/dynamoLib";
import {API} from "aws-amplify/lib/index";
import {toast} from "react-toastify";
import {updateUser} from "../../../actions/LambdaCalls";


const mapStateToProps = state => {
    return {jwt : state.authState.JWT, personalDetails: state.onboardingState.personalDetails, bankingDetails: state.onboardingState.bankingDetails, roleDetails: state.onboardingState.roleDetails,superDetails : state.onboardingState.superDetails,error:state.onboardingState.error };
};

const mapDispatchToProps = dispatch => {
    return {
        handleWageDetails : (name,value) => dispatch(handleWageDetails(name,value)),
        handleRoleDetails : (name,value) => dispatch(handleRoleDetails(name,value)),
        submitChanges : (type, data) => dispatch(submitChanges(type, data)),
        handleStage : (page) => dispatch(handleStage(page)),
        updateRoleStatus : (status) => dispatch(updateRoleStatus(status)),
        handleError : (error) => dispatch(handleError(error))
    };
};

class ConfirmationForm extends Component{
    constructor(props) {
        super(props);
        this.submitChanges = props.submitChanges;
        this.handleStage = props.handleStage;
        this.handleRoleDetails = props.handleRoleDetails;
        this.handleWageDetails = props.handleWageDetails;
        this.handleError = props.handleError;
        this.file = [];
        this.state = {
            isLoading : false,
            monday:false,
            tuesday:false,
            wednesday:false,
            thursday:false,
            friday:false
        }
    }

componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(prevProps)
}

    componentWillMount(){
        let mon,tue,wed,thur,fri,sat,sun = null;
        let user = this.props.personalDetails;
        if(user.availability){
            mon = user.availability.data.monday;
            tue = user.availability.data.tuesday;
            wed = user.availability.data.wednesday;
            thur = user.availability.data.thursday;
            fri = user.availability.data.friday;
            sat = user.availability.data.saturday;
            sun = user.availability.data.sunday;

        }
        if(mon[0].start === "na"){
            this.setState({monday:true})
        }
        if(tue[0].start  === "na"){
            this.setState({tuesday:true})
        }
        if(wed[0].start  === "na"){
            this.setState({wednesday:true})
        }
        if(thur[0].start  === "na"){
            this.setState({thursday:true})
        }
        if(fri[0].start === "na"){
            this.setState({friday:true})
        }
        if(sat[0].start  === "na"){
            this.setState({saturday:true})
        }
        if(sun[0].start  === "na"){
            this.setState({sunday:true})
        }

    }
    /**This function is sent to all children for validation. validation is done in parent level
     * as validation could be different for this components depending on where they are used. */
    getValidationState = (type) => {

    };

    getWagePay = async(data) =>{
        return await API.post("users", "wages/age",{
            body:{data}
        });
    };
    handleReviewChange(checked){
        this.setState({reviewDeclare:checked})
    }

    wageCalculator = async (cType,dob) => {

        let dobArray = dob.split('/');

        let currentDate = new Date();
        let birthDate = new Date(dobArray[2], dobArray[1], dobArray[0]);
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        let m = currentDate.getMonth() - birthDate.getMonth();

        if (m < 0 || (m === 0 && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }
        if (age >= 21) {
            age = 21;
        }
        if(age < 16) {
            age = 15
        }
        let data = {
            type:cType,
            age:age
        };

        return await this.getWagePay(data);

    };

    handleSubmit = async event => {
        event.preventDefault();
        if(this.props.error === true){
            this.handleError(false);
        }
        this.setState({ isLoading: true });
        let role = this.props.roleDetails;
        let userWageInfo = {
            userId:"",
            employment_classification:"C REL1",
            wage:"na",
            pay_type: "H"
        };
        if(role.applicant_status === "STAGE_3_ONBOARDING"){
            let role_details = this.props.roleDetails;
            role_details['applicant_status'] = role.trial_type;
            this.props.updateRoleStatus(role.trial_type);
            userWageInfo.userId=role.userId;
            let type = userWageInfo.employment_classification;
            let dateBirth = this.props.personalDetails.dob.data;
            console.log(dateBirth);
            userWageInfo.wage = await this.wageCalculator(type,dateBirth);
            await this.handleWageDetails(null,userWageInfo);
            await this.handleRoleDetails("applicant_status", role.trial_type);
            await this.submitChanges("wage",userWageInfo);
            await this.submitChanges("role",role_details);
            await updateUser("status",role.trial_type);
        }


        if(this.props.error === true){
            const options = {
                autoClose: 3000,
                type: toast.TYPE.ERROR,
                hideProgressBar: false,
                position: toast.POSITION.TOP_RIGHT,
                pauseOnHover: true,
            };
            this.setState({ isLoading: false });
            toast("Oops! Something went wrong, please try refreshing your browser and try again. If\n" +
                "                            problem persists, please contact hr@universalstore.com.au",options);
        }else {
            this.setState({ isLoading: false });
            this.props.handleStage(4);
        }


    };


    availabilityRender(user){
        let mon,tue,wed,thur,fri,sat,sun = null;
        if(user.availability){
            mon = user.availability.data.monday;
            tue = user.availability.data.tuesday;
            wed = user.availability.data.wednesday;
            thur = user.availability.data.thursday;
            fri = user.availability.data.friday;
            sat = user.availability.data.saturday;
            sun = user.availability.data.sunday;

        }
        return(
            <div>
                <div className={this.state.monday ? ' dayHidden' : 'review-availability-times'}>
                    <div>
                        <h4>Mon: </h4>
                    </div>
                    <div className="review-times-right">
                        {mon.map(m =>(
                            (m.start === "12:00am" && m.end === "12:00am")
                                ? <p className={"availability-day-info"}>All Day</p>
                                : (m.start !== "na" && m.end !== "na")
                                ? <p className={"availability-day-info"}>{m.start} - {m.end}<br /></p>
                                : null
                        ))}
                    </div>
                </div>

                <div className={this.state.tuesday ? ' dayHidden' : 'review-availability-times'}>
                    <div>
                        <h4>Tue: </h4>
                    </div>
                    <div className="review-times-right">
                        {tue.map(m =>(
                            (m.start === "12:00am" && m.end === "12:00am")
                                ? <p className={"availability-day-info"}>All Day</p>
                                : (m.start !== "na" && m.end !== "na")
                                ? <p className={"availability-day-info"}>{m.start} - {m.end}<br /></p>
                                : null
                        ))}
                    </div>
                </div>

                <div className={this.state.wednesday ? ' dayHidden' : 'review-availability-times'}>
                    <div>
                        <h4>Wed: </h4>
                    </div>
                    <div className="review-times-right">
                        {wed.map(m =>(
                            (m.start === "12:00am" && m.end === "12:00am")
                                ? <p className={"availability-day-info"}>All Day</p>
                                : (m.start !== "na" && m.end !== "na")
                                ?  <p className={"availability-day-info"}>{m.start} - {m.end}<br /></p>
                                :   null
                        ))}
                    </div>
                </div>

                <div className={this.state.thursday ? ' dayHidden' : 'review-availability-times'}>
                    <div>
                        <h4>Thu: </h4>
                    </div>
                    <div className="review-times-right">
                        {thur.map(m =>(
                            (m.start === "12:00am" && m.end === "12:00am")
                                ? <p className={"availability-day-info"}>All Day</p>
                                : (m.start !== "na" && m.end !== "na")
                                ? <p className={"availability-day-info"}>{m.start} - {m.end}<br /></p>
                                : null
                        ))}
                    </div>
                </div>
                <div id="fri" className={this.state.friday ? "dayHidden" : "review-availability-times fri"}>
                    <div>
                        <h4>Fri: </h4>
                    </div>
                    <div className="review-times-right">
                        {fri.map(m =>(
                            (m.start === "12:00am" && m.end === "12:00am" )
                                ? <p className={"availability-day-info"}>All Day</p>
                                : (m.start !== "na" && m.end !== "na")
                                ? <p className={"availability-day-info"}>{m.start} - {m.end}<br /></p>
                                : null
                        ))}
                    </div>
                </div>
                <div className={this.state.saturday ? ' dayHidden' : 'review-availability-times'} >
                    <div>
                        <h4>Sat: </h4>
                    </div>
                    <div className="review-times-right">
                        {sat.map(m =>(
                            (m.start === "12:00am" && m.end === "12:00am")
                                ? <p className={"availability-day-info"}>All Day</p>
                                : (m.start !== "na" && m.end !== "na")
                                ? <p className={"availability-day-info"}>{m.start} - {m.end}<br /></p>
                                : null
                        ))}
                    </div>
                </div>
                <div className={this.state.sunday ? ' dayHidden' : 'review-availability-times'}>
                    <div>
                        <h4>Sun: </h4>
                    </div>
                    <div className="review-times-right">
                        {sun.map(m =>(
                            (m.start === "12:00am" && m.end === "12:00am")
                                ? <p className={"availability-day-info"}>All Day</p>
                                : (m.start !== "na" && m.end !== "na")
                                ?<p className={"availability-day-info"}>{m.start} - {m.end}<br /></p>
                                : null
                        ))}
                    </div>
                </div>
            </div>

        )
    }

    renderReviewForm(user,bank,superA,roleDetails){

        return(
            <div>
                <div className="review-container">
                    <Row className = "personal-review-container">
                        <Col sm={3}>
                            <h3>Personal Details</h3>
                            {user ? <div>
                                <p>{user.fName.data}{" "+user.lName.data}</p>
                                    {user.address.data ?
                                        <div>
                                            <p>{user.address.data.street_one}</p>
                                            <p>{user.address.data.suburb}</p>
                                            <p>{user.address.data.postcode}</p>
                                            <p>{user.dob.data}</p>
                                            {(user.citizenship.data === 'au-citizen')
                                                ? <p>Australian Citizen</p>
                                                : (user.citizenship.data === 'nz-citizen')
                                                    ? <p>New Zealand Citizen</p>
                                                    : <p>Not an AU/NZ citizen</p>
                                            }
                                        </div> :null}
                                        </div>
                                        : null}



                        </Col>
                        {/*<Col sm={2}>*/}
                        {/*    <h3> Availability</h3>*/}
                        {/*    {this.availabilityRender(user)}*/}
                        {/*</Col>*/}
                        <Col sm={3}>
                            <h3> Emergency Contacts</h3>
                            {user.emergency.data ?
                                <div>
                                <p>{user.emergency.data.contactOne.fName+" "+user.emergency.data.contactOne.lName}</p>
                                <p>{user.emergency.data.contactOne.number}</p>
                                <p>{user.emergency.data.contactOne.relationship}</p>
                                <br />
                            {user.emergency.data.contactTwo ?
                                <div>
                                <p>{user.emergency.data.contactTwo.fName+" "+user.emergency.data.contactTwo.lName}</p>
                                <p>{user.emergency.data.contactTwo.number}</p>
                                <p>{user.emergency.data.contactTwo.relationship}</p>
                                </div>
                                : null}

                                </div>
                                : null}


                        </Col>
                        <Col sm={3}>
                            <h3>References</h3>
                            {user.user_references.data?
                                <div>
                                    <p>{user.user_references.data.contactOne.fName+" "+user.user_references.data.contactOne.lName}</p>
                                    <p>{user.user_references.data.contactOne.number}</p>
                                    <p>{user.user_references.data.contactOne.relationship}</p>
                                    <br />
                                </div>
                                :null}
                            {user.user_references.data?
                                <div>
                                    <p>{user.user_references.data.contactTwo.fName+" "+user.user_references.data.contactTwo.lName}</p>
                                    <p>{user.user_references.data.contactTwo.number}</p>
                                    <p>{user.user_references.data.contactTwo.relationship}</p>
                                    <br />
                                </div>
                                : null}
                        </Col>
                        <Col sm={3} className="button-review-container">
                            <LoaderButton
                                className="edit-user-button submit-button  "
                                variant={"dark"}
                                bsSize="large"
                                onClick = {()=> this.handleStage(1)}
                                isLoading={this.state.isLoading}
                                text="Edit Personal Details"
                                loadingText="Updating…"
                            />
                        </Col>
                    </Row>

                    <Row className = "bank-review-container">
                        <Col sm={2}>
                            <h3>Tax Details</h3>
                            <p>{"Tax File Number: "+bank.tfn.data}</p>
                            {(bank.tax_res.data === 'au-resident')
                                ? <p>An Australian resident for tax purposes</p>
                                : (bank.tax_res.data === 'foreign-resident')
                                    ? <p>A foreign resident for tax purposes</p>
                                    : <p>A working holiday maker</p>
                            }
                        </Col>
                        <Col sm={2}>
                            <h3>Bank Details</h3>
                            <p>{bank.bank_name.data}</p>
                            <p>{bank.account_name.data}</p>
                            <p>{bank.bsb.data+" "}{bank.account_number.data}</p>
                        </Col>
                        <Col sm={3}>
                            <h3>Super Details</h3>
                            {superA.sup_fund.data ? <div>

                                <p>{superA.sup_fund.data.name}</p>
                                <p>{superA.account_name.data}</p>
                                <p>{superA.account_number.data}</p>
                                <p>{'\n'}</p>
                            </div>:  <div>
                                <p>Universal Store will create you an account with our preferred superfund provider Sunsuper.</p>
                                <p>{'\n'}</p>
                            </div>
                            }

                        </Col>
                        <Col sm={4} className="button-review-container">
                            <LoaderButton
                                className="edit-user-button submit-button "
                                variant={"dark"}
                                bsSize="large"
                                onClick = {()=> this.handleStage(2)}
                                isLoading={this.state.isLoading}
                                text="Edit Payment Details"
                                loadingText="Updating…"
                            />
                        </Col>
                    </Row>

                </div>
                <form onSubmit={this.handleReviewSubmit}>
                    <FormGroup bsSize="large">
                        {(roleDetails.applicant_status !== "TRIAL" || roleDetails.applicant_status !=="INDUCTION") ?
                            <Checkbox text={"I declare the information I have given is true and correct. I understand there are penalties for deliberately making a false or misleading statement.\n"}
                                      onChange={e => this.handleReviewChange(e.target.checked)}>
                            </Checkbox>
                            :null}
                    </FormGroup>
                    {(roleDetails.applicant_status !== "TRIAL" || roleDetails.applicant_status !=="INDUCTION") ?
                        <div className="submit-review-button-container submit-button-container">
                            <LoaderButton
                                className="submit-button"
                                variant={"dark"}
                                bsSize="large"
                                type="submit"
                                disabled={!this.state.reviewDeclare}
                                isLoading={this.state.isLoading}
                                text="Submit"
                                loadingText="Updating…"
                            />
                        </div>
                        :null}

                </form>
            </div>
        )

    }
    render(){

        return (

            <form onSubmit={this.handleSubmit}  className="form-container">
                {this.renderReviewForm(this.props.personalDetails,this.props.bankingDetails,this.props.superDetails,this.props.roleDetails)}
            </form>

        )
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ConfirmationForm);