import React, { Component } from "react";
import {connect} from "react-redux";
import {Row, Col} from "react-bootstrap";
import * as dynamolib from "../../../../libs/dynamoLib"

import Input from '../../../../components/form/inputs/Input'
import Select from '../../../../components/form/inputs/Select'
import LoaderButton from "../../../../components/LoaderButton";
import {postRole} from "../../../../actions/RolesActions";
import {successNotification, errorNotification} from "../../../../actions/NotificationActions";
import PermissionsTree from "./PermissionsTree";

const mapStateToProps = state => {
    return {user: state.authState};
};

const mapDispatchToProps = dispatch =>{
    return {
        successNotification : message => {dispatch(successNotification(message))},
        errorNotification : message => {dispatch(errorNotification(message))}
    }
};



class AddRole extends Component {
    constructor(props){
        super(props);
        this.successNotification = this.props.successNotification;
        this.errorNotification = this.props.errorNotification;
        this.postRole = this.props.postRole;
        this.state = {
            permissions:[],
            roleName:'',
            roleId:'',
            selectedPermissions:[],
            manage:[],
            reportTo:[],
            isLoading: false
        }
    }

    componentDidMount() {
        this.createPermissionsOptions();
    }

    createPermissionsOptions(){
        let permissionOptions = [];
        let permissions = this.props.user.userPermissions;
        let selectedPermissions = this.state.selectedPermissions;
        for(let p of permissions){
            if(!selectedPermissions.includes(p)){
                let permissionObject = {value:p,label:p};
                permissionOptions.push(permissionObject);
            }
        }
        return permissionOptions
    }


    handlePermissionsChange = (permissions) => {
        console.log(permissions);
        this.setState( {
                permissions: permissions
        });
    };

    handleRoleChange = event => {
        let target = event.target.id;
        let value = event.target.value;
        this.setState({
            [target]:value,
        });
        let uppercase = value.toUpperCase();
        let id = uppercase.replace(/ /g,"_");
        this.setState({
            roleId:id
        })
    };


     handleCreateRole = async event =>{
        event.preventDefault();
        this.setState({isLoading: true});
        let roleData = {
            roleId:this.state.roleId,
            roleName: this.state.roleName,
            permissions:this.state.permissions,
            manage:this.state.manage,
            reportTo:this.state.reportTo
        };
        let result =await postRole(roleData);
        if(result.status){
            this.successNotification('role created')
        }
        else {
            this.errorNotification('role not created')
        }
         this.setState({isLoading: false});
    };

    render() {
        return (
          <div className="role-add">
              <form onSubmit = {this.handleCreateRole}>
                <Row>
                    <Col sm={4}>
                        <Input id="roleName" placeholder="Role Name" onChange={this.handleRoleChange} value={this.state.roleName} required={true} />
                    </Col>
                    <Col sm={4}>
                        <Input readonly id="roleId" placeholder="Role ID" onChange={true} value={this.state.roleId} required={true}/>
                    </Col>
                </Row>
                  <Row>
                      <Col sm={10} >
                          <PermissionsTree handlePermissionsChange = {this.handlePermissionsChange} />
                      </Col>
                  </Row>
                  <Row>
                      <Col sm={10}>
                      <LoaderButton
                          className="add-user-button large-button"
                          block
                          bsSize="large"
                          disabled={false}
                          type="submit"
                          isLoading={this.state.isLoading}
                          text="Create Role"
                          loadingText="Creating…"
                      />
                      </Col>
                  </Row>
              </form>

          </div>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(AddRole);