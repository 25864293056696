/*AUTH CONSTANTS*/
export const AUTHORISE_USER = "AUTHORISE_USER";
export const AUTHORISE_STORE = "AUTHORISE_STORE";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const IDLE_USER = "IDLE_USER";
export const UPDATE_ROLE_USER = "UPDATE_ROLE_USER";
export const LOAD_COGNITO_INFO = "LOAD_COGNITO_INFO";

/*STORES CONSTANTS*/
export const ADD_STORE = "ADD_STORE";
export const GET_STORE_STATE = "GET_STORES";
export const FETCH_STORES = "FETCH_STORES";
export const SET_CURRENT_STORE = "SET_CURRENT_STORE";
export const LOADING_STORES = "LOADING_STORES";
export const ADD_ROLE = 'ADD_ROLE';

/*APPLICANTS CONSTANTS*/
export const CLEAR_APPLICANTS = "CLEAR_APPLICANTS";
export const ADD_APPLICANT = "ADD_APPLICANT";
export const INIT_APPLICANTS = "INIT_APPLICANTS";
export const LIST_APPLICANTS = "LIST_APPLICANTS";
export const LOADING_APPLICANTS = "LOADING_APPLICANTS";
export const UPDATE_APPLICANT = "UPDATE_APPLICANT";
export const ADD_TO_VISIBLE_USERS = "ADD_TO_VISIBLE_USERS";
export const REPLACE_VISIBLE_USERS = "REPLACE_VISIBLE_USERS";
export const REPLACE_SEARCH_USERS = "REPLACE_SEARCH_USERS";
export const CLEAR_VISIBLE_USERS = "CLEAR_VISIBLE_USERS";
export const SET_FOCUSED_APPLICANT = "SET_FOCUSED_APPLICANT";
export const CLEAR_FOCUSED_APPLICANT = "CLEAR_FOCUSED_APPLICANT";
export const ADD_ALL_USERS = "ADD_ALL_USERS";


/*FORM CONSTANTS*/

export const CHANGE_ADDRESS = "CHANGE_ADDRESS";
export const CHANGE_DOB = "CHANGE_DOB";
export const CHANGE_GENDER = "CHANGE_GENDER";
export const CHANGE_CITIZENSHIP = "CHANGE_CITIZENSHIP";
export const CHANGE_AVAILABILITY = "CHANGE_AVAILABILITY";
export const CHANGE_ROLE_DETAILS = "CHANGE_ROLE_DETAILS";
export const CHANGE_REFERENCES = "CHANGE_REFERENCES";
export const CHANGE_EMERGENCY = "CHANGE_EMERGENCY";
export const CHANGE_APPLICANT_STATUS = "CHANGE_APPLICANT_STATUS";
export const UPDATE_USER = "UPDATE_USER";

// BANK - SUPER CONSTANTS
export const CHANGE_PERSONAL_DETAILS = "CHANGE_PERSONAL_DETAILS";
export const CHANGE_BANK_DETAILS = "CHANGE_BANK_DETAILS";
export const CHANGE_SUPER_DETAILS = "CHANGE_SUPER_DETAILS";


export const CHANGE_WAGE_DETAILS = "CHANGE_WAGE_DETAILS";

//SUPER CONSTANTS
export const LOAD_SUPER_DETAILS = "LOAD_SUPER_DETAILS";
export const LOAD_WAGE_DETAILS = "LOAD_WAGE_DETAILS";
export const LOAD_PERSONAL = "LOAD_PERSONAL";
export const LOAD_ROLE_DETAILS = "LOAD_ROLE_DETAILS";
export const LOAD_BANK_DETAILS = "LOAD_BANK_DETAILS";
export const LOAD_APPROVAL_ATTRIBUTES = "LOAD_APPROVAL_ATTRIBUTES";
export const LOAD_PERSONAL_ATTRIBUTES = "LOAD_PERSONAL_ATTRIBUTES";
export const HANDLE_PAGE = "HANDLE_PAGE";
export const HANDLE_ERROR = "HANDLE_ERROR";


//test constant
export const CHANGE_FORM_DETAILS = "CHANGE_FORM_DETAILS";
export const SET_FOCUSED_INPUT = "SET_FOCUSED_INPUT";


//dynamic form constants
export const INIT_DATA_STRUCTURE = "INIT_DATA_STRUCTURE";
export const ADD_COMPONENT = "ADD_COMPONENT";
export const UPDATE_FORM_STRUCTURE = "UPDATE_FORM_STRUCTURE";
export const CREATE_FORM_STRUCTURE = "CREATE_FORM_STRUCTURE";


/*COGNITO CONSTANTS*/
export const CREATE_USER = "CREATE_USER";



/*USER CONSTANTS*/
export const LOADING_USERS = "LOADING_USERS";
export const SEARCH_USER = "SEARCH_USER";
export const EDIT_USER = "EDIT_USER";

/*ROLE CONSTANTS*/
export const CREATE_ROLE = "CREATE_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";


/*
ADMIN CONSTANTS
 */
export const ADMIN_IS_LOADING = "ADMIN_IS_LOADING";