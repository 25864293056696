import React, { Component } from "react";
import {Row, Col} from "react-bootstrap";
import LoaderButton from "../../../../components/LoaderButton";
import "../../../Add.css";
import Select from '../../../../components/form/inputs/Select'

import {connect} from "react-redux";
import {createUser, theFridayThing} from "../../../../actions/UsersActions"
import {getStores, getMembersOfStore,} from "../../../../actions/StoreActions";
import {loadRoleFromStores} from "../../../../actions/RolesActions"

const mapStateToProps  = state => {
return {stores:state.storesState.stores, isLoading: state.adminState.isLoading}
};

const mapDispatchToProps = dispatch => {
    return {
        createUser : (cognitoParams) => dispatch(createUser(cognitoParams)),
        getStores : () => dispatch(getStores()),
        theFridayThing : (userId) => dispatch(theFridayThing(userId))
    }
};
class SearchUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stores_dropdown: [{value: '0', label: "ALL"}],
            stores: [],
            selected_store : null,
            selected_role : null,
            roles_dropdown : [{value: '0',label:"ALL"}],
            users_dropdown : null,
            isLoading: false,
            selectedUser:null,
            isRoleChange : false,
            selected_new_role: null
        };
    }
     componentDidMount() {
         this.props.getStores();
    }
    componentDidUpdate(prevProps) {
        if (this.props.stores !== prevProps.stores) {
            this.setStoreDropdown();
        }
    }

    setStoreDropdown = () => {
        let tempStores = this.props.stores;
        let storesDropdown = [];
        for(let store of tempStores){
            let store_entry = {value: store.storeId, label: store.storeName};
            storesDropdown.push(store_entry);
        }
        this.setState({stores_dropdown:storesDropdown});
    };

    handleStoreChange = async(event)=> {
        let state = this.state;
        state['selected_store'] = event;
        this.setState({
            state
        });
        let roles = await loadRoleFromStores(event.value);
        this.setState({roles_dropdown:roles});
    };

    handleDropdownChange = async(type,event) => {
        let state = this.state;
        state[type] = event;

        this.setState({
            state
        });
    };


    handleUserChange = async(event) => {
        let state = this.state;
        state['selectedUser'] = event;

        this.setState({
            state
        });
    };

    handleRoleDropdownChange = async(type,event) => {
        let state = this.state;
        state[type] = event;

        this.setState({
            state
        });
    };

    handleSearch =async(event)=> {
        event.preventDefault();
        this.setState({isLoading:true});
        let store = this.state.selected_store.value;
        let role = this.state.selected_role.value;
        let users =  await getMembersOfStore(store,role);
        console.log(users);
        this.setState({users_dropdown:users});
        this.setState({isLoading:false});
    };

    handleFridayThing = (event) => {
        event.preventDefault();
        console.log(this.state.selectedUser.value);
        this.props.theFridayThing(this.state.selectedUser.value);
    };

    isRoleChange = () => {
        this.setState({isRoleChange:true});
    };

    render(){
        return(
            <div className="page-div">
                    <h1 className="heading-text">Search for a user</h1>
                    <Row>
                        <Col sm={4}>
                            <Select
                                label = {"Select a store"}
                                className={"select"}
                                value={this.state.selected_store}
                                onChange={(e) => this.handleStoreChange(e)}
                                options={this.state.stores_dropdown}
                                id={"store-search"}/>
                        </Col>
                        <Col  sm={5}>
                            <Select
                                label = {"Select Role"}
                                className={"select"}
                                value={this.state.selected_role}
                                onChange={(e) => this.handleDropdownChange('selected_role', e)}
                                options={this.state.roles_dropdown}
                                id={"store-search"}/>
                        </Col>
                        <Col sm={3}>
                            <form onSubmit={this.handleSearch}>
                            <LoaderButton
                                className="small-button"
                                block
                                bsSize="large"
                                disabled={false}
                                type="submit"
                                text="Search"
                                loadingText="Sending…"
                            />
                            </form>
                        </Col>
                    </Row>
                    <Row>
                        {
                            (this.state.isLoading)?
                                <div className="loading-spinner loading-spinner-blue"></div>
                                :
                                this.state.users_dropdown === null ?
                                    <span>select a store and role to begin search</span>
                                    :
                                    <Row>
                                        <Col sm={6}>
                                            <Select
                                                label = {"Select User"}
                                                className={"select"}
                                                value={this.state.selectedUser}
                                                onChange={(e) => this.handleUserChange(e)}
                                                options={this.state.users_dropdown}
                                                id={'select-user-select'}
                                            />
                                        </Col>
                                        {this.state.selectedUser ?
                                            <div>
                                                <Col sm={3}>
                                                    <form onSubmit={this.handleFridayThing}>
                                                        <button
                                                        className="small-button"
                                                        disabled={false}
                                                        type="submit"
                                                        >Reset Account</button>
                                                    </form>
                                                </Col>
                                                <Col sm={3}>
                                                    <form onSubmit={this.isRoleChange}>
                                                        <button
                                                        className="small-button"
                                                        disabled={false}
                                                        type="submit"
                                                        >Change Role</button>
                                                    </form>
                                                </Col >
                                            </div>
                                            :null
                                        }
                                    </Row>
                        }
                    </Row>
                    <Row>
                        {
                            (this.state.isRoleChange) ?
                                <div>
                                    <Row>
                                        <Col  sm={5}>
                                            <Select
                                                label = {"Select New Role"}
                                                className={"select"}
                                                value={this.state.selected_new_role}
                                                onChange={(e) => this.handleRoleDropdownChange('selected_new_role', e)}
                                                options={this.state.roles_dropdown}
                                                id={"store-search"}/>
                                        </Col>
                                    </Row>
                                </div>
                                : null
                        }
                    </Row>



            </div>
        )
    }
}

export default connect( mapStateToProps,mapDispatchToProps)(SearchUser);

