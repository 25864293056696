import React from 'react';
import Form from "react-bootstrap/Form";
import PropTypes from 'prop-types'
import "./inputs.scss"
const Input = (props) => (
    <Form.Group className={props.gap ?? "mb-4"} controlId={props.controlId}>
        <Form.Label>{props.label}</Form.Label>
            <Form.Control
                className = {"uni"}
                onFocus = {props.onFocus}
                name = {props.name}
                placeholder ={props.placeholder}
                type={props.type}
                as={props.as}
                value={props.value}
                onChange={props.onChange}
                required = {props.required}
                onBlur = {props.onBlur}
                readOnly={props.readOnly}
                isValid = {props.isValid}
                isInvalid = {props.isInvalid}
            />
            <Form.Control.Feedback type="invalid">
                {props.error}
            </Form.Control.Feedback>
        { props.help ? <Form.Text className={"show-help"}>{props.help}</Form.Text> : null}
    </Form.Group>
);

Input.propTypes = {
    controlId:PropTypes.string.isRequired,
    name:PropTypes.string.isRequired,
    onValidate:PropTypes.func,
    onFocus:PropTypes.func,
    placeholder: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value:PropTypes.string.isRequired,
    label:PropTypes.string,
    required:PropTypes.bool,
    help:PropTypes.string,
    type:PropTypes.string,
    componentClass:PropTypes.string,
    readOnly: PropTypes.bool,
    error:PropTypes.string
};

export default Input;

//onChange={(e) => this.inputHandle('store',"home_store",e)}