import React, { Component } from "react";

import {ListGroup, ListGroupItem } from "react-bootstrap";
import { Row,Col } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import "../Home.css";
import Select from "../../components/form/inputs/Select"
import Input from "../../components/form/inputs/Input"
import DateInput from "../../components/form/inputs/DateInput"
import ToolTip from '../../components/form/inputs/ToolTip'


import {
    FormGroup,
    FormControl,
} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "../Tasks.css";
import "./christmas.css"
import moment from "moment/moment";



export default class Tasks extends Component {
    constructor(props) {
        super(props);
        this.file = [];
        this.child = React.createRef();

        this.state = {
            tasks: [],
            tfnUpload: "",
            search_word_string: "",
            isLoading: false,
            visible_tasks: [],
            userId: "",
            allUsers: "",
            session: "",
            users_loaded: false,
            christmas_applicants: null,
            end_date: "01/02/2020",
            isDenyloading: false,
            showDeny: "",
            termination_reason: {
                reason: "na",
                comment:"na"
            },
            isRemove : false,
            terminationOptions : [
                {value: 'end of contract', label: "End of Christmas casual contract"},
                {value: 'Unsuccessful_Trial', label: "Unsuccessful trial"},
                {value: 'Cancelled trial', label: "Cancelled Trial"},
                ,{value: 'resignation', label: "By resignation"},
                {value: 'other', label: "By some other manner"},

            ],
            selectedOption:null,
            selectedUser : ""
        };
    }

    async componentDidMount() {
        let user = await Auth.currentAuthenticatedUser();
        this.setState({userId: user.username})

        try {
            Auth.currentSession().then(
                session => {
                    this.setState({session:session});
                    this.getChristmasCasuals(session)
                });


        } catch (e) {
            console.log(e);
        }

    }

    getUserRole = username =>{
        return API.get("users", `users/roles/get/${username}`);
    };

    getManagedUsers =(session) => {
        return API.post("users", "users/managed", {
            body: {
                token: session.accessToken.jwtToken
            }
        });
    };
    dateNow() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();

        if(dd < 10) {
            dd = '0'+dd
        }

        if(mm < 10) {
            mm = '0'+mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }
    getChristmasCasuals(session){
        let christmasApplicants = [];
        this.getManagedUsers(session).then(users => {
            console.log(users);
            for (let i in users){
               if(users[i].christmas_casual && (users[i].applicant_status !== 'PENDING_TERMINATION' && users[i].applicant_status !== 'TERMINATED' && users[i].applicant_status !== 'UNSUCCESSFUL_TRIAL') ){
                   christmasApplicants.push(users[i]);
               }
            }
            console.log(christmasApplicants);
            this.setState({christmas_applicants:christmasApplicants})
        })

    }

    handleClick = async(userId, action) => {
        let userRole = await this.getUserRole(userId);

        if(action === "approved"){
            let loading = []
            loading[userId] = true;
            this.setState({selectedUser:userId});
            userRole.applicant_status = "SUCCESSFUL_APPLICANT";
            userRole.christmas_casual = false;
            await API.put("users",`roles/update/${userId}`,{
                body: userRole
            });

            await API.post("users", `users/applicants/status/update/successful/${false}`, {
                body: {
                    userId: userId,
                    start_date: this.dateNow()

                }
            });
            this.setState({selectedUser:""})
            this.getChristmasCasuals(this.state.session)

        }else if (action === "denied"){
            //this.getChristmasCasuals(this.state.session)
            this.setState({showDeny : userId})
        }

    };

//     await API.post("users", "applicants/status/update/unsuccessful", {
//     body: {
//         userId: userId,
//         termination_reason: this.state.termination_reason,
//         end_date: this.state.end_date
//     }
// });

    renderConfirmationAction(userId){
        return (
            <Col sm={12}>

                <div className={"button-wrapper"}>
                    <Col sm={6}>
                        <ToolTip type="help"
                                 placement="bottom"
                                 trigger="focus"
                                 message="This will send the team member a new, ongoing casual contract & PD to accept via email and SMS">
                            <LoaderButton
                                block
                                type="submit"
                                text="Stay"
                                id={userId}
                                isLoading={userId === this.state.selectedUser}
                                value={"approved"}
                                disabled = {this.state.selectedUser !== ""}
                                onClick={(e) => this.handleClick(userId, "approved")}
                                className={"approve-button small-button"}
                            />
                        </ToolTip>
                    </Col>
                    <Col sm={6}>
                        <ToolTip type="help"
                                 placement="bottom"
                                 trigger="focus"
                                 message=" if the team member is leaving or has already left">
                        <LoaderButton
                            block
                            type="submit"
                            text="Remove"
                            isLoading={false}
                            onClick={(e) => this.handleClick(userId, "denied")}
                            disabled = {this.state.selectedUser !== ""}
                            className={"deny-button small-button"}
                        />
                        </ToolTip>
                    </Col>


                </div>

            </Col>
        )
    }

    handleTerminationChange = event =>{
        let reason = this.state.termination_reason;
        reason.comment= event.target.value;
        this.setState({
            termination_reason:reason
        });

    };
    handleSelectChange = event =>{
        let reason = this.state.termination_reason;
        console.log(this.state.termination_reason.reason)
        reason.reason = event.label;

        this.setState({termination_reason:reason,selectedOption:event})
    };

    handleDenySubmit = async (event,userId) => {
        event.preventDefault();
        this.setState({ isDenyLoading: true });
        try{
            await API.post("users", "users/applicants/status/update/unsuccessful", {
                body: {
                    userId: userId,
                    termination_reason: this.state.termination_reason,
                    end_date: this.state.end_date,
                    terminate:true
                }
            });
            this.getChristmasCasuals(this.state.session);
            this.setState({selectedUser: "", selectedOption: null, termination_reason :{
                    reason: "na",
                    comment:"na"
                } });
            this.setState({ isDenyLoading: false });
        } catch (e) {
            console.log(e);
        }

    };

    validateForm() {
        let end_Date =this.state.end_date;
        var date = moment(end_Date, 'DD/MM/YYYY',true);
        var endDate = moment(new Date(2090, 2, 1));
        var startDate   = moment(new Date(1990, 2, 1));

        if(date.isBetween(startDate,endDate) &&  this.state.termination_reason.reason !== "na") {
            return true
        }
    }

    handleDateChange = (value) => {
        let date = this.state.end_date;
        date = value;
        this.setState({
            end_date:date,
        });

    };

    getValidationState(type) {
        if (type === "end_date") {
            const trialDate = this.state.end_date;
            var date = moment(trialDate, 'DD/MM/YYYY',true);
            var today = moment(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1));

            var endDate = moment(new Date(2090, 2, 1));
            var startDate   = moment(new Date(1990, 2, 1));

            if (date.isValid()) {

                if (date.isBetween(startDate, endDate)) {

                    return 'success'
                } else if (!date.isBetween(startDate, endDate)) {
                    return 'error'
                }
            } else if (trialDate.length > 3 && trialDate.length < 10) {

                return 'error'
            } else return null
        }

    }

    renderDenyForm(userId){
        return (

            <form className={"deny-form"} onSubmit = {(event) => this.handleDenySubmit(event,userId)}>
                <Row>
                    <Col sm={4} md = {4} lg={3}>
                        <Select id={"termination_reason"}
                                label = {'Reason for termination'}
                                options={this.state.terminationOptions}
                                selectedOption={this.state.selectedOption}
                                required={true}
                                onChange={this.handleSelectChange}
                        />
                    </Col>
                    <Col sm={3} md = {3} lg={3}>
                        <DateInput id={"termination date"} onValidate={this.getValidationState("end_date")} onChange={(e)=>this.handleDateChange(e.target.value)} value={this.state.end_date} label={"Effective date"} required={true}/>
                    </Col>
                </Row>
                <Row>

                    <Col sm={7} md = {7} lg={6}>
                        <Input
                            name={"comment"}
                            label={"Additional Comments (e.g. Would hire again)"}
                            id={"termination_reason"}
                            placeholder={""}
                            required={false}
                            componentClass="textarea"
                            value={this.state.termination_reason.comment === "na" ? "" :this.state.termination_reason.comment}
                            onChange={this.handleTerminationChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={7} md = {7} lg={6}>

                        <LoaderButton
                            block
                            bsSize="large"
                            disabled={!this.validateForm()}
                            type="submit"
                            isLoading={this.state.isDenyLoading}
                            className={"offer-button terminate-button"}
                            text="Remove Team Member"
                            loadingText="Removing…"
                        />

                    </Col>
                </Row>
            </form>
            )

    }

    handleSearch = async(event) => {
        let searchQuery = event.target.value.toLowerCase();
        let splitQuery = searchQuery.split(' ');
        let numberString = "";
        let wordString = "";

        for (var i in splitQuery) {
            let element = splitQuery[i];
            wordString += element;

        }

        await this.setState({
            search_word_string: wordString,
        });

        await this.updateVisibleTasks();

    };


    renderUserList(users) {
        if(users){
            if(users.length > 0){
                return users.map(
                    (user, i) =>
                        <ListGroupItem key={user.userId} className={"christmas-casual"}>
                            <Row className={"task-wrapper"}>
                                <Col sm ={1} className = {"task-type"} ><img src={require("../../images/xmas-tree.svg")}/></Col>
                                <Col sm={6} >
                                    <span  className={"task-name"}>({user.home_store})</span>
                                    <span  className={"task-name"}>{user.first_name + ' ' + user.last_name + ' '}</span>

                                </Col>
                                <Col sm={5} className={"task-action"}>
                                    {this.renderConfirmationAction(user.userId)}
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    {this.state.showDeny === user.userId ?
                                        this.renderDenyForm(user.userId)
                                        : null}
                                </Col>

                            </Row>

                        </ListGroupItem>
                );
            }else if (users.length === 0){
                return <div>All Done! No Christmas Casuals to process</div>;
            }

        } else {
            return <div>Loading Users...</div>;
        }

    }


    render(){
        return (
            <div className={"wrapper"}>
                <Row className={"heading"}>
                    <Col sm={8}>
                        <h1 className={"heading-text"}>Your Christmas Casuals</h1>
                        <div className={"small-heading-text"}>Christmas Casual contracts end on 01/02/2021. Let us know before then, should they stay or should they go?</div>
                    </Col>
                    <Col sm={4}>
                        {/* <input type="text" className="search" placeholder="Search by store..." onChange={this.handleSearch}/> */ }
                    </Col>
                </Row>
                <div className={"list"}>
                    <div>
                        <ListGroup>
                            {this.renderUserList(this.state.christmas_applicants)}
                        </ListGroup>
                    </div>
                </div>
            </div>
        )
    }
}