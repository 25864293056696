import React, { Component } from "react";

import {ListGroup, ListGroupItem } from "react-bootstrap";
import {Row,Col} from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import "./Home.css";
import {protectedDownload, publicDownload, s3CustomUpload, s3Upload} from "../libs/awsLib";
import {
    FormGroup,
    FormControl,
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Tasks.css";
import {Storage} from "aws-amplify/lib/index";
import {connect} from "react-redux";
let JSZip = require('jszip')


var fileDownload = require('js-file-download');

const mapStateToProps = state => {
    return {jwt : state.authState.JWT, username:state.authState.username, cogId: state.authState.identityId};
};

class Tasks extends Component {
    constructor(props) {
        super(props);
        this.file = [];

        this.state = {
            fileNames : [],
            tasks : [],
            tfnUpload: "",
            search_word_string : "",
            isLoading:false,
            visible_tasks:[],
            userId:"",
            jwt:props.jwt
        };
    }

    async componentDidMount(){
        this.getTasks(this.props.username).then(this.processTasks).catch(this.processFail);
        let user = await Auth.currentAuthenticatedUser();
        console.log(user);

    }

    getTasks = username => {
        return API.get("users", `users/user/tasks/${username}`);
    };

    async handleUpload(task) {
        var zip = new JSZip();
        let attachmentPC ="";
        let userId = this.state.userId;
        const self = this;
        let upload = "";
        let jwt = this.props.jwt;
        if(this.file.length > 1){
            attachmentPC = userId+"-PC.zip"
            for (let i in this.file){
                zip.file(self.file[i].name,self.file[i],{base64: true})
            }
            zip.generateAsync({type:"blob"}).then(await function(content) {
                s3CustomUpload(content,attachmentPC,self.props.cogId);
                // s3Upload(content,attachmentPC)
                upload = {
                    userId: self.props.username,
                    type: task.identifier,
                    attribute_type: task.attribute_type,
                    filename: attachmentPC,
                    attachment: attachmentPC,
                    token: jwt
                };
                API.post("users", "users/upload" , {
                    body: upload
                })
            });
            await this.processTasks(this.getTasks(this.props.username));

        }else if(this.file.length) {
            attachmentPC = await s3CustomUpload(this.file[0],this.file[0].name,this.props.cogId);
            console.log(attachmentPC);
            upload = {
                userId: this.props.username,
                type: task.identifier,
                attribute_type: task.attribute_type,
                filename: attachmentPC.fileName,
                attachment: attachmentPC.fileName,
                token: jwt
            };
            await API.post("users", "users/upload" , {
                body: upload
            }).then(response =>{
                this.processTasks(this.getTasks(this.props.username));
            });;
        }
    }

    handleFileChange = async (task, event) => {
        const fileNames = [];
        console.log(this.file);
        if(event.target.files.length > 1){
            for (let i=0; i <(event.target.files.length); i++){
                this.file.push(event.target.files[i]);
                fileNames.push(event.target.files[i]);
            }
        }else {
            this.file.push(event.target.files[0]);
            fileNames.push(event.target.files[0]);
        }

        this.setState({fileNames: this.state.fileNames.concat(fileNames)})
        // await this.handleUpload(task)
    };

    clearTask = async (taskId) => {
        await API.post("users", "users/tasks/delete" , {
            body: {
                taskId: taskId
            }
        });
        this.processTasks(await this.getTasks(this.props.username));
    };

    getUploadInfo = async (userId,attribute_type) => {
        console.log(userId);
        let type = "";
        if (attribute_type === "HR_TFN"){
            type = "tfn"
        }else if (attribute_type === "HR_RESUME" ){
            type ="resume"
        }else if (attribute_type === "HR_IDCHECK" ){
            type ="idcheck"
        }
        let personalResponse = await API.get("users",`users/${userId}`);
        let response = await API.post("users", "users/uploads/applicants", {
            body: {
                userId: userId
            }
        });

        if (response.Count > 0) {
            let files = response.Items;
            for(var a in files){
                if(files[a].file_type.toLowerCase() === type){
                    if(personalResponse.cogId){
                        await protectedDownload(personalResponse.cogId,files[a].file);
                    }else {
                        await publicDownload(files[a].file);
                    }
                }
            }
        }
    };

    handleFileDownload = async(userId, e) => {
        this.getUploadInfo(userId).then(file =>{

        })
    };

    handleClick = async (taskId,userId,file_status, attribute_type) => {
        let session = await Auth.currentSession();
        let type = "";
        if (attribute_type === "HR_TFN"){
            type = "tfn"
        }else if (attribute_type === "HR_RESUME" ){
            type ="resume"
        }
        else if (attribute_type === "HR_IDCHECK" ){
            type ="idcheck"
        }
        else if (attribute_type === "HR_PC" ){
            type ="pc"
        }

        API.post("users", "users/uploads/applicants", {
            body: {
                userId: userId
            }
        }).then(response => {

            if (response.Count > 0) {

                let files = response.Items;
                for(var a in files){
                    let file = files[a]
                    if (file.file !== null && file.file_type.toLowerCase() === type) {
                        try {
                            if(file.file_status !== 'denied'){
                                API.post("users", "users/uploads/status/change", {
                                    body: {
                                        file: file.file,
                                        file_status: file_status,
                                        file_type: type,
                                        userId: userId,
                                        file_category: "applicant",
                                        token: session.accessToken.jwtToken
                                    }
                                }).then(response =>{
                                    console.log(response)
                                });
                            }


                        } catch (e) {
                            console.log(e);
                        }
                    }


                }


            }

            this.clearTask(taskId)
        });

    };

    renderUploadAction(task){
        return(
            <Row>
                <Col sm={6} md={7}>
                    <FormGroup controlId="file" className={"selectFileButton"}>
                        <FormControl className="inputfile inputfile-2 " multiple onChange={this.handleFileChange.bind(this, task)} type="file" />
                        <label htmlFor="file">
                            <span>Select Files</span>
                        </label>
                        <div> {this.state.fileNames.map(function(file, index){
                            return <span className={'inputfileName'} key={index}> {file.name} <br/></span>
                        })} </div>
                        <div><span style={{fontStyle: "italic"}}>You can select multiple files if needed.</span></div>
                    </FormGroup>
                </Col>
                <Col sm={6} md={5}>
                    {this.state.fileNames.length > 0 ?
                        <FormGroup>
                            <FormControl className="inputfile inputfile-2 uploadFileButton" onClick={() => this.handleUpload(task)}/>
                            <label htmlFor="file">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                    <path
                                        d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                </svg>
                                <span>Upload Files</span>
                            </label>
                        </FormGroup>
                        : null}

                </Col>
            </Row>
        )
    }

    updateVisibleTasks =  async () => {
        let tasks = this.state.tasks;

        let wordString = this.state.search_word_string;

        let match = this.searchMatch;
        if(wordString === ""){
            await this.setState({
                visible_tasks: tasks,
            });
        }else {
            let displayedTasks = tasks.filter(function(task) {

                let visibleCandidate = true;
                visibleCandidate = match(wordString, task);

                if (visibleCandidate) {
                    return task;
                }

            });

            await this.setState({
                visible_tasks: displayedTasks,
            });
        }

    };

    searchMatch =(wordString, task) => {

        let type= "";
        if (task.attribute_type === 'HR_TFN'){
            type ="tfn"
        }else if (task.attribute_type === 'HR_RESUME'){
            type ="resume"
        } else if (task.attribute_type === 'HR_IDCHECK'){
            type ="id"
        }
        else if (task.attribute_type === 'HR_PC'){
            type ="pc"
        }
        let searchName = (type);


        // Check if the word matches
        if (searchName.indexOf(wordString) !== -1 && wordString !== '') {

                return searchName.indexOf(wordString) !== -1;
            // Check if just the number matches
        }
    }

    handleSearch = async(event) => {
        let searchQuery = event.target.value.toLowerCase();
        let splitQuery = searchQuery.split(' ');
        let numberString = "";
        let wordString = "";

        for (var i in splitQuery) {
            let element = splitQuery[i];
                wordString += element;

        }

        await this.setState({
            search_word_string: wordString,
        });

        await this.updateVisibleTasks();

    };

    renderApproveAction(task){

        return(
                <Row>
                    <Col sm={4} xs={12}>
                            <span className={"file-type"}>{}</span>
                            <div className={"download-wrapper"} onClick = {(e)=>this.getUploadInfo(task.userId,task.attribute_type)}>
                                <img src={require("../images/download-icon.svg")}/>
                                <span>Download</span>
                            </div>
                    </Col>
                    <Col sm={8} xs={12}>

                            <div className={"button-wrapper"}>
                                <LoaderButton
                                    block
                                    type="submit"
                                    text="Approve"
                                    isLoading={this.state.isLoading}
                                    value={"approved"}
                                    file={task.attribute}
                                    onClick={(e) => this.handleClick(task.taskId,task.userId, "approved", task.attribute_type)}
                                    className={"approve-button small-button"}
                                />
                                <LoaderButton
                                    block
                                    type="submit"
                                    text="Deny"
                                    isLoading={this.state.isLoading}
                                    onClick={(e) => this.handleClick(task.taskId,task.userId, "denied", task.attribute_type)}
                                    className={"deny-button small-button"}
                                />
                            </div>

                    </Col>
                </Row>
        )
    }

    renderConfirmationAction(taskId){
        return(
            <FormGroup controlId="file">
                <LoaderButton
                    block
                    className="task-confirm"
                    bsSize="large"
                    type="submit"
                    onClick={(e) => this.clearTask(taskId)}
                    text="Mark As Complete"
                />
            </FormGroup>
        )
    }

    processStatus = (status) =>{
        this.setState({status});

    };

    processTasks = (tasks) =>{
        this.setState({tasks,visible_tasks:tasks});

    };
    processFail=(err) => {
        console.log("failed: " + err);
    };

    renderTasksList(tasks) {
        if(tasks.length > 0){
            return tasks.map(
                (task, i) =>
                    <ListGroupItem key={i}>
                        <Row className={"task-wrapper"}>
                            <Col sm ={2} xs={2} className = {"task-type"} >{task.identifier}</Col>
                            <Col sm={6} xs={10} className={"task-name"} dangerouslySetInnerHTML={{__html: task.message}} />
                            <Col sm={4} xs={12} className={"task-action"}>
                                {task.attribute_type === "CONFIRMATION"
                                    ? this.renderConfirmationAction(task.taskId)
                                    : task.context_addon !== undefined
                                        ? this.renderUploadAction(task)
                                        : (task.attribute_type === "HR_TFN" || task.attribute_type === "HR_RESUME" || task.attribute_type === "HR_IDCHECK" || task.attribute_type === "HR_PC") ? this.renderApproveAction(task) : null
                                }
                            </Col>
                        </Row>
                    </ListGroupItem>
            );
        }else{
            return <div>No tasks</div>;
        }

    }

    render() {
        return (
            <div className={"wrapper"}>
                    <Row className={"heading"}>
                        <Col sm={6}>
                            <div className={"heading-text"}>Your Tasks</div>
                            <div className={"small-heading-text"}>Here are all your tasks:</div>
                        </Col>
                        <Col sm={6}>
                            <input type="text" className="search" placeholder="Search by type..." onChange={this.handleSearch}/>
                        </Col>
                    </Row>
                <div className={"list"}>
                    <div>
                        <ListGroup>
                            {this.renderTasksList(this.state.visible_tasks)}
                        </ListGroup>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps,null)(Tasks);