import React, { Component } from "react";
import AddStore from "./AddStore";
import EditStore from "./EditStore";


export default class StoreManagement extends Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    handleUserSearch(){

    }

    render() {
        return (
            <div className="store-menu wrapper">
                <div className="store-add">
                    <EditStore />
                </div>
            </div>
        );
    }
}