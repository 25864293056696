import {API} from "aws-amplify/lib/index";

/*
ROLES LAMBDA CALLS
 */
export const createRole = (roleData) => {
   return API.post('users','users/role/create',{
       body:roleData
   })
};
export const getRole = (roleId) => {
    return API.post('users','users/role/get', {
        body:roleId
    })
};

export const loadRoles = () => {
    return API.get('users','users/role/load')
};

/*
STORES LAMBDA CALLS
 */

export const getStores =() => {
    return API.get("stores", "get/all");
};

export const getSingleStore= (store) =>{
    return  API.get("stores",`get/${store}`)
};
export const updateStore = store =>{
    return API.post("users",`stores/update/${store.storeId}`,{
        body:store
    })
};

/*
USER INFO LAMBDA CALLS
 */

export const getUserInfo = userId =>{
    return API.get("users",`users/${userId}`);
};

export const getBank = userId => {
    return API.get("users", `users/bank/${userId}`);

};

export const getSuperInfo = userId => {
    return API.get("users", `users/super/${userId}`);

};

export const getWageInfo = userId =>{
    return API.get("users", `wages/get/${userId}`);
};

export const getPersonalAttributes = userId => {
    return API.get("users", `users/user/personalatts/${userId}`);
};

export const getApprovalAttributes = userId => {
    return API.get("users", `users/user/approvalatts/${userId}`);
};

export const getUserRole = username => {
    return API.get("users", `users/roles/get/${username}`);
};

export const deleteCognitoUser=(user) => {
    return API.post("users", `users/delete`, {
        body: {username: user}
    })
};

export const deleteDynamoUser=(user) => {
    return API.post("users", `users/delete`, {
        body: {type:"DATABASE", username: user}
    })
};


export const getElasticSearch = (user,query) => {
    return API.post("users","users/elastic/query/role",{
        body: {
            user,
            queryOptions:query
        }
    })
};
/*
APPLICANTS LAMBDA CALLS
 */

// /**
//  *
//  * @param session = AWS Session
//  * @param page = which page the user is currently on
//  * @param pageSize = the size of the page
//  * @returns {Promise<any>}
//  */
// export const getResponsibleUsers = (session,page,pageSize, terminated) => {
//     return API.post("users", "users/responsible", {
//         body: {
//             token: session.accessToken.jwtToken,
//             page:page,
//             pageSize:pageSize,
//             terminated: terminated
//         }
//     });
// };

export const getResponsibleUsers = (users) => {
    return API.post("users","users/responsible/search",{
        body: {
            users
        }
    })
};

export const authoriseUser = () => {
    return API.post("users","auth/user",{
        body: {
        }
    })
};


export const getSingleResponsibleUser = (userId) => {
    return API.post("users","users/responsible/single",{
        body: {
            userId: userId
        }
    })
};

export const updateUser = (type,data) => {
    return API.post("users", `users/update`, {
        body: {
            data:data,
            type:type
        }
    })
};

export const uploadFile = (data) => {
    return API.post("users", "users/upload" , {
        body: data
    })
};

/*
COGNITO / CREATE USER LAMBDA CALLS
 */
/*
        var userparamslambda = {
            Username: lowerCaseEmail.toLowerCase(),
            UserAttributes: [
                { Name: "given_name", Value: fName },
                { Name: "family_name", Value: lName},
                { Name: "name", Value: fName + " " + lName},
                { Name: "email", Value: lowerCaseEmail.toLowerCase()},
                { Name: "phone_number", Value: "+"+number},

            ],
            user:this.state.user,
            wages: this.state.wageInfo,
            userRole:this.state.userRole,
            type: "create"
        };

 */
// example params above
export const adminCreateUser =  (params) =>{
    return API.post("users","users/admin/create",{
        body:{...params}
    })
};

export const forceMicropayExport = userId => {
    return API.post("users", 'micropay/force',{
        body:{
            userId:userId
        }
    });
};


export const getMessages = type =>{
    return API.post("users",'feed/get/content',{
        body:type
    })
};