export const ADD_TO_CART = "ADD_TO_CART"
export const UPDATE_CART_ID = "UPDATE_CART_ID"
export const UPDATE_CART_ITEMS = "UPDATE_CART_ITEMS"
export const LOADING_CHECKOUT = "LOADING_CHECKOUT"
export const UPDATE_SIZE = "UPDATE_SIZE"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const UPDATE_TOTAL = "UPDATE_TOTAL"
export const UPDATE_DISCOUNTS = "UPDATE_DISCOUNTS"
export const UPDATE_CUSTOMER_DETAILS = "UPDATE_CUSTOMER_DETAILS"
export const UPDATE_ADDRESS_DETAILS = "UPDATE_ADDRESS_DETAILS"
export const UPDATE_STAGE = "UPDATE_STAGE"
export const UPDATE_SHIPPING_METHOD = "UPDATE_SHIPPING_METHOD"
export const SET_AVAILABLE_METHODS = "SET_AVAILABLE_METHODS"
export const UPDATE_CONFIRMATION = "UPDATE_CONFIRMATION"
export const UPDATE_RECEIPT_ID = "UPDATE_RECEIPT_ID"
export const UPDATE_FULFILLER = "UPDATE_FULFILLER"
export const CLEAR_STATE = "CLEAR_STATE"
export const CLEAR_CART_ITEMS = "CLEAR_CART_ITEMS"