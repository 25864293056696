import React, { Component } from "react";

import { API } from "aws-amplify";
import {Row,Col, ButtonGroup, Button, FormGroup} from "react-bootstrap";
import Checkbox from "../../components/form/inputs/Checkbox"
import LoaderButton from "../../components/LoaderButton";
import {s3Upload} from "../../libs/awsLib";
import Select from "../../components/form/inputs/Select"
import Input from "../../components/form/inputs/Input"
import DateInput from "../../components/form/inputs/DateInput"
import moment from "moment/moment";
import {updateApplicant} from "../../actions/ApplicantActions";
import {connect} from "react-redux";

const mapDispatchToProps = dispatch =>{
    return {
        updateApplicant : userId => {dispatch(updateApplicant(userId))}
    }
};

class ApplicantPendingTrial extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: this.props.userId,
            phone: this.props.phone,
            uploadInfo: "",
            responses: {
                haveCalled: this.props.haveCalled,
                successful: this.props.successful,
                references: {
                    ref1: {
                        name: "na",
                        date: "",
                        relationship: "na",
                        organisation: "na"
                    },
                    ref2: {
                        name: "na",
                        date: "na",
                        relationship: "na",
                        organisation: "na"
                    }
                },
                role: "TEAM_MEMBER",
                home_store: this.props.home_store,
                start_date: "",
                termination_reason: {
                    reason: "na",
                    comment: "na"
                }
            },
            loading: {
                idcheck: false,
                tfn: false,
                resume: false
            },
            denyIsLoading: false,
            approveIsLoading: false,
            trial_date: this.props.trial_date,
            idcheck: this.props.idcheck,
            resume: this.props.resume,
            trial_type: this.props.trial_type,
            user_stores: this.props.user_stores,
            stores_dropdown: [{value: '0', label: "initial"}],
            selected_store: "0",
            roles_dropdown: [{value: this.props.applicant_role, label: "Team Member" }],
            selected_role: "TEAM_MEMBER",
            availability: this.props.availability,
            work_restriction_notes: this.props.work_restriction_notes,
            tfn: this.props.tfn,
            user_role: this.props.user_role,
            availability_notes: this.props.availability_notes,
            reference: this.props.references,
            ref1_dropdown: [{value: '0', blah: "test", label: "initial"}],
            selected_ref1: "0",
            ref2_dropdown: [{value: '0', label: "initial"}],
            selected_ref2: "0",
            referenceCheck:this.props.referenceCheck,
            terminationOptions : [{value: 'Unsuccessful_Trial', label: "Unsuccessful trial"}
                ,{value: 'resignation', label: "By resignation"}
                ,{value: 'Cancelled trial', label: "Cancelled Trial"},
                {value: 'other', label: "By some other manner"},
            ],
            selectedOption:null
        };
    }

    async componentDidMount() {
        await this.getStores();
        await this.setRefDropdowns();
    }
    getValidationState(type){

        if(type=== "startDate"){
            let start = this.state.responses.start_date;
            var date = moment(start, 'DD/MM/YYYY',true);
            var endDate = moment(new Date(2030, 1, 15));
            var startDate   = moment(new Date);
            if(date.isValid()){
                if(date.isBetween(startDate,endDate))
                {
                    return 'success'
                }else if(!date.isBetween(startDate,endDate)) {
                    return 'error'
                }
            } else if(start.length>3 && start.length<10 && !date.isValid()){
                return 'error'
            } else if(start.length === 10 && date.isValid()){
                return 'success'
            } else if(start.length === 10 && !date.isValid()){
                return 'success'
            } else return null
        }

    }

    setRefDropdowns() {
        let refs = this.props.references;
        let ref_array = [];

        for (var i in refs) {
            let ref = refs[i];
            let ref_array_element = {
                value: i,
                name: ref.fName + ' ' + ref.lName,
                relationship: ref.relationship,
                organisation: ref.organisation,
                number: ref.number,
                label: ref.fName + " " + ref.lName + ", " + ref.number + " - " + ref.relationship +
                    ((ref.organisation !== undefined) ? ", " + ref.organisation : "")
            };

            ref_array.push(ref_array_element);
        }

        let ref_array_element = {
            value: "new",
            label: "Add New Reference"
        };

        ref_array.push(ref_array_element);

        this.setState({
            ref1_dropdown: ref_array,
            ref2_dropdown: ref_array
        })
    }

    getStores = async() =>{
        API.get("users","stores/get/all").then(response => {
            let allowed_stores = [];
            let user_stores = this.props.user_stores;
            for (var i in user_stores) {
                allowed_stores.push(user_stores[i].store);
            }

            let store_dropdown = [];

            for (var j in response) {
                let storeId = response[j].value;
                if (allowed_stores.indexOf(storeId) !== -1) {
                    store_dropdown.push(response[j]);
                }
            }

            this.setState({stores_dropdown: store_dropdown});
        })
    };

    validateApproveForm() {
        return (
            ((this.state.responses.successful !== null &&
            this.state.responses.haveCalled !== false) || this.props.trial_type === 'INDUCTION' ) &&
            this.state.responses.references.ref1.name !== "na" &&
            this.state.responses.references.ref1.date !== "" &&
            this.state.responses.references.ref1.relationship !== "na" &&
            this.state.responses.references.ref2.name !== "na" &&
            this.state.responses.references.ref2.date !== "" &&
            this.state.responses.references.ref2.relationship !== "na" &&
            this.state.selected_role !== "" &&
            this.state.selected_store !== "0" &&
            this.state.responses.start_date !== "" &&
            this.props.referenceCheck === "true" &&
            this.props.hr_idcheck === "true" &&
            this.props.resume === "true" &&
            ((this.props.app_pc ==="true"  && (this.props.hr_pc === "true" )||this.props.app_pc === "na")) &&
            (this.props.hr_vevo ==="true"  ||this.props.hr_vevo === "na")
        )
            ;
    }

    validateDenyForm() {
        return (
            this.state.responses.successful !== null &&
            this.state.responses.haveCalled !== false &&
            this.state.responses.termination_reason !== ""
        );
    }

    handleCalledChange = event => {
        let responses = this.state.responses;
        responses.haveCalled = !this.state.responses.haveCalled;

        this.setState({
            responses
        });
    };

    handleSuccessChange = event => {
        let responses = this.state.responses;
        if(event === "true"){
            responses.successful = true
        }else {
            responses.successful = false
        }
        console.log( responses.successful)
        this.setState({
            responses
        });
    };

    handleApproveSubmit = async event => {
        event.preventDefault();
        console.log(this.props.home_store);
        console.log(this.state.selected_role.value);


        this.setState({ approvalIsLoading: true });

        try{

            if(this.props.home_store !== this.state.selected_store.value){
                await this.getSingleStore(this.state.selected_store.value).then(response =>{
                    let store = response;
                    let members = store.responsibilities[0];
                    if (this.state.selected_role.value === "TEAM_MEMBER"){

                        members.TEAM_MEMBER.push(this.props.userId);
                    }
                    store.responsibilities[0] = members;

                    this.updateStore(store)

                });

                await this.getSingleStore(this.props.home_store).then(response =>{
                    let store = response;
                    let members = store.responsibilities[0];
                    if (this.state.selected_role.value === "TEAM_MEMBER"){

                        var index = members.TEAM_MEMBER.indexOf(this.props.userId)
                        if (index !== -1) {
                            members.TEAM_MEMBER.splice(index, 1);
                        }
                        store.responsibilities[0] = members;
                        this.updateStore(store)
                    }

                });
            }


            await API.post("users", `users/applicants/status/update/successful/${false}`, {
                body: {
                    userId: this.props.userId,
                    responses: this.state.responses
                }
            });
            this.setState({ approvalIsLoading:false});
            this.props.updateApplicant(this.props.userId);
        } catch (e) {
            console.log(e);
        }
        this.setState({ approvalIsLoading: false });
    };

    handleDenySubmit = async event => {
        event.preventDefault();

        this.setState({ denyIsLoading: true });
        try{
            await API.post("users", "users/applicants/status/update/unsuccessful", {
                body: {
                    userId: this.props.userId,
                    termination_reason: this.state.responses.termination_reason,
                    end_date: this.props.trial_date
                }
            });

            this.setState({denyIsLoading:false});
            this.props.updateApplicant(this.props.userId)
        } catch (e) {
            console.log(e);
        }
        this.setState({ denyIsLoading: false });
    };

    handleRefChange = event => {
        console.log(event.target);
        console.log(event.target.name);
        console.log(event.target.id);
        let responses = this.state.responses;
        responses.references[event.target.id][event.target.name] = event.target.value;

        this.setState({
            responses
        });
    };

    handleChange = event => {
        let responses = this.state.responses;
        responses[event.target.id] = event.target.value;

        this.setState({
            responses
        });
    };

    handleRef1Change = event => {
        let ref1 = this.state.responses.references.ref1;

        if (event.value !== "new") {
            ref1.name = event.name;
            ref1.relationship = event.relationship;
            ref1.organisation = event.organisation;
        } else {
            ref1.name = "na";
            ref1.relationship = "na";
            ref1.organisation = "na";
        }

        this.setState({
            ref1,
            selected_ref1: event
        });
    };

    handleRef2Change = event => {
        let ref2 = this.state.responses.references.ref2;

        if (event.value !== "new") {
            ref2.name = event.name;
            ref2.relationship = event.relationship;
            ref2.organisation = event.organisation;
        } else {
            ref2.name = "na";
            ref2.relationship = "na";
            ref2.organisation = "na";
        }

        this.setState({
            selected_ref2: event
        });

        if (event.value !== "new") {
            this.setState(ref2);
        }
    };

    handleUserRoleChange = event => {
        let responses = this.state.responses;
        responses.role = event.value;

        this.setState({
            responses,
            selected_role: event
        });
    };

    handleUserStoreChange = async(event) => {
        console.log(event);
        let responses = this.state.responses;
        responses.home_store = event.value;




        this.setState({
            responses,
            selected_store: event
        });
    };

    handleRefDateChange = (refId, event) => {
        let responses = this.state.responses;
        responses.references[refId].date = event.target.value;

        this.setState({
            responses
        });
    };

    getSingleStore=async(store) =>{
        return await API.get("users",`stores/get/${store}`)

    };

    updateStore = async(store) =>{
        await API.post("users",`stores/update/${store.storeId}`,{
            body:store
        }).then(response =>{
            console.log(response);
        })
    };
    handleStartDateChange = event => {
        let responses = this.state.responses;
        responses.start_date = event.target.value;

        this.setState({
            responses
        });
    };

    handleUpload = async (identifier, attribute_type, event) => {
        event.persist();

        let loading = this.state.loading;

        if (loading[identifier] === false) {
            loading[identifier] = true;
            this.setState(loading);

            const attachment = await s3Upload(event.target.files[0]);

            let upload = {
                userId: this.state.userId,
                type: identifier,
                attribute_type: attribute_type,
                filename: attachment,
                attachment: attachment
            };


            await API.post("users", "users/upload", {
                body: upload
            });

            loading[identifier] = false;
            this.setState(loading);

            this.props.updateApplicant(this.props.userId)
        }
    };


    handleTerminationChange = event =>{
        let responses = this.state.responses;
        responses.termination_reason.comment= event.target.value;
        this.setState({
            responses
        });

    };
    handleSelectChange = event =>{
        let termination_reason = this.state.responses.termination_reason;
        console.log(event);
        termination_reason.reason = event.label;

        this.setState({termination_reason,selectedOption:event})
        console.log(this.state.responses.termination_reason)
    };


    renderForm() {
        return ((this.state.responses.successful) === true || this.props.trial_type === 'INDUCTION' )
            ?
            <Row>
                <form className={"pending-form"} onSubmit={this.handleApproveSubmit}>
                    <Row>
                        <Col sm ={4}>
                            <Row>
                                <Col>
                                    <span className={"submenu-heading"}>Reference Check</span>
                                    <Row>
                                        {(this.state.selected_ref1.value !== "new")
                                            ? <Select
                                                label={"Reference One"}
                                                id={"select"}
                                                onValidate={this.getValidationState("reference")}
                                                selectedOption={this.state.selected_ref1}
                                                onChange={this.handleRef1Change}
                                                options={this.state.ref1_dropdown}
                                            />
                                            : <div>
                                                <Row>
                                                    <Select id={"select"}
                                                            onValidate={this.getValidationState("reference")}
                                                            selectedOption={this.state.selected_ref1}
                                                            onChange={this.handleRef1Change}
                                                            options={this.state.ref1_dropdown}
                                                    />
                                                </Row>
                                                <Row>
                                                    <Input
                                                        name={"name"}
                                                        label={"Name"}
                                                        controlId={"ref1"}
                                                        onValidate={this.getValidationState("ref1name")}
                                                        placeholder={""}
                                                        value={this.state.responses.references.ref1.name === "na" ? "" : this.state.responses.references.ref1.name}
                                                        onChange={this.handleRefChange}
                                                        required={true} 
                                                       />
                                                </Row>
                                                <Row>
                                                    <Input
                                                        name={"relationship"}
                                                        label={"Relationship"}
                                                        controlId={"ref1"}
                                                        onValidate={this.getValidationState("ref1relo")}
                                                        placeholder={""}
                                                        value={this.state.responses.references.ref1.relationship === "na" ? "" : this.state.responses.references.ref1.relationship}
                                                        onChange={this.handleRefChange}
                                                        required={true}/>
                                                </Row>
                                            </div>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DateInput id={"dateContacted"}
                                                       onValidate={this.getValidationState("dateContactedOne")}
                                                       value={this.state.responses.references.ref1.date}
                                                       onChange={this.handleRefDateChange.bind(this, "ref1")}
                                                       label={"Date Contacted"}
                                                       required={true} name={"dateContacted"}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col sm={4}>
                            <Row>
                                <Col>
                                    <span className={"submenu-heading"}><br></br></span>
                                    <Row>
                                        {(this.state.selected_ref2.value !== "new")
                                            ? <Select
                                                label={"Reference Two"}
                                                id={"select"}
                                                onValidate={this.getValidationState("reference")}
                                                selectedOption={this.state.selected_ref2}
                                                onChange={this.handleRef2Change}
                                                options={this.state.ref2_dropdown}
                                            />
                                            : <div>
                                                <Row>
                                                    <Select id={"select"}
                                                            onValidate={this.getValidationState("reference")}
                                                            selectedOption={this.state.selected_ref2}
                                                            onChange={this.handleRef2Change}
                                                            options={this.state.ref2_dropdown}
                                                    />
                                                </Row>
                                                <Row>
                                                    <Input
                                                        name={"name"}
                                                        label={"Name"}
                                                        controlId={"ref2"}
                                                        onValidate={this.getValidationState("ref1name")}
                                                        placeholder={""}
                                                        value={this.state.responses.references.ref2.name === "na" ? "" : this.state.responses.references.ref2.name}
                                                        onChange={this.handleRefChange}
                                                        required={true}/>
                                                </Row>
                                                <Row>
                                                    <Input
                                                        name={"relationship"}
                                                        label={"Relationship"}
                                                        controlId={"ref2"}
                                                        onValidate={this.getValidationState("ref1relo")}
                                                        placeholder={""}
                                                        value={this.state.responses.references.ref2.relationship === "na" ? "" : this.state.responses.references.ref2.relationship}
                                                        onChange={this.handleRefChange}
                                                        required={true}/>
                                                </Row>
                                            </div>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DateInput id={"dateContacted"}
                                                       onValidate={this.getValidationState("dateContactedTwo")}
                                                       value={this.state.responses.references.ref2.date}
                                                       onChange={this.handleRefDateChange.bind(this, "ref2")}
                                                       label={"Date Contacted"}
                                                       required={true} name={"dateContacted"}/>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={4} className={"offer-details"} >
                            <span className={"submenu-heading"}>Role Details</span>
                            <Row>
                                <Select
                                    label={"Position"}
                                    id={"role"}
                                    onValidate={this.getValidationState("role")}
                                    selectedOption={this.state.selected_role}
                                    onChange={this.handleUserRoleChange}
                                    options={this.state.roles_dropdown}/>
                            </Row>
                            <Row>
                                <DateInput id={"startDate"}
                                           onValidate={this.getValidationState("startDate")}
                                           value={this.state.responses.start_date}
                                           onChange={this.handleStartDateChange}
                                           label={"Start Date"}
                                           required={true}
                                />
                            </Row>
                            <Row>
                                <Select
                                    label={"Home Store"}
                                    id={"store"}
                                    onValidate={this.getValidationState("store")}
                                    selectedOption={this.state.selected_store}
                                    onChange={this.handleUserStoreChange}
                                    options={this.state.stores_dropdown}/>
                            </Row>

                            <Row>
                                <FormGroup bsSize="large">
                                    <LoaderButton
                                        block
                                        bsSize="large"
                                        type="submit"
                                        disabled={!this.validateApproveForm()}
                                        isLoading={this.state.approvalIsLoading}
                                        className={"offer-button"}
                                        text="Send Letter of Offer"
                                        loadingText="Approving…"
                                    />
                                    {(this.props.hr_vevo ==="false" || this.props.hr_pc === "false" ||this.props.hr_tfn === "false") ? <span style={{textAlign:"right",color:"red"}} className={"submenu-heading"}>Waiting on HR Approval</span>:null}

                                </FormGroup>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </Row>
            : ((this.state.responses.successful) === false)
                ? <Row>
                    <form className={"pending-form"} onSubmit={this.handleDenySubmit}>
                        <Row>
                            <Col sm={6}>
                                <Select id={"termination_reason"}
                                        options={this.state.terminationOptions}
                                        selectedOption={this.state.selectedOption}
                                        required={true}
                                        onChange={this.handleSelectChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <Input
                                    name={"comment"}
                                    label={"Termination Reason"}
                                    id={"termination_reason"}
                                    placeholder={""}
                                    required={true}
                                    componentClass="textarea"
                                    value={this.state.responses.termination_reason.comment === "na" ? "" :this.state.responses.termination_reason.comment}
                                    onChange={this.handleTerminationChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={6}>
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    type="submit"
                                    disabled={!this.validateDenyForm()}
                                    isLoading={this.state.denyIsLoading}
                                    className={"offer-button"}
                                    text="Deny The Trial"
                                    loadingText="Denying…"
                                />
                            </Col>
                        </Row>
                    </form>
                </Row>
                : null
    }
    render() {
        return (
            <Row>
                    {(this.props.trial_type === 'PENDING_OFFER' || this.props.trial_type === 'TRIAL')
                        ?   <Row className={"submenu"}>
                            <Row className={"text-left"}>
                                <Checkbox
                                    checked={this.state.responses.haveCalled}
                                    onChange={this.handleCalledChange}
                                    id={"called-checkbox"}
                                    text={"Have you called the applicant? Their phone number is"+ this.state.phone}
                                />
                            </Row>
                            <span className={"submenu-heading"}>Was the applicant successful?</span>
                            <Row>
                                <Col sm={5}>
                                    <ButtonGroup
                                        type="radio"
                                        name="successful"
                                    >
                                        <Col sm={6}>
                                            <Button
                                                className={"btn-block"}
                                                value={true}
                                                onClick={(e) => this.handleSuccessChange(e.currentTarget.value)}
                                            > Yes
                                            </Button>
                                        </Col>
                                        <Col Col sm={6}>
                                            <Button
                                                className={"btn-block"}
                                                onClick={(e) => this.handleSuccessChange(e.currentTarget.value)}
                                                value={false}> No
                                            </Button>
                                        </Col>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </Row>
                        : null
                    }
                {this.renderForm()}

            </Row>
        )
    }
}

export default connect(null,mapDispatchToProps)(ApplicantPendingTrial)