import React, {Component} from "react";
import { option, Row, Col} from "react-bootstrap";
import Input from '../../../../components/form/inputs/Input'
import Select from '../../../../components/form/inputs/Select'
import LoaderButton from "../../../../components/LoaderButton";
export default class AddUser extends Component {
    constructor(props){
        super(props);
        this.state={
            storeId:'',
            storeName:'',
            phone:'',
            street1:'',
            street2:'',
            suburb:'',
            storeState:'',
            postcode:'',
            country:'',
            responsibilities:'',
            active:true,
        }
    }

    componentDidMount() {
    }

    getValidationState(){

    }
    HandleChange(){

    }

    validateForm(){

    }
    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <Row>
                    <Col lg={3}>
                        <Input id={"storeId"}
                               onValidate={this.getValidationState()}
                               placeholder={"Store ID"}
                               onChange={this.handleChange}
                               value={this.state.storeId}
                               required={true}
                        />
                    </Col>
                    <Col lg={9}>
                        <Input id={"storeName"}
                               onValidate={this.getValidationState()}
                               placeholder={"Store Name"}
                               onChange={this.handleChange}
                               value={this.state.storeName}
                               required={true}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} md={8} lg={6}>
                        <Input id={"phone"}
                               onValidate={this.getValidationState()}
                               placeholder={"Phone"}
                               onChange={this.handleChange}
                               value={this.state.phone}
                               required={true}
                        />
                    </Col>
                    <Col sm={12} md={8} lg={6}>
                        <Input
                            id={"street1"}
                            onValidate={this.getValidationState()}
                            placeholder={"Street"}
                            onChange={this.handleChange}
                            value={this.state.street1}
                            required={true}
                        />
                    </Col>
                </Row>


                <Row>
                    <Col sm={12} md={8} lg={6}>
                        <Input id={"suburb"}
                               onValidate={this.getValidationState()}
                               placeholder={"suburb"}
                               onChange={this.handleChange}
                               value={this.state.suburb}
                               required={true}
                        />
                    </Col>
                    <Col sm={12} md={8} lg={6}>
                        <Input id={"storeState"}
                               onValidate={this.getValidationState()}
                               placeholder={"State"}
                               onChange={this.handleChange}
                               value={this.state.storeState}
                               required={true}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={12} md={8} lg={6}>
                        <Input
                            id={"postcode"}
                            onValidate={this.getValidationState()}
                            placeholder={"Postcode"}
                            onChange={this.handleChange}
                            value={this.state.postcode}
                            required={true}/>
                    </Col>
                    <Col sm={12} md={8} lg={6}>
                        <Input
                            id={"country"}
                            onValidate={this.getValidationState()}
                            placeholder={"Country"}
                            onChange={this.handleChange}
                            value={this.state.country}
                            required={true}
                        />
                    </Col>
                </Row>
                <LoaderButton
                    block
                    bsSize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    isLoading={this.state.isLoading}
                    text="Create Store"
                    loadingText="Creating…"
                />
            </form>
        )

    }
}