import * as type from "../constants/actions/NotificationTypes";
import { toast } from "react-toastify";
import React from 'react';
import Refresh from "../components/notifications/Refresh"

//This function now takes the message, as well as the force. Force will determine if the error message is shown in toast.
export function errorNotification(message,position = 'top_right',id="error", force=true) {
    let pos = {
        top_center: toast.POSITION.TOP_CENTER,
        top_right:toast.POSITION.TOP_RIGHT
    }

    return function(dispatch) {
        dispatch({
            type: type.ERROR,
            payload: message
        });
        const options = {
            toastId:id,
            autoClose: 6000,
            type: toast.TYPE.ERROR,
            hideProgressBar: true,
            position: pos['top_center'],
            pauseOnHover: true,
        };
        if(force){
            toast(message,options);
        }
    };
}
export function successNotification(message, time = 4000) {
    return function(dispatch) {
        dispatch({
            type: type.SUCCESS
        });
        const options = {
            autoClose: time,
            type: toast.TYPE.SUCCESS,
            hideProgressBar: false,
            position: toast.POSITION.TOP_RIGHT,
            pauseOnHover: true,
        };
        toast(message,options)
    };
}

export function warningNotification(message) {
    return function(dispatch) {
        dispatch({
            type: type.WARNING
        });
        const options = {
            autoClose: 6000,
            type: toast.TYPE.WARNING,
            hideProgressBar: true,
            position: toast.POSITION.TOP_RIGHT,
            pauseOnHover: true,
        };
        toast(message,options)
    };
}

export function refreshAction() {
    return function(dispatch) {
        dispatch({
            type: type.ACTION
        });
        const options = {
            autoClose: false,
            type: toast.TYPE.INFO,
            hideProgressBar: true,
            position: toast.POSITION.TOP_RIGHT,
            pauseOnHover: true,
        };
        toast(Refresh,options)
    };
}

export function shipmentNotification(message) {
    return function(dispatch) {
        dispatch({
            type: type.ACTION
        });
        const options = {
            autoClose: false,
            type: toast.TYPE.INFO,
            hideProgressBar: true,
            position: toast.POSITION.TOP_RIGHT,
            pauseOnHover: true,
        };
        toast(message,options)
    };
}


export function removeNotification (message){
    return function(dispatch) {
        dispatch({
            type: type.REMOVE_NOTIFICATION,
            payload: message
        });
    }

}