import React, {Component} from "react";
import {connect} from "react-redux";
import {Row,Col,Table} from "react-bootstrap";
import moment from "moment";
import './reportshipping.css'
import Select from "../../../components/form/inputs/Select";
import {getStoresByUser} from "../../../actions/StoreActions";
import {getPendingOverview,
    getPendingDetails,
    generateOlderShipments,
    getCompleteOverview,
    getCompleteDetails,
    generateCompleteArchivedShipments,
    getRejectedOverview,
    getAllStoreView,
    getRejectedDetails} from "../../../actions/ReportingActions";
import { Doughnut, Line } from 'react-chartjs-2';
import ToolTip from "../../../components/form/inputs/ToolTip";
const mapStateToProps = state => {
    return {userId:state.authState.username,shipmentData: state.reportingState.shipmentData,loading:state.reportingState.isLoading}

};

const mapDispatchToProps = dispatch =>{
    return {
        getAllStoreView: (date, store, method, status, state)=>{dispatch(getAllStoreView(date, store, method, status, state))},
        getPendingOverview: ()=>{dispatch(getPendingOverview())},
        getPendingDetails : (storeId)=>{dispatch(getPendingDetails(storeId))},
        generateOlderShipments : (storeId)=>{dispatch(generateOlderShipments(storeId))},
        getCompleteOverview : (date)=>{dispatch(getCompleteOverview(date))},
        getCompleteDetails : (store,date)=> {dispatch(getCompleteDetails(store,date))},
        generateCompleteArchivedShipments : (store,date) => {dispatch(generateCompleteArchivedShipments(store,date))},
        getRejectedOverview: (date)=>{dispatch(getRejectedOverview(date))},
        getRejectedDetails : (store,date)=> {dispatch(getRejectedDetails(store,date))},
    }
};

const doughOptions = {
    legend:{
        position:'bottom'
    },
    layout: {
        padding: {
            top: 20,
            bottom: 20
        }
    }
}
class StoreOrders extends Component {
    constructor(props){
        super(props);
        this.state = {
            statusOptions: [{value:'pending',label: "Pending"},
                {value:'complete',label: "Complete"},
                {value:'rejected',label: "Rejected"}],
            selectedStatus: {value:'pending',label: "Pending"},
            status: 'pending',
            doughData: null,
            lineData:null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.state !== this.props.state ||
            prevProps.dateRange !== this.props.dateRange ||
            prevProps.method !== this.props.method ||
            prevProps.store !== this.props.store
        ){
            this.props.getAllStoreView(this.props.dateRange,this.props.store,this.props.method,this.props.status,this.props.state);
        }
    }

    componentDidMount() {
        this.props.getAllStoreView(this.props.dateRange,this.props.store,this.props.method,this.props.status);
    }

    createOverviewDough(stores) {
        let stateData = [];
        for(let store of stores){
            if(stateData.find(state => state.id === store.storeState)){
                stateData = stateData.map((state)=>(
                    state.id === store.storeState ? {...state, no: state.no+=store.shipments} :state
                ))
            }else{
                stateData.push({id:store.storeState,no:store.shipments})
            }
        }
        let labels = [];
        let datasets = [];
        let data = [];
        for (let state of stateData){
            labels.push(state.id);
            data.push(state.no);
        }
        let options = {
            data:data,
            backgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#b0ffb9',
                '#ff88bb',
                '#966dff',
                '#2cffb5'
            ],
            hoverBackgroundColor: [
                '#FF6384',
                '#36A2EB',
                '#FFCE56',
                '#b0ffb9',
                '#ff88bb',
                '#966dff',
                '#2cffb5'
            ]
        };
        datasets.push(options);
        let doughData = {
            labels,
            datasets
        };
        this.setState({
            doughData
        })
    }

    createLineGraph(lastWeek,fortnight){
        if(lastWeek && fortnight){
            let labels = [];
            let lastWeekCompleted= [];
            let PreviousWeekCompleted= [];
            lastWeek = lastWeek.reverse();
            fortnight = fortnight.reverse();
            for(let day of lastWeek){
                let date = moment(day.dateTime).format('MM-DD');
                labels.push(date);
                lastWeekCompleted.push(day.completed);
            }
            let data= {
                //Bring in data
                labels: labels,
                datasets: [
                    {
                        label: "Last 7 Days",
                        data:lastWeekCompleted,
                        fill: false,
                        borderColor: "#98B9AB"
                    }
                ]
            };
            this.setState({lineData:data})
        }
    }

    getPending = () =>{
        if(this.props.store === 'all' || this.props.store === null){
            this.props.getPendingOverview()
        }else {
            this.props.getPendingDetails(this.props.store)
        }
    };

    getComplete = (store,date) =>{
        if(store === 'all' || store === null){
            this.props.getCompleteOverview(date)
        }else {
            this.props.getCompleteDetails(store,date)
        }
    };

    getRejected = (store,date) =>{
        if(store === 'all' || store === null){
            this.props.getRejectedOverview(date)
        }else {
            this.props.getRejectedDetails(store,date)
        }
    };


    handleStatusChange = async(event) => {
        this.setState({
            selectedStatus:event,
            status:event.value
        });
        if (event.value === "pending") {
            this.getPending();
        } else if (event.value === "complete") {
            this.getComplete(this.props.store,this.props.dateRange);
        } else if(event.value ==="rejected") {
            this.getRejected(this.props.store,this.props.dateRange);
        }
    };

    generateOlderShipmentsList = () => {
        let store = this.props.store ==='all' ? null : this.props.store;
        this.props.generateOlderShipments(store)
    };

    generateCompleteShipmentsList = () => {
        let store = this.props.store ==='all' ? null : this.props.store;
        this.props.generateCompleteArchivedShipments(store,this.props.dateRange)
    };

    render() {
        let loading = this.props.loading;
        let method = this.props.method;
        let allStoreView = this.props.shipmentData.allStoreView;
        let stateView = this.props.shipmentData.stateView;
        let pendingOverview = this.props.shipmentData.pendingOverview;
        let pendingDetails = this.props.shipmentData.pendingDetails;
        let completeOverview = this.props.shipmentData.completeOverview;
        let completeDetails = this.props.shipmentData.completeDetails;
        let rejectedOverview = this.props.shipmentData.rejectedOverview;
        let rejectedDetails = this.props.shipmentData.rejectedDetails;
        let type = 'all';
        if(allStoreView){
            type = 'store-shipping-view'
        }
        if(pendingOverview){
            type= 'pending-overview'
        }else if(pendingDetails){
            type = 'pending-details'
        }else if (completeOverview){
            type = 'complete-overview'
        }else if (completeDetails){
            type = 'complete-details'
        }else if (rejectedOverview){
            type = 'rejected-overview'
        }else if (rejectedDetails){
            type = 'rejected-details'
        }
        return (
                loading ?
                    <span>
                        loading...
                    </span> :
                    <div className="card shipmentData">
                        {this.renderData(type)}
                    </div>
        )
    }

    renderData(type) {
        switch(type) {
            case 'store-shipping-view':
                return(
                    <div>
                        <Table striped bordered hover responsive="sm">
                            <thead>
                            <tr>
                                <th> </th>
                                <th>Store</th>
                                <th style={{background:"antiquewhite"}}> Pending C&C </th>
                                <th style={{background: '#ffe797'}}>Pending US</th>
                                <th style={{background: '#ffe797'}}>Pending PS</th>
                                <th style={{background: '#ffe797'}}>Value</th>
                                <th style={{background: '#ffe797'}}>SLA (Hrs)</th>
                                <th style={{background: '#c6d1ec'}}>Completed US</th>
                                <th style={{background: '#c6d1ec'}}>Completed PS</th>
                                <th style={{background: '#c6d1ec'}}>Value</th>
                                <th style={{background: '#c6d1ec'}}>Average (Hrs)</th>
                                <th style={{background: '#ecb39c'}}>Rejected</th>
                                <th style={{background: '#ecb39c'}}>Value</th>
                                <th style={{background: '#ecb39c'}}>Average (Hrs)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderAllStoreShippingView()}
                            {this.renderTotals()}
                            </tbody>
                        </Table>
                    </div>
                    );
            case 'pending-overview':
                return (
                    <div>
                        {this.renderDoughnut()}
                        {this.renderPendingOverview()}
                    </div>
                );
            case 'pending-details':
                return (
                    <div>
                        {this.renderPendingDetails()}
                    </div>
                );
            case 'complete-overview':
                return (
                    <div>
                        {this.renderDoughnut()}
                        {this.renderCompleteOverview()}
                    </div>
                );

            case 'complete-details':
                return (
                    <div>
                        {this.renderLine()}
                        {this.renderCompleteDetails()}
                    </div>
                );

            case 'rejected-overview':
                return (
                    <div>
                        {this.renderDoughnut()}
                        {this.renderRejectedOverview()}
                    </div>
                );

            case 'rejected-details':
                return (
                    <div>
                        {this.renderLine()}
                        {this.renderRejectedDetails()}
                    </div>
                );
            default:
                return 'loading...';
        }
    }

    renderDoughnut(){
        let doughData = this.state.doughData;
        return(
            doughData ?
                    <Row>
                        <Doughnut data={doughData} options={doughOptions}/>
                    </Row>
                    :null
            )
    }

    renderLine(){
        let lineData = this.state.lineData;
        return(
            lineData ?
                <Row>
                    <Line data={lineData} />
                </Row>
                :null
        )
    }

    renderAllStoreShippingView(){
        let storeView = this.props.shipmentData.allStoreView;
        let list = storeView.map((store)=>
                <tr>
                    <td><span style={{fontWeight:700}}>{store.state}</span></td>
                    <td><span>{store.storeName}</span></td>
                    <td > {store.pending ? <span>{store.pending.ccShipments}</span> : <span> 0 </span>}</td>
                    <td > {store.pending ? <span>{store.pending.us_shipments}</span> : <span> 0 </span>}</td>
                    <td > {store.pending ? <span>{store.pending.ps_shipments}</span> : <span> 0 </span>}</td>
                    <td > {store.pending ? <span>${store.pending.value.toFixed(2)}</span> : <span> 0 </span>} </td>
                    <td > {store.pending ? <span>{((store.pending.us_shipments+store.pending.ps_shipments) * .141).toFixed(2)}</span> : <span> 0 </span>}</td>
                    <td > {store.completed ? <span>{store.completed.us_shipments}</span> : <span> 0 </span>}</td>
                    <td > {store.completed ? <span>{store.completed.ps_shipments}</span> : <span> 0 </span>}</td>
                    <td > {store.completed ? <span>${store.completed.value?.toFixed(2)}</span> : <span> 0 </span>} </td>
                    <td > {store.completed ? <span>{store.completed.averagePP?.toFixed(2)}</span> : <span> 0 </span>} </td>
                    <td > {store.rejected ? <span>{store.rejected.shipments}</span> : <span> 0 </span>}</td>
                    <td > {store.rejected ? <span>${store.rejected.value.toFixed(2)}</span> : <span> 0 </span>} </td>
                    <td > {store.rejected ? <span>{store.rejected.averageHrs?.toFixed(2)}</span> : <span> 0 </span>} </td>>
                </tr>
        );

        return list;
    }

    renderPendingOverview(){
        let pendingOverview = this.props.shipmentData.pendingOverview;
        let list = pendingOverview.map((store)=>
            <Row className ={"pending-overview-item"}>
                <Col sm ={6} className = {"store-text"}>
                    <span>{store.storeName}</span>
                </Col>
                <Col sm={3}>
                    <span>{store.shipments}</span>
                </Col>
                <Col sm={3} className={"store-value"}>
                    <span>${store.value.toFixed(2)}</span>
                </Col>
            </Row>
        );
        return (
            <div>
                <Row className ={"pending-overview-header"} >
                    <Col sm={6}>
                        Store
                    </Col>
                    <Col  sm={3}>
                        Shipments
                    </Col>
                    <Col  sm={3} className={'store-value'}>
                        Value
                    </Col>
                </Row>
                {pendingOverview.length > 0 ?
                        <div>
                            {list}
                        </div>
                        :<p>loading...</p>
                }
            </div>
        )
    }

    renderCompleteOverview(){
        let completeOverview = this.props.shipmentData.completeOverview;
        let list = completeOverview.map((store)=>
            <Row className ={"pending-overview-item"}>
                <Col sm ={6} className = {"store-text"}>
                    <span>{store.storeName}</span>
                </Col>

                <Col sm={3}>
                    <span>{store.shipments}</span>
                </Col>
                <Col sm={3}>
                    <span>${store.value.toFixed(2)}</span>
                </Col>
            </Row>
        );
        return (
            <div>
                <Row className ={"pending-overview-header"} >
                    <Col sm={6}>
                        Store
                    </Col>
                    <Col  sm={3}>
                        Shipments
                    </Col>
                    <Col  sm={3}>
                        Value
                    </Col>
                </Row>
                {completeOverview.length > 0 ?
                    <div>
                        {list}
                    </div>
                    :<p>loading...</p>
                }
            </div>
        )
    }

    renderRejectedOverview(){
        let rejectedOverview = this.props.shipmentData.rejectedOverview;
        let list = rejectedOverview.map((store)=>
            <Row className ={"pending-overview-item"}>
                <Col sm ={6} className = {"store-text"}>
                    <span>{store.storeName}</span>
                </Col>

                <Col sm={3}>
                    <span>{store.shipments}</span>
                </Col>
                <Col sm={3}>
                    <span>${store.value.toFixed(2)}</span>
                </Col>
            </Row>
        );
        return (
            <div>
                <Row className ={"pending-overview-header"} >
                    <Col sm={6}>
                        Store
                    </Col>
                    <Col  sm={3}>
                        Rejected
                    </Col>
                    <Col  sm={3}>
                        Value
                    </Col>
                </Row>
                {rejectedOverview.length > 0 ?
                    <div>
                        {list}
                    </div>
                    :<p>loading...</p>
                }
            </div>
        )
    }

    renderPendingDetails(){
        let store = this.props.shipmentData.pendingDetails;
        return(
            <div>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <span> Express shipments: <span>{store.express}</span></span>
                    </Col>
                </Row>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <span> shipments from today: <span>{store.today}</span></span>
                    </Col>
                </Row>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <button onClick={()=>this.generateOlderShipmentsList()}> shipments older than today: <span>{store.older}</span></button>
                    </Col>
                </Row>
            </div>

            )

    }

    renderCompleteDetails(){
        let store = this.props.shipmentData.completeDetails;
        return(
            <div>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <span> Express shipments: <span>{store.express}</span></span>
                    </Col>
                </Row>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <span> shipments from today: <span>{store.today}</span></span>
                    </Col>
                </Row>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <button onClick={()=>this.generateCompleteShipmentsList()}>Shipments completed during date range: <span>{store.overall}</span></button>
                    </Col>
                </Row>
            </div>

        )

    }

    renderRejectedDetails(){
        let store = this.props.shipmentData.rejectedDetails;
        return(
            <div>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <span> Express shipments rejected: <span>{store.express}</span></span>
                    </Col>
                </Row>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <span> rejected shipments from today: <span>{store.today}</span></span>
                    </Col>
                </Row>
                <Row className ={"pending-detail-item"}>
                    <Col sm ={8}>
                        <span>Shipments rejected during date range: <span>{store.overall}</span></span>
                    </Col>
                </Row>
            </div>

        )

    }

    renderTotals() {
        let storeView = this.props.shipmentData.allStoreView;
        let total = {
            ccPending: 0,
            pending: 0,
            completed: 0,
            rejected: 0,
            average: 0
        };
        storeView.filter((a) => {
            total.ccPending += a.pending ? a.pending.ccShipments : 0 ;
            total.pending += a.pending ? a.pending.shipments : 0 ;
            total.completed += a.completed ? a.completed.shipments : 0 ;
            total.rejected += a.rejected ? a.rejected.shipments : 0 ;
        });

        if(storeView.length > 1){
            return (
                <tr style={{background:"aliceblue"}}>
                    <th>Total</th>
                    <th></th>
                    <th>{total.ccPending}</th>
                    <th>{total.pending}</th>
                    <th></th>
                    <th></th>
                    <th>{total.completed}</th>
                    <th></th>
                    <th></th>
                    <th>{total.rejected}</th>
                    <th></th>
                </tr>
            )
        }else {
            return null;
        }

    }

}

const storeOrders = connect(mapStateToProps, mapDispatchToProps)(StoreOrders);
export default storeOrders;