// Icon
import React, {Component} from 'react'


class addIcon extends Component{
    render() {
        return (
            <svg id="789caf95-8ee2-40f8-ad15-b9966c4b1a82" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                <title>icons</title>
                <path d="M44,35.47H39.09v5a1,1,0,1,1-2,0v-5H32.19a1,1,0,0,1,0-2h4.93v-5a1,1,0,1,1,2,0v5H44a1,1,0,0,1,0,2Zm-5.91-5Zm-13.79-5a10,10,0,1,1,9.85-10A9.93,9.93,0,0,1,24.31,25.5Zm0-18a8,8,0,1,0,7.88,8A7.94,7.94,0,0,0,24.31,7.5Zm9.85,20.68v2.36a17.42,17.42,0,0,0-9.85-3A17.79,17.79,0,0,0,7,41.5H5a19.79,19.79,0,0,1,19.31-16A19.41,19.41,0,0,1,34.16,28.18Z"/>
            </svg>
        )
    }
};

export default addIcon