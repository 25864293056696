import React, {Component} from "react";
import moment from "moment";
import {Col, Row} from "react-bootstrap";
import Select from "../../../components/form/inputs/Select";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Input from "../../../components/form/inputs/Input";
import ToolTip from "../../../components/form/inputs/ToolTip";
import PropTypes from "prop-types";
import DateOfBirth from "../../../components/form/DateOfBirth";

class Filters extends Component {
    constructor(props) {
        super(props);
        this.state={
            stores: [],
            dates: [],
            selectedMethod: null,
            selectedStore:null,
            selectedState : null,
            store:null,
            state:null,
            method: null,
            status: null,
            dateRange: {
                startDate:moment().startOf('week').format('YYYY-MM-DD HH:mm:ss') ,
                endDate:  moment().format('YYYY-MM-DD HH:mm:ss')
            },
            displayDate: {
                displayStart:moment().startOf('week').format('MMM DD, YYYY') ,
                displayEnd:moment().format('MMM DD, YYYY')
            },
            states: [
                {label:"All",value:null},
                {label:"Queensland",value:"QLD"},
                {label:"Victoria",value:"VIC"},
                {label:"New South Wales",value:"NSW"},
                {label:"Western Australia",value:"WA"},
                {label:"Northern Territory",value:"NT"},
                {label:"South Australia",value:"SA"},
                {label:"Australian Capital Territory",value:"ACT"}
            ],
            methods: [
                {label:"All",value:null},
                {label:"Click & Collect",value:"Collect"},
                {label:"Standard Shipping",value:"Standard"},
                {label:"Express Shipping",value:"Express"},
            ]
        }
    }

    componentDidMount() {
        this.props.handleDate(this.state.dateRange);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({stores: nextProps.stores})
    }

    handleStoreChange = async(event) => {
        this.setState({
            selectedStore:event,
            store:event.value
        });
        this.props.handleStore(event.value)
    };

    handleStateChange = async(event) => {
        this.setState({
            selectedState:event,
            state:event.value
        });
        this.props.handleState(event.value)
    };

    handleMethodChange = async(event) => {
        this.setState({
            selectedMethod:event,
            method:event.value
        });
        this.props.handleMethod(event.value)
    };

    handleDateChange = async(event, picker) => {
        let startDate = picker.startDate.format();
        let endDate =picker.endDate.format();
        let displayStart = picker.startDate.format('MMM DD, YYYY');
        let displayEnd = picker.endDate.format('MMM DD, YYYY');
        console.log(startDate);
        console.log(endDate);
        let date = {
            startDate,
            endDate
        };
        let displayDate = {
            displayStart,
            displayEnd
        };
        this.setState({
            dateRange:date,
            displayDate
        });
        this.props.handleDate(date);
    };

    clearFilters = () => {
        this.setState({
            store:null,
            selectedStore:null,
            selectedState:null,
            selectedMethod: null,
            state:null,
            method: null}
            )
        this.props.handleStore(null);
        this.props.handleMethod(null);
        this.props.handleState(null);
    };

    render(){
        return (
            <Row className={"shipping-select-wrapper"}>
                <Col md={2} sm={3}>
                    <Select inline={true} selectedOption = {this.state.selectedMethod} placeholder={""} id={"store-select"} label={'Filter'} options={this.state.methods} onChange={(e)=>this.handleMethodChange(e)}/>
                </Col>
                {
                    this.props.permissions.includes("reporting::full")?
                    <Col md={2} sm={3}>
                         <Select inline={true} selectedOption = {this.state.selectedState} placeholder={""} id={"store-select"} label={'State'} options={this.state.states} onChange={(e)=>this.handleStateChange(e)} disabled = {true}/>
                    </Col>
                    :null
                }
                {
                    this.props.permissions.includes("reporting::full") ?
                        <Col md={3} sm={3}>
                            <Select inline={true} selectedOption = {this.state.selectedStore} placeholder={""} id={"store-select"} label={'Store'} options={this.state.stores} onChange={(e)=>this.handleStoreChange(e)}/>
                        </Col>
                        :null
                }
                <Col md ={3} sm={3}>
                    <DateRangePicker startDate={moment().startOf('week')} endDate={moment()} timePicker={true} timePicker24Hour={true} onApply={this.handleDateChange}>
                        <Input value ={this.state.displayDate ? this.state.displayDate.displayStart + ' - ' +this.state.displayDate.displayEnd :null}
                               inline={true} id={'date-input'}  placeholder={"Select a Date"} label={'During'} />
                    </DateRangePicker>
                </Col>
                <Col md={1} sm={1} className={"reporting-tooltip"}>
                    <ToolTip type="info"
                             placement="right"
                             trigger="hover"
                             message="Date range only works with 'Completed shipments' report. Pending shipments
                                 report will always show all outstanding shipments.">
                        <img className= "info-icon"src={require("../../../images/info.png")} />
                    </ToolTip>
                </Col>
                <Col md ={1} sm={1}>
                    <button onClick={()=>this.clearFilters()} className={'clear-filter-button'}> Clear </button>
                </Col>
            </Row>
        )
    }
}
Filters.propTypes = {
    /** HandleChange is the parent logic to handle input and what to do with the input in terms of storing the data */
    handleMethod: PropTypes.func.isRequired,
    handleState: PropTypes.func.isRequired,
    handleStore: PropTypes.func.isRequired,
    handleDate: PropTypes.func.isRequired,
};
export default Filters;


