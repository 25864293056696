const initialState = {
    errors: []
};


export default function NotificationReducer (state = initialState, action) {
    switch (action.type) {
        case "ERROR":
            let error = action.payload;
            return {...state,errors : [...state.errors, error]};
        case "REMOVE_NOTIFICATION":
            return {...state,errors : state.errors.filter(error => action.payload !== error)};
            default:
            return state;
    }
}
