import * as type from "../constants/actions/ReportingActionTypes";
import * as lambda from "../actions/ReportingCalls"
import * as s3 from "../libs/awsLib";
const loading = (loading) => {
    return (
        {
            type:type.LOADING_REPORTING_DATA,
            loading:loading
        }
    )
};

const addDefaultData = (shipmentData,rejectedData,manifestData) => {
    return (
        {
            type:type.GET_DEFAULT_DATA,
            shipmentData,
            rejectedData,
            manifestData
        }
    )
};

const addManifestData = (manifestedStores) => {
    return (
        {
            type:type.GET_MANIFEST_DATA,
            manifestData: manifestedStores
        }
    )
};

const addStoreView = (storeView) => {
    return (
        {
            type:type.GET_ALL_STORE_VIEW,
            storeView
        }
    )
};

const addStateView = (stateView) => {
    return (
        {
            type:type.GET_STATE_VIEW,
            stateView
        }
    )
};

const addPendingOverview = (pendingOverview) => {
    return (
        {
            type:type.GET_PENDING_OVERVIEW,
            pendingOverview
        }
    )
};

const addCompleteOverview = (completeOverview) => {
    return (
        {
            type:type.GET_COMPLETE_OVERVIEW,
            completeOverview
        }
    )
};

const addCompleteDetails = (completeDetails) => {
    return (
        {
            type:type.GET_COMPLETE_DETAILS,
            completeDetails
        }
    )
};
const addRejectedOverview = (rejectedOverview) => {
    return (
        {
            type:type.GET_REJECTED_OVERVIEW,
            rejectedOverview
        }
    )
};

const addRejectedDetails = (rejectedDetails) => {
    return (
        {
            type:type.GET_REJECTED_DETAILS,
            rejectedDetails
        }
    )
};

const addPendingDetails = (pendingDetails) => {
    return (
        {
            type:type.GET_PENDING_DETAILS,
            pendingDetails
        }
    )
};

const resetShipmentView = ()=> {
    return (
        {
            type:type.RESET_SHIPMENT_VIEW
        }
    )
}

const fetchRejectedForGeneration = (storeId,date) =>{
    return () => {
        return lambda.generateArchived(storeId,date).then(response => {
            return response;
        })
    }
};
const fetchManifestedStores = (storeId,date,userId) => {
    return () => {
        return lambda.getManifestedStores(storeId,date,userId).then(response => {
            return response;
        })
    }
};
const fetchPublicDownload = (filename) => {
    return () => {
        return s3.publicDownload(filename).then(response => {
            return response;
        })
    }
};

const fetchPendingOverview = (userId) => {
    return () => {
        return lambda.getPendingShipments(null,null,userId).then(response => {
            return response;
        })
    }
};

const fetchPendingDetails = (store,userId) => {
    return () => {
        return lambda.getPendingShipments(store,null,userId).then(response => {
            return response;
        })
    }
};

const fetchOlderShipmentsForStore = (store,userId) => {
    return () => {
        return lambda.generatePendingOlderShipments(store,null,userId).then(response => {
            return response;
        })
    }
};

const fetchCompleteArchivedForStore = (store,date,userId) => {
    return () => {
        return lambda.generateCompleteShipments(store,date,userId).then(response => {
            return response;
        })
    }
};

const fetchCompleteOverview = (date,userId) => {
    return () => {
        return lambda.getCompleteShipments(null,date,userId).then(response => {
            return response;
        })
    }
};

const fetchRejectedOverview = (date,userId) => {
    return () => {
        return lambda.getRejectedShipments(null,date,userId).then(response => {
            return response;
        })
    }
};

const fetchCompleteDetails= (store,date,userId) => {
    return () => {
        return lambda.getCompleteShipments(store,date,userId).then(response => {
            return response;
        })
    }
};

const fetchRejectedDetails= (store,date,userId) => {
    return () => {
        return lambda.getRejectedShipments(store,date,userId).then(response => {
            return response;
        })
    }
};

const fetchAllStoreView = (method,store,date,status,userId)=> {
    return () => {
        return lambda.getAllStoreView(method,store,date,status,userId).then(response => {
            return response;
        })
    }
}

export function generateRejectedItems(storeId=null,date=null){
    return (dispatch,getState) => {
        dispatch(loading(true));
        dispatch(fetchRejectedForGeneration(storeId,date)).then(response =>{
            if(response.status){
                dispatch(fetchPublicDownload(response.file)).then(()=>{
                    dispatch(loading(false));
                })
            }
        })
    }
}

export function generateOlderShipments(storeId=null){
    return (dispatch,getState) => {
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchOlderShipmentsForStore(storeId,userId)).then(response =>{
            if(response.status){
                dispatch(fetchPublicDownload(response.file)).then(()=>{
                    dispatch(loading(false));
                })
            }
        })
    }
}

export function generateCompleteArchivedShipments(storeId=null,date=null){
    return (dispatch,getState) => {
        //dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchCompleteArchivedForStore(storeId,date,userId)).then(response =>{
            if(response.status){
                dispatch(fetchPublicDownload(response.file)).then(()=>{
                   // dispatch(loading(false));
                })
            }
        })
    }
}

export function getManifestedStores(storeId=null,date=null){
    return (dispatch,getState) => {
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchManifestedStores(storeId,date,userId)).then(response =>{;
            if(response.status){
                let stores = response.stores.sort((a,b)=>{
                    return (a.state-b.state) ? 1 : (a.state >b.state) ? -1 : 0
                });
                dispatch(addManifestData(stores));
                dispatch(loading(false));
            }
        })
    }
}

export function getPendingOverview(){
    return (dispatch,getState) => {
        dispatch(resetShipmentView());
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchPendingOverview(userId)).then(response => {
            console.log(response);
            if(response.status){
                dispatch(addPendingOverview(response.data));
                dispatch(loading(false));
            }
        })

    }
};

export function getCompleteOverview(date){
    return (dispatch,getState) => {
        dispatch(resetShipmentView());
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchCompleteOverview(date,userId)).then(response => {
            console.log(response);
            if(response.status){
                dispatch(addCompleteOverview(response.data));
                dispatch(loading(false));
            }
        })

    }
};

export function getRejectedOverview(date){
    return (dispatch,getState) => {
        dispatch(resetShipmentView());
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchRejectedOverview(date,userId)).then(response => {
            console.log(response);
            if(response.status){
                dispatch(addRejectedOverview(response.data));
                dispatch(loading(false));
            }
        })

    }
};

export function getPendingDetails(storeId){
    return (dispatch,getState) => {
        dispatch(resetShipmentView());
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchPendingDetails(storeId,userId)).then(response => {
            console.log(response);
            if(response.status){
                dispatch(addPendingDetails(response.data));
                dispatch(loading(false));
            }
        })

    }
};

export function getCompleteDetails(storeId,date=null){
    return (dispatch,getState) => {
        dispatch(resetShipmentView());
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchCompleteDetails(storeId,date,userId)).then(response => {
            console.log(response);
            if(response.status){
                dispatch(addCompleteDetails(response.data));
                dispatch(loading(false));
            }
        })

    }
};

export function getRejectedDetails(storeId,date=null){
    return (dispatch,getState) => {
        dispatch(resetShipmentView());
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchRejectedDetails(storeId,date,userId)).then(response => {
            console.log(response);
            if(response.status){
                dispatch(addRejectedDetails(response.data));
                dispatch(loading(false));
            }
        })

    }
};


/* ----- NEW FUNCTIONS ------ */

/**
 * get all store shipments overview for date range
 * @param date
 * @param store
 * @param method
 * @param status
 * @param state
 * @returns {Function}
 */
export function getAllStoreView(date, store, method, status, state = null){
    return (dispatch,getState) => {
        dispatch(resetShipmentView());
        dispatch(loading(true));
        let userId = getState().authState.username;
        dispatch(fetchAllStoreView(method,store,date,status,userId)).then(response => {
            let rows = response.data.sort((a,b)=>{
                return (a.state-b.state) ? 11 : (a.state >b.state) ? -1 : 0
            });
            rows = rows.sort((a,b)=>{
                if(a.completed && b.completed){
                    return (a.completed.averagePP-b.completed.averagePP) ? 1 : (a.completed.averagePP >b.completed.averagePP) ? -1 : 0
                }
                return 0;
            });
            if(response.status){
                if(state){
                    rows = filterStoreViewByState(state,response.data);
                }
                dispatch(addStoreView(rows));
                dispatch(loading(false));
            }
        })

    }
}

function filterStoreViewByState(state,rows){
    return rows.filter((row) => {
        if(row.state === state){
            return true;
        }
    });
}
