
import { API } from "aws-amplify";

/**
 * Ask API Gateway for Iot policy whitelisting for public rooms
 * 1. Ensure we are logged in
 * 2. Fetch AWS credentials from sessionStorage, identityId and attach to a new MQTT client
 * 3. Ask for Connect, Publish, Subscribe, Receive policies
 */
export const acquirePublicPolicies = () => {
    API.post("users","policy/attach/connect");
    API.post("users","policy/attach/publish");
    API.post("users","policy/attach/subscribe");
    API.post("users","policy/attach/receive");
};


