// Icon
import React, {Component} from 'react'


class addIcon extends Component{
    render() {
        return (
            <svg id="3d1fe530-a427-4014-acce-ed6ab7f4df21" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><title>icons</title><path d="M43.29,34.94A8.61,8.61,0,0,0,35,28.31a9,9,0,0,0-3.48.73,17.07,17.07,0,0,0-1.56-1.12,10.73,10.73,0,0,1,5-1.26,10.28,10.28,0,0,1,10,8.28ZM35,26.66a5,5,0,1,1,5-5A5,5,0,0,1,35,26.66Zm0-8.29a3.32,3.32,0,1,0,3.31,3.32A3.32,3.32,0,0,0,35,18.37ZM21.23,26.66a8.29,8.29,0,1,1,8.28-8.29A8.29,8.29,0,0,1,21.23,26.66Zm0-14.91a6.63,6.63,0,1,0,6.63,6.62A6.62,6.62,0,0,0,21.23,11.75ZM37.47,39.91H35.76a14.89,14.89,0,0,0-29,0H5a16.57,16.57,0,0,1,32.47,0Z"/></svg>
        )
    }
};

export default addIcon