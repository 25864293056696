import React, { Component } from "react";
import { Card, Row, Col } from "react-bootstrap";

/**
 * Component is used to display cart items in a horizontal fashion.
 * takes in a product/item and returns callbacks for handling size
 * or removing item from cart.
 */
export default class CartRules extends Component {
    render(){
        let rules = this.props.rules?.rules ?? {};

        return(
            <div>
                <hr />
                {Object.keys(rules).map(rule_id => (
                    <Card.Body key={rule_id} style={{padding: "4px 16px"}}>
                        <Row>
                            <Col sm={10}>
                                <span>{rules[rule_id].label}</span>
                            </Col>
                            <Col sm={2}>
                                <span>-${rules[rule_id].discount_amount}</span>
                            </Col>
                        </Row>
                    </Card.Body>
                ))}
            </div>
        )
    }
}
