import store from "../../store/ReduxStore";

import {reloadShipments,updateShipmentLive,addShipmentLive} from "../../actions/ShippingActions";
/**
 * This class will hold all logic behind MQTT messages. at the moment it is used just for SFS
 * can be broken up when needed in the future.
 */
class MqttLib {

    testLib(payload){
        console.log(payload);
        store.dispatch(reloadShipments())
    }

    /**
     * payload
     * @param payload
     */
    handleMessage(payload){
       switch(payload.type){
           case "ADD_SHIPMENT":
               store.dispatch(addShipmentLive(payload.shipmentId));
               break;

           case "SHIPPING_METHOD":
               store.dispatch(updateShipmentLive(payload.shipmentId,payload.type));
               break;

           case "HOLD_SHIPMENT":
               store.dispatch(updateShipmentLive(payload.shipmentId,payload.type));
               break;

           case "PENDING_SHIPMENT":
               store.dispatch(updateShipmentLive(payload.shipmentId,payload.type));
               break;

           // case "DELETE_SHIPMENT":
           //     store.dispatch(addShipmentLive(payload.shipmentId));
           //     break;
       }
    }


}



export default MqttLib