import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
    FormGroup,
    FormControl,
    Col,
    Row,
} from "react-bootstrap";

class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.handleChange = props.handleChange;
        this.state = {
            file_upload_status:false
        }
    }

    handleFileChange = event => {
        if(event.target.files.length > 1){
            for (let i=0; i <(event.target.files.length); i++){
                this.props.file.push(event.target.files[i])
            }
        }else {
            this.props.file.push(event.target.files[0]);
        }
        this.setState({file_upload_status:true})
    };

    render(){
        let {file, label}=this.props
        return (
            <Row controlId="file">
                <Col sm={12}>
                    <FormGroup bsSize="large">
                        <FormControl className="inputfile inputfile-2" multiple onChange={this.handleFileChange} type="file" />
                        <label htmlFor="file-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                <path
                                    d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                            </svg>
                            {(this.state.file_upload_status === false)
                                ?  <span>Upload Parental Consent Form</span>
                                : <span>Upload Another File</span>
                            }
                        </label>
                        {(this.state.file_upload_status === false)
                            ? <div><span>You can select multiple files if needed.</span></div>
                            : <div>
                                {file.length> 0 ?
                                    (file.map((file,i) =>
                                        <span>{file.name}<br /></span>
                                    ))
                                    :null}
                                <span style={{fontWeight:600}}><br />Your files will upload when you complete this form! </span>
                            </div>


                        }
                    </FormGroup>
                </Col>
            </Row>
        )
    }
}

UploadFile.propTypes = {
    /** if data is preloaded from backend pass in the following props with the corresponding data*/
    file: PropTypes.array.isRequired,
    label : PropTypes.string

};
UploadFile.defaultProps = {
    //  street_one: "",
    file : [],
    label: "Upload File"
};


export default UploadFile
