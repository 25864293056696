import React from "react";
import { Route, Redirect } from "react-router-dom";
import PermissionContainer from './Permissions'
import {injectReducer} from "redux-reducers-injector";
import applicantsReducer from "../reducers/ApplicantsReducer";
import withReducer from "./WithAuth"

export default ({ component: C, props: cProps, allowedPermissions, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            cProps.isAuthenticated
                ?  <PermissionContainer allowedPermissions={allowedPermissions}
                                        renderNoAccess={() => null}
                >
                    <C {...props} {...cProps} />
                </PermissionContainer>
                : <Redirect
                    to={`/login`}
                />}

    />;