// Auspost Logo
import React, {Component} from 'react'

class AuspostLogo extends Component{
    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="22.421" height="22.42" viewBox="0 0 22.421 22.42">
                <path className="a" d="M93.622,12.805a11.213,11.213,0,0,0,.006,21.472Z"
                      transform="translate(-85.647 -12.33)"/>
                <path className="a"
                      d="M96.854,12.33c-.262,0-.521.01-.779.028v1.15c.151-.01.308-.016.462-.016a6.974,6.974,0,0,1,.087,13.948l0,7.307c.078,0,.155,0,.234,0a11.21,11.21,0,1,0-.007-22.42"
                      transform="translate(-85.647 -12.33)"/>
            </svg>
        )
    }
}

export default AuspostLogo
