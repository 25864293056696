import * as type from "../constants/actions/CatalogActionTypes";
import {IN_STOCK} from "../constants/actions/CatalogAvailabilityTypes";
import { hasCookedDiscount } from "../helpers/cooked-afterpay-day";

const initialState = {
    reload: true, //  make sure re-renders do not occur
    products: [],
    isLoading: false, // loading indicators for frontend use
};

export default function catalogReducer (state = initialState , action)  {
    switch (action.type) {
        case type.LOADING_PRODUCTS:
            return {...state, isLoading:action.loading};
        case type.ADD_PRODUCTS:
            return {...state,products:action.products};
        case type.CLEAR_PRODUCTS:
            return {...state,products:[]};
        // After retrieving extra product information from magento add to the object without mutating array object.
        case type.ADD_PRODUCT_INFO:
            let available_products = [];
            let unavailable_products = [];
            let updated_products = state.products

            for(let product of action.products) {
                const objIndex = updated_products.findIndex(obj => obj.sku === product.sku);
                let updatedObj = updated_products[objIndex]
                for(let key in product){
                    updatedObj = { ...updatedObj, [key]: product[key]};
                }

                //  ------- AfterPay Day -------

                // Get the product object and pass the sku into the cooked afterpay day discount checker
                const { apd_sale, apd_discount, apd_message } = hasCookedDiscount(updatedObj.sku);

                // If a product is part of afterpay day apply discount
                if (apd_sale) {
                    let newfinalPrice = parseFloat(updatedObj.final_price * apd_discount).toFixed(2);
                    updatedObj = { ...updatedObj, final_price: newfinalPrice, apd_sale: true, on_sale: true, apd_message: apd_message };
                }

                //  ------- End AfterPay Day -------

                // Store available and unavailable items in different arrays. This is done so that we can show the
                // available items before the unavailable ones in the list.
                if (product.status === IN_STOCK) {
                    available_products.push(updatedObj);
                } else {
                    unavailable_products.push(updatedObj);
                }

            }
            return {...state,
                products: [...available_products, ...unavailable_products]
            };
        case type.SELECT_SIZE:
            return {
                ...state,
                products:state.products.map((product)=>(
                    product.sku === action.sku ? {...product,selected_size:action.size} : product)
                )
            };
        default:
            return state;
    }
};
