import React, { Component } from "react";


import "./Privacy-policy.css"

export default class Offer extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    async componentDidMount() {

    }

    render() {
        return (
            <div className="privacy-container">
                <h1 className={"privacy-heading-text"}>Human resources privacy policy</h1>

                <p>This human resources privacy policy outlines the personal information handling practices for employees and prospective employees in Universal Store. See our <a href="https://www.universalstore.com/privacy-policy" target="_blank">main privacy policy</a> for when we handle personal information in wider circumstances.</p>

                <p>We will update this policy when our information handling practices change. Updates will be publicised on our website.</p>

                <h2 className={"privacy-heading-text"}>Personal information handling practices</h2>

                <p>Universal Store will not disclose personal information contained in human resources files to any third parties, except as required or authorised by law. Your information will only be used for recruitment and employee management purposes. Your human resources information will not be used for marketing or other promotional materials.</p>

                <h3 className={"privacy-heading-text"}>Personal information in human resources files</h3>

                <p>Human resources files hold personal information including:</p>

                <ol>
                    <li>- employee, referee and emergency contact details</li>
                    <li>- applications for employment and supporting documents</li>
                    <li>-selection committee reports</li>
                    <li>- employment contracts, and other records relating to terms and conditions of employment</li>
                    <li>- details of financial and other personal interests supplied by employees and their immediate family members for the purpose of managing perceived or potential conflicts of interest</li>
                    <li>- proof of Australian citizenship </li>
                    <li>- proof of the right to work in Australia if not an Australian citizen</li>
                    <li>- records relating to salary, employment benefits and leave</li>
                    <li>- medical certificates or health related information supplied by an employee or their medical practitioner</li>
                    <li>- taxation details</li>
                    <li>- banking information necessary to pay salary and wages</li>
                    <li>- superannuation contributions</li>
                    <li>- information relating to employees’ training and development</li>
                    <li>- information about an employee’s performance.</li>
                </ol>

                <h3 className={"privacy-heading-text"}>How we collect and hold personal information</h3>

                <p>Universal Store generally collects personal information directly from employees and applicants but may also collect personal information from an employee’s supervisors, other employees or intermediaries such as recruitment agents and personnel providers.</p>

                <p>Universal Store may also collect personal information about employees and applicants from third parties, for example previous employers, when it is relevant to the selection process.</p>

                <p>In rare cases we may collect sensitive information without your consent such as where it is necessary to investigate suspected unlawful activity or misconduct of a serious nature within our organisation.</p>

                <h3 className={"privacy-heading-text"}>Third-party service provider access to and use of personal information</h3>

                <p>Universal Store may use third-party service providers to process job applications and manage the recruitment process. We may disclose personal information about prospective employees to third-party service providers for this purpose.</p>

                <p>Third-party service providers may collect, hold, use, and disclose personal information of prospective employees in the provision of recruitment services to Universal Store. These recruitment services may include reference checking.</p>

                <h3 className={"privacy-heading-text"}>Quality of personal information</h3>

                <p>Universal Store maintains and updates personal information in its human resources files as necessary, or when we are advised that the personal information has changed.</p>

                <h3 className={"privacy-heading-text"}>Storage and security of personal information</h3>

                <p>Universal Store takes steps to protect the security and confidentiality of personal information it holds. These steps include password protection for accessing our electronic IT system, audit trails of electronic systems and physical access restrictions.</p>

                <p>When no longer required, Universal Store destroys personal information in a secure manner. Application files are stored for 7 years. After this period you may request to have your application data destroyed.</p>

                <h3 className={"privacy-heading-text"}>Purpose for which we collect, hold, use and disclose personal information</h3>

                <p>Universal Store keeps and handles human resources records to enable us to properly manage our business affairs and the employment of staff.</p>

                <p>The purpose of keeping records on candidates for employment (application files) is to allow us to assess the suitability of candidates for employment at Universal Store.</p>

                <h3 className={"privacy-heading-text"}>Accessing and correcting personal information or making a complaint</h3>

                <p>You can access, and ask that we correct, the personal information we hold about you on our human resource files, or make a complaint about how we have handled your personal information, in accordance with the processes set out in the <a href="https://www.universalstore.com/privacy-policy" target="_blank">main privacy policy.</a></p>
            </div>
        );
    }
}

