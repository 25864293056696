import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createHttpLink } from 'apollo-link-http';
import config from "../config";

export const AppSyncQL = new ApolloClient({
    cache: new InMemoryCache(),
    link: createHttpLink({
        uri: config.appSync.URL,
        headers: {
            'X-API-KEY': config.appSync.API_KEY
        }
    }),
});