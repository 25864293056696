// Icon
import React, {Component} from 'react'


class christmasIcon extends Component{
    render() {
        return (
            <svg aria-hidden="true" data-prefix="fal" data-icon="tree-christmas"
                 className="svg-inline--fa fa-tree-christmas fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 512 512">
                <path
                    d="M304 352c-26.5 0-48 21.5-48 48s21.5 48 48 48 48-21.5 48-48-21.5-48-48-48zm0 64c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm-32-144c0-26.5-21.5-48-48-48s-48 21.5-48 48 21.5 48 48 48 48-21.5 48-48zm-48 16c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zm281.3 172.4l-84.5-108.7c10.5-1.6 19.7-8.3 24.3-18.1 5.3-11.2 3.6-24.5-4.4-34.1l-134.1-161 27.9-13.9c10.8-5.4 17.7-16.5 17.7-28.6s-6.8-23.2-17.7-28.6l-33.1-16.6-16.6-33.1C279.2 6.8 268.1 0 256 0c-12.1 0-23.2 6.8-28.6 17.7l-16.6 33.1-33.1 16.6C166.8 72.8 160 83.9 160 96s6.8 23.2 17.7 28.6l27.9 13.9-134.1 161c-7.9 9.5-9.7 22.8-4.4 34.1 4.6 9.8 13.8 16.5 24.3 18.1L6.7 460.4c-7.5 9.6-8.9 22.7-3.5 33.7S19.8 512 32 512h448c12.2 0 23.4-7 28.7-17.9s4.1-24.1-3.4-33.7zM192 96l42.7-21.3L256 32l21.3 42.7L320 96l-42.7 21.3L256 160l-21.3-42.7L192 96zM32 480l124.4-160H96l127.6-153.2 3.7 7.5c5.4 10.8 16.5 17.7 28.6 17.7 12.1 0 23.2-6.8 28.6-17.7l3.7-7.5L416 320h-60.4L480 480H32z"></path>
            </svg>
        )
    }
};

export default christmasIcon

