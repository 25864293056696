import React, {Component} from 'react';
import ToolTip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import PropTypes from 'prop-types'

import './inputs.css'


/*
*
* Type of ToolTips = 'help',    'success',  'info',   'error'
*
* */
class Tooltip extends Component {

    static propTypes = {
        type:PropTypes.string.isRequired,
        urgent:PropTypes.string,
        message: PropTypes.string.isRequired,
        placement: PropTypes.string.isRequired,
        trigger: PropTypes.string.isRequired,
        ref:PropTypes.string,
        error:PropTypes.string,
        success:PropTypes.string
    };
    constructor(props){
        super(props);
        this.state = {
            type: props.type,
            message: props.message,
            placement: "right"
        }
    }
    error =(t) =>{
        if(t==='success'){
            this.refs.popOver.hide();
            this.setState({type:"success",message:this.props.success});
            this.refs.popOver.show();
        }else if(t==='error'){
            this.refs.popOver.hide();
            this.setState({type:"error",message:this.props.error});
            this.refs.popOver.show();
        }else if (t==='help'){
            this.setState({type:"help",message:this.props.message});
            this.refs.popOver.show();
        }

        else if (t==='null'){
            this.setState({type:"help",message:this.props.message});
            this.refs.popOver.hide();
        }

    };
    componentDidMount(){
        if(window.innerWidth > 1000){
            this.setState({placement: "right"});
        }else if(window.innerWidth < 1000){
                this.setState({placement: "top"});

            }

    }


    render(){
        let props = this.props;
        let type=this.state.type;
        return(
            <OverlayTrigger
                ref="popOver"
                trigger={props.trigger}
                placement = {this.state.placement}
                overlay={
                    <ToolTip id ="tooltip" className={type+"-tooltip" + " tippy"} {...props} placement={this.state.placement}>
                        <strong>{props.urgent}</strong>{this.state.message}
                    </ToolTip>
                }
                delayShow={100}
                delayHide={100}
            >
                <div>
                    {props.children}
                </div>
            </OverlayTrigger>
        )
    }
}


export default Tooltip;