import React, { Component } from "react";
import {Col, Dropdown} from "react-bootstrap";
import {Shipping} from "../ShippingTest";

/**
 * Shipping constants
 */
import METHOD from "../../../constants/shipping/MethodTypes";

class PickSlipDropdownMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Col sm={8} style={{display: "inline-block"}}>
            <Dropdown>
                <Dropdown.Toggle className={"all-shipment-button-toggle"} id="dropdown-basic">
                    Print Pick Slips
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item className={"all-shipment-button-list"} onClick={() => this.props.generatePickSlips(METHOD.CLICK_COLLECT)}>
                        Print Click & Collect
                    </Dropdown.Item>
                    <Dropdown.Item className={"all-shipment-button-list"} onClick={() => this.props.generatePickSlips(METHOD.SAME_DAY)}>
                        US Same Day Delivery
                    </Dropdown.Item>
                    <Dropdown.Item className={"all-shipment-button-list"} onClick={() => this.props.generatePickSlips()}>
                        US All Pick Slips
                    </Dropdown.Item>
                    <Dropdown.Item className={"all-shipment-button-list"} onClick={() => this.props.generatePickSlips(METHOD.SAME_DAY, 'perfect_stranger')}>
                        PS Same Day Delivery
                    </Dropdown.Item>
                    <Dropdown.Item className={"all-shipment-button-list"} onClick={() => this.props.generatePickSlips('all', 'perfect_stranger')}>
                        PS All Pick Slips
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </Col>
    }
}

export default PickSlipDropdownMenu;
