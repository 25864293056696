import React from 'react'
import ShipmentLabel from './ShipmentLabel'
import {moveItem} from "./Actions";
import { useDrop } from 'react-dnd'
const DraggableType = {
    SHIPPINGITEM: 'item',
};

function changeBackground(isActive, canDrop) {
    if (isActive) {
        return 'darkgreen'
    } else if (canDrop) {
        return 'rgb(125, 156, 95)'
    } else {
        return '#fff'
    }
}

export default function DroppableShipmentLabel({label,change,disableDrag,remove,parcel,selectedParcel,collection}) {
    const [{ canDrop, isOver }, drop] = useDrop({
        accept: DraggableType.SHIPPINGITEM,
        drop: (item,monitor)=>(
            change(label,item),
                {
            name: `label: ${label.labelId}`,
            id:label.labelId
        }),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });
    const isActive = canDrop && isOver;
    const backgroundColor = changeBackground(isActive, canDrop);
    return (
        <div ref={drop} style={{ backgroundColor }}>
            <ShipmentLabel  label={label} style={{backgroundColor}} disableDrag={disableDrag}
                            remove={remove} parcel={parcel} selectedParcel={selectedParcel}
                            collection={collection}/>
        </div>
    )
}