import React from 'react';
import ReactSelect from "react-select"
import Form from "react-bootstrap/Form"
import PropTypes from 'prop-types'
import "./inputs.css";
const Select = (props) => (
    <Form.Group  className = {props.id} controlId={props.id} bsSize="large" validationState={props.onValidate}>
            <Form.Label className={"select-label"}>{props.label}</Form.Label>
            <ReactSelect
                onFocus = {props.onFocus}
                isMulti = {props.multi}
                defaultValue = {props.initial}
                onChange={props.onChange}
                className="custom-form-select"
                value={props.selectedOption}
                options={props.options}
                style = {{minHeight:'46px'}}
                isDisabled={props.isDisabled}
                placeholder = {props.placeholder}
                noOptionsMessage = { () => {return props.noOptionsMessage} }
            />
        { /*Empty input hidden, this is for required inputs, as React-select does not support required=-true*/ }
        <input
            style={{height:0,width:0,opacity:0}}
            tabIndex={-1}
            value={props.multi? "na" :props.selectedOption}
            required={props.required}
            onChange={props.onChange}
            autoComplete={props.autoCompleteOff === true ? "new-password" : ""}
        ></input>
        {/*<HelpBlock className={this.state.storeHelp? "show-help" :"hide-help"}>Make sure you've selected the right store</HelpBlock>*/}
    </Form.Group>
);

Select.propTypes = {
    id:PropTypes.string.isRequired,
    onValidate:PropTypes.func,
    label: PropTypes.string,
    onFocus : PropTypes.func,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    initial:PropTypes.object,
    required:PropTypes.bool,
    multi:PropTypes.bool,
    autoCompleteOff: PropTypes.bool,
    noOptionsMessage: PropTypes.func,
};

export default Select;
