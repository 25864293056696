import React, {Component} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {updateStage,confirmOrderDetails} from "../../../../actions/checkout/CheckoutActions"
import CartItemView from "../items/CartItemView";
import LoaderButton from "../../../../components/LoaderButton";
import Dropdown from "../../../../components/form/inputs/Dropdown";

const TILL_ONE="thermal_printer_one";
const TILL_TWO = "thermal_printer_two";
const mapStateToProps = state => {
    return {
        cart_items: state.checkoutState.cart_items,
        stage:state.checkoutState.stage,
        total:state.checkoutState.total,
        shipping_cost: state.checkoutState.shipping_cost,
        shipping_method: state.checkoutState.shipping_method,
        customer_details:state.checkoutState.order_details.customer,
        delivery_details:state.checkoutState.order_details.shipping,
        receipt_id: state.checkoutState.receipt_id,
        loading:state.checkoutState.loading
    };
};

const mapDispatchToProps = dispatch =>{
    return {
        updateStage: (stage) =>{
            dispatch(updateStage(stage))
        },
        confirmOrderDetails : () => {
            dispatch(confirmOrderDetails())
        }
    }
};

class ConfirmDetailsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true,
            printers: [
                {label:"TILL ONE",value:TILL_ONE}, {label:"TILL TWO",value:TILL_TWO}
            ],
            selected_printer:null
        }
    };


    /**
     * if printer previously selected and is in session storage, load that and default select that
     */
    componentDidMount() {
        if(window.sessionStorage.getItem("printer")){
            this.setState({selected_printer: JSON.parse(window.sessionStorage.getItem("printer"))});
        }
    }

    handleDeliveryChange = (method) => {
        this.setState({selected_method:method})
    }

    handleClose = () => {
        this.setState({showModal: false})
        this.props.updateStage(2);
    }

    handleEditCustomerDetails = () => {
        this.setState({showModal: false})
        this.props.updateStage(1);
    }

    submitConfirmDetails = () => {
        this.props.confirmOrderDetails()
    }

    handlePrinterSelect = (selection) => {
        let printer = JSON.parse(selection)
        // set the stringified response to storage item
        window.sessionStorage.setItem("printer", selection);
        this.setState({selected_printer:printer})
    }

    render(){
        let customer_details = this.props.customer_details
        let address_details = this.props.delivery_details
        let selected_printer = this.getSelectedPrinter();
        return(
            <Modal className = {"confirm-details-view"} animation={false} show={this.state.showModal} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><span>CONFIRM DETAILS</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={5} className={"confirm-customer-details"}>
                            <Row>
                                <span><b>Name</b><br />{customer_details.fullname}</span>
                            </Row>
                            <Row>
                                <span><b>Mobile</b><br />{customer_details.telephone}</span>
                            </Row>
                            <Row>
                                <span><b>Email</b><br />{customer_details.email}</span>
                            </Row>
                            <Row>
                                <span><b>Address</b>
                                    <br />{ address_details.street[0] }
                                    <br />{address_details.city}, {address_details.postcode} {address_details.region_code}
                                </span>
                            </Row>
                            <LoaderButton
                                className={"small"}
                                variant="outline-secondary"
                                onClick={this.handleEditCustomerDetails}
                                text={"Edit Customer Details"}
                            />

                        </Col>
                        <Col sm={7} className={"confirm-order-details"}>
                            {Object.keys(this.props.cart_items).map(keyName => <CartItemView key ={keyName} cart_item={this.props.cart_items[keyName]}/> )}
                            <Row className={"confirm-total"}>
                                <Col sm={12}>
                                    <span><b>SHIPPING:</b> ${this.props.shipping_method.amount.value.toFixed(2)}</span>
                                </Col>
                                <Col sm={12}>
                                    <span><b>TOTAL:</b> ${(this.props.total).toFixed(2)}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <LoaderButton
                        variant="outline-danger"
                        onClick={this.handleClose}
                        text = "CANCEL" />
                    <Dropdown
                        onSelect={this.handlePrinterSelect}
                        list={this.state.printers}
                        placeholder={selected_printer ?? "SELECT PRINTER"}
                        variant={"outline-secondary"}
                    />
                    <LoaderButton
                        isLoading={this.props.loading}
                        variant="success"
                        disabled = {!this.state.selected_printer}
                        onClick={this.submitConfirmDetails}
                        text = " CONFIRM ORDER + PRINT BARCODES"
                    >
                    </LoaderButton>
                </Modal.Footer>
            </Modal>
        )
    }

    getSelectedPrinter = () => {
        return typeof(this.state.selected_printer) === 'object' && this.state.selected_printer !== null ?
            'label' in this.state.selected_printer ?
            this.state.selected_printer['label'] : null :null
    }
}
const customerDetailsView = connect(mapStateToProps,mapDispatchToProps)(ConfirmDetailsView);
export default customerDetailsView;