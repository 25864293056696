import React, { useEffect } from 'react'
import {useDrag, useDrop} from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import ShipmentItem from './ShipmentItem'
import {Row, Col} from "react-bootstrap";
const DraggableType = {
    SHIPPINGITEM: 'item',
};
function getStyles(left, top, isDragging) {
    const transform = `translate3d(${left}px, ${top}px, 0)`
    return {
        position: 'absolute',
        transform,
        WebkitTransform: transform,
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : '',
    }
}
const DraggableShipmentItem = (item) => {
    const [{ isDragging }, drag] = useDrag({
        item: { type: DraggableType.SHIPPINGITEM, ...item },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (item && dropResult) {
            }
        },
        canDrag: !item.disableDrag,
        collect: monitor => (
            {
            isDragging: !!monitor.isDragging(),
        }),
    });
    return (
            <div ref={drag}
                 style={{
                     opacity: isDragging ? 0.5 : 1,
                     cursor: 'move',
                 }}>
                <ShipmentItem item={item} />
            </div>
    )
};
export default DraggableShipmentItem
