// Icon
import React, {Component} from 'react'


class addIcon extends Component{
    render() {
        return (
            <svg id="d6d44e31-b3be-4004-b41b-4f3d1e47e643" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><title>icons</title><path d="M41,41H9a4,4,0,0,1-4-4V13A4,4,0,0,1,9,9H41a4,4,0,0,1,4,4V37A4,4,0,0,1,41,41ZM9,39H41a2,2,0,0,0,2-2V13.28L27.83,28.46a4,4,0,0,1-5.66,0L7,13.28V37A2,2,0,0,0,9,39ZM9,11a2,2,0,0,0-1.12.34L23.59,27a2,2,0,0,0,2.82,0l15.71-15.7A2,2,0,0,0,41,11Z"/></svg>)    }
};

export default addIcon