import React, { Component } from "react";
import * as dynamolib from "../../../../libs/dynamoLib"
import Input from "../../../../components/form/inputs/Input";
import AddUser from "./AddUser";
import SearchUser from "./SearchUser";


export default class UserManagement extends Component {
    constructor(props){
        super(props);

        this.state = {

        }
    }

    componentDidMount() {
    }

    handleUserSearch(){

    }

    render() {
        return (
            <div className="user-menu wrapper">
                <div className="user-add">
                    <AddUser />
                    <SearchUser />
                </div>
            </div>

        );
    }
}