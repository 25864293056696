import React, {Component} from 'react';


import Checkbox from '../../../components/form/inputs/Checkbox'
import ReactPlayer from 'react-player';

import {connect} from "react-redux";
import {handleRoleDetails, submitChanges} from '../../../actions/OnboardingActions'

const mapStateToProps = state => {
    return {
        jwt : state.authState.JWT,
        personalDetails: state.onboardingState.personalDetails,
        roleDetails: state.onboardingState.roleDetails,
        personalAtts: state.onboardingState.personalAttributes,
        approvalAtts: state.onboardingState.approvalAttributes

    };
};

const mapDispatchToProps = dispatch => {
    return {

        handleRoleDetails : (name,value) => dispatch(handleRoleDetails(name,value)),
        submitChanges : (token, type, data) => dispatch(submitChanges(token, type, data)),

    };
};

class ConfirmationForm extends Component{
    constructor(props) {
        super(props);
        this.submitChanges = props.submitChanges;
        this.file = [];
        this.state = {
            isLoading : false
        }
    }

    componentWillMount(){

    }
    /**This function is sent to all children for validation. validation is done in parent level
     * as validation could be different for this components depending on where they are used. */
    getValidationState = (type) => {

    };


    renderConfirmationForm(user,personalAtt){
        return(
            <div className = "confirmation-form-container">
                <div className={"welcome-video-container"}>
                    <p>Thanks for registering your details. Now we know a little about you, here's a little info about us. Watch our team values video.</p>
                    <ReactPlayer className={'welcome-video-player'} url='https://vimeo.com/294491577' />
                </div>
                <div>
                    {personalAtt ?
                        (personalAtt.app_pc === 'false' || (user.citizenship.data === 'not-citizen'&& this.props.approvalAtts.hr_vevo === "false")) ? <h2>We still need you to provide a:</h2> :null
                        :null}
                    {personalAtt ?
                        (personalAtt.app_pc === "false") ? <div>
                            <h3>Parent Consent Form </h3>
                            <p>Remember to get your parent or guardian to complete a <a target={"_blank"} href={"https://s3-ap-southeast-2.amazonaws.com/us-public-files/forms/USParentConsentForm.pdf"}>Parent Consent Form</a>. Log back in to upload the form at least 24 hours before you come in so HR have time to approve it.</p>
                        </div> :null
                        :null}
                    {user.citizenship.data ==='not-citizen' && this.props.approvalAtts.hr_vevo === "false"? (
                            <div>
                                <br></br>
                                <h3>VEVO check</h3>
                                <p>VEVO is Visa Entitlement Verification Online. It’s a service run by the Department of Home Affair’s that allows visa holders to easily prove you are eligible to work in Australia.
                                </p>
                                <div className = "confirmation-vevo">
                                    <h4>Step 1:</h4>
                                    <p>Download the MyVEVO app OR visit the 'Visa holder enquiry' page:  <a  target="_blank" href={" https://online.immi.gov.au/evo/firstParty?actionType=query"}>https://online.immi.gov.au/evo/firstParty?actionType=query</a></p>
                                    <h4>Step 2: </h4>
                                    <p>All you need is your passport or ImmiCard info and 1 of the following reference numbers to acces your visa details online</p>
                                    <p>&nbsp;&nbsp;&nbsp; A. Transaction Reference Number (TRN)</p>
                                    <p>&nbsp;&nbsp;&nbsp; B. Visa Grant Number</p>
                                    <p>&nbsp;&nbsp;&nbsp; C. Visa Evidence Number (online only, not available in the myVEVO app).</p>
                                    <h4>Step 3: </h4>
                                    <p>Once you have accessed your visa details, click the 'Send Email' option to open the 'Create email' screen.</p>
                                    <p> &nbsp;&nbsp;&nbsp;1. Recipient email address: Enter 'hr@universalstore.com.au'</p>
                                    <p> &nbsp;&nbsp;&nbsp;2. Your email address: Enter your own email.</p>
                                    <p> &nbsp;&nbsp;&nbsp;3. Click 'Send Email'</p>
                                </div>
                                <br></br>
                                <br></br>
                                <p>This will send your visa details to our HR team, and a confirmation email to you. </p>
                            </div>
                        )
                        :null}

                </div>



            </div>
        )
    }


    handleSubmit = async event => {
        event.preventDefault();
    };


    render(){

        return (

            <form onSubmit={this.handleSubmit}  className="form-container">
                {this.renderConfirmationForm(this.props.personalDetails,this.props.personalAtts)}
                <br></br>
                <br></br>
            </form>

        )
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(ConfirmationForm);