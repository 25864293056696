import * as type from "../constants/actions/ActionTypes";
import * as lambda from "../actions/LambdaCalls";

export const loadingApplicants = (loading) => {
    return(
        {
            type: type.LOADING_APPLICANTS,
            loading: loading
        }
    )
};

export const clearApplicants = () => {
    return(
        {
            type: type.CLEAR_APPLICANTS,
        }
    )
};

export const addApplicant = (applicant) => {
    return(
        {
            type: type.ADD_APPLICANT,
            payload: applicant
        }
    )
};

export const setFocusedApplicant = applicant => {
    return(
        {
            type: type.SET_FOCUSED_APPLICANT,
            payload: applicant
        }
    )
};

export const clearFocusedApplicant = () => {
    return(
        {
            type: type.CLEAR_FOCUSED_APPLICANT,
            payload:{}
        }
    )
};

export const addToVisibleUsers = users =>{

    return(
        {
            type: type.ADD_TO_VISIBLE_USERS,
            payload: users
        }
    )
};

export const replaceVisibleUsers = users =>{
    return(
        {
            type: type.REPLACE_VISIBLE_USERS,
            payload: users
        }
    )
};

export const replaceSearchedUsers = users =>{
    return(
        {
            type: type.REPLACE_SEARCH_USERS,
            payload: users
        }
    )
};

export const addAllUsers = users =>{
    return(
        {
            type: type.ADD_ALL_USERS,
            payload: users
        }
    )
};

export const clearVisibleUsers = () =>{
    return(
        {
            type: type.CLEAR_VISIBLE_USERS,
        }
    )
};

export const updateResponsibleApplicant = user =>{
        return (

            {
                type:type.UPDATE_APPLICANT,
                payload: user
            }
        )

};


/**
 * GET FETCH LOGIC FUNCTIONS
 * */


export function updateApplicant (userId){
    return (dispatch) =>{
        lambda.getSingleResponsibleUser(userId).then(applicant=> {
            dispatch(updateResponsibleApplicant(applicant));
        })
    }
}

// get focused applicant info and set to redux state
export function getFocusedApplicant(userId){
    return (dispatch,getState) =>{
        if(getState().applicantsState.focusedUser !== {}){
            if(getState().applicantsState.focusedUser.userId === userId){
                dispatch(clearFocusedApplicant());
            }else {
                lambda.getSingleResponsibleUser(userId).then(applicant=> {
                    dispatch(setFocusedApplicant(applicant));
                })
            }
        }else {
            lambda.getSingleResponsibleUser(userId).then(applicant=> {
                dispatch(setFocusedApplicant(applicant));
            })
        }
    }
}

const fetchResponsibleApplicants = (users) => {
    return () => {
        return  lambda.getResponsibleUsers(users).then(response =>{
            return response
        });
    }
};

export const fetchElasticSearch = (user,query) => {
    return()=> {
        return lambda.getElasticSearch(user,query).then(response => {
            return response;
        })
    }
};

export function getResponsibleApplicants(user,query) {
    console.log(query);
    return dispatch => {
        dispatch(clearApplicants());
        dispatch(loadingApplicants(true));
        dispatch(fetchElasticSearch(user,query)).then(response =>{
            if(response.status){
                return dispatch(fetchResponsibleApplicants(response.search)).then(response => {
                    dispatch(replaceVisibleUsers(response));
                    dispatch(loadingApplicants(false));
                })
            }
        });
    }
}

export function getSearchedApplicants(users) {
    return dispatch => {
        dispatch(clearVisibleUsers());
        dispatch(loadingApplicants(true));
            return dispatch(fetchResponsibleApplicants(users)).then(response => {
                dispatch(replaceVisibleUsers(response));
                dispatch(loadingApplicants(false));
            })
    }
}


