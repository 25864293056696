import React, { Component } from "react";
import { API, Auth } from "aws-amplify";
import {option,Row,Col} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import Checkbox from "../../components/form/inputs/Checkbox"
import Select from "../../components/form/inputs/Select"
import Input from "../../components/form/inputs/Input"
import DateInput from "../../components/form/inputs/DateInput"
import {updateApplicant} from "../../actions/ApplicantActions";
import {connect} from "react-redux";


const mapDispatchToProps = dispatch =>{
    return {
        updateApplicant : userId => {dispatch(updateApplicant(userId))}
    }
}

class ApplicantTerminateInduction extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // keyProp: this.props.keyProp,
            userId: this.props.userId,
            removals: {
                removeWfs: false,
                removeMyob: false
            },
            isLoading: false,
            termination_reason: {
                reason: "na",
                comment:"na"
            },
            isRemove : false,
            terminationOptions : [{value: 'Unsuccessful_Trial', label: "Unsuccessful trial"}
                ,{value: 'Cancelled trial', label: "Cancelled trial"},
                ,{value: 'resignation', label: "By resignation"},
                {value: 'other', label: "By some other manner"},
            ],
            selectedOption:null
        };
    }


    handleTerminationChange = event =>{
        let reason = this.state.termination_reason;
        reason.comment= event.target.value;
        this.setState({
            termination_reason:reason
        });

    };
    handleSelectChange = event =>{
        let reason = this.state.termination_reason;
        reason.reason = event.label;

        this.setState({termination_reason:reason,selectedOption:event})
    };

    handleRemoveChange = event => {
        let remove = this.state.isRemove;
        remove = !this.state.isRemove;

        this.setState({
            isRemove: remove
        });
    };
    handleSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });

        try{
            await API.post("users", "users/applicants/status/update/unsuccessful", {
                body: {
                    userId: this.props.userId,
                    termination_reason: this.state.termination_reason,
                    end_date: this.props.trial_date
                }
            });

            this.setState({denyIsLoading:false});
            this.props.updateApplicant(this.props.userId)
        } catch (e) {
            console.log(e);
        }

        this.setState({ isLoading: false });
    };

    // form validation, checks storeId is not empty
    validateForm() {
        return (
            this.state.termination_reason.reason !== "na"
        );
    }
    handleChange = event => {
        let reason = this.state.termination_reason;
        reason = event.target.value;
        this.setState({termination_reason:reason})

    };

    render() {
        return (
            <Row className={"submenu"}>
                <Checkbox
                    className={"text-left"}
                    checked={this.state.isRemove}
                    onChange={this.handleRemoveChange}
                    text={"Remove this applicant"}
                    id={"remove-checkbox"} />
                {this.state.isRemove ?
                    <div>
                        <form className={"pending-form"} onSubmit={this.handleSubmit}>
                          <Row>
                              <Col sm={6}>
                                  <Select id={"termination_reason"}
                                          label = {'Reason for termination'}
                                          options={this.state.terminationOptions}
                                          selectedOption={this.state.selectedOption}
                                          required={true}
                                          onChange={this.handleSelectChange}
                                  />
                              </Col>
                          </Row>
                            <Row>

                            <Col sm={6}>
                                <Input
                                    name={"comment"}
                                    label={"Additional Comments"}
                                    id={"termination_reason"}
                                    placeholder={""}
                                    required={true}
                                    componentClass="textarea"
                                    value={this.state.termination_reason.comment === "na" ? "" :this.state.termination_reason.comment}
                                    onChange={this.handleTerminationChange}
                                />
                            </Col>
                            </Row>
                            <Row>
                            <Col sm={3}>
                                <LoaderButton
                                block
                                bsSize="large"
                                disabled={!this.validateForm()}
                                type="submit"
                                isLoading={this.state.isLoading}
                                className={"offer-button terminate-button"}
                                text="Remove Applicant"
                                loadingText="Removing…"
                                />
                            </Col>
                            </Row>
                        </form>
                    </div>
                    :null}

            </Row>
        )
    }
}
export default connect(null,mapDispatchToProps)(ApplicantTerminateInduction)