/**
 * This file contains api calls that are used for non redux functions / components
 */
import {API} from "aws-amplify/lib/index";

/**
 * type= "basic,personal,bank,super,role,wage,attributes"
 * data = "user,roleInformation,superAnn,bankInfo,wageInfo, personal_attributes, approval_attributes"
 * @param type\
 * @param data
 * @returns {Promise<void>}
 */
export async function updateUser (session,type,data){
    await API.post("users", `users/update`, {
        body: {token: session.accessToken.jwtToken, data:data, type:type}
    }).then(response => {

        console.log(response)
    });
}
export async function getResponsibleUsers(session) {
    return API.post("users", "users/responsible", {
        body: {
            token: session.accessToken.jwtToken
        }
    });
}
export async function deleteUser(session,user){
    await API.post("users", `users/delete`, {
        body: {token: session.accessToken.jwtToken, username: user}
    }).then(response => {
        console.log(response)
    });
}
export async function listUserByType(type){
    return await API.post("users", 'users/list',{
        body:{
            type:type
        }
    })
}
export async function getUserInfo(userId){
    return await API.get("users",`users/${userId}`);
}

/*
MICROPAY EXPORT CALL
 */
export async function getYesterdaysExport(day){
    return await API.get("users",`micropay/get/${day}`);
}


export async function getUserRole (username) {
    return await API.get("users", `users/roles/get/${username}`);
};

export async function getSingleStore (store){
    return await API.get("users",`stores/get/${store}`)
};
export async function updateStore (store){
    return API.post("users",`stores/update/${store.storeId}`,{
        body:store
    })
};

export async function getCognito(userId){
    return await API.post("users","users/admin/get",{
        body:{username:userId}
    })
}

export async function deleteDatabase(token,user){
    await API.post("users", `users/delete`, {
        body: {token: token, username: user,type:'DATABASE'}
    })
}