import React, { Component } from "react";
import {Row, Col} from "react-bootstrap";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

import ApplicantDropDownComponent from "./ApplicantDropDownComponent"
import {connect} from "react-redux";

import TickIcon from "../../images/tick-icon.png";
import ErrorIcon from "../../images/error-icon.png";
import Arrow from "../../images/arrow.png"
import ChristmasIcon from "../../images/xmas-tree.svg"

const mapStateToProps = state => {
    return {visibleUsers : state.applicantsState.visibleUsers, loading: state.applicantsState.loading };
};

class ApplicantList extends Component {
    constructor(props){
        super(props);
        this.state ={
            showAddress : ""
        }
    }
    componentDidMount(){

    }

    convertDate(oldDate) {
        let dateArray = oldDate.split('-');
        return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
    }
    renderAttributeType(user) {
        switch (user.applicant_status) {
            case 'STAGE_1_ONBOARDING':
            case 'STAGE_2_ONBOARDING':
            case 'STAGE_3_ONBOARDING':
            case 'TRIAL':
            case 'PENDING_OFFER':
            case 'SUCCESSFUL_APPLICANT':
            case 'TEAM_MEMBER':
            case 'TERMINATED':
            case 'UNSUCCESSFUL_TRIAL':
            case 'PENDING_TERMINATION':
            case 'INDUCTION':
            case 'NOT_LOGGED_IN':
                return (
                    <div className={"right-accordion-header"}>
                        <Col sm={12} xs={12} className = "attr-view">
                            <Row>
                                <span className={"quarter"}>{this.renderBeforeAttMessage(user.app_pc, user.hr_pc, "PC")}</span>
                                <span className={"quarter"}>{this.renderAfterAttMessage(user.hr_tfn, "TFN")}</span>
                                <span className={"quarter"}>{this.renderAfterAttMessage(user.hr_resume, "RÉSUMÉ")}</span>
                            </Row>
                            <Row>
                                <span className={"quarter"}>{this.renderBeforeAttMessage(null, user.hr_vevo, "VEVO")}</span>
                                <span className={"quarter"}>{this.renderAfterAttMessage(user.hr_idcheck, "ID")}</span>
                                <span className ={"quarter"}>{this.renderAfterAttMessage(user.man_referencecheck,"REFERENCES")}</span>

                            </Row>
                        </Col>
                        <Col sm={12} xs={12} className = "mobile-attr-view">
                            <Row>
                                <span className={"quarter"}>{this.renderBeforeAttMessage(user.app_pc, user.hr_pc, "PC")}</span>
                                <span className={"quarter"}>{this.renderBeforeAttMessage(null, user.hr_vevo, "VEVO")}</span>
                            </Row>
                            <Row>
                                <span className={"quarter"}>{this.renderAfterAttMessage(user.hr_tfn, "TFN")}</span>
                                <span className={"quarter"}>{this.renderAfterAttMessage(user.hr_resume, "RÉSUMÉ")}</span>
                            </Row>
                            <Row>
                                <span className ={"quarter"}>{this.renderAfterAttMessage(user.man_referencecheck,"REFERENCES")}</span>
                                <span className={"quarter"}>{this.renderAfterAttMessage(user.hr_idcheck, "ID")}</span>
                            </Row>
                        </Col>
                    </div>
                );
        }
    }

    renderBeforeAttMessage(app, hr, word) {
        return ((app === "true" && hr === "true") || (app === null && hr === "true"))
            ? <div>
                <span className={"attribute-heading"}>{word} <img src={TickIcon} /></span>
            </div>
            : (app === "true")
                ? <div>
                    <span className={"attribute-heading"}>{word} <img src={ErrorIcon} /></span>
                    <span className={"attribute-message"}>Pending HR Approval</span>
                </div>
                : (app === "false")
                    ? <div>
                        <span className={"attribute-heading"}>{word} <img src={ErrorIcon} /></span>
                        <span className={"attribute-message"}>Pending Upload</span>
                    </div>
                    : (app === null && hr === "false")
                        ? <div>
                            <span className={"attribute-heading"}>{word} <img src={ErrorIcon} /></span>
                            <span className={"attribute-message"}>Pending HR Approval</span>
                        </div>
                        : <div>
                            <span className={"attribute-heading grey"}>{word}</span>
                        </div>
    }
    renderAfterAttMessage(hr, word) {
        return (hr === "true")
            ? <div>
                <span className={"attribute-heading"}>{word} <img  src={TickIcon} /></span>
            </div>
            : (hr === "false")
                ?
                <div>
                    <span className={"attribute-heading"}>{word} <img src={ErrorIcon} /></span>
                    <span className={"attribute-message"}>Pending HR Approval</span>
                </div>
                :
                <div>
                    <span className={"attribute-heading"}>{word} <img src={ErrorIcon} /></span>
                    <span className={"attribute-message"}>Pending Upload</span>
                </div>
    }
    render(){
        return (
            ((this.props.visibleUsers.length > 0)
                    ?
                    <Accordion open={true} onChange = {e=>this.props.getFocusedApplicant(e)} >
                        {this.props.visibleUsers.map((user , i)=>
                        user.error === true ?
                            <AccordionItem uuid={user.userId} key={user.userId} className={"accordion-node with-error"} expanded = {false}>
                                <AccordionItemTitle style={{"padding": 0, "box-shadow": "none"}} verticalAlignment={"center"} className={"applicant-accordion-header"}>
                                    <div className={"accordion-header-wrapper"}>
                                        <Row>
                                            <Col sm={6} xs={12}>
                                                <div className={"left-accordion-header"}>
                                                <p>There was a problem loading this user</p>
                                                </div>
                                            </Col>
                                            <Col sm={6} xs={12}>
                                                <p>Please notify HR when you can with your store number </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </AccordionItemTitle>
                                <AccordionItemBody className={"accordion-panel"}>
                                    <div>
                                    </div>
                                </AccordionItemBody>
                            </AccordionItem>

                            :
                            <AccordionItem uuid={user.userId} key={user.userId} className={"accordion-node"} expanded = {false}>
                                <AccordionItemTitle style={{"padding": 0, "box-shadow": "none"}} verticalAlignment={"center"} className={"applicant-accordion-header"}>
                                    <div className={"accordion-header-wrapper applicants"}>
                                        <Row>
                                            <Col sm={6} xs={12}>
                                                <Row className={"left-accordion-header"}>
                                                    <Col sm={3} xs={3}>
                                                        <div className={"store"}>
                                                            <span>{user.home_store === "WAREHOUSE"? "Distribution Centre" : user.home_store === "100" ? "Processing" : user.home_store ==='na'? 'OPS'  : user.home_store} </span>
                                                            {(user.christmas_casual)
                                                                ? <img src={ChristmasIcon}/>
                                                                : null
                                                            }
                                                        </div>
                                                    </Col>
                                                    <Col sm={4} xs={9}>
                                                        <div className={"name-position"}>
                                                            <span
                                                                className={"name"}>{user.first_name + ' ' + user.last_name + ' '}</span>
                                                            <span
                                                                className={"role"}>{user.role.split('_').join(' ').toLowerCase()} </span>
                                                        </div>
                                                    </Col>
                                                    <Col sm={4} xs={12}>
                                                        <div className={"status"}>
                                                            {user.applicant_status === "STAGE_1_ONBOARDING" || user.applicant_status === "STAGE_2_ONBOARDING" || user.applicant_status === "STAGE_3_ONBOARDING" ?
                                                                <span>NEED TO FINISH ONBOARDING</span> :
                                                                <span>{user.applicant_status.split('_').join(' ')} </span>}
                                                            <span className={"trial-date"}>
                                                            {user.applicant_status === "PENDING_TERMINATION" ?
                                                                "Effective Date: " + this.convertDate(user.end_date)
                                                                : (user.trial_type === "INDUCTION" ? "Induction Date: " + this.convertDate(user.trial_date) : "Trial Date: " + this.convertDate(user.trial_date))}
                                                        </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={5} xs={12}>
                                                {this.renderAttributeType(user)}
                                            </Col>
                                            <Col className={"arrow-col"} sm={1}  xs={12} >
                                                <span className={"quarter arrow"}>
                                                        <img style={{marginTop:'10px',marginBottom:'10px'}}className={"toggle-arrow"} src={Arrow}/>
                                                </span>
                                            </Col>
                                        </Row>

                                    </div>
                                </AccordionItemTitle>
                                <AccordionItemBody className={"accordion-panel"}>
                                    <div>
                                        <ApplicantDropDownComponent
                                            refreshPanel={this.applicantCallback}
                                            user = {user}
                                            user_role = {this.props.user_role}
                                            successful = {this.state.successful}
                                            haveCalled = {this.state.haveCalled}
                                            user_stores = {this.props.user_stores}
                                            adminId = {this.props.adminId}
                                        />
                                    </div>
                                </AccordionItemBody>
                            </AccordionItem>)

                        }
                    </Accordion>

                :this.props.loading ? <div className="loading-spinner loading-spinner-blue"></div> : <p>No applicants meet this criteria</p>)

        )
    }
}

export default connect(mapStateToProps)(ApplicantList);