import React from 'react';
import Form from "react-bootstrap/Form";
import PropTypes from 'prop-types'
import MaskedInput from "react-text-mask/dist/reactTextMask";
const DateInput = (props) => (
    <Form.Group className="mb-4" controlId={props.controlId}>
        <Form.Label>{props.label}</Form.Label>
        <MaskedInput
            name={props.name}
            onFocus = {props.onFocus}
            mask = {[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            value = {props.value}
            onChange = {props.onChange}
            guide={false}
            placeholder={"dd/mm/yyyy"}
            className={"form-control "+ props.className}
            required={props.required}
            onBlur = {props.onBlur}
        />
    </Form.Group>
);

DateInput.propTypes = {
    name:PropTypes.string.isRequired,
    id:PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value:PropTypes.string.isRequired,
    label:PropTypes.string.isRequired,
    required:PropTypes.bool
};

export default DateInput;

// import React from 'react';
// import Form from "react-bootstrap/Form";
// import PropTypes from 'prop-types'
// const DateInput = (props) => (
//     <Form.Group controlId={props.id}>
//         <Form.Label>{props.label}</Form.Label>
//         <Form.Control
//             type="date"
//             name={props.name}
//             value={props.value}
//             isValid={props.isValid}
//             isInvalid={props.isInvalid}
//         />
//     </Form.Group>
// );
//
// DateInput.propTypes = {
//     name:PropTypes.string.isRequired,
//     id:PropTypes.string.isRequired,
//     onChange: PropTypes.func.isRequired,
//     value:PropTypes.string.isRequired,
//     label:PropTypes.string.isRequired,
//     required:PropTypes.bool
// };