
import React, {Component} from 'react';
import { withRouter } from "react-router-dom";
import MyStepper from "../../components/form/stepper"
import "./onboarding-form.scss"
//import PersonalForm from './personal/ConnectedPersonalForm';
// import BankingForm from './banking/ConnectedBankingForm';
import ConfirmationForm from './confirmation/ConnectedConfirmationForm'
import ReviewForm from './review/ConnectedReviewForm'

import PersonalForm from "./personal/personal-form"
import BankingForm from "./banking/banking-form"

import "./forms.css";
import {
    getPersonalInfo,
    getRoleInfo,
    getBankInfo,
    getSuperInfo,
    getApprovalAtts,
    getPersonalAtts,
    handleStage,
    getWageInfo
} from "../../actions/OnboardingActions";
import {connect} from "react-redux";

const mapStateToProps = state => {
    return { user: state.authState.username,
        stage : state.onboardingState.stage,
        personalAtts: state.onboardingState.personalAttributes,
        error: state.onboardingState.error,
        personalDetails: state.onboardingState.personalDetails,
        roleStatus: state.authState.roleStatus
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getRoleInfo: (userId) => dispatch(getRoleInfo(userId)),
        getBankInfo : (userId) => dispatch(getBankInfo(userId)),
        getSuperInfo: (userId) => dispatch(getSuperInfo(userId)),
        getWageInfo: (userId) => dispatch(getWageInfo(userId)),
        getPersonalInfo : (userId) => dispatch(getPersonalInfo(userId)),
        getApprovalAtts : (userId) => dispatch(getApprovalAtts(userId)),
        getPersonalAtts : (userId) => dispatch(getPersonalAtts(userId)),
        handleStage : (page) => dispatch(handleStage(page))
    };
};

class OnboardingForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: props.stage
        }
    }

    componentDidMount(){
        this.props.getRoleInfo(this.props.user);
        this.props.getPersonalInfo(this.props.user);
        if(this.props.roleStatus === "STAGE_3_ONBOARDING"){

            this.props.getSuperInfo(this.props.user);
            this.props.getBankInfo(this.props.user);
        }
        this.props.getApprovalAtts(this.props.user);
        this.props.getPersonalAtts(this.props.user);
        this.props.getWageInfo(this.props.user);
    }

    changeStage  = (page) => {
        this.props.handleStage(page);
    };

    render(){
        // temporary fix before we take out alerts and create a state for them seperately
        if(this.props.personalAtts.app_offer === "false"){
            this.props.history.push('/offer')

        }
        return (
            <div className="page-div">
                <MyStepper
                    page = {this.props.stage}
                    ref = {ref => this.child = ref}
                />
                <div className={"form-wrapper"}>
                    {this.props.stage === 1 ? <PersonalForm changeStage = {this.changeStage}/>
                        : this.props.stage === 2? <BankingForm  changeStage = {this.changeStage} />
                            : this.props.stage === 3 ? <ReviewForm /> :this.props.stage === 4 ? <ConfirmationForm />
                               : null}
                </div>
            </div>
        );
    }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingForm));