import React from 'react';
import Form from "react-bootstrap/Form"
import PropTypes from 'prop-types'
import "./inputs.css";

const Select = (props) => (
    <Form.Group className="mb-4" controlId={props.controlId}>
        <Form.Label>{props.label}</Form.Label>
        <Form.Select
            name={props.name}
            onChange={props.onChange}
            value = {props.value}
            placeholder="Please Select a region,state or province"
            isInvalid = {props.isInvalid}
        >
            {props.list.map((state,i) =>
                <option
                    key={i}
                    value={state.value}
                >
                    {state.label}
                </option>
            )}
        </Form.Select>
    </Form.Group>
);

Select.propTypes = {
    id:PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    required:PropTypes.bool,
    name:PropTypes.string.isRequired
};

export default Select;
