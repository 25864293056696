import React from "react";
import "./notifications.css"
/**
 * imported react to create a refersh page href link as a task notification
 * @returns {JSX.Element}
 */

 const refresh = () => (
    <div className = "refresh-task">
        <a href="/">Session timed out! Click me to refresh </a>
    </div>
);

export default refresh