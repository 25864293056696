import * as type from "../constants/actions/CheckoutActionTypes";

const initialState = {
    cart_id: null,
    stage:1,
    total: 0,
    cart_items: [],
    available_methods: [],
    discounts: null,
    order_details: {
        shipping:{
            street: [],
            city: "",
            postcode: "",
            region_code:"",
            country_id:"AU",
        },
        customer:{
            fullname: "",
            email: "",
            telephone: ""
        }
    },
    shipping_method: null,
    shipping_cost: 0,
    loading: false, // loading indicators for frontend use
    fulfiller: '',
    confirmed: false,
    receipt_id: null
};

export default function storeReducer (state = initialState , action)  {
    switch (action.type) {
        case type.ADD_TO_CART:
            return {
                ...state,
                total: false,
                cart_items: containsItem(action.item,state.cart_items)
                    ? {
                        ...state.cart_items,
                        [action.item.selected_size.simple]: {
                            ...state.cart_items[action.item.selected_size.simple],
                            uid: null,
                            qty: state.cart_items[action.item.selected_size.simple].qty + 1
                        }
                    }
                    : {
                        ...state.cart_items,
                        [action.item.selected_size.simple]: {
                            ...action.item,
                            uid: null
                        }
                    }
            }

        case type.UPDATE_CART_ID:
            return {
                ...state,
                cart_id: action.cart_id,
            }

        case type.UPDATE_CART_ITEMS:
            let items = action.cart_items.reduce((previousValue, item) => {
                previousValue[item.simple_sku] = {...previousValue[item.simple_sku]}
                previousValue[item.simple_sku].uid = item.uid
                previousValue[item.simple_sku].qty = item.quantity
                previousValue[item.simple_sku].price = item.prices.row_total.value
                previousValue[item.simple_sku].discount_total = item.prices.total_item_discount.value
                previousValue[item.simple_sku].dicounts = JSON.parse(item.serialized_discount_amounts)
                return previousValue;
            }, state.cart_items);
            for (let sku in items) {
                if (items[sku].uid === null) {
                    delete items[sku];
                }
            }
            return {
                ...state,
                cart_items: items
            };

        case type.LOADING_CHECKOUT:
            return {...state,loading: action.loading}

        case type.UPDATE_SIZE:
            return {
                ...state,
                cart_items: {
                    ...state.cart_items,
                    [action.sku]: action.size
                }
            };
        case type.UPDATE_TOTAL:
            let totalFinalPrice = 0;
            // Loop over cart_items and sum the final_price
            for (let itemId in state.cart_items) {
                if (state.cart_items.hasOwnProperty(itemId)) {
                    const item = state.cart_items[itemId];
                    console.log('item', item);
                    totalFinalPrice += parseFloat(item.final_price * item.qty);
                }
            }
            return {
                ...state,
                total: totalFinalPrice
            }
        case type.UPDATE_DISCOUNTS:
            return {
                ...state,
                discounts: {...JSON.parse(action.discounts)}
            }
        case type.REMOVE_FROM_CART:
            let cart_items = {...state.cart_items}
            delete cart_items[action.item.selected_size.simple];
            return {
                ...state,
                cart_items: {...cart_items}
            };

        case type.UPDATE_CUSTOMER_DETAILS:
            return {
                ...state,
                order_details: {
                    ...state.order_details,
                    customer: action.customer_details
                }
            }

        case type.UPDATE_ADDRESS_DETAILS:
            return {
                ...state,
                order_details: {
                    ...state.order_details,
                    shipping: action.address_details
                }
            }

        case type.UPDATE_SHIPPING_METHOD:
            return {...state, shipping_method: action.shipping_method,shipping_cost: action.shipping_cost}

        case type.SET_AVAILABLE_METHODS:
            return {...state, available_methods: action.methods}

        case type.UPDATE_STAGE:
            return {...state,stage: action.stage}

        case type.UPDATE_CONFIRMATION:
            return {...state,confirmed: action.confirmation}

        case type.UPDATE_RECEIPT_ID:
            return {...state,receipt_id: action.receipt_id}

        case type.UPDATE_FULFILLER:
            return {...state,fulfiller: action.fulfiller}
        case type.CLEAR_STATE:
            return { ...initialState };
        case type.CLEAR_CART_ITEMS:
            return {...state, cart_items: {}};
        default:
            return state;
    }
};


function containsItem(item, list) {
    let found = false;
    Object.keys(list).forEach(function(key) {
        if (key === item.selected_size.simple) {
            found = true;
        }
    });
    return found;
}

