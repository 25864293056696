import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import {authorize, loginUser} from "../../actions/AuthActions";
import {signIn,authenticateNewPassword} from "../../actions/login/LoginActions"
import {connect} from "react-redux";

import LoginForm from "../../components/login/user-login"
import ChangePasswordForm from "../../components/login/user-new-password"
import "./login.scss"


const mapStateToProps = state => {
    return {
        user : state.authState
    }
}
const mapDispatchToProps = dispatch => {
    return {
        authorize : (session) => dispatch(authorize(session)),
        loginUser : () => dispatch(loginUser())
    };
};

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username:null,
            password:null,
            NotAuthorizedException:false,
            UserNotFoundException: false,
            NEW_PASSWORD_REQUIRED: false,
            LOGGED_IN:false,
            loading:false
        };
    }

    handleLogin = async(username,password,setStatus) =>{
        this.setState({loading:true})
        let result = await signIn(username,password)
        this.setState({[result]: true,username,password})
        if(result === "LOGGED_IN"){
            this.props.authorize();
            sessionStorage.setItem('loggedIn','true');
            this.setState({loading:false})
            await this.props.loggedIn();
        }else {
            setStatus(result)
            this.setState({loading:false})
        }
    }

    handleNewPassword = async(new_password) => {
        let result = await authenticateNewPassword(this.state.username,this.state.password,new_password)
        this.setState({[result]: true})
        if(result === "LOGGED_IN"){
            this.props.authorize();
            sessionStorage.setItem('loggedIn','true');
            await this.props.loggedIn();
        }else {
            console.log(result);
        }
    }

    renderPasswordChange() {
        return (
            <div className="login-wrapper">
                <div className="logo">
                    <img className="login-logo" src = 'https://www.universalstore.com/static/version1600211604/frontend/universalstore/universalstore/en_AU/images/logo.png'></img>
                </div>
                <ChangePasswordForm submitDetails ={this.handleNewPassword} />
            </div>
        );
    }

    renderLogin() {
        return (
            <div className="login-wrapper">
                <div className="logo">
                    <img className="login-logo" src = 'https://www.universalstore.com/static/version1600211604/frontend/universalstore/universalstore/en_AU/images/logo.png'></img>
                </div>
                <LoginForm loading = {this.state.loading} submitDetails = {this.handleLogin}/>
                <Link to='/forgotten'> Forgot Password</Link>
                <p style={{color:'white',marginTop:'10px',fontSize:'14px',width:'280px'}} className = "privacy-info"> By entering Universal Store's Team Portal, I agree to the <Link to='/privacy'>privacy policy</Link>, and allow Universal Store to use and store the information I provide for employment and recruitment purposes.</p>
                <p style={{color:'white',marginTop:'10px',fontSize:'14px'}}><a href="mailto:devsupport@universalstore.com.au">Need Help?</a></p>
            </div>
        );
    }
    render() {
        return (
            <div className="signup">
                {this.state.NEW_PASSWORD_REQUIRED
                    ? this.renderPasswordChange()
                    :this.renderLogin()}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Login));
