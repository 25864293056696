import applicantsReducer from "./ApplicantsReducer";
import storesReducer from "./StoresReducer";
import onboardingReducer from "./OnboardingReducer";
import { injectReducer } from '../store/ReduxStore';
import adminReducer from "./AdminReducer";
import shippingReducer from "./ShippingReducer";
import reportingReducer from "./ReportingReducer";
import feedReducer from "./FeedReducer";
import catalogReducer from "./CatalogReducer";
import checkoutReducer from "./CheckoutReducer";

import {combineReducers} from "redux";

export const addReducer =(reducer)=> {
    if(reducer === "applicantsState"){
        injectReducer(reducer,applicantsReducer)
    }
    if(reducer === "storesState"){
        injectReducer(reducer,storesReducer)
    }
    if(reducer === "onboardingState"){
        injectReducer(reducer,onboardingReducer)
    }
    if(reducer === "adminState"){
        injectReducer(reducer,adminReducer);
    }
    if(reducer === "shippingState"){
        injectReducer(reducer,shippingReducer)
    }
    if(reducer === "reportingState"){
        injectReducer(reducer,reportingReducer)
    }
    if(reducer === "feedState"){
        injectReducer(reducer,feedReducer)
    }
    if(reducer === "checkoutState"){
        injectReducer(reducer,checkoutReducer)
    }
    if(reducer === "catalogState"){
        injectReducer(reducer,catalogReducer)
    }
};