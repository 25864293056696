import * as type from "../constants/actions/ActionTypes";
import * as lambda from "../actions/LambdaCalls";
import * as storeActions from "../actions/StoreActions";
import * as authActions from "../actions/AuthActions";

import * as dynamolib from "../libs/dynamoLib";
import {loadRole} from "./OnboardingActions";
import * as notification from "./NotificationActions";
import {updateStore} from "../actions/StoreActions";

const isLoading = (loading) => {
    return(
        {
            type: type.ADMIN_IS_LOADING,
            loading: loading
        }
    )
}

export const loadingUsers = (loading) => {
    return(
        {
            type: type.LOADING_USERS,
            loading: loading
        }
    )
};
export const searchUser = (search) => {

};
//TODO initialize all user stuff before sending to cognito / and dynamo in this instead of react frontend
export const initializeNewUser  = () => {

}

export const createUser = (cognitoParams, role, store) => {
    return dispatch => {
        return dispatch(createCognitoUser(cognitoParams)).then(response =>{
            return dispatch(storeActions.getSingleStore(store)).then(storeObj => {
                let userId = response;
                return dispatch(storeActions.updateStoreMembers([storeObj],userId,role));
            })
        })
    }
};

export const createCognitoUser = (cognitoParams)  => {
    return () => {
        return  lambda.adminCreateUser(cognitoParams).then(response => {
            return response;
        })
    }
};

export const createUserTwo = (cognitoParams, role, stores) => {
    return (dispatch,getState) => {
        let token = getState().authState.JWT;
        return dispatch(createCognitoUser(cognitoParams,token)).then(response =>{
            if('status' in response){
                if(response['status'] === true){
                    let userId = response['username'];
                    return dispatch(storeActions.updateStoreMembers(stores,userId,role));
                }
            }
            console.log(response);
        })
    }
};

export const updateCognitoInfo = (cogParams) => {

};

export const verifyEmail = (cognitoParams,role,status) =>{

};

export const updateUserStatus =async(token,type,data) => {
        let result = await  lambda.updateUser(token,type,data)
};

export const fetchUserRole = (userId) => {
    return () => {
        return  lambda.getUserRole(userId).then(response =>{
           return response
        });
    }
};

export const fetchUserInfo = (userId) => {
    return () => {
        return  lambda.getUserInfo(userId).then(response =>{
            return response
        });
    }
};

const deleteCognitoUser = (userId) => {
    return () => {
        return  lambda.deleteCognitoUser(userId).then(response =>{
            return response
        });
    }
};

const deleteDynamoUser = (userId) => {
    return () => {
        return  lambda.deleteDynamoUser(userId).then(response =>{
            return response
        });
    }
};

const forceMicropayExport = (userId) => {
    return () => {
        return  lambda.forceMicropayExport(userId).then(response =>{
            return response
        });
    }
};

export const theFridayThing = (userId) => {
    let oldUserId = userId;
    let role ;
    let stores;
    let number ;
    return (dispatch,getState) => {
        dispatch(isLoading(true));
        return dispatch(fetchUserRole(oldUserId)).then(userRole =>{
            role = userRole.user_role;
            stores = userRole.stores; // stores variable from userRole table
            return dispatch(fetchUserInfo(oldUserId)).then(userInfo => {
                number = userInfo.number.data;
                if(number.startsWith('04')){
                    number = number.replace('04', '614');
                }else if(number.startsWith('61 4')){
                    number = number.replace('61 4', '614');
                }else if(number.startsWith('+614')){
                    number = number.replace('+614', '614');
                }else if(number.startsWith('+61 4')){
                    number = number.replace('+61 4', '614');
                }
                number = number.replace(/\s/g, '')

                let cognitoParams = {
                    Username: userInfo.email.data,
                    UserAttributes: [
                        {Name: "given_name", Value: userInfo.fName.data},
                        {Name: "family_name", Value:  userInfo.lName.data},
                        {Name: "name", Value: userInfo.fName.data + " " + userInfo.lName.data},
                        {Name: "email", Value: userInfo.email.data},
                        {Name: "phone_number", Value: "+" + number},

                    ],
                    oldUserId : oldUserId,
                    type: "recreate",
                    existing_user:true
                };

                return dispatch(deleteCognitoUser(oldUserId)).then(() =>{
                    return dispatch(createCognitoUser(cognitoParams)).then(response =>{
                        console.log(response);
                        let newUserId = response.username;
                        for(let i in stores){
                            dispatch(storeActions.fetchSingleStore(stores[i].store)).then(response => {
                                let storeObject = response;
                                return dispatch(storeActions.updateStoreMembers(response,newUserId,role)).then(() => {
                                    return dispatch(storeActions.removeMemberFromStores(storeObject,oldUserId,role)).then(()=>{
                                        return dispatch(deleteDynamoUser(oldUserId)).then(()=>{
                                            dispatch(isLoading(false));
                                        })
                                    });
                                });
                            });

                        }
                    })
                })

            })


        })


    }
};

export const forceSingleExport = (userId) => {
    return dispatch => {
        dispatch(isLoading(true));
        return dispatch(forceMicropayExport(userId)).then(response => {
            if(response.status){
                dispatch(isLoading(false));
                dispatch(notification.successNotification(' user exported'));
            }else {
                dispatch(isLoading(false));
                dispatch(notification.errorNotification(' user not exported'));
            }
        });
    }
};

export const handleRoleChange = (userId,newRole) => {
    return dispatch => {
        dispatch(isLoading(true));
        return dispatch(storeActions.removeMemberFromStores())
    }
}