import React, {Component} from "react";
import {Row, Col} from "react-bootstrap";


const ShipmentItem = props => {
   const {media,sku,product_name,qty,itemId,size,localId} = {...props.item.item};
   const key = props.item.key;
    return (
                    <div key={localId} className={"shipment-label-table-item item-"+localId}>
                         <Row>
                             <Col sm={12} style={{padding:"1%"}}>
                                 <img className={"label-image"} src={media} alt={<i className="fad fa-tshirt"/>}/>
                             </Col>
                         </Row>
                        <Row>
                            <Col sm={12} className={"shipment-label-item-qty"}>
                                <span>SIZE:</span> <span>{size}</span>
                            </Col>
                        </Row>
                     </div>
    )
};

export default ShipmentItem
