import React from 'react';
import {Formik, Form, getIn} from 'formik';
import * as yup from 'yup';
import Input from "../form/inputs/Input";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoaderButton from "../LoaderButton";
import PropTypes from "prop-types";
import LoginForm from "./user-new-password";

class UpdateDetailsForm extends React.Component {
    render() {
        return (
            <Formik
                validateOnChange={true}
                initialValues={{...this.props.cognito_details}}
                validationSchema={this.buildUpdateSchema}
                onSubmit={(values) => {
                    let updated_username = this.props.cognito_details.email !== values.email
                    values.name = values.given_name + " " + values.family_name;
                    this.props.submitDetails(values,updated_username)
                }}
            >
                {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit
                      /* and other goodies */
                  }) => (
                    <Col sm={12} md={6} lg={4} xl={4}>
                    <Form noValidate onSubmit={handleSubmit} className={"user-update-form"}>
                        <Row>
                            <Col sm={12}>
                                <Input
                                    controlId={"email"}
                                    name={"email"}
                                    type={"email"}
                                    onChange={handleChange}
                                    value={values.email}
                                    placeholder={"Update Email"}
                                    isInvalid = {!!getIn(errors,"email")}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} >
                                <Input
                                    controlId={"password_confirm"}
                                    name={"given_name"}
                                    onChange={handleChange}
                                    value={values.given_name}
                                    placeholder={"First Name"}
                                    isInvalid = {!!getIn(errors,"given_name")}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input
                                    controlId={"password_confirm"}
                                    name={"family_name"}
                                    onChange={handleChange}
                                    value={values.family_name}
                                    placeholder={"Family Name"}
                                    isInvalid = {!!getIn(errors,"family_name")}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <Input
                                    controlId={"password_confirm"}
                                    name={"phone_number"}
                                    onChange={handleChange}
                                    value={values.phone_number}
                                    placeholder={"Phone Number"}
                                    isInvalid = {!!getIn(errors,"phone_number")}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12}>
                                <LoaderButton
                                    className={"btn-block"}
                                    variant="dark"
                                    type="submit"
                                    text={"UPDATE/CONFIRM"}
                                />
                            </Col>
                        </Row>





                    </Form>
                    </Col>
                )}
            </Formik>
        )
    }

    buildUpdateSchema = () => {
        const mobile_pattern = /^(?:\+?(61))? ?(?:\((?= \)))?(0?[4-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;

        return yup.object().shape({
            email: yup.string().required(),
            phone_number: yup.string().matches(mobile_pattern, 'Invalid Phone Number').required('required'),
        })
    }
}

LoginForm.propTypes = {
    submitDetails: PropTypes.func.isRequired,
    cognito_details: PropTypes.func.isRequired
};

export default UpdateDetailsForm ;