import React from "react";
import Button from "react-bootstrap/Button";
let loading_spinner = <div className = "checkout-spinner"><i className="fas fa-spinner fa-spin" /></div>
import "./loader-button.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default ({ isLoading, block,
                    variant, logo , icon, text, className = "", disabled = false, ...props
                }) =>
    <Button className={`LoaderButton ${className}`} variant={variant?? "primary"} disabled={disabled || isLoading} {...props}>
        {logo? <i className = {logo} style={{marginRight: "16px", fontSize: 25 + 'px'}} />:null}
        {icon? <FontAwesomeIcon icon = {icon} style={{marginRight: "16px", fontSize: '16px'}} />:null}
        {/*{isLoading && <fa glyph="refresh" className="spinning" />}*/}
        {!isLoading ? <span>{text}</span> : <span>{loading_spinner}</span>}
    </Button>;