import React, { Component } from "react";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Productview from "./ProductView"

const mapStateToProps = state => {
    return {
        products: state.catalogState.products,
        isLoading: state.catalogState.isLoading,
    };
};


class CatalogGridView extends Component {
    renderProduct(product) {
        return (
            <Col style={{ paddingBottom: '2rem' }} key={product.sku} xl={4} lg={6}>
                <Productview product={product} isLoading={this.props.isLoading} actionDisabled={this.props.actionDisabled} />
            </Col>
        )
    }

    render() {
        return (
            <div className="overflow-auto product-grid-view">
                <Row>
                    {this.props.products.map(product => this.renderProduct(product))}
                </Row>
            </div>
        )
    }
}
const catalogGrid = connect(mapStateToProps, null)(CatalogGridView);
export default catalogGrid;
