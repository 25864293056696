// Icon
import React, {Component} from 'react'


class addIcon extends Component{
    render() {
        return (
            <svg id="42fa5d50-adc6-422b-94b5-bbeeabc2f5ed" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><title>icons</title><path d="M44.92,25.37h0a1.21,1.21,0,0,1-.18.28h0L40,30.34A.95.95,0,0,1,38.7,29l3.06-3.06H21.52a.94.94,0,1,1,0-1.88H41.79L38.7,21A.95.95,0,0,1,40,19.63l4.69,4.69A1,1,0,0,1,44.92,25.37ZM35,12.8a1.88,1.88,0,0,0-1.87-1.88H8.76A1.88,1.88,0,0,0,6.88,12.8V37.2a1.88,1.88,0,0,0,1.88,1.88H33.17A1.88,1.88,0,0,0,35,37.2V29.7h1.88v7.5A3.75,3.75,0,0,1,33.17,41H8.76A3.76,3.76,0,0,1,5,37.2V12.8A3.76,3.76,0,0,1,8.76,9H33.17a3.75,3.75,0,0,1,3.75,3.76v7.51H35Z"/></svg>        )
    }
};

export default addIcon