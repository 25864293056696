import React from 'react';
import {Formik, Form, getIn} from 'formik';
import * as yup from 'yup';
import Input from "../form/inputs/Input";
import LoaderButton from "../LoaderButton";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: true
        }
    };

    handleClose = () => {
        this.setState({showModal: false})
    }
    render() {
        return (
            <Modal className = {"confirm-details-view"} animation={false} show={this.state.showModal} onHide={this.handleClose}>
                <Modal.Header>
                    <Modal.Title><span>CONFIRM EMAIL</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        validateOnChange={true}
                        initialValues={{
                            confirmation_code:''
                        }}
                        validationSchema={this.buildConfirmationSchema}
                        onSubmit={(values) => {
                            this.handleClose()
                            this.props.submitDetails(values.confirmation_code)
                        }}
                    >
                        {({
                              values,
                              errors,
                              handleChange,
                              handleSubmit
                              /* and other goodies */
                          }) => (
                            <Form noValidate onSubmit={handleSubmit} className={"user-update-form"}>
                                {this.props.confirm_error ?
                                    <Alert>
                                        <span>Incorrect Code! Please contact your Manager or Dev Support</span>
                                    </Alert>
                                    :null
                                }
                                <Input
                                    controlId={"confirmation_code"}
                                    name={"confirmation_code"}
                                    type={"text"}
                                    onChange={handleChange}
                                    value={values.password}
                                    placeholder={"You will have received a confirmation code in you inbox"}
                                    isInvalid = {!!getIn(errors,"confirmation_code")}
                                    required
                                />
                                <LoaderButton
                                    className={"btn-block"}
                                    variant="dark"
                                    type="submit"
                                    text={"CONFIRM NEW EMAIL"}
                                />
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        )
    }

    buildConfirmationSchema = () => {
        return yup.object().shape({
            confirmation_code: yup.string().
            required("Please enter a valid password"),
        })
    }
}

LoginForm.propTypes = {
    submitDetails: PropTypes.func.isRequired,
};

export default LoginForm ;