import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import store, {history} from "./store/ReduxStore"
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router'
import App from "./App";

window.store = store;


ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root')
);

// below code is a temporary solution for updating the website after a deploy due to the registerServiceWorker.js no longer functioning as intended.
if('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            registration.waiting.postMessage('SKIP_WAITING');
                            window.location.reload();
                        }else {
                            console.log('Content is cached for offline use.');
                        }
                    }
                }
            }
        });
}
else {
    console.log('cannot register');
}
