import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import Select from "../../../../components/form/inputs/Select";
import {getSingleStore, getStores, updateStoreRoles} from "../../../../actions/StoreActions";
import {connect} from "react-redux";
import {errorNotification, successNotification} from "../../../../actions/NotificationActions";
import {loadRoles} from "../../../../actions/RolesActions";
import LoaderButton from "../../../../components/LoaderButton";
import * as dynamoDB from "../../../../libs/dynamoLib";


const mapDispatchToProps = dispatch => {
    return {
        getStores : () => dispatch(getStores()),
        getSingleStore: (storeId) => dispatch(getSingleStore(storeId)),
        successNotification : message => {dispatch(successNotification(message))},
        errorNotification : message => {dispatch(errorNotification(message))},
        updateStoreRoles : (store,roles) => {dispatch(updateStoreRoles(store,roles))}
    }
};

const mapStateToProps  = state => {
    return {JWT:state.authState.JWT, store:state.storesState.store,stores:state.storesState.stores}
};

class EditStore extends Component {
    constructor(props){
        super(props);
        this.successNotification = this.props.successNotification;
        this.errorNotification = this.props.errorNotification;
        this.state = {
            stores_dropdown: [{value: '0', label: "ALL"}],
            stores: [],
            selected_store : null,
            roleOptions: [],
            selectedRoles: []
        }
    }

    componentDidMount() {
        this.props.getStores();


    }

    componentDidUpdate(prevProps) {

        if (this.props.stores !== prevProps.stores) {
            this.setStoreDropdown();
        }
    }

    setStoreDropdown = () => {
        let tempStores = this.props.stores;
        let storesDropdown = [];
        for(let store of tempStores){
            let store_entry = {value: store.storeId, label: store.storeName};
            storesDropdown.push(store_entry);
        }
        this.setState({stores_dropdown:storesDropdown});
    };

    handleDropdownChange = async(type,event) => {
        let state = this.state;
        state[type] = event;
        this.setState({
            state
        });
        await this.props.getSingleStore(event.value);
        let roles =  await loadRoles();
        this.setState({roleOptions: roles});
    };

    handleRoleChange = async(event) => {
        let roles = this.state.selectedRoles;
        roles=event;
        this.setState({
            selectedRoles:roles
        });
    };

    updateRoles = async (event) => {
        event.preventDefault();
        let roles = this.state.selectedRoles;
        let roleIds = [];
        for (let role of roles ){
            roleIds.push(role.value);
        }
        this.props.updateStoreRoles(this.props.store,roleIds);
    };

    updateTerminated = async(event) => {
        event.preventDefault();
        this.updateTerminatedByStore(this.props.store);
    }

     moveToTerminated =async(responsibilities)=>{
        for ( let role in responsibilities){
            for (let user of responsibilities[role]) {
                try {
                    await dynamoDB.getCognito(user).then(async response => {
                        if(response.error){
                           //  let newResponsibilities = responsibilities[role];
                           // let index = newResponsibilities.indexOf(user);
                           //  if(index !== -1){
                           //     let databaseDelete = await dynamoDB.deleteDatabase(this.props.JWT,user);
                           //     console.log(databaseDelete);
                           //      newResponsibilities.splice(index, 1);
                           //      console.log("deleted: "+ user);
                           //      responsibilities[role] = newResponsibilities;
                        }
                        await dynamoDB.getUserRole(user).then(async response => {
                            let applicantStatus = response.applicant_status;
                            let userRole = response.user_role;
                            if(userRole === "OLD"){
                                let newResponsibilities = responsibilities[role];
                                let index = newResponsibilities.indexOf(user);
                                if(index !== -1){
                                    let databaseDelete = await dynamoDB.deleteDatabase(this.props.JWT,user);
                                    newResponsibilities.splice(index, 1);
                                    console.log("deleted: "+ user);
                                    responsibilities[role] = newResponsibilities;
                                }
                            }else if(applicantStatus === "TERMINATED" && role !== "TERMINATED"){
                                let newResponsibilities = responsibilities[role];
                                let index = newResponsibilities.indexOf(user);
                                if(index !== -1){
                                    console.log("terminated: "+user);
                                    newResponsibilities.splice(index, 1);
                                    responsibilities[role] = newResponsibilities;
                                }
                                responsibilities["TERMINATED"].push(user);
                            }
                        });

                    })
                }catch (e) {
                    console.log(e);
                }
            }
        }
        return responsibilities;
    };
    updateTerminatedByStore = async(store) =>{
        let storeId = store.storeId;
        let updatedStore = await dynamoDB.getSingleStore(storeId).then(async response => {
            let store = response;
            let responsibilities = response.responsibilities[0];
            store.responsibilities[0] = await this.moveToTerminated(responsibilities);
            return store;
        });
        console.log('updating store');
        dynamoDB.updateStore(updatedStore).then(response => {
            if(response){
                if(response.updated){
                   console.log('updated');
                }else {

                }
            }else {

            }
        });

    }

    render() {
        let currentStore = this.props.store;
        return (
            <div className="store-menu wrapper">
                <div className="store-add">
                    <Row>
                        <Col sm={4}>
                            <Select
                                label = {"Search By Location"}
                                className={"select"}
                                value={this.state.selected_store}
                                onChange={(e) => this.handleDropdownChange('selected_store', e)}
                                options={this.state.stores_dropdown}
                            />
                        </Col>
                    </Row>
                    {this.state.selected_store && currentStore !==null ?
                        <Row>
                            <form onSubmit={this.updateRoles}>
                                <p>Add roles to store</p>
                                <Col sm={8}>
                                    <Select id={"role"}
                                            label={"Role"}
                                            multi={true}
                                            options={this.state.roleOptions}
                                            selectedOption={this.state.selectedRoles}
                                            onChange={(e)=>this.handleRoleChange(e)}/>
                                </Col>
                                <Col sm={2}>
                                <LoaderButton
                                    className="add-user-button large-button"
                                    block
                                    bsSize="small"
                                    disabled={false}
                                    type="submit"
                                    text="update roles"
                                    loadingText="Sending…"
                                />
                                </Col>
                            </form>
                            <form onSubmit={this.updateTerminated}>
                                <p>Move Terminated Applicants</p>
                                <Col sm={8}>
                                    <Select id={"role"}
                                            label={"Role"}
                                            multi={true}
                                            options={this.state.roleOptions}
                                            selectedOption={this.state.selectedRoles}
                                            onChange={(e)=>this.handleRoleChange(e)}/>
                                </Col>
                                <Col sm={2}>
                                    <LoaderButton
                                        className="add-user-button large-button"
                                        block
                                        bsSize="small"
                                        disabled={false}
                                        type="submit"
                                        text="Move Terminated Applicants"
                                        loadingText="Sending…"
                                    />
                                </Col>
                            </form>


                        </Row>

                        :null

                    }
                </div>
            </div>

        );
    }
}

export default connect( mapStateToProps,mapDispatchToProps)(EditStore);