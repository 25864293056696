import {API} from "aws-amplify/lib/index";
/*
DYNAMO CALLS
 */
export const getResponsibleShipments=(store)=>{
  return API.get("shipment", `responsible/${store}`, {
  });
};

export const getSingleResponsibleShipment = (shipmentId) =>{
  return API.get("shipment", `get/${shipmentId}`, {
  });
};

/*
MAGENTO CALLS
 */

export const getLabel = (shipmentId,parcels,createdAt) =>{
  return API.post('shipment', 'magento/generate/labels',{
    body: {
      shipmentId,
      parcels,
      createdAt
    }
  })
};


export const getManifest = (storeId) => {
    return  API.get("shipping",`shipments/manifest/${storeId}`)
};

export const getPickslip = (shipmentId) => {
  return  API.post("shipment", 'magento/pickslip',{
    body:{
      shipmentId
    }
  })
};

export const getAllPickslips = (store, type = 'all', webstore = 'universal_store') => {
  return  API.post("shipment", 'magento/pickslip/all',{
    body:{
      store,
      type,
      webstore
    }
  })
};

export const generateManifest = (store) => {
  return  API.post("shipment", 'magento/generate/manifest',{
    body:{
      store
    }
  })
};
export const fulfillShipment = (storeId, shipmentToEdit, status, collection)=> {
  return API.post('shipment','magento/fulfill/old', {
    body: {
      storeId, shipment:shipmentToEdit, status, collection
    }
  });
};


export const fulfilShipment = (shipment,storeId,status)=> {
  return API.post('shipment','fulfil', {
    body: {
      shipment,
      storeId,
      status
    }
  });
};


export const rejectShipment = (shipment,storeId,status)=> {
  return API.post('shipment','reject', {
    body: {
      shipment,
      storeId,
      status
    }
  });
};


export const generateCCLabel = (shipment,parcels,store)=> {
  return API.post('shipment', 'generate/labels/cc', {
    body: {
      shipment: shipment,
      parcels: parcels,
      store:store
    }
  });
};

export const queryFulfilledByIncrement = (incrementId,storeId) => {
  return API.post('shipment','fulfilled/get/increment',{
    body:{
      storeId: storeId,
      incrementId: incrementId
    }
  })
};

export const getPendingShipments = (store = null,date = null,userId) =>{
  return API.post('shipping','shipping/shipment/report/get', {
    body: {
      store,
      date,
      userId,
      query:'query-pending'
    }
  });
};

export const getFulfillerData = (storeId ,futuraId) =>{
  return API.post('shipment','fulfiller/lookup', {
    body: {
      storeId,
      futuraId
    }
  });
};