import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

import {FormGroup, FormLabel, option, Col, Row, FormControl} from "react-bootstrap";
import StyledSwitch from '../../components/StyledSwitch';
import Select from "react-select";
import {connect} from "react-redux";
import {
    addToVisibleUsers,
    addAllUsers,
    replaceVisibleUsers,
    clearApplicants,
    replaceSearchedUsers,
    getResponsibleApplicants, clearVisibleUsers,
    getSearchedApplicants,
    loadingApplicants} from "../../actions/ApplicantActions";

import {getStoresByUser} from "../../actions/StoreActions";
import {API} from "aws-amplify/lib/index";
import AwesomeDebouncePromise from 'awesome-debounce-promise'; //used for debouncing search to limit the amount of requests made.
import Arrow from "../../images/arrow.png"
const retrieveElasticSearch = async (user,query) => {
    return await API.post("users","users/elastic/query/role",{
        body: {
            user:user,
            queryOptions:query
        }
    })
};
let debouncedSearch = AwesomeDebouncePromise(retrieveElasticSearch,300);


const mapStateToProps = state => {
    return {username:state.authState.username, role:state.authState.role, users: state.applicantsState.users, visibleUsers : state.applicantsState.visibleUsers, searchedUsers: state.applicantsState.searchedUsers, loading: state.applicantsState.loading };
};
const mapDispatchToProps = dispatch => {
    return {
        addToVisibleUsers : users => dispatch(addToVisibleUsers(users)),
        getResponsibleApplicants : (user,query) => dispatch(getResponsibleApplicants(user,query)),
        addAllUsers : users => dispatch(addAllUsers(users)),
        replaceVisibleUsers : users => dispatch(replaceVisibleUsers(users)),
        replaceSearchedUsers : users => dispatch(replaceSearchedUsers(users)),
        clearApplicants : () => dispatch(clearApplicants()),
        clearVisibleUsers: () => dispatch(clearVisibleUsers()),
        getSearchedApplicants: users =>dispatch(getSearchedApplicants(users)),
        loadingApplicants: loading =>dispatch(loadingApplicants(loading))
    };
};

class SearchFilters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle_hr: true,
            toggle_man: true,
            toggle_app: true,
            haveCalled: false,
            successful: null,
            toggle_pending: false,
            toggle_complete: false,
            search_word_string: "",
            search_number_string: "",
            visible_users: [],
            stores_dropdown: [{value: '0', label: "ALL"}],
            selected_store: {value: '0', label: "ALL"},
            asm_dropdown: [{value: '0', label: "ALL"}],
            selected_asm: {value: '0', label: "ALL"},
            status_dropdown: [{value: '0', label: "ALL"},
                {value: 'STAGE_1_ONBOARDING', label: "Onboarding Stage One"},
                {value: 'STAGE_2_ONBOARDING', label: "Onboarding Stage Two"},
                {value: 'STAGE_3_ONBOARDING', label: "Onboarding Stage Three"},
                {value: 'TRIAL', label: "Trial"},
                {value: 'INDUCTION', label: "Induction"},
                {value: 'SUCCESSFUL_APPLICANT', label: "Successful Applicant"},
                {value: 'PENDING_TERMINATION', label: "Pending Termination"},
                {value: 'UNSUCCESSFUL_TRIAL', label: "Unsuccessful Trial"},
                {value: 'NOT_LOGGED_IN', label: "Not Logged In"}],
            selected_status: {value: '0', label: "ALL"},
            visible_loaded: false,
            sortByTrial: false,
            search: [],
            textQuery: null,
            storeQuery: null,
            completedQuery: null,
            statusQuery: null,
            pendingQuery: null
        }
    }
    componentDidMount(){

    }
    componentDidUpdate(prevProps) {
        if(this.props.loading !== prevProps.loading) // Check if it's a new user, you can also use some unique property, like the ID
        {
            if(this.props.loading === false){
                getStoresByUser(this.props.username).then(response =>{
                    this.setState({stores_dropdown:response})
                });
            }

        }
    }

    handleSearch = async(event,type) => {
        this.props.loadingApplicants(true);
        let textQuery = this.state.textQuery;
        let storeQuery = this.state.storeQuery;
        let completedQuery = this.state.completedQuery;
        let statusQuery = this.state.statusQuery;
        let pendingQuery = this.state.pendingQuery;
        ;        switch(type){
            case "TEXT":
                let searchQuery = event.target.value.toLowerCase();
                if(searchQuery.length === 3 && !isNaN(searchQuery)){
                    console.log('searched for store');
                    textQuery ={
                        multi_match: {
                            query: searchQuery,
                            fields: [
                                "store"
                            ]
                        }
                    }
                } else if(searchQuery.length > 2){
                    textQuery= {
                        multi_match: {
                            query: searchQuery,
                            fields: [
                                "fName",
                                "lName"
                            ]
                        }
                    }
                }else if(searchQuery.length < 2){
                    textQuery = null;
                }
                this.setState({textQuery});
                break;
            case "COMPLETED":
                if(this.state.toggle_complete){
                    completedQuery={
                        bool: {
                            should: [
                                [
                                    {
                                        match: {
                                            status: "TEAM_MEMBER"
                                        }
                                    },
                                    {
                                        match: {
                                            status: "TERMINATED"
                                        }
                                    }
                                ]
                            ]
                        }
                    };
                }else {
                    completedQuery = null
                }
                this.setState({completedQuery});
                break;
            case "STORE":
                if(this.state.selected_store.value !== '0'){
                    storeQuery={
                        match : {
                            store : {
                                query : this.state.selected_store.value
                            }
                        }
                    };
                }else {
                    storeQuery = null;
                }
                this.setState({storeQuery});
                break;
            case "STATUS":
                if(this.state.selected_status.value !== '0'){
                    statusQuery={
                        match : {
                            status : {
                                query : this.state.selected_status.value
                            }
                        }
                    };
                }else {
                    statusQuery= null;
                }

                this.setState({statusQuery});
                break;
            case "COMPLETED_FORM":
                if(this.state.toggle_pending){
                    completedQuery={
                        bool: {
                            should: [
                                [
                                    {
                                        match: {
                                            status: "TRIAL"
                                        }
                                    },
                                    {
                                        match: {
                                            status: "INDUCTION"
                                        }
                                    }
                                ]
                            ]
                        }
                    };
                }else {
                    pendingQuery = null
                }
                this.setState({pendingQuery});
        }
        let search = [textQuery,statusQuery,storeQuery,completedQuery];
        let filteredSearch = search.filter(Boolean);
        this.setState({filteredSearch});
        let results = await debouncedSearch(this.props.username,filteredSearch);
        if('search' in results){
            let users = results.search;
            this.handleElasticSearch(users);
        }
    };

    handleElasticSearch = async (search) => {
        this.props.getSearchedApplicants(search);
        // this.props.getResponsibleApplicants(this.props.username,search);
    };

    handleToggle = async(event) => {
        let toggleName = event.target.name;
        let state = this.state;
        state[toggleName] = !this.state[toggleName];
        this.setState({
            state
        });
        if(toggleName === "toggle_complete"){
            this.handleSearch(this.state[toggleName],"COMPLETED")
        }else if(toggleName ==="toggle_pending"){
            this.handleSearch(this.state[toggleName],"COMPLETED_FORM")
        }

    };

    handleDropdownChange = async(type,event) => {
        let state = this.state;
        state[type] = event;
        this.setState({
            state
        });
        if(type === "selected_status"){
            this.handleSearch(this.state[type],"STATUS")
        }else if(type === "selected_store"){
            this.handleSearch(this.state[type],"STORE")
        }
    };
    getResponsibleUsers = async (session,page,pageSize) => {
        return API.post("users", "users/responsible", {
            body: {
                token: session.accessToken.jwtToken,
                page:page,
                pageSize:pageSize
            }
        });
    };

    convertDate(oldDate) {
        let dateArray = oldDate.split('-');
        return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
    }

    renderSearchFilter() {
        return(
            <Accordion accordion ={true}>
                <AccordionItem className={"accordion-node"} key={0}>
                    <AccordionItemTitle style={{"padding": 0, "box-shadow": "none"}} verticalAlignment={"center"} className={"applicant-accordion-header"}>
                        <Row className={"accordion-header-wrapper search-header"}>
                            <Col sm={10} className={"wide-side"}>
                                <div className={"text"}>
                                    <span>Search & Filter</span>
                                </div>
                            </Col>
                            <Col sm={2} className={"narrow-side"}>
                                <span>
                                    <img className={"toggle-arrow"} style={{float:'right'}} src={Arrow}/>
                                </span>
                            </Col>
                        </Row>
                    </AccordionItemTitle>
                    <AccordionItemBody className={"accordion-panel"}>
                        <div className={"search-filter-wrapper"}>
                            <Row className={"search-wrapper"}>
                                <Col sm={6}>
                                    <FormLabel>Search</FormLabel>
                                    <input type="text" className="search" placeholder="Search by name or store ID..." onChange={(e)=>this.handleSearch(e,'TEXT')}/>
                                </Col>

                            </Row>
                            <Row className={"filter-wrapper"}>
                                <Col md={6} sm={6} className={"left-side"}>
                                    <FormLabel className={"filter-label"}>Use these to filter down the applicants.</FormLabel>
                                    {(this.props.role === "HR" || this.props.role === "ASM")
                                        ? <div className={"dropdown asm"}>
                                            <FormGroup className={"half-width"} controlId="home_store" bsSize="large">
                                                <FormLabel>Store</FormLabel>
                                                <Select
                                                    className={"select"}
                                                    value={this.state.selected_store}
                                                    onChange={(e) => this.handleDropdownChange('selected_store', e)}
                                                    options={this.state.stores_dropdown}
                                                />
                                            </FormGroup>
                                        </div>
                                        : null
                                    }
                                    <div className={"dropdown status"}>
                                        <FormGroup className={"half-width"} controlId="home_store" bsSize="large">
                                            <FormLabel>Applicant Status</FormLabel>
                                            <Select
                                                className={"select"}
                                                value={this.state.selected_status}
                                                onChange={(e) => this.handleDropdownChange('selected_status', e)}
                                                options={this.state.status_dropdown}
                                            />
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col md={6} sm={6} className={"right-side"}>
                                    <FormLabel className={"filter-label"}>Each toggle will cause the applicants that fulfil the toggle's criteria to show up.</FormLabel>
                                    <div className={"toggle-wrapper"}>
                                        <StyledSwitch name={"toggle_pending"} onChange={this.handleToggle} checked={this.state.toggle_pending}/>
                                        Completed Form, Awaiting Trial/Induction
                                    </div>
                                    <div className={"toggle-wrapper"}>
                                        <StyledSwitch name={"toggle_complete"} onChange={this.handleToggle} checked={this.state.toggle_complete}/>
                                        Completed Applicants (Team Member / Terminated )
                                    </div>
                                </Col>
                                <div style={{clear:"both"}}/>
                            </Row>
                        </div>
                    </AccordionItemBody>
                </AccordionItem>
            </Accordion>
        )
    }
    render(){
        return(
            this.renderSearchFilter()
        )
    }
}

const SearchFilter = connect( mapStateToProps,mapDispatchToProps)(SearchFilters);

export default SearchFilter;