import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import IntegerInput from "../form/inputs/IntegerInput";
import LoaderButton from "../LoaderButton";

import PropTypes from "prop-types";
import { Formik } from 'formik';

export default class TillLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fulfiller:""
        };
    }

    render(){
        return (
                <div className={"store-login-wrapper"}>
                    <Formik
                        initialValues={{fulfiller:""}}
                        onSubmit={(values) => {
                            this.props.handleLogin(values.fulfiller)
                        }}
                    >
                        {({
                              values,
                              handleChange,
                              handleSubmit
                          }) => (
                        <form onSubmit={handleSubmit} >
                            <Row>
                                <Col sm={12}>
                                    <IntegerInput  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                   name={"fulfiller"}
                                                   id={"fulfiller"}
                                                   placeholder={'TILL NUMBER'}
                                                   onChange={handleChange}
                                                   value={values.fulfiller}
                                                   required={true}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <LoaderButton
                                        variant={"dark"}
                                        className="submit-button"
                                        type="submit"
                                        disabled={values.fulfiller.length < 1}
                                        text="Next"
                                        isLoading={this.props.loading}
                                    />
                                </Col>
                            </Row>
                        </form>
                        )}
                    </Formik>
                </div>

        )
    }
}

TillLogin.propTypes = {
    handleLogin: PropTypes.func.isRequired,
    loading: PropTypes.bool
};