import React, { Component } from "react";
import {Row,Col} from "react-bootstrap";
export default class ApplicantFinished extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Row className={"submenu"}>
                <Col className={"submenu-heading"}>
                    This applicant has been terminated. There is nothing more to be done with them.
                </Col>
            </Row>
        )
    }
}