import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { match } from "react-router-dom"
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './stepper.css'

function getSteps() {
    return ['Personal Details', 'Payment Details', 'Review & Submit','Confirmation '];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return 'Select campaign settings...';
        case 1:
            return 'What is an ad group anyways?';
        case 2:
            return 'This is the bit I really care about!';
        default:
            return 'Uknown stepIndex';
    }
}

class MyStepper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: props.page,
        };
    }

    componentDidMount(){

    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.page!==prevState.page){
            return { someState: nextProps.page};
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.page!==this.props.page){
            //Perform some operation here
            this.setState({activeStep: (this.props.page-1)});
        }
    }

    handleNext = () => {
        console.log(this.state.activeStep);
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    render() {
        const { classes } = this.props;
        const steps = getSteps();
        const { activeStep } = this.state;

        return (
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map(label => {
                        return (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>

        );
    }
}

MyStepper.propTypes = {
    classes: PropTypes.object,
    ref:PropTypes.string,
    page:PropTypes.string.isRequired
};

export default MyStepper;