import React, {Component} from "react";
import {getYesterdaysExport} from "../../../../libs/dynamoLib";
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';
import {Col, Row} from "react-bootstrap";
import {API} from "aws-amplify";
import {connect} from "react-redux";
import {forceSingleExport} from "../../../../actions/UsersActions";

let offset = 10 * 3600 * 1000;
let oneDay = 3600 * 24 * 1000;
let yesterday = new Date(new Date().getTime()- oneDay + offset );
let day = yesterday.getDate();
var d = new Date();
var month = d.getMonth() +1;

const mapDispatchToProps = dispatch => {
    return {
        forceSingleExport : (userId) => dispatch(forceSingleExport(userId))
    }
};

const mapStateToProps  = state => {
    return {isLoading: state.adminState.isLoading}
};


class ViewExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            day:day,
            month :month
        }
    }

    async componentDidMount() {
        let day =this.state.day;
        console.log(day);
        let errors = await getYesterdaysExport('day'+day);
        if(errors !== null){
            this.setState({errors:errors})
        }
      ;
    }

    backDay= async()=>{
        let day = (this.state.day) -1 ;
        this.setState({day:day});
        console.log(day);
        let errors = await getYesterdaysExport('day'+day);
        if(errors !== null){
            this.setState({errors:errors})
        }else {
            this.setState({errors:[]})
        }
    };

    forwardDay= async()=> {
        let day = (this.state.day) + 1 ;
        this.setState({day:day});
        console.log(day);
        let errors = await getYesterdaysExport('day'+day);
        if(errors !== null){
            this.setState({errors:errors})
        }else {
            this.setState({errors:[]})
        }
    };

    forceExport =async(userId)=> {
        this.props.forceSingleExport(userId);
    };

    render() {
        let errors = this.state.errors;
        console.log(errors);
            return (
                <div>
                    <div>
                        <p>{this.state.day} / {this.state.month} </p>
                    </div>
                    <div>
                        <button onClick={this.backDay}>Previous day</button>
                        <button onClick={this.forwardDay}>Next day</button>
                    </div>
                    {errors.length > 0 ?
                        <Accordion>
                            {errors.map((error,i)=>
                                <AccordionItem key={error.userId} className={"accordion-node"} expanded={false}>
                                    <AccordionItemTitle className={"applicant-accordion-header"}>
                                        <div className={"accordion-header-wrapper"}>
                                            <Row>
                                                <Col sm={2} xs={12}>
                                                    <span>{error.errType}</span>
                                                </Col>
                                                <Col sm={5} xs={12}>
                                                    <span>{error.name}</span>
                                                </Col>
                                                <Col sm={5} xs={12}>
                                                    <span>{error.store}</span>
                                                </Col>
                                                <Col>
                                                    <button onClick={()=>this.forceExport(error.userId)}>Manual export</button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </AccordionItemTitle>
                                    <AccordionItemBody className={"accordion-panel"}>
                                        <Row>
                                            <Col sm={12} xs={12}>
                                                <span>{error.error}</span>
                                            </Col>
                                        </Row>
                                    </AccordionItemBody>
                                </AccordionItem>
                            )}
                        </Accordion>
                        :<p>no errors for today</p>
                    }
                </div>
            )
    }
}

export default connect( mapStateToProps,mapDispatchToProps)(ViewExport);