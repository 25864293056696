const debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

import { useCallback } from 'react';

import "../search.css";
export default function ProductSearch({ handleSearch }) {

    function changeHandler(event) {
        event.persist();
        debounceOnChange(event.target.value);
    }

     const debounceOnChange = useCallback(debounce(value => handleSearch(value), 400), [handleSearch]);
    return (
        <div className={"product-search-wrapper"}><input type="text" className="search-input" placeholder="Search by product name or scan barcode..." onChange={changeHandler} /></div>
    );
}