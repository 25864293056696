import React, {Component, useState,useCallback} from "react";
import {Row, Col} from "react-bootstrap";
import { useDrop } from 'react-dnd'
//import update from 'immutability-helper'
import DraggableShipmentItem from "./DraggableShipmentItem";
import {moveItem} from "./Actions";
import {Toggle} from "./ParcelSelect";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
function renderItem(item, key,labelId,disableDrag){
    return <DraggableShipmentItem item={item} key={key} labelId={labelId} disableDrag={disableDrag} />
}

const mapStateToProps = state => {
    return {user: state.authState, store: state.authState.store};
};

const ShipmentLabel = ({store,label,children, onDrop, item, disableDrag,remove,parcel,selectedParcel,collection})=>{
    let even = label.labelId  % 2 === 0 ? "red" : "";
    let cols = label.items;
    let rows = cols/3;
    return (
            <div key={label.labelId} >
                <div className={"shipment-label-table "+even}>
                    <div className={"shipment-label-table-header "+even+" "+disableDrag}>
                        <Col sm={10}>
                            {
                                collection ?   <span className = {'parcel-label'}>UNIVERSAL STORE BAG #{label.labelId}</span>
                                    :  <span className = {'parcel-label'}>PARCEL LABEL #{label.labelId}</span>

                            }

                        </Col>
                        {
                            ((!label.items || label.items.length < 1) && label.labelId !==1 ) ?
                                <Col sm={1} >
                                    <i onClick={()=>remove(label.labelId)} className="fal fa-minus-circle" style={{fontSize:18+'px'}} />
                                </Col>
                                :
                                <Col sm={1} >
                                </Col>

                        }

                        {!disableDrag ?
                            <Col className={'parcel-col'} sm={12}>
                                <Toggle collection = {collection} store ={store} selectedItem={selectedParcel} onSelect={(e)=>parcel(e,label.labelId)} />
                            </Col>
                            :null
                        }

                    </div>
                        {(label.items && label.items.length > 0 ) ?

                            <Row className={"shipment-label-table-item"}>
                                {Object.keys(label.items).map(key => renderItem(label.items[key], key, label.labelId, disableDrag))}
                            </Row>

                            :
                            <Row className={"shipment-label-table-item"}>
                            <Col sm={12}>
                            <span>
                            There are currently no items listed.
                            Move an item onto this parcel label.
                            </span>
                            </Col>
                            </Row>
                        }
                </div>
            </div>
    )
};


const shipmentLabel = connect(mapStateToProps, null)(ShipmentLabel);
export default shipmentLabel;
// export default function ShipmentLabel({label, item}){
//     return (
//             <Col sm={12}>
//                 <Row key={label.labelId}>
//                     <div className={"shipment-label-table"}>
//                         <Row className={"shipment-label-table-header"}>
//                         </Row>
//                         {item}
//                     </div>
//                 </Row>
//             </Col>
//     )
// }
