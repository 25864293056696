import * as type from "../constants/actions/ActionTypes";

const initialState = {
    reload: true, // this will be false if a new store has been added to the state or if any changes have been made to the state, fixes re renders
    loading:false,
    users: [],
    visibleUsers: [],
    focusedUser: {},
    searchedUsers: []
};
export default function applicantsReducer (state = initialState , action) {
    switch (action.type) {
        case type.LOADING_APPLICANTS:
            return {...state, loading:action.loading};
        case type.CLEAR_APPLICANTS:
            return {...state, users: []};
        case type.INIT_APPLICANTS:
            return{ ...state, users: action.payload };
        case type.ADD_APPLICANT:
            return { ...state, users: state.users.concat(action.payload) };
        case type.LIST_APPLICANTS:
            return { ...state, users: [...state.users, action.payload] };
        case type.SET_FOCUSED_APPLICANT:
            return {...state, focusedUser : action.payload};
        case type.CLEAR_FOCUSED_APPLICANT:
            return {...state, focusedUser : action.payload};
        case type.UPDATE_APPLICANT:
            //return { ...state, users: [...state.users, action.payload] };
            return {
                ...state,
                users: state.users.map(
                    (user, i) => user.userId === action.payload.userId ? user
                        : user
                ),
                visibleUsers: state.visibleUsers.map(
                    (user, i) => user.userId === action.payload.userId ? {...action.payload} : user
                ),
                focusedUser: action.payload
            };
        case type.ADD_TO_VISIBLE_USERS:
            return { ...state, visibleUsers: state.visibleUsers.concat(action.payload) };

        case type.REPLACE_SEARCH_USERS:
            return { ...state, searchedUsers: action.payload };
        case type.REPLACE_VISIBLE_USERS:
            return { ...state, visibleUsers: action.payload};
        case type.CLEAR_VISIBLE_USERS:
            return {...state,visibleUsers:[]};
        case type.ADD_ALL_USERS:
            return {...state, users:action.payload};
        default:
            return state;
    }
};

