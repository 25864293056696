import React, { Component } from "react";
import {Row,Col} from "react-bootstrap"
import ApplicantChangeTrial from "./ApplicantChangeTrial";
import "./Applicants.css";

export default class ApplicantContactInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user: this.props.user
        };
    }

    convertDate(oldDate) {
        let dateArray = oldDate.split('-');
        return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0];
    }
    renderWorkNotes() {
        return (
            <div className={"submenu"}>
                <Row>
                    <Col sm={6} xs={12}>
                        <span className={"submenu-heading"}>Availability</span>
                    </Col>
                </Row>

                <div className={"work-notes-submenu"}>
                    {this.props.availability ?
                        <Row >

                                <Col className={"availability-day"} lg = {1} sm={1} xs={6}>
                                    <span className={"submenu-heading day"}>Monday</span>
                                    {this.props.availability.monday.map((times, i) =>
                                        (times.start === "12:00am" && times.end === "12:00am")
                                            ? <span className={"availability-day-info"}>All Day</span>
                                            : (times.start === "na" && times.end === "na")
                                            ? <span className={"availability-day-info"}>Can't work</span>
                                            : <span className={"availability-day-info"}>{times.start} - {times.end}<br /></span>
                                    )}
                                </Col>
                                <Col className={"availability-day"} lg = {1} sm={2} xs={6}>
                                    <span className={"submenu-heading day"}>Tuesday</span>
                                    {this.props.availability.tuesday.map((times, i) =>
                                        (times.start === "12:00am" && times.end === "12:00am")
                                            ? <span className={"availability-day-info"}>All Day</span>
                                            : (times.start === "na" && times.end === "na")
                                            ? <span className={"availability-day-info"}>Can't work</span>
                                            : <span className={"availability-day-info"}>{times.start} - {times.end}<br /></span>
                                    )}
                                </Col>
                                <Col className={"availability-day"} lg = {1} sm={2} xs={6}>
                                    <span className={"submenu-heading day"}>Wednesday</span>
                                    {this.props.availability.wednesday.map((times, i) =>
                                        (times.start === "12:00am" && times.end === "12:00am")
                                            ? <span className={"availability-day-info"}>All Day</span>
                                            : (times.start === "na" && times.end === "na")
                                            ? <span className={"availability-day-info"}>Can't work</span>
                                            : <span className={"availability-day-info"}>{times.start} - {times.end}<br /></span>
                                    )}
                                </Col>
                                <Col className={"availability-day"} lg = {1} sm={2} xs={6}>
                                    <span className={"submenu-heading day"}>Thursday</span>
                                    {this.props.availability.thursday.map((times, i) =>
                                        (times.start === "12:00am" && times.end === "12:00am")
                                            ? <span className={"availability-day-info"}>All Day</span>
                                            : (times.start === "na" && times.end === "na")
                                            ? <span className={"availability-day-info"}>Can't work</span>
                                            : <span className={"availability-day-info"}>{times.start} - {times.end}<br /></span>
                                    )}
                                </Col>
                                <Col className={"availability-day"} lg = {1} sm={2} xs={6}>
                                    <span className={"submenu-heading day"}>Friday</span>
                                    {this.props.availability.friday.map((times, i) =>
                                        (times.start === "12:00am" && times.end === "12:00am")
                                            ? <span className={"availability-day-info"}>All Day</span>
                                            : (times.start === "na" && times.end === "na")
                                            ? <span className={"availability-day-info"}>Can't work</span>
                                            : <span className={"availability-day-info"}>{times.start} - {times.end}<br /></span>
                                    )}
                                </Col>
                                <Col className={"availability-day"} lg = {1} sm={2} xs={6}>
                                    <span className={"submenu-heading day"}>Saturday</span>
                                    {this.props.availability.saturday.map((times, i) =>
                                        (times.start === "12:00am" && times.end === "12:00am")
                                            ? <span className={"availability-day-info"}>All Day</span>
                                            : (times.start === "na" && times.end === "na")
                                            ? <span className={"availability-day-info"}>Can't work</span>
                                            : <span className={"availability-day-info"}>{times.start} - {times.end}<br /></span>
                                    )}
                                </Col>
                                <Col className={"availability-day"} lg = {1} sm={2} xs={6}>
                                    <span className={"submenu-heading day"}>Sunday</span>
                                    {this.props.availability.sunday.map((times, i) =>
                                        (times.start === "12:00am" && times.end === "12:00am")
                                            ? <span className={"availability-day-info"}>All Day</span>
                                            : (times.start === "na" && times.end === "na")
                                            ? <span className={"availability-day-info"}>Can't work</span>
                                            : <span className={"availability-day-info"}>{times.start} - {times.end}<br /></span>
                                    )}
                                </Col>
                        </Row>
                        :null}

                </div>
                <div className={"work-notes-submenu"}>
                    {this.props.user.availability_notes === "There are no holiday notes for this applicant." ?
                        <div>
                            <span className={"submenu-heading"}>Availability Notes</span>
                            <ul>
                                <li>{this.props.user.availability_notes !== "na" ? this.props.user.availability_notes : "There are no availability notes for this applicant."} </li>
                                {this.props.user.applicant_status === "TEAM_MEMBER" ? this.props.user.ten_hour_rule ? <li>10-hour minimum break between shifts</li> : <li>12-hour minimum break between shifts</li> : null}
                            </ul>

                        </div>
                        :<div>
                            <span className={"submenu-heading"}>Availability Notes</span>
                            <ul>
                            <li>{this.props.user.availability_notes !== "na" ? this.props.user.availability_notes : "There are no availability notes for this applicant."} </li>
                                {this.props.user.applicant_status === "TEAM_MEMBER" ? this.props.user.ten_hour_rule ? <li>10-hour minimum break between shifts</li> : <li>12-hour minimum break between shifts</li> : null}
                            </ul>
                        </div>}
                </div>
            </div>
        )
    }

    render() {
        console.log(this.props.user);
        let emergencyContactOne = this.props.user.emergency ? this.props.user.emergency.contactOne ? this.props.user.emergency.contactOne : "" : "";
        let emergencyContactTwo = this.props.user.emergency ? this.props.user.emergency.contactTwo  ? this.props.user.emergency.contactTwo : "" : "";
        return (
            <Row>
                <Col sm={6}>
                    {(this.props.user.applicant_status === "STAGE_1_ONBOARDING" || this.props.user.applicant_status === "STAGE_2_ONBOARDING" || this.props.user.applicant_status === "STAGE_3_ONBOARDING")?
                        <Row>
                            <Col sm ={12}>
                                <span className={"contact-info"}>Onboarding Form Progress: {this.props.user.applicant_status === "STAGE_1_ONBOARDING" ? "Personal Details (Step 1)": this.props.user.applicant_status === "STAGE_2_ONBOARDING" ? "Payment Details (Step 2)" : this.props.user.applicant_status === "STAGE_3_ONBOARDING" ? "Review & Submit (Step 3)" :null}</span>
                            </Col>
                        </Row>
                        :null}

                    <Row style={{paddingLeft: '2%'}}>
                        <Col sm={12} >
                            <div className={"contact-heading"}>CONTACT INFO:</div>
                            <span className={"contact-info"}>Email Address: {this.props.user.email}</span>
                            <span className={"contact-info"}>Phone Number: {this.props.user.phone}</span>
                            {this.props.user.previously_employed ?
                                <span className={"contact-info"}>Has worked for Universal Store in the past.</span>
                                :null}
                        </Col>
                    </Row>
                    {
                        this.props.user_role !== "MANAGER" ?
                            <Row style={{paddingLeft: '2%'}}>
                                <Col sm={12} >
                                    <div className={"contact-heading"}>EMERGENCY INFO:</div>
                                    {
                                        emergencyContactOne ?
                                            <Row>
                                                <Col sm={12}>
                                                    <span className={"contact-info"}>Name: {emergencyContactOne.fName}</span>
                                                    <span className={"contact-info"}>Relationship: {emergencyContactOne.relationship}</span>
                                                    <span className={"contact-info"}>Phone Number: {emergencyContactOne.number}</span>
                                                </Col>
                                            </Row>
                                            : null
                                    }
                                    {
                                        emergencyContactTwo ?
                                            <Row>
                                                <Col sm={12}>
                                                    <span className={"contact-info"}>Name: {emergencyContactTwo.fName}</span>
                                                    <span className={"contact-info"}>Relationship: {emergencyContactTwo.relationship}</span>
                                                    <span className={"contact-info"}>Phone Number: {emergencyContactTwo.number}</span>
                                                </Col>
                                            </Row>
                                            : null
                                    }

                                </Col>
                            </Row>
                            :null
                    }
                    {(this.props.user.vevo.visa_no !== undefined)
                        ? <Row>
                            <Col sm={12 }>
                                <div className={"contact-heading"}>VEVO Info:</div>
                                <span className={"contact-info"}>Visa No: {this.props.user.vevo.visa_no}</span>
                                <span className={"contact-info"}>Visa Expiry: {this.convertDate(this.props.user.vevo.visa_expiry)}</span>
                                <span className={"contact-info"}>Work Restriction Notes: {this.props.user.vevo.work_restriction_notes}</span>
                            </Col>
                        </Row>
                        : null
                    }
                </Col>
                <Col sm={6}>
                {(this.props.applicant_status === "SUCCESSFUL_APPLICANT" || this.props.applicant_status ==="TEAM_MEMBER" ||
                    this.props.applicant_status === "TERMINATED") ? null :
                    <ApplicantChangeTrial applicant_status ={this.props.applicant_status}
                                          user={this.props.user}
                                          userId={this.props.user.userId}
                                          user_role = {this.props.user_role}
                                          user_stores={this.props.user_stores}
                                          callbackFromParent={this.props.callbackFromParent}/>}
                </Col>
                <Col sm={12}>

                        {this.renderWorkNotes()}
                </Col>

            </Row>

        )
    }
}

