import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Progress from "react-bootstrap/ProgressBar";
import PropTypes from "prop-types";
/**
 * Takes in the stage to show in the progress bar.
 * stgaes 1-4 will work
 */
export default class CheckoutProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render(){
        let stage = this.props.stage
        let percentage = ((stage/4)*100)
        return(
                <Row className={'checkout-progress-bar'}>
                    <Col sm={3}>
                        <span className = {stage === 1 ? 'selected-font' : null}>Order Items</span>
                    </Col>
                    <Col sm={3}>
                        <span className = {stage === 2 ? 'selected-font' : null}>Details</span>
                    </Col>
                    <Col sm={3}>
                        <span className = {stage === 3 ? 'selected-font' : null}>Delivery</span>
                    </Col>
                    <Col sm={3}>
                        <span className = {stage === 4 ? 'selected-font' : null}>Success</span>
                    </Col>
                    <Col sm={12}>
                        <Progress variant={"custom-success"} animated now={percentage}/>
                    </Col>
                </Row>
        )
    }
}
CheckoutProgress.propTypes = {
    stage: PropTypes.number.isRequired
};