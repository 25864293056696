import React, { Component } from 'react';
import PermissionContainer from './Permissions'
import { Redirect } from "react-router-dom";
import {connect} from "react-redux";

import {addReducer} from "../reducers/CreateReducers"

const mapStateToProps = state => {
    return { user: state.authState };
};

// HOCs are usually written with `with` at the beginning
export default function withAuth(WrappedComponent,state,allowedPermissions,allowedRoles) {
    class ComponentContainer extends Component {
        constructor() {
            super();
        this.state = {
            injected:false
            }
        }

        componentDidMount() {
            //checks if this route needs a reducer to be created, if so, createReducer is called then a new state is added to the store
            if(state){
                if(state instanceof(Array)){
                    for(let s of state){
                        addReducer(s);
                    }
                }else {
                    //console.log("reducer adding");
                    addReducer(state)
                }

            }
                this.setState({injected:true})
        }




        render() {
            if(this.props.user.status === "LOGGED_IN" && this.state.injected){
                   return (
                       <PermissionContainer allowedPermissions={allowedPermissions}
                                            allowedRoles = {allowedRoles}
                                         renderNoAccess={() => null}>
                            <WrappedComponent />
                       </PermissionContainer>)


            }
            else if(this.props.user.status === "LOGGED_OUT") {
                return <Redirect
                    to={`/login`}
                />
            }

            else return null


        }
    }
    return connect(mapStateToProps)(ComponentContainer);
}
