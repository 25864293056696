import React from "react";
import AWSConfig from "../../config";
import {connect} from "react-redux";
import uuid from "uuid";
import * as Actions from "./IotActions";
import {Auth} from 'aws-amplify';
import MqttLib from "./mqtt-lib";
let AWSIoTData = require('aws-iot-device-sdk');


const mapStateToProps = state => {
    return {user: state.authState};
};

let mqttClient;
const mqttLib = new MqttLib();
class MqttAgent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            awsCreds: null
        }
    }

        /**
         * On MqttAgent mount acquire public policies and connect to the service which will give access to the appropriate topics.
         * @returns {Promise<void>}
         */
    async componentDidMount() {
        await Actions.acquirePublicPolicies();
        await Auth.currentUserCredentials().then(
             credentials => {
                 this.setState({awsCreds: credentials});
             }
         );
        await this.connect();
    }
    connect = async() => {
        mqttClient = AWSIoTData.device({
            region: AWSConfig.cognito.REGION,
            host:AWSConfig.IotData.ENDPOINT,
            clientId: uuid(1),
            protocol: 'wss',
            maximumReconnectTimeMs: 8000,
            accessKeyId: this.state.awsCreds.accessKeyId,
            secretKey: this.state.awsCreds.secretAccessKey,
            sessionToken: this.state.awsCreds.sessionToken
        });
        mqttClient.on('connect', this.onConnect);
        mqttClient.on('message', this.onMessage);
        mqttClient.on('close', this.onClose);
    };

    /**
     * Subscribe users to the correct topics for mqtt messaging
     * for the moment we are only subscribing stores to their store, and users to their personal username room
     */
    onConnect = () => {
        mqttClient.subscribe("/universalstore/room/"+this.props.user.store ? "/universalstore/room/"+this.props.user.store :"/universalstore/room/"+this.props.user.username, null, function (err,result) {
            console.log('client connected to their personal topic');
        });
        //mqttClient.publish('/universal/store/100',payloadT); // send messages
    };
    /**
     * @param topic
     * @param message
     * {"type": "REFRESH"}
     */
    onMessage = (topic, message) => {
        var messageString = new TextDecoder("utf-8").decode(message);
        let payload = JSON.parse(messageString);
        console.log(payload);
        mqttLib.handleMessage(payload);
    };

    onClose = () => {
        // console.log('closed');
    };

    render(){
        return (
            <div>
            </div>
        )
    }
}
const agent = connect(mapStateToProps, null)(MqttAgent);
export default agent;