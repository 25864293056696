import React,{Component} from "react";
import {Row, Col} from "react-bootstrap";
import {
    updateItemAvailability,
    updateShipmentStage,
    updateShipment,
    calculateLabels,
    generateCCLabel
} from "../../../actions/ShippingActions";
import {connect} from "react-redux";

import LoaderButton from "../../../components/LoaderButton";
import {DndProvider} from "react-dnd";
import HTML5Backend from 'react-dnd-html5-backend'
import TouchBackend from 'react-dnd-touch-backend'
import DroppableShipmentLabel from "./stagetwo/DroppableShipmentLabel";
import CustomDragLayer from "./stagetwo/CustomDragLayer";
import Checkbox from "../../../components/form/inputs/Checkbox";
import Popup from "./LabelPopup"
var moment = require('moment-timezone');

const mapStateToProps = state => {
    return {loading:state.shippingState.isLoading,focusedShipment:state.shippingState.focusedShipment};
};

const mapDispatchToProps = dispatch =>{
    return {
        updateShipment : (shipmentId,status,reason,collection) => {dispatch(updateShipment(shipmentId,status,reason,collection))},
        updateItemAvailability: (shipmentId,itemId,availability) => {dispatch(updateItemAvailability(shipmentId,itemId,availability))},
        updateShipmentStage : (stage,shipmentId) => {dispatch(updateShipmentStage(stage,shipmentId))},
        calculateLabels : (shipmentId,labels,createdAt) => {dispatch(calculateLabels(shipmentId,labels,createdAt))},
        generateCCLabel: (shipment,parcels) => {dispatch(generateCCLabel(shipment,parcels))}
    }
};



class ShipmentStageTwo extends Component {

    constructor(props) {
        super(props);
        this.updateShipmentStatus = this.props.updateShipmentStatus;
        this.updateItemAvailability = this.props.updateItemAvailability;
        this.state = {
            labels: [],
            selectedParcel: 1,
            updateConfirmation:false,
            labelPopup:false
        };
    }
    componentDidMount() {
        this.prepareLabels();
    }

    componentDidUpdate(prevProps) {
        if((prevProps.shipment.label !== this.props.shipment.label) && this.props.shipment.label.length > 0){
            this.setState({labelPopup:true})
        }
    }

    prepareLabels () {
        let labelOne = {};
        let items = this.props.shipment.items;
        items = items.filter(x => x.availability === "true" || x.availability === true);
        labelOne.parcelType = 9;
        labelOne.items = [...items];
        labelOne.labelId = "1";
        let newArray = this.state.labels.concat(labelOne);
        if(this.state.labels.length < 1){
            this.setState({labels:newArray});
        }
    }

    updateLabels(newLabels){
        if(newLabels){
            let localLabels = this.state.labels;
            localLabels.map((label)=>{
                newLabels.map((newLabel=>{
                    if(newLabel.labelId === label.labelId){
                        label.labelId = newLabel.auspostLabel;
                    }
                }))
            })
            this.setState({labels:localLabels});
        }
    }

    addLabel() {
        let label = {};
        let no = (this.state.labels.length)+1;
        label.parcelType = 1;
        label.labelId = no; //TODO will retrieve from magento 2
        let newArray = this.state.labels.concat(label);
        this.setState({labels:newArray});
    }
    removeLabel =(labelId)=> {
        let labels = this.state.labels;
        let newLabels = labels.filter(x => {
            return x.labelId !== labelId;
        });
        this.setState({labels:newLabels});
    };

    changeParcelType =(index,labelId)=>{
        let labels = this.state.labels;
        this.setState({
            labels: this.state.labels.map((label)=>(
                label.labelId === labelId ? {...label,
                    parcelType: index
                }:label
            ))
        });
    };

    renderLabel (label, callback, disableDrag,removeLabel,parcel,selectedParcel,collection){
        return (
            <DroppableShipmentLabel label={label} change={callback} disableDrag={disableDrag}
                                    remove ={removeLabel} parcel={parcel} selectedParcel={selectedParcel}
                                    collection={collection}
            />
        )
    }

    editLabel =(labelId,item) =>{
        this.setState({
            labels: this.state.labels.map((label)=>(
                label.labelId === item.labelId ? {...label,
                    items: label.items.filter(x => x.localId !== item.item.localId)
                }:label
            ))
        });

        let labels = this.state.labels;
        let tempLabel = labels.find(x => x.labelId === labelId.labelId);
        if(tempLabel.items){
            tempLabel.items.push(item.item);
        }else {
            tempLabel.items = [];
            tempLabel.items.push(item.item);
        }
        this.setState({
                labels: this.state.labels.map((label)=>(
                    label.labelId === tempLabel.labelId ? {...label,items:tempLabel.items}:label
                ))
        })
    };

    updateConfirmation(){
        let confirmation = this.state.updateConfirmation;
        confirmation = !confirmation;
        this.setState({updateConfirmation:confirmation});
    }

    submitBack=(event)=>{
        event.preventDefault();
        let stage = this.props.shipment.stage;
        if(stage === "STAGE_THREE"){
            this.props.updateShipmentStage('STAGE_TWO',this.props.shipment.shipmentId);
        }else if(stage === "STAGE_TWO") {
            this.props.updateShipmentStage('STAGE_ONE',this.props.shipment.shipmentId);
        }
    };

    submitStepTwo=(event)=>{
        event.preventDefault();
        //Update shipment table to update status
        if(this.props.collection){
            this.props.generateCCLabel(this.props.shipment,this.state.labels); // if click and collect, run a seperate label generation
        }else {
            this.props.calculateLabels(this.props.shipment.shipmentId,this.state.labels,this.props.shipment.createdAt);
        }
    };

    submitStepThree = (event) => {
        event.preventDefault();
        this.props.updateShipmentStage('STAGE_COMPLETE',this.props.shipment.shipmentId);
        let shipmentId = this.props.shipment.shipmentId;
        if(this.props.collection){
            this.props.updateShipment(shipmentId,'PENDING_PICKUP',false,true);
        }else {
             this.props.updateShipment(shipmentId,'COMPLETE');
        }

    };

    formatDateTime(date){
        let format = 'DD/MM/YYYY hh:mm A';
        return moment.tz(date, 'Australia/Brisbane').format(format);
    }

    handleLabelClose =()=>{
        this.setState({labelPopup:!this.state.labelPopup});
    }

    render(){
        /*backend={TouchBackend,HTML5Backend}  add this to dndProvider for touch devices in prod*/
        let labels = this.state.labels;
        let stage = this.props.stage;
        let shipment = this.props.shipment;
        let customerData = this.props.shipment.customerData ?  this.props.shipment.customerData : null;
        let disableDrag = stage === 'STAGE_THREE';   // disable dragging if stage_three
        return (
            <div>
                {
                    this.props.shipment.label && this.state.labelPopup ?
                        <Popup
                            handleClose = {this.handleLabelClose}
                            content={
                                <iframe id={"labelprint"} type="application/pdf" name={"labelprint"} width="100%" height="100%" src={this.props.shipment.label} style={{display:"block"}}/>
                            }
                        />
                        :null
                }
                <Row className={"dynamic-label-row"}>
                    {labels.length > 0 ?
                        labels.map((label,i)=>
                            <DndProvider backend={HTML5Backend} key={i}>
                            <Col sm={5}>
                                {this.renderLabel(label,this.editLabel, disableDrag, this.removeLabel,this.changeParcelType,label.parcelType,this.props.collection)}
                                <CustomDragLayer />
                            </Col>
                            </DndProvider>
                        ):<p>loading label...</p>
                    }{
                      !disableDrag ?
                            <Col sm={3}>
                                <LoaderButton
                                    variant={"dark"}
                                    onClick={() => this.addLabel()}
                                    className="shipment-label-button btn-block"
                                    block bsSize="large"
                                    logo = "fal fa-plus-circle"
                                    text="ADD ANOTHER PARCEL"/>
                            </Col>
                            : customerData && shipment.method === "Collect"?
                            <Col sm ={3}>
                                <div className={"shipment-label-complete"}>
                                    <p style={{fontSize: "30px",textTransform:"uppercase"}}>{customerData.fName + " " + customerData.lName}</p>
                                    <p style={{fontSize: "24px",margin:0}}>Ready for pick-up:</p>
                                    <p style={{fontSize: "24px", lineHeight:"0.5em"}}>{shipment.fulfilledAt !== "pending" ? this.formatDateTime(shipment.fulfilledAt) : "NOW"}</p>
                                    <p style={{fontSize: "24px",margin:0}}>Order date:</p>
                                    <p style={{fontSize: "24px",lineHeight:"0.5em"}}>{this.formatDateTime(shipment.orderDate)}</p>
                                    <p style={{fontSize: "22px"}}>Shipment ID: {shipment.shipmentId}</p>

                                </div>
                            </Col>
                          :null
                      }
                </Row>
                {stage === "STAGE_THREE" && shipment.status !=="PENDING_PICKUP" ?
                    <div>
                        <Row>
                            <Col sm={12}
                            style={{display:'flex',justifyContent:'center'}}>
                                <Checkbox
                                    id="shipment-order-confirmation"
                                    onChange={(e)=>this.updateConfirmation(e)}
                                    checked={this.state.updateConfirmation}
                                    text={"This order is now packed, labelled and waiting for collection"} />
                            </Col>
                        </Row>
                        <Row>
                            <p style={{color: "red",fontSize:'32px'}}>Please only use the most recently printed label on the parcel</p>
                        </Row>
                    </div>
                    :null
                }
                {
                    (shipment.status === "PENDING_PICKUP" && this.props.loading) || (shipment.status === "PENDING" || shipment.status === "COMPLETE_ERROR") ?
                        <Row className="shipment-order-actions">
                            {shipment.labelsPrinted ? null
                                 :<Col sm={6}>
                                    <form onSubmit={this.submitBack} >
                                        <LoaderButton
                                            variant={"dark"}
                                            className="shipment-order-button btn-block"
                                            block bsSize="large"
                                            type="submit"
                                            disabled={ shipment.status === "PENDING_PICKUP"}
                                            text={"GO BACK"}/>
                                    </form>
                                </Col>}

                            <Col sm={6}>
                                <form onSubmit={stage === "STAGE_TWO" ? this.submitStepTwo: this.submitStepThree} >
                                    <LoaderButton
                                        variant={"success"}
                                        className="shipment-order-button btn-block"
                                        block bsSize="large"
                                        type="submit"
                                        isLoading={this.props.loading}
                                        disabled={ (shipment.status === "PENDING_PICKUP") || (stage === "STAGE_THREE" && !this.state.updateConfirmation)}
                                        text={stage === "STAGE_TWO" ? "PRINT LABEL" :  stage === "STAGE_COMPLETE" ? "COMPLETED":"COMPLETE SHIPMENT"}>
                                    </LoaderButton>
                                </form>
                            </Col>

                        </Row>
                        :null
                }
            </div>
        )
    }

}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStageTwo);