import React, { Component } from "react";
import BootCheckbox from "../../components/form/inputs/Checkbox"
import { Auth } from "aws-amplify";
import {API, Storage} from "aws-amplify/lib/index";
import LoaderButton from "../../components/LoaderButton";
import jsPDF from 'jspdf'
import ScrollArea from 'react-scrollbar'
import './offer.css'
import * as dynamo from "../../libs/dynamoLib";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

var fileDownload = require('js-file-download');
import DownloadIcon from "../../images/download-icon.svg"
import StoreLogo from "../../images/store-logo.png"
const mapStateToProps = state => {
    return {user: state.authState,status: state.authState.roleStatus, role: state.authState.role, cogInfo : state.authState.cognitoInfo};
};


class Offer extends Component {
    constructor(props) {
        super(props);
        this.acceptOffer = this.acceptOffer.bind(this);
        this.declineOffer= this.declineOffer.bind(this);
        this.file = null;
        this.state = {
            user: {
                userId:"",

                email:{

                    p_type: "2"
                },
                fName: {

                    p_type: "2"
                },
                lName: {

                    p_type: "2"
                },
                number: {

                    p_type: "2"
                },

                address:{

                    p_type: "1"
                },

                gender: {

                    p_type: "2"
                },

                dob: {

                    p_type: "1"
                },

                citizenship:{

                    p_type: "1"
                },


                availability:{

                    p_type: "1"
                },
                availability_notes:{

                    p_type: "1"
                },
                emergency:{

                    p_type: "2"
                },
                updatedat:{
                    time:"get_time"
                },
                type:{

                    p_type:"2"
                },
            },
            bank: {
                userId: "",
                tfn: {
                    p_type:"0"
                },
                tax_res:{
                    p_type:"0"
                },
                tax_free:{
                    p_type:"0"
                },
                help_loan:{
                    p_type:"0"
                },
                financial_debt:{
                    p_type:"0"
                },
                bank_name:{
                    p_type:"0"
                },
                account_name:{
                    p_type:"0"
                },
                bsb:{
                    p_type:"0"
                },
                account_number:{
                    p_type:"0"
                }
            },
            superann:{
                userId:"",
                existing_fund:{
                    data:false,
                    p_type:"0"
                },
                sup_fund:{
                    p_type:"0"
                },
                account_name:{
                    p_type:"0"
                },
                account_number:{
                    p_type:"0"
                }
            },
            userRole:{
                userId: "",
                futuraId: "0",
                role: "no",
                applicant_status:"STAGE_1_ONBOARDING",
                home_store:"no",
                stores: [{role:"no",store:"no"}],
                trial_date: "no",
                trial_time: "no",
                start_date:"no",
                end_date:"no",
                second_start_date:"no",
                termination_reason: "no",
                references: "no",
                ten_hour_rule: false,
                christmas_casual: false,
                offer_date: "no"
            },
            wageInfo:{},
            isLoading:false,
            isOffer:false,
            status:{},
            session:{},
            username:"",
            personalatts:{},
            isAccepted: false,
            alreadyAccepted:false,
            offer_date:"no",

            agreements:{
                one:false,
                two:false,
                optional:false
            },
            termsPDF:null,
            positionPDF:null,
            acceptedDate:null,
            uploadInfo:null,
            storeName: null,
            uploaded:false,
            agreed:false,
            rejected:false
        };
    }

    async componentDidMount() {
        let session = await Auth.currentSession();
        this.setState({session});
        this.setState({username:this.props.user.username});
        this.getStatus(this.props.user.username).then(this.process).catch(this.processFail)
        await this.getUserRole(this.props.user.username);
        await this.getWageInfo(this.props.user.username);
        await this.getUploadInfo(this.props.user.username);
    }

    uploadDocument = async (document,type) => {
        let pdf = {
            base:document,
            type:type,
            id:this.state.user.userId
        };
        try {
            //TODO FIX the logic here, its a bit weird.
            await API.post("users", "pdf/create",{
               body: pdf
            }).then(response => {
                console.log(response)
            });

            // let uploadPC = {
            //     filename: attachmentPC,
            //     attachment: attachmentPC,
            //     attribute_type: "",
            //     file_status: "uploaded",
            //     type: type,
            //     userId: this.state.user.userId,
            //     token: this.state.session.accessToken.jwtToken
            // };



            // await API.post("users", "users/upload", {
            //     body: uploadPC
            // }).then(response => {
            //     console.log(response)
            // });

        }catch (e) {
            console.log(e)
        }
    };


    printTermsAndConditions = async() => {
        let file =null;

        if(this.state.alreadyAccepted){
            if(this.state.uploadInfo){
                let Items = this.state.uploadInfo.Items;

                for (var i of Items){

                    if(i.file_type==='TAC'){

                    file=i.file;
                    }
                }
            }
            await this.handleFileDownload(file,null)
        }else{
            let pdf = new jsPDF('p','pt','a4');
            let userInfo = {
                id:this.state.username,
                fName: this.state.user.fName.data,
                lName: this.state.user.lName.data,
                address:{
                    data:{
                        street_one: this.state.user.address.data.street_one,
                        street_two: "",
                        suburb: this.state.user.address.data.suburb,
                        postcode: this.state.user.address.data.postcode,
                        state: this.state.user.address.data.state,
                        country:"Australia"
                    },
                    p_type: "1"
                },
                chrisCasual: this.state.userRole.christmas_casual,
                acceptedDate: null,
                offerDate: this.state.offer_date,
                storeName: this.state.storeName,
                type:"TAC",
                role:this.props.role
            };
            await this.generateStaticPDF(userInfo).then(response =>{
                var url = response.pdf

                fetch(url)
                    .then(res => res.blob())
                    .then(blob => {
                        fileDownload(blob,'TermsConditions.pdf')
                    })
            });

        }
    };

    printPositionDescription= async() => {
        let file =null;
        if(this.state.alreadyAccepted){
            if(this.state.uploadInfo){
                console.log(this.state.uploadInfo)
                let Items = this.state.uploadInfo.Items;

                for (var i of Items){

                    if(i.file_type==='PD'){
                        file=i.file;
                    }
                }
            }
            await this.handleFileDownload(file,null)
        }else {
            let userInfo = {
                id:this.state.username,
                fName: this.state.user.fName.data,
                lName: this.state.user.lName.data,
                address:{
                    data:{
                        street_one: this.state.user.address.data.street_one,
                        street_two: "",
                        suburb: this.state.user.address.data.suburb,
                        postcode: this.state.user.address.data.postcode,
                        state: this.state.user.address.data.state,
                        country:"Australia"
                    },
                    p_type: "1"
                },
                chrisCasual: this.state.userRole.christmas_casual,
                acceptedDate: null,
                offerDate: this.state.offer_date,
                storeName: this.state.storeName,
                type:"PD",
                role:this.props.role
            };
            await this.generateStaticPDF(userInfo).then(response =>{
                var url = response.pdf

                fetch(url)
                    .then(res => res.blob())
                    .then(blob => {
                        fileDownload(blob,'PositionDescription.pdf')
                    })


            })

        }
    }


    handleAgreement = (type,e) =>{
        let agreement = this.state.agreements;
        if (type ==="one"){
            agreement.one=e.target.checked;
        }else if (type ==="two"){
            agreement.two = e.target.checked;
        }else if(type ==="optional"){
            agreement.optional = e.target.checked;
        }

        if(agreement.one && agreement.two){
            this.setState({agreed:true})
        } else this.setState({agreed:false})

    };


    process= (status) =>{
        this.setState({personalatts:status});

        /*check if user has offer*/
        if(status.app_offer==='false'){
            console.log("user has a offer");
            this.setState({isOffer:true})
        }

        API.get("users",`users/${this.state.username}`).then(response => {

            this.setState({user:response})
        })
    };


    getStatus = username =>{
        return API.get("users", `users/user/personalatts/${username}`);
    };

    getWageInfo = username =>{
        return API.get("users", `wages/get/${username}`).then(response => {
            this.setState({wageInfo:response})

        });;
    };
    getUserRole = async(username) =>{
        return API.get("users", `users/roles/get/${username}`).then(response =>{
            this.setState({userRole:response});

            this.getStoreName(response.home_store);
            var date =this.normaliseDate(response.offer_date)
            this.setState({offer_date:date});
            if(this.state.userRole.applicant_status==="TEAM_MEMBER"){
                this.setState({alreadyAccepted:true});
            }
        });
    };


    handleFileDownload = async(name, e) => {
        if(this.props.user.identityId){
            Storage.get(name, {level: 'protected',identityId:this.props.user.identityId,download: true})
                .then(result => fileDownload(result.Body, name))
                .catch(err => console.log(err));
        }else {
            Storage.get(name, {download: true})
                .then(result => fileDownload(result.Body, name))
                .catch(err => console.log(err));
        }
    };

    getUploadInfo= username => {
        return API.post("users", "users/uploads/applicants", {
            body: {
                userId: username
            }
        }).then(response=>{
            this.setState({uploadInfo:response});
        });
    };


    getStoreName = storeId => {
        return API.get ("users", `stores/get/${storeId}`).then(response=>{
            this.setState({storeName:response.storeName});
        })
    };

    normaliseDate = (date) =>{

        let dateArray = date.split("-");
        let startDay = dateArray[2];
        let startMonth = dateArray[1];
        let startYear = dateArray[0];



        return (startDay+"/"+startMonth+"/"+startYear)
    }

    generateStaticPDF=async(user)=>{

        try {
            //TODO FIX the logic here, its a bit weird.
            return API.post("users", "pdf/create",{
                body: user
            })
        }catch (e) {
            console.log(e)
        }
    };

    generatePDF=async(user)=>{

        try {
            //TODO FIX the logic here, its a bit weird.
            await API.post("users", "pdf/create",{
                body: user
            }).then(response => {

                return API.post("users", "users/uploads/applicants", {
                    body: {
                        userId: user.id
                    }
                }).then(response=>{
                    this.setState({uploadInfo:response});
                    if(response){
                        let Items = response.Items;
                        let file =null;
                        for (var i of Items){

                            if(i.file_type==='TAC' || i.file_type === "PD"){

                                file=i.file;
                                this.handleFileDownload(file,null)
                            }

                        }
                    }
                });
            });


        }catch (e) {
            console.log(e)
        }
    };

    async acceptOffer(){
        this.setState({isLoading:true})
        var today = new Date(), todaysDate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        let userRole = this.state.userRole;
        let agreement = this.state.agreements;
        let wageInfo = this.state.wageInfo;
        if(agreement.one && agreement.two){
          if (agreement.optional) {
              wageInfo.employment_classification = "C REL1 10HR";
              userRole.ten_hour_rule = true;
              await dynamo.updateUser(this.state.session,"wage",wageInfo);
              // await API.post("users", `wages/update/${this.state.user.userId}`,{
              //     body: wageInfo
              // }).then(response =>{
              //     console.log(response)
              // });
          } else {
              userRole.ten_hour_rule = false;
          }

          userRole.applicant_status = "TEAM_MEMBER";
          await dynamo.updateUser(this.state.session,"role",userRole);
            let userInfo = {
                id:this.state.username,
                fName: this.state.user.fName.data,
                lName: this.state.user.lName.data,
                address:{
                    data:{
                        street_one: this.state.user.address.data.street_one,
                        street_two: "",
                        suburb: this.state.user.address.data.suburb,
                        postcode: this.state.user.address.data.postcode,
                        state: this.state.user.address.data.state,
                        country:"Australia"
                    },
                    p_type: "1"
                },
                chrisCasual: this.state.userRole.christmas_casual,
                acceptedDate: todaysDate,
                offerDate: this.state.offer_date,
                storeName: this.state.storeName,
                role:this.props.role
            };
            await this.generatePDF(userInfo);
            this.setState({isAccepted:true});
          this.setState({isLoading:false,uploaded:true})
        } else {
          this.setState({disabled:true})
        }

    }
    async declineOffer(){
        var today = new Date(), todaysDate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();

        let userRole = this.state.userRole;
        let agreement = this.state.agreements;
        userRole.applicant_status = "UNSUCCESSFUL_APPLICANT";
        await dynamo.updateUser(this.state.session,"role",userRole);
        // await API.put("users",`roles/update/${this.state.username}`, {
        //     body:userRole
        // }).then(response => {
        //     console.log(response);
        //     this.setState({rejected:true})
        // });

    }


    renderOffer(){
        let user =this.state.user;

        return(
            <div>

            </div>

            )

    }



    // ? says if true then render that, : means if not true then render that
    render() {
        let user =this.state.user;
        let userRole = this.state.userRole
        let isOffer = this.state.isOffer;
        var alreadyAccepted = this.state.alreadyAccepted;
        var storeName = this.state.storeName;
        var today = new Date(),
            todaysDate = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        var date=this.state.offer_date;
        return (
            <div>
            {this.state.isAccepted  && this.state.uploaded ?
                <div className="welcome-para">
                    <h1 className = "heading-text">Yeeow ! Thanks for accepting</h1>
                    <p> Congratulations {user.fName.data}! Welcome to the team officially. Please keep your contract and
                        position description files for your records. If you misplace them, you can log back in and download copies
                        any time during your employment with us.
                    </p>
                        <p>
                            If you have any questions about your role, please do not hesitate to contact your manager
                            or <a href="mailto:hr@universalstore.com.au">hr@universalstore.com.au</a>
                        </p>
                </div>:
                <div className="offer-container">
                    {((isOffer) && this.props.role === 'TEAM_MEMBER')|| (!isOffer && this.state.userRole.applicant_status === 'TEAM_MEMBER') ?
                    <div>
                            <div className="welcome-para">
                                <h1 className="heading-text"> Congratulations {user.fName.data} ! Welcome to the team officially.</h1>

                                {this.state.alreadyAccepted ?
                                    <div>
                                        <p>
                                            Well done on becoming a part of the Universal Store team. Now that you have accepted our offer this page will be here just in case you lose your copy of the contract or want to re-print it.	                                        <p>
                                            If there is anything you don’t understand or want to clarify, ask HR on <a href="mailto:hr@universalstore.com.au">hr@universalstore.com.au</a>. There’s no such thing as a silly question!	                                            Please keep your contract and position description files for your records. If you misplace them, you can log back in and download copies any time during your employment with us.
                                            Please read and agree to the terms and conditions to accept our employment offer.	                                        </p>
                                        </p>
                                    </div>
                                    :
                                    <p>
                                    Well done on your successful application! We are pleased to offer you casual employment with us at Universal Store (‘the employer’) on the terms and conditions set out in this letter.
                                        The T&Cs are written in legal speak because this is a contract so if there is anything you don’t understand or want to clarify, ask HR on <a href="mailto:hr@universalstore.com.au">hr@universalstore.com.au</a>. There’s no such thing as a silly question!
                                    Please read and agree to the terms and conditions to accept our employment offer.
                                </p>}

                            </div>
                            <ScrollArea
                                speed={0.8}
                                className="area"
                                contentClassName="listdiv content"
                                horizontal={false}
                            >
                                <div className ="terms-conditions-container">
                                    <img className="contract-logo" src={StoreLogo}/>
                                    {userRole.christmas_casual ? <h2  className="contract-heading-text">Letter of Engagement for Christmas Casual Salesperson</h2> :<h2  className="contract-heading-text">Letter of Engagement for Casual Salesperson</h2>}
                                    <p style={{fontWeight:800,textAlign:"center"}}>Private and confidential</p>
                                    <div className="quick-description-container">
                                        <p>Date:<span>{" "+date}</span></p>
                                        <p>Name: <span>{user.fName.data} {user.lName.data}</span></p>
                                        <p>Address: {user.address.data ? <span>{user.address.data.street_one}{(user.address.data.street_two !== "na" && (user.address.data.street_two !== undefined)) && user.address.data.street_two !==null ? ', '+user.address.data.street_two : ""  },{' '+user.address.data.suburb}, {user.address.data.state}, {user.address.data.postcode} </span> :null}</p>
                                        {this.state.acceptedDate ?<p>Date offer was accepted: <span>{this.state.acceptedDate}</span> </p>:null}                                    </div>
                                    {userRole.christmas_casual ? <h3 className="contract-sub-heading-text">Christmas Casual Salesperson Employment Terms and Conditions </h3> : <h3 className="sub-heading-text">Casual Salesperson Employment Terms and Conditions </h3>}
                                    <ol>
                                        <li>Position
                                            <ol>
                                                <li>Your employment will be on a casual basis, as required. </li>
                                                <li>Each occasion that you work will be a separate contract of employment which ceases at the end of that engagement.</li>
                                                <li>As a casual employee, there is no guarantee of ongoing or regular work.</li>
                                                <li>The duties of this position are set out in the position description provided. On each occasion that you work you will be required to perform these duties and any others duties the employer may assign to you, having regard to your skills, training and experience.</li>
                                                <li>You will be required to perform your duties at {storeName} or elsewhere as reasonably directed by the employer. </li>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Classification
                                            <ol>
                                                <li>For the purpose of the General Retail Industry Award 2020 (Modern Award), your classification will be Retail Employee Level 1.</li>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Terms and conditions of employment
                                            <ol>
                                                <li>Unless more generous provisions are provided in this letter, the terms and conditions of your employment will be those set out in the General Retail Industry Award 2020 and applicable legislation. This includes, but is not limited to, the National Employment Standards in the Fair Work Act 2009. Neither the General Retail Industry Award 2020 nor any applicable legislation are incorporated into your contract of employment.</li>
                                                {userRole.christmas_casual ?<li>Christmas Casual Employment ends on 03/02/2021. Your employment may be terminated at any time by providing you with notice, to apply at the end of your current engagement.</li>:
                                                    <li>Your employment may be terminated at any time by providing you with notice, to apply at the end of your current engagement.</li>}

                                            </ol>
                                        </li>
                                        <br />
                                        <li>Remuneration
                                            <ol>
                                                <li>You will be paid per hour at the rate applicable in the Modern Award which is in store, including the applicable casual loading.</li>
                                                <li>Your remuneration will be subject to applicable tax and will be paid to you weekly on Wednesday, by direct bank deposit into your nominated bank account.</li>
                                                <li>The employer will also make superannuation payments on your behalf in accordance with the Superannuation Guarantee (Administration) Act 1992.</li>
                                                <li>Your remuneration will be reviewed annually, and may be increased as the Modern Award dictates.</li>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Over Award Payments
                                            <ol>
                                                <li>Where your remuneration or any other benefits or entitlements paid to you at any time exceeds the minimum wage or any other minimum benefits or entitlements payable to you under the Modern Award or the Fair Work Act 2009 (Cth) (<strong>FW Act</strong>) (including any penalty or loading, howsoever described, paid in error) (<strong>Over Award Payments</strong>):</li>
                                                <ol>
                                                    <li> those Over Award Payments will be applied towards and set off against any claims that you may at any time have against the employer relating to any underpayment or non-payment of minimum wages, overtime, penalties, allowances, loadings or any other monetary entitlement under the Modern Award or the FW Act; and</li>
                                                    <li> any entitlement that you may have in respect of wages, overtime, penalties, allowances, loadings or any other monetary entitlement payable under the Modern Award or the FW Act will, so far as possible, be calculated by reference to the relevant minimum Modern Award rate, rather than the Over Award Payment. </li>
                                                </ol>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Your obligations to the employer
                                            <ol>
                                                <li>Perform all duties to the best of your ability at all times</li>
                                                <li>Use your best endeavours to promote and protect the interests of the employer</li>
                                                <li>Follow all reasonable and lawful directions given to you by the employer, including complying with all Employee guidelines, policies and procedures as amended from time to time.</li>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Confidentiality
                                            <ol>
                                                <li>By accepting this letter of offer, you acknowledge and agree that you will not, during the course of your employment or thereafter, except with the consent of the employer, as required by law or in the performance of your duties, use or disclose confidential information relating to the business of the employer, including but not limited to client lists, trade secrets, client details and pricing structures.</li>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Conflict of Interest
                                            <ol>
                                                <li>It is a condition of your employment that you do not enter into any arrangements or understandings that conflict with your duties as an employee or appear to do so. You must not enter into any business arrangement that is perceived by the employer to be in direct competition with the employer. In particular you must not accept any payment or other benefit from a third party in return for any act, or any failure to act, in connection with the employer. </li>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Security
                                            <ol>
                                                <li>You consent to the employer or a representative of the employer inspecting any bags, containers, vehicles and/or other personal property brought onto the employer’s property by you as requested.</li>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Surveillance
                                            <ol>
                                                <li>The employer utilises a number of practises to monitor its stores and offices. You consent to the employer storing, accessing, tracking, monitoring and reading all information transmitted or received by the employer’s monitoring systems, including in respect of the employer’s technology resources.</li>
                                            </ol>
                                        </li>
                                        <br />
                                        <li>Entire agreement
                                            <ol>
                                                <li>The terms and conditions referred to in this letter constitute all of the terms and conditions of your employment and replace any prior understanding or agreement between you and the employer. </li>
                                                <li>The terms and conditions referred to in this letter may only be varied by a written agreement signed by both you and the employer.</li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>Employees and employers may also seek information about minimum terms and conditions of employment from the Fair Work Ombudsman. You can contact them on 13 13 94 or visit their website at <a href="https://www.fairwork.gov.au/" target="_blank">www.fairwork.gov.au</a>.</p>
                                </div>
                            </ScrollArea>
                            <div className="download-contract-icon"><a onClick = {this.printTermsAndConditions}><img src={DownloadIcon}/><span>Download Letter of Engagement</span></a></div>

                            <div className="agreement-area">
                                {!alreadyAccepted ?<BootCheckbox
                                        onChange = {e=>this.handleAgreement("one",e)}
                                        id = {"agree-one"}
                                        text={"I confirm that I have read and understood this letter and accept the offer of employment from Universal Store on the terms and conditions set out in the letter. I understand that each engagement will constitute a separate contract of employment between us."}
                                    />
                                    : null}
                            </div>

                            <div className="position-description-head-para">
                                <div>
                                    <p>
                                        Great job in getting this far! Now this is the part where we explain your role in more detail, and how it fits into our team. Again, if you have any questions about your new position, please don’t hesitate to contact us on <b>hr@universalstore.com.au</b>.
                                    </p>
                                </div>
                            </div>

                            <ScrollArea
                                speed={0.8}
                                className="area"
                                contentClassName="content"
                                horizontal={false}
                            >
                                <div className ="position-description-container">
                                    <h2 className="contract-heading-text">Position Description</h2>
                                    <div className="quick-description-container">
                                        <p>Date:<span>{" "+date}</span></p>
                                        <p>Job Title: <span>{userRole.christmas_casual ? "Christmas Casual Salesperson": "Casual Salesperson"}</span></p>
                                        <p>Reporting to: <span>Store Sales Manager</span></p>
                                        <p>Location: <span>{storeName}</span></p>
                                        <p>Hours: <span>There are no set hours for Casual Employees. All statutory rights are provided as per the General Retail Industry Award.</span></p>

                                        {this.state.acceptedDate ?<p>Date offer was accepted:  <span>{this.state.acceptedDate}</span> </p>:null}
                                    </div>
                                    <h4 className="sub-heading-text">Purpose of the position</h4>
                                    <p>As a  {userRole.christmas_casual ? "Christmas"  : null} Casual Salesperson your primary role is to support your Store Manager and 2IC to achieve set targets for your store by being an effective Salesperson, Administrator and Merchant. To engage your customers and co-workers and build lasting loyalty for the Universal Store brand.</p>
                                    <p>{userRole.christmas_casual ? "Christmas"  : null} Casual employees are casual for 1 of 2 reasons generally. 1. You are doing something outside of work that is your “primary” concern such as study, another job, raising a family etc. In this event we want you to enjoy your time with Universal Store and have a happy, working environment where you contribute 100% on the days you are rostered.  2. You are working your way toward a longer term career.  In this case, we will provide you with as much training and development as possible and you need to let your leaders know that you are interested in further development. In either case, this description outlines the primary duties of a Casual Employee and allows for development if you are so interested.</p>
                                    <h4>Responsibilities & Duties</h4>
                                    <p><strong>▌Effective Sales Person – Demonstrate the customer service standard by personal example. Able to personally provide superior customer service, achieve targeted sales budgets to achieve store profitability. Demonstrate a high level of customer service orientation. Lives the behaviours and follows all policy and procedure outlined in the Policy and Procedure Manual.</strong></p>
                                    <p>Work standard – Service and selling are your primary work objectives and so you must be a role model for Customer Service Orientation.  Be able to serve multiple customers at one time. Maintain high level of shop floor awareness while taking on other tasks including serving and selling. Demonstrate a good level of rapport building with all customers and a variety of people. Treat all customers with dignity and respect.</p>
                                    <p>Demonstrate the “drop and go” behaviours required to put customer service first. Lead by example and gain customer confidence by demonstrating a high level of personal grooming and fashion knowledge. Promote Universal Store brands.</p>
                                    <p>All customers are welcomed sincerely into the store and served with enthusiasm and warmth.</p>
                                    <p>Make appropriate stock suggestions to each customer based on the knowledge you have gained from the open-ended questions you have asked. Each customer feels that they have been listened to and genuinely served and cared for. Do the best job you can every day!</p>
                                    <p><strong>Demonstrate a good capability in the following behaviours outlined in your Behavioural Competency Dictionary:  Adaptability, Critical Information seeking, Customer Service Orientation.</strong></p>

                                    <p><strong>▌Effective Team Working at all times. Develop skills in Team Working and should you accept a Shift Supervisor opportunity, demonstrate a strong capability of team leadership. Lives the behaviours outlined in the dictionary and follows all policy and procedure outlined in the Policy and Procedure manual.</strong></p>
                                    <p>To support your branch having adequate coverage, be sure your leaders know your availability in advance of the rosters being published. Openly communicating in advance what your personal work availability will be.  Work with the Manager and 2IC to ensure your store is staffed with a full and qualified team prepared for holidays, peak trade and all eventualities.</p>
                                    <p>Work standard – Be aware of all peak trade occasions such as school holidays, festivals, special events in your centre, concerts, sporting events, VIP days, sale etc. to the extent that those peak trade times are the drivers for our business. Increased hours are available to existing staff in peak trade.  If you are not going to be available for any peak trade period, we request a minimum of 2 weeks’ notice prior to the roster for that time being posted. Use the Availability Chart. Be available for last minute shifts and short notice coverage needs as much as is practicable.</p>
                                    <p>All areas of the Employee Guidelines will be adhered to and it is the employee’s responsibility to ensure all amendments and updates to the Policy and Procedure Manual are read and understood.</p>
                                    <p><strong>Demonstrate a good capability in the following behaviours outlined in your Behavioural Competency Dictionary: Confidence, Integrity, Self Control, Team Working, Universal Spirit. Maintains own knowledge of all areas in the Policy and Procedure manual and follows all company and leadership directives.</strong></p>

                                    <p><strong>▌Effective Merchant – Minimise costs such as shrinkage and controllable expenses while maximising stock potential via effective visual merchandising and store presentation. Implement and support all business strategies and events effectively.</strong></p>
                                    <p>Work standard – Work along with your Manager and 2IC to follow the VM guidelines and ensure the store is refreshed as per the guidelines. Stock must be unpacked the day it arrives and completed within 24 hours of store delivery.</p>
                                    <p>Refill – Stock is refilled daily. All size and tidy processes are followed at the end of day or when instructed. Tags- if you find an item without a tag, get a new tag for the item. If you find a tag, locate the garment asap and re-tag the item.</p>
                                    <p>Stock is steamed and looks its best. All stock has a security tag appropriately placed on the garment. All stock is tagged and has the correct code and price clearly visible and correctly attached. All stock is represented on the shop floor including accessories and shoes. Shoes are pair checked at time of sale, watches are double box checked at time of sale and any time when replacing them in their boxes. Shrinkage is reduced due to good stock management and shop floor awareness.</p>
                                    <p><strong>Demonstrate a high capability in the following behaviours outlined in your Behavioural Competency Dictionary – Achievement Drive, Attention to Detail, Quality of Thinking.</strong></p>
                                    <p><strong>▌Effective Administrator – Completes all paperwork in accordance with training and directives.</strong></p>
                                    <p>Work standard – Follows all directives outlined in the employee guidelines book, workplace health and safety guidelines, memos, e-mails and directives from your leaders. As you progress you may have the opportunity to tackle additional duties - some of those duties may include: Ensuring all banking, payroll and till transactions as well as end of day paperwork meet the company guidelines. Works toward a nil banking difference daily. Ensures all compliance measures are met. Reports all incidents and equipment faults in a timely manner.</p>
                                    <p>Complies with all HR, Admin and Payroll requirements accurately and within time guidelines.</p>
                                    <p>Supports the Store Manager and 2IC to ensure all stocktakes are well prepared and all Stock Movement paperwork is accurate and up to date.</p>
                                    <p>All workplace health and safety matters are reported immediately and all relevant paperwork if accurately completed within the set frames.</p>
                                    <p>All emails, phone calls and conversations are respectful and demonstrate teamwork qualities. Accept training in the till when it is offered and ensure you follow all till procedures accurately.</p>
                                    <p>You must follow all processes for receiving resumes and being polite to all individual who ask to apply for work with our organisation.</p>
                                    <p><strong> Demonstrate a good capability in the following behaviours outlined in your Behavioural Competency Dictionary: Achievement Drive, Attention to Detail, Quality of Thinking, Safety Orientation.</strong></p>

                                    <h4>Technical Capabilities</h4>
                                    <table>
                                        <tr>
                                            <th>Essential</th>
                                            <th>Desirable</th>
                                        </tr>
                                        <tr>
                                            <td>Basic computer skills, Able to use the till</td>
                                            <td>Avid fashion blog reader</td>
                                        </tr>
                                        <tr>
                                            <td>Basic maths skills</td>
                                            <td>Instagram, social media and Facebook user</td>
                                        </tr>
                                        <tr>
                                            <td>Basic visual merchandising skills</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>Willingness to accept feedback</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </table>

                                    <h4>Work experience & skills</h4>
                                    <table>
                                        <tr>
                                            <th>Customer service experience</th>
                                            <th>1 Year in a customer service role or fashion retail business</th>
                                        </tr>
                                        <tr>
                                            <td>Polite, well spoken with experience dealing with a variety of age groups</td>
                                            <td>Experience working within a garment fashion retailer</td>
                                        </tr>
                                        <tr>
                                            <td>Sales and/or Service experience with the general public</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </table>

                                    <h4>Personal qualities & behavioural traits</h4>
                                    <table>
                                        <tr>
                                            <th>Essential</th>
                                            <th>Desirable</th>
                                        </tr>
                                        <tr>
                                            <td>Personal grooming standards are high</td>
                                            <td>Good written communication skills</td>
                                        </tr>
                                        <tr>
                                            <td>Verbal communication standards are good </td>
                                            <td>Communication upwards is respectful and well presented</td>
                                        </tr>
                                        <tr>
                                            <td>Able to accept feedback positively and put it into action</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr>
                                            <td>All behaviours outlined in Behavioural competency Dictionary</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                    </table>

                                    <h4>Relationships</h4>
                                    <table>
                                        <tr>
                                            <th>With</th>
                                            <th>Purpose</th>
                                        </tr>
                                        <tr>
                                            <td>1. Store Manager and 2IC</td>
                                            <td>The Store Manager is your Direct Leader. The 2IC is also your leader and will assume Manager role in the Manager’s absence. Your training and directives will primarily be given to you from the Store Manager and you must support and work well with both of your leaders. Your Store Manager is here to help you in every way possible. Their feedback and directives are given to support your long-term success.</td>
                                        </tr>
                                        <tr>
                                            <td>2. Area Sales Manager (ASM)</td>
                                            <td>The Area Sales Manager is your Manager once removed. Your training and directives will primarily be given to you from the Store Manager as part of the Area directives given to the Store Manager by the ASM. Your ASM is also here to help your development and you are able and encouraged to discuss any issues with your Area Manager that you deem necessary.</td>
                                        </tr>
                                        <tr>
                                            <td>3. Visual Merchandiser (VM)</td>
                                            <td>This position will from time to time visit your store and will provide VM guides and coaching via phone, e-mail and store visits. The VM may pass on operational instruction and feedback for the Area Manager.</td>
                                        </tr>
                                        <tr>
                                            <td>4. Regional Manager (RM)</td>
                                            <td>The Regional Manager will provide a higher level coaching and support. If you are unable to reach the Area Sales Manager in an emergency then please contact the Regional Manager.</td>
                                        </tr>
                                    </table>
                                    <p>&nbsp;</p>
                                    <p>Probationary period will be 3 months from start date unless you have moved internally within the business and have already had 3 months of consecutive employment with Universal Store.
                                        Performance review period: You will receive ongoing feedback from your Manager and 2IC as part of your employment.</p>

                                </div>
                            </ScrollArea>
                            <div className="download-contract-icon"><a onClick = {this.printPositionDescription}><img src={DownloadIcon}/><span>Download Position Description</span></a></div>
                            <div className="agreement-area">
                                {!alreadyAccepted ?<BootCheckbox
                                        onChange = {e=>this.handleAgreement("two",e)}
                                        id={"agree-one"}
                                        text={"I confirm that I have read and understood this position description, and accept the position."}
                                    />
                                    :null}
                            </div>


                            <ScrollArea
                                speed={0.8}
                                className="area"
                                contentClassName="content"
                                horizontal={false}
                            >
                                <div className ="position-description-container">
                                    <h2 className="contract-heading-text">Right to request casual conversion</h2>
                                    <h3  className="contract-heading-text">Overview </h3>
                                <p>The Fair Work Commission reviewed modern awards for part-time and casual employment. In 2018, they have introduced an additional clause called the “Right to request casual conversion” to the Fair Works Act 2009.

                                Basically, it gives casual employees who have worked regular and systematic hours for a period of at least 12 months the right to request their role be changed to part-time or full-time employment where their work hours resemble part-time or full-time employment.

                                For the vast majority of our casual team members, rosters vary according to demand throughout the year so this may not be applicable to your situation. Nevertheless, please read the following clause to learn your rights and how to request casual conversion:
                                    <p>&nbsp;</p>

                                    <h3 style={{textAlign:'left'}} className="contract-heading-text">11.6) Right to request casual conversion</h3>
                                <p>(a) A person engaged by a particular employer as a regular casual employee may request that their employment be converted to full-time or part-time employment.</p>

                                <p>(b) A regular casual employee is a casual employee who has over a calendar period of at least 12 months worked a pattern of hours on an ongoing basis which, without significant adjustment, the employee could continue to perform as a full-time employee or part-time employee under the provisions of this award.</p>

                                <p>(c) A regular casual employee who has worked an average of 38 or more hours a week in the period of 12 months’ casual employment may request to have their employment converted to full-time employment.</p>

                                <p>(d) A regular casual employee who has worked at the rate of an average of less than 38 hours a week in the period of 12 months casual employment may request to have their employment converted to part-time employment consistent with the pattern of hours previously worked.</p>

                                <p>(e) Any request under this subclause must be in writing and provided to the employer.</p>

                                <p>(f) Where a regular casual employee seeks to convert to full-time or part-time employment, the employer may agree to or refuse the request, but the request may only be refused on reasonable grounds and after there has been consultation with the employee.</p>

                                <p>(g) Reasonable grounds for refusal include that:</p>

                                <ol>
                                    <li>(i) it would require a significant adjustment to the casual employee’s hours of work in order for the employee to be engaged as a full-time or part-time employee in accordance with the provisions of this award – that is, the casual employee is not truly a regular casual as defined in paragraph (b);</li>

                                    <li>&gt;(ii) it is known or reasonably foreseeable that the regular casual employee’s position will cease to exist within the next 12 months;</li>

                                    <li>(iii) it is known or reasonably foreseeable that the hours of work which the regular casual employee is required to perform will be significantly reduced in the next 12 months; or</li>

                                    <li>(iv) it is known or reasonably foreseeable that there will be a significant change in the days and/or times at which the employee’s hours of work are required to be performed in the next 12 months which cannot be accommodated within the days and/or hours during which the employee is available to work.</li>
                                </ol>

                                <p>(h) Where the employer refuses a regular casual employee’s request to convert, the employer must provide the casual employee with the employer’s reasons for refusal in writing within 21 days of the request being made. If the employee does not accept the employer’s refusal, this will constitute a dispute that will be dealt with under the dispute resolution procedure in clause 29. Under that procedure, the employee or the employer may refer the matter to the Fair Work Commission if the dispute cannot be resolved at the workplace level.</p>

                                <p>(i) Where it is agreed that a casual employee will have their employment converted to full-time or part-time employment as provided for in this clause, the employer and employee must discuss and record in writing:</p>

                                <ol>
                                    <li>(i) the form of employment to which the employee will convert – that is, full-time or part-time employment; and</li>

                                    <li>(ii) if it is agreed that the employee will become a part-time employee, the matters referred to in clause 10.4.</li>
                                </ol>

                                <p>(j) The date from which the conversion will take effect is the commencement of the next pay cycle following such agreement being reached unless otherwise agreed.</p>

                                <p>(k) Once a casual employee has converted to full-time or part-time employment, the employee may only revert to casual employment with the written agreement of the employer.</p>

                                <p>(l) A casual employee must not be engaged and/or re-engaged (which includes a refusal to re-engage), or have his or her hours reduced or varied, in order to avoid any right or obligation under this clause.</p>

                                <p>(m) Nothing in this clause obliges a regular casual employee to convert to full-time or part-time employment, nor permits an employer to require a regular casual employee to so convert.</p>

                                <p>(n) Nothing in this clause requires an employer to increase the hours of a regular casual employee seeking conversion to full-time or part-time employment.</p>

                                <p>(o) An employer must provide a casual employee, whether a regular casual employee or not, with a copy of the provisions of this subclause within the first 12 months of the employee’s first engagement to perform work.</p>

                                <p>(p) A casual employee’s right to convert is not affected if the employer fails to comply with the notice requirements in paragraph (o).</p>

                                <p> If you have any questions, contact <a href="mailto:hr@universalstore.com.au">hr@universalstore.com.au</a>.</p></p>
                                </div>
                            </ScrollArea>
                            <div className="download-contract-icon"><a target="_blank" href = "https://s3-ap-southeast-2.amazonaws.com/us-public-files/forms/Casual-Conversion-information.pdf"><img src={DownloadIcon}/><span>Download Casual Conversion </span></a></div>
                            {!alreadyAccepted ?
                                <div>
                                    <div className="agreement-area">
                                        <p style={{fontWeight:800}}>Allowable minimum break time between shifts</p>
                                        <p>We roster a minimum 12 hour break between shifts unless you choose to have 10 hour breaks.
                                            By saying yes, we can roster you to finish at 9:30pm then start at 9am the next day.
                                            You will not be disadvantaged if you prefer 12 hour breaks, it means you will have a later start time
                                            the day after a late-night shift. 10 hour breaks is entirely optional.
                                        </p>

                                        <BootCheckbox
                                            onChange = {e=>this.handleAgreement("optional",e)}
                                            id={"agree-one"}
                                            text={"I agree to reduce the minimum allowable break time between shifts with Universal Store from 12 hours to 10 hours."}
                                        />
                                    </div>
                                    <div className="offer-buttons">

                                        <LoaderButton
                                            onClick={this.acceptOffer}
                                            className="accept-offer-button accept"
                                            block
                                            bsSize="large"
                                            disabled={!this.state.agreed}
                                            isLoading={this.state.isLoading}
                                            text="Accept Offer"
                                            loadingText="Updating…"
                                            />
                                            <LoaderButton
                                            onClick ={this.declineOffer}
                                            className="reject-offer-button"
                                            block
                                            bsSize="large"
                                            isLoading={this.state.isLoading}
                                            text="No, Thank you"
                                            />
                                    </div>
                                </div>
                                : null}
                    </div>
                    :((isOffer) && this.props.role === 'DC_TEAM_MEMBER' )||(!isOffer && this.state.userRole.applicant_status === 'TEAM_MEMBER' && this.state.userRole.role === "DC_TEAM_MEMBER") ?
                    <div>
                        <div className="welcome-para">
                        <h1 className="heading-text"> Congratulations {user.fName.data} ! Welcome to the team officially.</h1>

                        {this.state.alreadyAccepted ?
                            <div>
                                <p>
                                    Well done on becoming a part of the Universal Store team. Now that you have accepted our offer this page will be here just in case you lose your copy of the contract or want to re-print it.	                                        <p>
                                    If there is anything you don’t understand or want to clarify, ask HR on <a href="mailto:hr@universalstore.com.au">hr@universalstore.com.au</a>. There’s no such thing as a silly question!	                                            Please keep your contract and position description files for your records. If you misplace them, you can log back in and download copies any time during your employment with us.
                                    Please read and agree to the terms and conditions to accept our employment offer.	                                        </p>
                                </p>
                            </div>
                            :
                            <div>
                            <p>
                                Well done on your successful application! We are pleased to offer you casual employment in the position of Casual Salesperson with us at Universal Store (‘the employer’) on the terms and conditions set out in this letter.
                                The T&Cs are written in legal speak because this is a contract so if there is anything you don’t understand or want to clarify, ask HR on <a href="mailto:hr@universalstore.com.au">hr@universalstore.com.au</a>. There’s no such thing as a silly question!
                                Please read and agree to the terms and conditions to accept our employment offer.
                            </p>
                            </div>
                        }
                        </div>
                    <ScrollArea
                    speed={0.8}
                    className="area"
                    contentClassName="listdiv content"
                    horizontal={false}
                    >
                    <div className ="terms-conditions-container">
                        <img className="contract-logo" src={StoreLogo}/>
                         <h2  className="contract-heading-text">Letter of Engagement for Warehouse Casual</h2>
                        <p style={{fontWeight:800,textAlign:"center"}}>Private and confidential</p>
                        <div className="quick-description-container">
                        <p>Date:<span>{" "+userRole.offer_date}</span></p>
                        <p>Name: <span>{user.fName.data} {user.lName.data}</span></p>
                        <p>Address: {user.address.data ? <span>{user.address.data.street_one}, {user.address.data.suburb}, {user.address.data.state}, {user.address.data.postcode} </span> :null}</p>
                        {this.state.acceptedDate ?<p>Date offer was accepted: <span>{this.state.acceptedDate}</span> </p>:null}                                    </div>
                         <h3 className="contract-sub-heading-text">Warehouse Casual Employment Terms and Conditions </h3>
                        <ol>
                        <li>1. Position
                        <ol>
                        <li>1.1 Your employment will be on a casual basis, as required. </li>
                        <li>1.2 Each occasion that you work will be a separate contract of employment which ceases at the end of that engagement.</li>
                        <li>1.3 As a casual employee, there is no guarantee of ongoing or regular work.</li>
                        <li>1.4 The duties of this position are set out in the position description provided. On each occasion that you work you will be required to perform these duties and any others duties the employer may assign to you, having regard to your skills, training and experience.</li>
                        <li>1.5 You will be required to perform your duties at {storeName} or elsewhere as reasonably directed by the employer. </li>
                        </ol>
                        </li>
                        <br />
                        <li>2. Classification
                        <ol>
                        <li>2.1 For the purpose of the General Retail Industry Award 2020 (Modern Award), your classification will be Retail Employee Level 1.</li>
                        </ol>
                        </li>
                        <br />
                        <li>3. Terms and conditions of employment
                        <ol>
                        <li>3.1 Unless more generous provisions are provided in this letter, the terms and conditions of your employment will be those set out in the General Retail Industry Award 2020 and applicable legislation. This includes, but is not limited to, the National Employment Standards in the Fair Work Act 2009. Neither the General Retail Industry Award 2020 nor any applicable legislation are incorporated into your contract of employment.</li>
                        <li>3.2 Your employment may be terminated at any time by providing you with notice, to apply at the end of your current engagement.</li>

                        </ol>
                        </li>
                        <br />
                        <li>4. Remuneration
                        <ol>
                        <li>4.1 You will be paid per hour at the rate applicable in the Modern Award which is in store, including the applicable casual loading.</li>
                        <li>4.2 Your remuneration will be subject to applicable tax and will be paid to you weekly on Wednesday, by direct bank deposit into your nominated bank account.</li>
                        <li>4.3 The employer will also make superannuation payments on your behalf in accordance with the Superannuation Guarantee (Administration) Act 1992.</li>
                        <li>4.4 Your remuneration will be reviewed annually, and may be increased as the Modern Award dictates.</li>
                        </ol>
                        </li>
                        <br />
                        <li>5. Over Award Payments
                        <ol>
                        <li>5.1 Where your remuneration or any other benefits or entitlements paid to you at any time exceeds the minimum wage or any other minimum benefits or entitlements payable to you under the Modern Award or the Fair Work Act 2009 (Cth) (<strong>FW Act</strong>) (including any penalty or loading, howsoever described, paid in error) (<strong>Over Award Payments</strong>):</li>
                        <ol>
                        <li>(a) those Over Award Payments will be applied towards and set off against any claims that you may at any time have against the employer relating to any underpayment or non-payment of minimum wages, overtime, penalties, allowances, loadings or any other monetary entitlement under the Modern Award or the FW Act; and</li>
                        <li>(b) any entitlement that you may have in respect of wages, overtime, penalties, allowances, loadings or any other monetary entitlement payable under the Modern Award or the FW Act will, so far as possible, be calculated by reference to the relevant minimum Modern Award rate, rather than the Over Award Payment. </li>
                        </ol>
                        </ol>
                        </li>
                        <br />
                        <li>6. Your obligations to the employer
                        <ol>
                        <li>6.1 Perform all duties to the best of your ability at all times;</li>
                        <li>6.2 Use your best endeavours to promote and protect the interests of the employer; and</li>
                        <li>6.3 Follow all reasonable and lawful directions given to you by the employer, including complying with all Employee guidelines, policies and procedures as amended from time to time.</li>
                        </ol>
                        </li>
                        <br />
                        <li>7. Confidentiality
                        <ol>
                        <li>7.1 By accepting this letter of offer, you acknowledge and agree that you will not, during the course of your employment or thereafter, except with the consent of the employer, as required by law or in the performance of your duties, use or disclose confidential information relating to the business of the employer, including but not limited to client lists, trade secrets, client details and pricing structures.</li>
                        </ol>
                        </li>
                        <br />
                        <li>8. Conflict of Interest
                        <ol>
                        <li>8.1 It is a condition of your employment that you do not enter into any arrangements or understandings that conflict with your duties as an employee or appear to do so. You must not enter into any business arrangement that is perceived by the employer to be in direct competition with the employer. In particular you must not accept any payment or other benefit from a third party in return for any act, or any failure to act, in connection with the employer. </li>
                        </ol>
                        </li>
                        <br />
                        <li>9. Security
                        <ol>
                        <li>9.1 You consent to the employer or a representative of the employer inspecting any bags, containers, vehicles and/or other personal property brought onto the employer’s property by you as requested.</li>
                        </ol>
                        </li>
                        <br />
                        <li>10. Surveillance
                        <ol>
                        <li>10.1 The employer utilises a number of practises to monitor its stores and offices. You consent to the employer storing, accessing, tracking, monitoring and reading all information transmitted or received by the employer’s monitoring systems, including in respect of the employer’s technology resources.</li>
                        </ol>
                        </li>
                        <br />
                        <li>11. Entire agreement
                        <ol>
                        <li>11.1 The terms and conditions referred to in this letter constitute all of the terms and conditions of your employment and replace any prior understanding or agreement between you and the employer. </li>
                        <li>11.2 The terms and conditions referred to in this letter may only be varied by a written agreement signed by both you and the employer.</li>
                        </ol>
                        </li>
                        </ol>
                        <p>Employees and employers may also seek information about minimum terms and conditions of employment from the Fair Work Ombudsman. You can contact them on 13 13 94 or visit their website at <a href="https://www.fairwork.gov.au/" target="_blank">www.fairwork.gov.au</a>.</p>
                    </div>
                    </ScrollArea>
                        <div className="download-contract-icon"><a onClick = {this.printTermsAndConditions}><img src={DownloadIcon}/><span>Download Letter of Engagement</span></a></div>

                        <div className="agreement-area">
                            {!alreadyAccepted ?<BootCheckbox
                                    onChange = {e=>this.handleAgreement("one",e)}
                                    id={"agree-one"}
                                    text={"I confirm that I have read and understood this letter and accept the offer of employment from Universal Store on the terms and conditions set out in the letter." +
                                "I understand that each engagement will constitute a separate contract of employment between us."} />
                                : null}
                        </div>

                    <ScrollArea
                        speed={0.8}
                        className="area"
                        contentClassName="content"
                        horizontal={false}
                    >
                            <div className="position-description-container"><h2 className="contract-heading-text">Position Description</h2><div className="quick-description-container">
                                <p>Job Title: <span>Warehouse Casual</span></p>
                                <p>Reporting to: <span>Processing Supervisor and/or Fulfilment & Replenish Manager </span></p>
                                <p>Location: <span>Distribution Centre</span></p>
                                <p>Hours: <span>There are no set hours for Casual Employees. All statutory rights are provided as per the General Retail Industry Award 2020.</span></p>
                                <p>Start Date as Casual: <span>{" "+userRole.offer_date}</span></p></div>
                                <h4 className="sub-heading-text">Purpose of the position</h4>
                                <p>As a Warehouse Casual your primary role is to assist with all the Warehouse and Fulfilment functions as required, including dispatch, processing, replenishment, deliveries, good in and general housekeeping of the warehouse areas.</p>
                                <ol >
                                    <li>Timely and accurate dispatch of deliveries, including but not limited to: checking stores and boxes, adjusting allocations and investigating discrepancies.</li>
                                    <li>Timely and accurate introduction of stock into inventory to branches.</li>
                                    <li>Accurate allocation of stock to be housed in branch 100 warehouse.</li>
                                    <li>Timely investigations and follow up of exceptions reported concerning stock adjustments/corrections.</li>
                                    <li>Excellent Team member- willing to help in any capacity requested.</li>
                                    <li>Maintenance of a clean, safe and efficient warehouse and workspace.</li>
                                    <li>Effective communicator.</li>
                                    <li>Effective prioritization of outstanding work for myself.</li>
                                    <li>Assist with stock takes and additional warehouse tasks as required.</li>
                                </ol>

                                <h4>Responsibilities &amp; Duties</h4>
                                <p><strong>▌Effective processing of stock</strong></p>

                                <p>Processing stock accurately and efficiently is your primary work objective so you must be a role model for Attention to Detail and Achievement Drive. Always cross check products to the job sheet to make sure our records match what is physically in the warehouse.</p>
                                <p>Ensure all items are barcoded and the barcode works with our scanners. When we deliver stock to stores that does not have working barcodes, it negatively impacts our customers and sales teams because it slows down their checkout process.</p>
                                <p>Ensure all items are accurately labeled. If you are processing a white t-shirt but the label describes it as black then it is likely a mistake. Report any inaccurate labels to your Processing Supervisor so they can investigate and determine whether this is an error that needs to be fixed or if them label is OK.</p>
                                <p>Ensure all stock is in pristine condition as you are processing it. Think about whether you’d be happy to buy the product in this condition in a store or in an online order. Report any faulty stock to the Processing Supervisor immediately so they can determine what to do with the faulty stock. </p>
                                <p>Ensure deliveries are securely packed, labelled, cross checked and ready for dispatch in a timely manner. Demonstrate the Achievement Drive and Proactivity required to meet dispatch deadlines.</p>
                                <p><strong>Demonstrate a good capability in the following behaviours outlined in your Behavioural Competency Dictionary:  Attention To Detail, Achievement Drive, Proactivity, Analytical Thinking, Multitasking, Customer Service Orientation</strong></p>

                                <p><strong>▌Accurate allocation of stock</strong></p>
                                <p>Count receiving stock accurately and immediately report any missing stock to the Processing Supervisor so they can work with you to investigate and adjust for any discrepancies. Always cross check your physical stock count matches what is in Futura (our ERP system) and notify your manager of any discrepancies. It is your responsibility to complete your tasks to the best of your ability so ensure you follow up with any investigation or discrepancy until the issue is resolved.</p>
                                <p><strong>Demonstrate a good capability in the following behaviours outlined in your Behavioural Competency Dictionary: Attention To Detail, Achievement Drive, Proactivity, Analytical Thinking</strong></p>


                                <p><strong>▌Effective Replenishment</strong></p>
                                <p>Accurate and timely picking of replenishment stock to stores.</p>
                                <p>Reporting discrepancies to Stock Controller and/or Fulfilment & Replenishment Manager.</p>
                                <p>Accurate placement of stock into store bays.</p>
                                <p>Maintain a safe and clean workspace adhering to OH&S standards (I feel like this one needs to be reiterated as replen is in a high traffic area).</p>
                                <p>Accurate refilling of excess stock into correct locations.</p>
                                <p>Ensuring all refills are completed in a timely manner.</p>
                                <p><strong>Demonstrate a good capability in the following behaviours outlined in your Behavioural Competency Dictionary: Attention To Detail, Achievement Drive, Proactivity, Analytical Thinking</strong></p>

                                <p><strong>▌Effective Organisation of Warehouse</strong></p>
                                <p>Maintain a safe and clean work environment by keeping shelves, pallet areas and workstations neat. Sweep, dust and mop when needed. Organise the warehouse and work areas to ensure orderliness at all times. A safe and clean work environment contributes to accurate stock processing and meeting dispatch deadlines in a timely manner.</p>
                                <p><strong>Demonstrate a good capability in the following behaviours outlined in your Behavioural Competency Dictionary: Attention To Detail, Safety Orientation</strong></p>

                                <h4>Key Performance Indicators (KPI)</h4>
                                <table>
                                    <tr>
                                        <th>KPI</th>
                                        <th>Measurement</th>
                                        <th>When & How</th>
                                    </tr>
                                    <tr>
                                        <td><strong>Accuracy and timeliness of processing stock</strong></td>
                                        <td><ol >
                                            <li>Processing at least 180 units per hour</li>
                                            <li>No human error</li>
                                        </ol>
                                        </td>
                                        <td><ol >
                                            <li>Daily performance review</li>
                                        </ol></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Accuracy and timeliness of online orders</strong></td>
                                        <td>
                                            <ol >
                                                <li>Ensuring stock is processed in an efficient and accurate manner</li>
                                                <li>3 minutes per order from pick to pack to meet Fulfilment KPIs and customer orders arrive on time</li>
                                            </ol>
                                        </td>
                                        <td>
                                            <ol >
                                                <li>Spot checks</li>
                                                <li>Corrections book</li>
                                                <li>Inventory data</li>
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Accuracy and timeliness of replenishment</strong></td>
                                        <td>
                                            <ol >
                                                <li>Processing at least 180 units per hour</li>
                                                <li>No human error</li>
                                                <li>Working with team members to maintain an efficient and accurate pick of replenishment procedure</li>
                                            </ol>
                                        </td>
                                        <td>
                                            <ol >
                                                <li>Performance Review</li>
                                                <li>Store feedback</li>
                                                <li>Inventory data</li>
                                                <li>Spot checks</li>
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Workspace Organisation, Cleanliness and Safety</strong></td>
                                        <td>
                                            <ol >
                                                <li>Organising shelves, pallet areas and workstations</li>
                                                <li>Follow Work, Health and Safety Protocols</li>
                                            </ol>
                                        </td>
                                        <td>
                                            <ol >
                                                <li>Visual reviews</li>
                                                <li>Cross checks to Goods In Records</li>
                                                <li>Performance Review</li>
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Effectiveness of Communication</strong></td>
                                        <td>
                                            <ol >
                                                <li>Verbal and written communication to team members must be respectful, professional and productive in relationship building</li>
                                                <li>Demonstrating self control under pressure</li>
                                                <li>Demonstrating a “Whole of Business” attitude</li>
                                            </ol>
                                        </td>
                                        <td>
                                            <ol>
                                                <li>Performance Review</li>
                                                <li>Supplier Feedback</li>
                                                <li>Team Feedback</li>
                                                <li>Sales & Operations Feedback</li>
                                            </ol>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Effective Team Member</strong></td>
                                        <td>
                                            <ol >
                                                <li>Level of warehouse workload throughput</li>
                                                <li>Contributing to warehouse team morale levels</li>
                                                <li>Demonstrating warehouse team service attitudes</li>
                                                <li>Reviewing and developing processes to suggest and make improvements</li>
                                            </ol>
                                        </td>
                                        <td>
                                            <ol >
                                                <li>Performance Review</li>
                                                <li>Team Feedback</li>
                                            </ol>
                                        </td>
                                    </tr>
                                </table>

                                <h4>Technical Capabilities</h4>
                                <table>
                                    <tr>
                                        <th>Essential</th>
                                        <th>Desirable</th>
                                    </tr>
                                    <tr>
                                        <td>Basic computer skills</td>
                                        <td>Basic Microsoft Excel spreadsheet skills</td>
                                    </tr>
                                </table>
                                <h4>Work experience & skills</h4>
                                <table>
                                    <tr>
                                        <th>Essential</th>
                                        <th>Desirable</th>
                                    </tr>
                                    <tr>
                                        <td>Competent in learning new programs and procedures</td>
                                        <td>Willingness to learn and grow with the team</td>
                                    </tr>
                                </table>
                                <h4>Personal qualities & behavioural traits</h4>
                                <table>
                                    <tr>
                                        <th>Essential</th>
                                        <th>Desirable</th>
                                    </tr>
                                    <tr>
                                        <td>Highly focussed on detail and accuracy</td>
                                        <td>Ambitious and motivated</td>
                                    </tr>
                                    <tr>
                                        <td>Able to work capably under pressure</td>
                                        <td>High level of service ethics and integrity</td>
                                    </tr>
                                </table>

                                <h4>Relationships</h4>
                                <table>
                                    <tr>
                                        <th>With</th>
                                        <th>Purpose</th>
                                    </tr>
                                    <tr>
                                        <td>1. Processing Supervisor</td>
                                        <td>You will report directly to the Processing Supervisor when processing stock.</td>
                                    </tr>
                                    <tr>
                                        <td>2. Fulfilment & Replenish Manager</td>
                                        <td>You will report directly to the Fulfilment & Replenish Manager when working on Replenishment for stores or Fulfilling online orders.</td>
                                    </tr>
                                    <tr>
                                        <td>3. Inventory & Logistics Manager</td>
                                        <td>The Inventory & Logistics Manager oversees all operations of the Distribution Centre. Your training and directives will primarily be given to you from the Processing Supervisor and Fulfilment & Replenish Manager as part of the directives given by the Inventory & Logistics Manager. Your Inventory & Logistics Manager is here to help your development and you are able and encouraged to discuss any issues with your Inventory & Logistics Manager that you deem necessary.</td>
                                    </tr>
                                    <tr>
                                        <td>4. Buyers & Merchandisers</td>
                                        <td>Members of the Buying Team will from time to time provide instructions and feedback on how to process and where to distribute specific products.</td>
                                    </tr>
                                    <tr>
                                        <td>5. Marketing Team</td>
                                        <td>Members of the Marketing Team will from time to time provide instructions and feedback on how to process marketing-related deliveries.</td>
                                    </tr>
                                    <tr>
                                        <td>6. Finance & Admin Team</td>
                                        <td>Members of the Admin Team will from time to time provide instructions and feedback on how to manage records.</td>
                                    </tr>
                                </table>

                                <p>&nbsp;</p><p>Probationary period will be 3 months from start date unless you have moved internally within the business and have already had 3 months of consecutive employment with Universal Store. </p>
                                <p>Performance review period: You will receive ongoing feedback from your Manager as part of your employment. Formal performance reviews will focus on all KPIs and will be conducted annually.
                                </p>
                            </div>
                    </ScrollArea>
                        <div className="download-contract-icon"><a onClick = {this.printPositionDescription}><img src={DownloadIcon}/><span>Download Position Description</span></a>
                        </div>
                        <div className="agreement-area">
                            {!alreadyAccepted ?<BootCheckbox
                                    onChange = {e=>this.handleAgreement("two",e)}
                                    id = {"agree-two"}
                                    text={"I confirm that I have read and understood this position description, and accept the position."}
                                />
                                :null}
                        </div>
                        <div className="download-contract-icon"><a target="_blank" href = "https://s3-ap-southeast-2.amazonaws.com/us-public-files/forms/Casual-Conversion-information.pdf"><img src={DownloadIcon}/><span>Download Casual Conversion </span></a></div>
                    {!alreadyAccepted ?
                        <div>
                            <div className="agreement-area">
                                <p style={{fontWeight:800}}>Allowable minimum break time between shifts</p>
                                <p>By default, we roster a minimum of 12 hour break between shifts unless you choose to have 10 hour breaks instead.
                                    By saying yes, we can roster you to finish at 7:00pm then start at 6:00am the next day during busy periods.
                                    You will not be disadvantaged if you prefer 12 hour breaks, it means you will have a later start time
                                    the day after a late shift.
                                </p>

                                <BootCheckbox
                                    onChange = {e=>this.handleAgreement("optional",e)}
                                    id = {"agree-three"}
                                    text={" I agree to reduce the minimum allowable break time between shifts with Universal Store from 12 hours to 10 hours."}
                                />
                            </div>
                            <div className="offer-buttons">

                                <LoaderButton
                                    onClick={this.acceptOffer}
                                    className="btn-block accept-offer-button accept"
                                    variant={"dark"}
                                    disabled={!this.state.agreed}
                                    isLoading={this.state.isLoading}
                                    text="Accept Offer"
                                    loadingText="Updating…"
                                />
                                <LoaderButton
                                    onClick ={this.declineOffer}
                                    className="btn-block reject-offer-button"
                                    variant={"secondary"}
                                    block
                                    bsSize="large"
                                    isLoading={this.state.isLoading}
                                    text="No, Thank you"
                                />
                            </div>
                        </div>
                        : null}
                    </div>
                    :null
                }
                </div> }
            </div>
        );
    }
}

const OfferPage =  connect(mapStateToProps,null)(Offer);
export default withRouter(OfferPage);
