import React, { Component } from "react";

import { API, Auth, Storage } from "aws-amplify";
import {Col,Row} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import InputMask from 'react-input-mask';

import "./Applicants.css";
import {updateApplicant} from "../../actions/ApplicantActions";
import {connect} from "react-redux";
import {protectedDownload, publicDownload} from "../../libs/awsLib";
import DownloadIcon from "../../images/download-icon.svg"
var fileDownload = require('js-file-download');

const mapStateToProps = state => {
    return {user: state.authState, focusedUser : state.applicantsState.focusedUser };
};
const mapDispatchToProps = dispatch =>{
    return {
        updateApplicant : userId => {dispatch(updateApplicant(userId))}
    }
}

class ApplicantDownloads extends Component {
    constructor(props) {
        super(props);
        this.managers = ["WAREHOUSE_MANAGER","INVENTORY_LOGISTICS_MANAGER","ASM","MANAGER"];
        this.admins=  ["HR","SUPERUSER","PAYROLL"];
        this.state = {
            userId: this.props.userId,
            uploadInfo: null
        };
    }

    async componentDidMount(){
        try {
            if (this.props.userId !== null) {
                await this.getUploadInfo();
            }

        } catch (e) {
            console.log(e);
        }

    }


    componentDidUpdate(prevProps){
        if (prevProps.refresh !== this.props.refresh) {
            this.getUploadInfo();
        }
    }

    getUploadInfo = async() => {
        return API.post("users", "users/uploads/applicants", {
            body: {
                userId: this.props.userId
            }
        }).then(uploadInfo => {
            this.setState({
                uploadInfo
            });
        });
    };

    handleFileDownload = async(name, e) => {
        if(this.props.focusedUser.cogId){
            await protectedDownload(this.props.focusedUser.cogId,name);
        }else {
            await publicDownload(name);
        }
    };

    handleFileDelete = async(name,file_type, e) => {
        let type =""
        Storage.remove(name,{level:'public'})
            .then(result => console.log(result))
            .catch(err => console.log(err));
        if (file_type.toLowerCase() === "tfn"){
            type = "HR_TFN"
        }else if (file_type.toLowerCase() === "resume" ){
            type = "HR_RESUME"
        }
        else if (file_type.toLowerCase() === "idcheck" ){
            type = "HR_IDCHECK"
        }
        else if (file_type.toLowerCase() === "pc" ){
            type = "HR_PC"
        }
        else if (file_type.toLowerCase() ==="reference"){
            type = "MAN_REFERENCECHECK"
        }
        let upload = {
            userId: this.props.focusedUser.userId,
            attribute_type: type,
            filename: name,
            attachment: name,
            uploaderId: this.props.adminId,
            action:"delete",
            token: this.props.user.JWT

        };


        await API.post("users", "users/upload", {
            body: upload
        }).then( response =>{
            this.getUploadInfo()
            this.props.updateApplicant(this.props.userId)
        });

    };



    render() {
        return (
            <div className={"submenu"}>
                {this.admins.includes(this.props.user_role) && this.state.uploadInfo ?
                    (this.state.uploadInfo.Count > 0
                        ?

                            <div>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <span className={"submenu-heading"}>Uploaded forms:</span>
                                    </Col>
                                </Row>
                                <Row className={"applicant-uploads"}>

                                    {this.state.uploadInfo.Items.map((upload, i) =>
                                        (upload.file_type.toLowerCase() === 'idcheck' || upload.file_type.toLowerCase() === 'tfn'
                                            || upload.file_type.toLowerCase() === 'resume' || upload.file_type.toLowerCase() === 'pc'
                                            || upload.file_type.toLowerCase() === 'reference')
                                            ?
                                            <Col xs={12} sm={6}>
                                                <div key={i}>
                                                    <div className={"downloads-menu"}>
                                        {
                                            upload.file_status ?
                                                (upload.file_status.toLowerCase() === 'approved' ?
                                                    <div className={"file-type approved"}>
                                                        <div className={"download-wrapper"} onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                            <img src={DownloadIcon}/>
                                                        </div>
                                                        <div className = {"type"}>
                                                            {upload.file_type}
                                                        </div>
                                                    </div>
                                                    :
                                                    (upload.file_status.toLowerCase() === 'denied' ?
                                                        <div className={"file-type denied"}>
                                                            <div className={"download-wrapper"} onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                                <img src={DownloadIcon}/>
                                                            </div>
                                                            <div className = {"type"}>
                                                                {upload.file_type}
                                                            </div>
                                                        </div>
                                                        :(upload.file_status.toLowerCase() === 'uploaded' ?
                                                            <div className={"file-type uploaded"}>
                                                                <div className={"download-wrapper"} onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                                    <img src={DownloadIcon}/>
                                                                </div>
                                                                <div className = {"type"}>
                                                                    {upload.file_type}
                                                                </div>
                                                            </div>
                                                            : (upload.file_status.toLowerCase() === 'pending' ?
                                                                    <div className={"file-type pending"}>
                                                                        <div className={"download-wrapper"} onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                                            <img src={DownloadIcon}/>
                                                                        </div>
                                                                        <div className = {"type"}>
                                                                            {upload.file_type}
                                                                        </div>

                                                                    </div>
                                                                    :null
                                                            ))))

                                                : null
                                        }

                                                        <div className={"status-wrapper"}>
                                                            {
                                                                upload.file_status ?
                                                                    (upload.file_status === "pending" || upload.file_status === "uploaded" ?
                                                                            <div className={"download-wrapper"} onClick={(e) => this.handleFileDelete(upload.file, upload.file_type, e)}>
                                                                                <span><span style={{fontWeight:700}} > X</span>  Remove</span>
                                                                            </div>
                                                                            : <span >{upload.file_status}</span>

                                                                    ) : null

                                                            }



                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            : null
                                    )}
                                </Row>
                            </div>

                        : null
                    )
                :(this.managers.includes(this.props.user_role)  && this.state.uploadInfo ?
                        (this.state.uploadInfo.Count > 0
                            ? <Row className={"applicant-uploads"}>
                                <span className={"submenu-heading"}>Uploaded forms:</span>
                                {this.state.uploadInfo.Items.map((upload, i) =>
                                    (upload.file_type.toLowerCase() === 'resume' || upload.file_type.toLowerCase() === 'pc'
                                        || upload.file_type.toLowerCase() === 'reference')
                                        ?
                                        <Col xs={12} sm={6}>
                                            <div key={i}>
                                                <div className={"downloads-menu"}>
                                                    {(upload.file_status.toLowerCase() === 'approved' ?
                                                        <div className={"file-type approved"}>
                                                            <div className={"download-wrapper"} onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                                <img src={DownloadIcon}/>
                                                            </div>
                                                            <div className = {"type"}>
                                                                {upload.file_type}
                                                            </div>
                                                        </div>
                                                        :
                                                        (upload.file_status.toLowerCase() === 'denied' ?
                                                            <div className={"file-type denied"}>
                                                                <div className={"download-wrapper"} onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                                    <img src={DownloadIcon}/>
                                                                </div>
                                                                <div className = {"type"}>
                                                                    {upload.file_type}
                                                                </div>
                                                            </div>
                                                            :(upload.file_status.toLowerCase() === 'uploaded' ?
                                                                <div className={"file-type uploaded"}>
                                                                    <div className={"download-wrapper"} onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                                        <img src={DownloadIcon}/>
                                                                    </div>
                                                                    <div className = {"type"}>
                                                                        {upload.file_type}
                                                                    </div>
                                                                </div>
                                                                : (upload.file_status.toLowerCase() === 'pending' ?
                                                                        <div className={"file-type pending"}>
                                                                            <div className={"download-wrapper"} onClick={(e) => this.handleFileDownload(upload.file, e)}>
                                                                                <img src={DownloadIcon}/>
                                                                            </div>
                                                                            <div className = {"type"}>
                                                                                {upload.file_type}
                                                                            </div>
                                                                        </div>
                                                                        :null
                                                                ))))
                                                    }
                                                    <div className={"status-wrapper"}>
                                                        {upload.file_status === "pending" || upload.file_status === "uploaded" ?
                                                            <div className={"download-wrapper"} onClick={(e) => this.handleFileDelete(upload.file, upload.file_type, e)}>
                                                                <span><span style={{fontWeight:700}} > X</span>  Remove</span>
                                                            </div>
                                                            : <span >{upload.file_status}</span>}

                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        : null
                                )}
                            </Row>
                            : null)
                  :null)
                }
            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ApplicantDownloads)