import React from 'react'
import { TimePicker } from 'antd';

import {FormGroup,FormLabel,FormControl} from "react-bootstrap";
import PropTypes from 'prop-types'
import moment from "moment/moment";
const TimeInput = (props) => (
    <FormGroup controlId={props.id} bsSize="large" validationState={props.onValidate}>
        <FormLabel>{props.label}</FormLabel>
        <TimePicker
            onBlur = {props.onBlur}
            onFocus = {props.onFocus}
                        required
                     className="trial-time"
                     use12Hours={true}
                     minuteStep ={15}
                     onChange={props.onChange}
                     defaultOpenValue={moment('00:00', 'HH:mm')}
                     format='HH:mm' />
        <FormControl.Feedback />
    </FormGroup>
);

TimeInput.propTypes = {
    id:PropTypes.string.isRequired,
    onValidate:PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label:PropTypes.string,
};

export default TimeInput;