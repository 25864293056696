import React, { Component } from "react";

import { API, Auth, Storage } from "aws-amplify";
import { Row,Col} from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import Input from '../../components/form/inputs/Input'
import DateInput from '../../components/form/inputs/DateInput'
import "./Applicants.css";
import moment from "moment/moment";
import {updateApplicant} from "../../actions/ApplicantActions";
import {connect} from "react-redux";
import DownloadIcon from "../../images/download-icon.svg"
var fileDownload = require('js-file-download');

const mapStateToProps = state => {
    return {user: state.authState, focusedUser : state.applicantsState.focusedUser };
};

const mapDispatchToProps = dispatch =>{
    return {
        updateApplicant : userId => {dispatch(updateApplicant(userId))}
    }
}
class ApplicantUploads extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userId: this.props.userId,
            vevo: this.props.vevo,
            uploadInfo: "",
            isLoading: false,
            vevo_check: {
                visa_no: "",
                work_restriction_notes: "",
                visa_expiry: ""
            }
        };
    }

    async componentDidMount(){
        try {
            if (this.props.userId !== null) {
                await this.getUploadInfo();
            }
        } catch (e) {
            console.log(e);
        }

    }
    getValidationState(type){

        if(type=== "expiryDate"){
            let start = this.state.vevo_check.visa_expiry;
            var date = moment(start, 'DD/MM/YYYY',true);
            var endDate = moment(new Date(2030, 1, 15));
            var startDate   = moment(new Date);
            if(date.isValid()){
                if(date.isBetween(startDate,endDate))
                {
                    return 'success'
                }else if(!date.isBetween(startDate,endDate)) {
                    return 'error'
                }
            } else if(start.length>3 && start.length<10 && !date.isValid()){
                return 'error'
            } else if(start.length === 10 && date.isValid()){
                return 'success'
            } else if(start.length === 10 && !date.isValid()){
                return 'success'
            } else return null
        }

    }

    componentDidUpdate(prevProps){
        if (prevProps.refresh !== this.props.refresh) {
            this.getUploadInfo();
        }
    }



    getUploadInfo = async() => {
        API.post("users", "users/uploads/applicants", {
            body: {
                userId: this.props.userId
            }
        }).then(response => {
            if (response.Count > 0) {
                this.setState({
                    uploadInfo: response
                });
            }
        });
    };

    handleClick = async (file, file_status, file_type, userId, e) => {

        if (file !== null && file_status !== false) {
            try {
                let session = await Auth.currentSession();

                await API.post("users", "users/uploads/status/change", {
                    body: {
                        file: file,
                        file_status: file_status,
                        file_type: file_type,
                        userId: userId,
                        file_category: "applicant",
                        token: session.accessToken.jwtToken
                    }
                });

                const uploadInfo = await this.getUploadInfo();

                this.setState({
                    uploadInfo
                });

                this.props.updateApplicant(this.props.userId);

            } catch (e) {
                console.log(e);
            }
        }
    };

    validateForm() {
        return (
            this.state.vevo_check.visa_expiry !== "" &&
            this.state.vevo_check.work_restriction_notes !== "" &&
            this.state.vevo_check.visa_no !== ""
        );
    }


    handleChange = event => {
        let vevo_check = this.state.vevo_check;
        vevo_check[event.target.id] = event.target.value;
        this.setState({
            vevo_check
        });
    };

    handleVevoExpiryChange = event => {
        let vevo_check = this.state.vevo_check;
        vevo_check.visa_expiry = event.target.value;

        this.setState({
            vevo_check
        });
    };

    handleVevoSubmit = async event => {
        event.preventDefault();

        this.setState({ isLoading: true });
        try{
            await API.post("users", "users/vevo/update", {
                body: {
                    userId: this.props.userId,
                    visa_no: this.state.vevo_check.visa_no,
                    work_restriction_notes: this.state.vevo_check.work_restriction_notes,
                    visa_expiry: this.state.vevo_check.visa_expiry
                }
            });

            this.setState({ isLoading:false});
            this.props.updateApplicant(this.props.userId)
        } catch (e) {
            console.log(e);
        }
        this.setState({ isLoading: false });
    };

    renderVevoForm() {

        return (
            <div className={"submenu"}>

                <form className={"pending-form"} onSubmit={this.handleVevoSubmit}>
                    <Row>
                            <span className={"submenu-heading"}>VEVO Pending Approval:</span>
                        <Col sm={3}>
                            <Input id={"visa_no"}
                                          value={this.state.vevo_check.visa_no}
                                          onChange={this.handleChange}
                                          label={"Visa No"}
                                          required={true}
                                          controlId={"visa_no"}
                                          name={"visa_no"}
                                          placeholder={"visa no"}/>
                        </Col>
                        <Col sm={3}>
                            <DateInput
                                id={"visa_expiry"}
                                value={this.state.vevo_check.visa_expiry}
                                onChange={this.handleVevoExpiryChange}
                                label={"Visa Expiry"}
                                required={true}
                                onValidate={this.getValidationState("expiryDate")}
                                controlId={"visa_expiry"} name={"visa_expiry"}  placeholder={"visa Expiry"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Input id={"work_restriction_notes"}
                                   value={this.state.vevo_check.work_restriction_notes}
                                   onChange={this.handleChange}
                                   label ={"Work Restriction Notes"}
                                   required={true}
                                   componentClass={"textarea"}
                                   controlId={"work_restriction_notes"} name={"work_restriction_notes"}  placeholder={"Notes"}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <LoaderButton
                                block
                                bsSize="large"
                                type="submit"
                                disabled={!this.validateForm()}
                                isLoading={this.state.isLoading}
                                className={"offer-button"}
                                text="Approve VEVO Check"
                                loadingText="Approving…"
                            />
                        </Col>
                    </Row>

                </form>
            </div>
        )
    }

    handleFileDownload = async(name, e) => {
        if(this.props.focusedUser.cogId){
            Storage.get(name, {level: 'protected',identityId:this.props.focusedUser.cogId,download: true})
                .then(result => fileDownload(result.Body, name))
                .catch(err => console.log(err));
        }else {
            Storage.get(name, {download: true})
                .then(result => fileDownload(result.Body, name))
                .catch(err => console.log(err));
        }
    };

    render() {
        return (
            <div>

                {this.props.user_role === 'HR' ?
                    <div>

                    {this.props.vevo === 'false' ? this.renderVevoForm():null}
                        {this.state.uploadInfo ?
                        <div>

                            {this.state.uploadInfo.Count > 0
                                ? <div className={"submenu uploads"}>
                                    <span className={"submenu-heading"}>Forms Pending Approval:</span>
                                    {this.state.uploadInfo.Items.map((upload, i) =>
                                        (upload.file_type.toLowerCase() === 'pc' || upload.file_type.toLowerCase() === 'tfn' || upload.file_type.toLowerCase() === 'resume' ||
                                            upload.file_type.toLowerCase() === 'idcheck')
                                            ? (upload.file_status === 'pending')
                                            ?<Row>
                                                <Col sm={6} key={i}>
                                                    <div className={"left-menu"}>
                                                        <span className={"file-type"}>{upload.file_type}</span>
                                                        <div className={"download-wrapper"} onClick = {(e)=>this.handleFileDownload(upload.file, e)}>
                                                            <img src={DownloadIcon}/>
                                                            <span>Download File</span>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    <div className={"right-menu"}>
                                                        <div className={"button-wrapper"}>
                                                            <LoaderButton
                                                                block
                                                                type="submit"
                                                                text="Approve"
                                                                value={"approved"}
                                                                file={upload.file}
                                                                onClick={(e) => this.handleClick(upload.file, "approved", upload.file_type, upload.userId, e)}
                                                                className={"approve-button small-button"}
                                                            />
                                                            <LoaderButton
                                                                block
                                                                type="submit"
                                                                text="Deny"
                                                                onClick={(e) => this.handleClick(upload.file, "denied", upload.file_type, upload.userId, e)}
                                                                className={"deny-button small-button"}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            : null
                                            : null
                                    )}
                                </div>
                                : null}
                            </div>
                    : null
                }

                    </div>
                    :
                   null
                }

            </div>
        )
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ApplicantUploads)