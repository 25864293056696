 9449
 import React, { Component } from "react";
import ApplicantUploads from './ApplicantUploads'
import ApplicantPendingTrial from './ApplicantPendingTrial'
import ApplicantSuccessfulTrial from './ApplicantSuccessfulTrial'
import ApplicantUnsuccessfulTrial from './ApplicantUnsuccessfulTrial'
import ApplicantFinished from './ApplicantFinished'
import ApplicantDownloads from "./ApplicantDownloads";
import ApplicantContactInfo from "./ApplicantContactInfo";
import ApplicantUploadActions from "./ApplicantUploadAction";
import ApplicantContractInfo from "./ApplicantContractInfo"
import ApplicantTerminateInduction from "./ApplicantTerminateInduction"
import ApplicantChangeTrial from "./ApplicantChangeTrial";
import {updateApplicant } from "../../actions/ApplicantActions";
import {connect} from "react-redux";


const mapDispatchToProps = dispatch =>{
    return {
        updateApplicant : userId => {dispatch(updateApplicant(userId))}
    }
};

const mapStateToProps = state => {
    if(state.applicantsState){
        return ({focusedUser: state.applicantsState.focusedUser})
    }else {
        return ({users:null})
    }

};



class ApplicantDropDownComponent extends Component {
    constructor(props) {
        super(props);
        this.managers = ["WAREHOUSE_MANAGER","INVENTORY_LOGISTICS_MANAGER","MANAGER","ASM"];
        this.admins=  ["HR","SUPERUSER","PAYROLL"];
        this.state = {
            refresh: false,
            click:false
        };
    }

    applicantCallback = (open) =>{
        this.refresh();
    };

    componentDidUpdate(prevProps){
        if (prevProps.user !== this.props.user) {
            this.refresh();
        }
        if((prevProps.focusedUser.userId !== this.props.focusedUser.userId)){
            // displaying fake loading while waiting for files to load
            if(this.props.user.applicant_status !== "NOT_LOGGED_IN") {
                setTimeout(() => {
                    this.click(this.props.focusedUser.userId);
                }, 450);
            }else {
                this.click(this.props.focusedUser.userId);
            }
        }
    }
    refresh() {
        let refresh = !this.state.refresh;
        this.setState({
            refresh
        });

    }
    click(clickedUserId){
        if(this.props.user.userId ===clickedUserId){
            let click = !this.state.click;
            this.setState({
                click
            });
        }
    }
    render(){
        let user = this.props.user;
        if(!this.state.click){
            return(<div className="loading-spinner loading-spinner-blue"></div>
            )
        }else {
            switch (user.applicant_status) {
                case 'STAGE_1_ONBOARDING':
                case 'STAGE_2_ONBOARDING':
                case 'STAGE_3_ONBOARDING':
                    return (
                        <div>
                            <ApplicantContactInfo user={user} availability={user.availability}
                                                  user_role={this.props.user_role} user_stores={this.props.user_stores}
                                                  applicant_status={user.applicant_status}
                                                  callbackFromParent={this.applicantCallback}/>
                            <ApplicantUploadActions adminId={this.props.adminId}
                                                    callbackFromParent={this.applicantCallback} userId={user.userId}
                                                    resume={user.hr_resume}
                                                    idcheck={user.hr_idcheck} successful={this.props.successful}
                                                    tfn={user.hr_tfn} referencecheck={user.man_referencecheck}
                                                    user_role={this.props.user_role}
                                                    app_pc={user.app_pc} hr_pc={user.hr_pc}/>
                            <ApplicantDownloads refresh={this.state.refresh} userId={user.userId}
                                                user_role={this.props.user_role}
                                                callbackFromParent={this.applicantCallback}/>

                            <ApplicantUploads refresh={this.state.refresh} userId={user.userId}
                                              user_role={this.props.user_role} vevo={user.hr_vevo}
                                              callbackFromParent={this.applicantCallback}/>

                            <ApplicantTerminateInduction trial_date={user.trial_date}
                                                         callbackFromParent={this.applicantCallback}
                                                         userId={user.userId}/>
                        </div>
                    );
                case 'NOT_LOGGED_IN':
                    return (<div>
                            <ApplicantContactInfo user={user} availability={user.availability}
                                                  user_role={this.props.user_role} user_stores={this.props.user_stores}
                                                  applicant_status={user.applicant_status}
                                                  callbackFromParent={this.applicantCallback}/>
                            <div><p>Applicant must log in before you can upload files</p></div>

                            <ApplicantTerminateInduction trial_date={user.trial_date}
                                                         callbackFromParent={this.applicantCallback}
                                                         userId={user.userId}/>
                        </div>
                    );
                case 'TRIAL':
                case 'INDUCTION':
                case 'PENDING_OFFER':
                    if (this.managers.includes(this.props.user_role) || this.admins.includes(this.props.user_role)) {
                        return (<div>
                            <ApplicantContactInfo user={user} availability={user.availability}
                                                  user_role={this.props.user_role} user_stores={this.props.user_stores}
                                                  applicant_status={user.applicant_status}
                                                  callbackFromParent={this.applicantCallback}/>
                            <ApplicantUploadActions adminId={this.props.adminId}
                                                    callbackFromParent={this.applicantCallback} userId={user.userId}
                                                    resume={user.hr_resume}
                                                    idcheck={user.hr_idcheck} successful={this.props.successful}
                                                    tfn={user.hr_tfn} referencecheck={user.man_referencecheck}
                                                    user_role={this.props.user_role}
                                                    app_pc={user.app_pc} hr_pc={user.hr_pc}/>
                            <ApplicantDownloads refresh={this.state.refresh} userId={user.userId}
                                                user_role={this.props.user_role}
                                                callbackFromParent={this.applicantCallback}/>
                            <ApplicantUploads refresh={this.state.refresh} userId={user.userId}
                                              user_role={this.props.user_role} vevo={user.hr_vevo}
                                              callbackFromParent={this.applicantCallback}/>
                            <ApplicantPendingTrial callbackFromParent={this.applicantCallback} userId={user.userId}
                                                   phone={user.phone} home_store={user.home_store}
                                                   first_name={user.first_name}
                                                   last_name={user.last_name}
                                                   applicant_role={user.role}
                                                   trial_date={user.trial_date} hr_idcheck={user.hr_idcheck}
                                                   resume={user.hr_resume} haveCalled={this.props.haveCalled}
                                                   successful={this.props.successful} trial_type={user.applicant_status}
                                                   user_stores={this.props.user_stores} availability={user.availability}
                                                   work_restriction_notes={user.work_restriction_notes}
                                                   hr_tfn={user.hr_tfn}
                                                   user_role={this.props.user_role}
                                                   availability_notes={user.availability_notes}
                                                   references={user.references} referenceCheck={user.man_referencecheck}
                                                   hr_vevo={user.hr_vevo} hr_pc={user.hr_pc} app_pc={user.app_pc}


                            />
                            <ApplicantTerminateInduction trial_date={user.trial_date}
                                                         callbackFromParent={this.applicantCallback}
                                                         userId={user.userId}/>

                        </div>);
                    }
                case 'SUCCESSFUL_APPLICANT':
                    if (this.managers.includes(this.props.user_role) || this.admins.includes(this.props.user_role)) {
                        return (
                            <div>
                                <ApplicantContactInfo user={user} availability={user.availability}
                                                      user_role={this.props.user_role}
                                                      user_stores={this.props.user_stores}
                                                      applicant_status={user.applicant_status}
                                                      callbackFromParent={this.applicantCallback}/>
                                <ApplicantUploadActions adminId={this.props.adminId}
                                                        callbackFromParent={this.applicantCallback} userId={user.userId}
                                                        resume={user.hr_resume}
                                                        idcheck={user.hr_idcheck} successful={this.props.successful}
                                                        tfn={user.hr_tfn} referencecheck={user.man_referencecheck}
                                                        user_role={this.props.user_role}
                                                        app_pc={user.app_pc} hr_pc={user.hr_pc}/>
                                <ApplicantDownloads refresh={this.state.refresh} userId={user.userId}
                                                    user_role={this.props.user_role}
                                                    callbackFromParent={this.applicantCallback}/>
                                <ApplicantUploads refresh={this.state.refresh} userId={user.userId}
                                                  user_role={this.props.user_role} vevo={user.hr_vevo}
                                                  callbackFromParent={this.applicantCallback}/>

                                <ApplicantSuccessfulTrial userId={user.userId}/>
                                <ApplicantTerminateInduction trial_date={user.trial_date}
                                                             callbackFromParent={this.applicantCallback}
                                                             userId={user.userId}/>
                            </div>);
                    }
                case 'PENDING_TERMINATION':
                case 'UNSUCCESSFUL_TRIAL':
                    if (this.admins.includes(this.props.user_role)) {
                        return (
                            <div>
                                <ApplicantContactInfo user={user} availability={user.availability}
                                                      user_role={this.props.user_role}
                                                      user_stores={this.props.user_stores}
                                                      applicant_status={user.applicant_status}
                                                      callbackFromParent={this.applicantCallback}/>
                                <ApplicantDownloads refresh={this.state.refresh} userId={user.userId}
                                                    user_role={this.props.user_role}
                                                    callbackFromParent={this.applicantCallback}/>
                                <ApplicantUnsuccessfulTrial callbackFromParent={this.applicantCallback}
                                                            storeId={user.home_store} userId={user.userId}/>
                            </div>);
                    }
                case 'TEAM_MEMBER':
                    if (this.admins.includes(this.props.user_role)) {
                        return (
                            <div>
                                <ApplicantContactInfo user={user} availability={user.availability}
                                                      user_role={this.props.user_role}
                                                      user_stores={this.props.user_stores}
                                                      applicant_status={user.applicant_status}
                                                      callbackFromParent={this.applicantCallback}/>
                                <ApplicantDownloads refresh={this.state.refresh} userId={user.userId}
                                                    user_role={this.props.user_role}
                                                    callbackFromParent={this.applicantCallback}/>
                                <ApplicantContractInfo user={user} userId={user.userId} user_role={this.props.user_role} cogId={user.cogId}/>
                                <ApplicantTerminateInduction trial_date={user.trial_date}
                                                             callbackFromParent={this.applicantCallback}
                                                             userId={user.userId}/>

                            </div>
                        );
                    } else if (this.managers.includes(this.props.user_role)) {
                        return (
                            <div>
                                <ApplicantContactInfo user={user} availability={user.availability}
                                                      user_role={this.props.user_role}
                                                      user_stores={this.props.user_stores}
                                                      applicant_status={user.applicant_status}
                                                      callbackFromParent={this.applicantCallback}/>
                                <ApplicantDownloads refresh={this.state.refresh} userId={user.userId}
                                                    user_role={this.props.user_role}
                                                    callbackFromParent={this.applicantCallback}/>
                                <ApplicantContractInfo userId={user.userId} cogId={user.cogId} user_role={this.props.user_role}/>
                                <ApplicantFinished/>
                            </div>
                        );
                    }

                case 'TERMINATED':
                    return (
                        <div>
                            <ApplicantContactInfo user={user} availability={user.availability}
                                                  user_role={this.props.user_role} user_stores={this.props.user_stores}
                                                  applicant_status={user.applicant_status}
                                                  callbackFromParent={this.applicantCallback}/> {(this.props.user_role === "HR")
                            ? <ApplicantDownloads refresh={this.state.refresh} userId={user.userId}
                                                  user_role={this.props.user_role}
                                                  callbackFromParent={this.applicantCallback}/>
                            : null
                        }<ApplicantFinished/>
                        </div>);
            }
        }

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ApplicantDropDownComponent)