import React from 'react';
import PropTypes from 'prop-types'
import Dropdown from "react-bootstrap/Dropdown";
import "./inputs.scss"
/**
 * Takes an array of objects with {value,label}
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const Input = (props) => (
    <Dropdown size="lg" onSelect = {props.onSelect}>
        <Dropdown.Toggle variant={props.variant ?? "outlined-primary"} id="dropdown-basic" className = {`uni ${props.className}`}>
            <span>{props.placeholder} </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>
            {props.list ?
                props.list.map((item, i) => <Dropdown.Item key={i} eventKey={JSON.stringify(item)}>{item.label}</Dropdown.Item>) :
            <Dropdown.Item href="#/action-1">Null</Dropdown.Item>
            }
        </Dropdown.Menu>
    </Dropdown>
);

const onSelect = (item) => {
    let parsed = JSON.parse(item)
    // props.onSelect(parsed);
}

Input.propTypes = {
    onSelect:PropTypes.func.isRequired,
    variant:PropTypes.string.isRequired,
    placeholder:PropTypes.string.isRequired,
    list:PropTypes.array.isRequired
};

export default Input;

//onChange={(e) => this.inputHandle('store',"home_store",e)}