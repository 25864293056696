import React from 'react';
import MaskedInput from 'react-text-mask'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
let tfn = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
let bank = [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/];
let mask = null;

const NumberInput = (props) => (
    <Form.Group className="mb-4" controlId={props.controlId}>
        {props.label ?  <Form.Label>{props.label}</Form.Label>: null}
        <MaskedInput
            name={props.name}
            onFocus = {props.onFocus}
            mask = {props.mask}
            value = {props.value}
            onChange = {props.onChange}
            guide={false}
            placeholder={props.placeholder}
            className={"form-control " + props.className}
            required={props.required}
            onBlur = {props.onBlur}
        />
        {props.help ? <Form.Control.Feedback type="invalid" >{props.help}</Form.Control.Feedback>:null}
    </Form.Group>
);

NumberInput.propTypes = {
    id:PropTypes.string.isRequired,
    onValidate:PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    value:PropTypes.string.isRequired,
    placeholder:PropTypes.string.isRequired,
    label:PropTypes.string,
    required:PropTypes.bool.isRequired,
    mask:PropTypes.array.isRequired,
    help:PropTypes.string
};

export default NumberInput;

//onChange={(e) => this.inputHandle('store',"home_store",e)}