import {Auth} from "aws-amplify";
import * as api from "../LambdaCalls"
const logged_in = "LOGGED_IN"

const applicants = ["TEAM_MEMBER","DC_TEAM_MEMBER"];
/**
 *
 * @param username
 * @param password
 * @return {Promise<any>}
 */
export async function signIn(username,password) {
    try {
        return await Auth.signIn(username, password).then(
            user => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    return user.challengeName;
                }else {
                    return logged_in;
                }
            }
        );

    } catch (error) {
        console.log(error.code)
        return error.code;
    }
}

/**
 *
 * @param username
 * @param password
 * @param newPassword
 * @return {Promise<any>}
 */
export async function authenticateNewPassword(username,password,newPassword) {
    try{
        return await Auth.signIn(username, password)
            .then(async (user) => {
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
                    return await Auth.completeNewPassword(
                        user,               // the Cognito User Object
                        newPassword,        // the new password
                        // OPTIONAL, the required attributes
                        {
                            email: username
                        }
                    ).then(user => {
                        console.log(user);
                        return logged_in;
                    }).catch(e => {
                        console.log(e);
                        return e.code;
                    });
                } else {
                    return logged_in;
                }
            }).catch(e => {
                console.log(e);
                return false;
            });
    }catch (e) {
        
    }
}

/**
 *
 * @return {Promise<*>}
 */
export async function getCognitoAttributes(){
    const user = await Auth.currentAuthenticatedUser()
    console.log(user.attributes);
    if('attributes' in user){
        return user.attributes;
    }
}

/**
 *
 * @param confirmation_code
 * @return {Promise<boolean>}
 */
export async function verifyUsernameUpdate(confirmation_code){
    let user = await Auth.currentAuthenticatedUser();
    try {
        await Auth.verifyUserAttributeSubmit(user, 'email', confirmation_code).catch((e)=> {
            console.log(e);
            return false
        })
        return true
    }catch (e) {
        console.log(e);
        return false
    }
}
/**
 * Update user attributes in cognito, if users first time logging in, update their email attribute
 * to be verified
 * @param user_attributes
 * @param updated_username
 * @return {Promise<void>}
 */
export async function updateCognitoAttributes(user_attributes,updated_username){
    const user = await Auth.currentAuthenticatedUser()

    if(updated_username){
        user_attributes['preferred_username'] = user_attributes['email'];
    }
    await Auth.updateUserAttributes(user,user_attributes).then(async (updated) => {
        console.log(updated);
        if(updated === "SUCCESS"){
            let params = {
                Username: user_attributes.email,
                UserAttributes: [
                    { Name: "email_verified", Value: "true" }
                ],
                type: "update"
            };
            await api.adminCreateUser(params);
            await updateDynamoAttributes(user_attributes)
        }
    });
}

/**
 * update dynamo Attributes after cognito attributes have been updated for user.
 * @return {Promise<void>}
 */
async function updateDynamoAttributes(user_attributes){
    let user = {
        fName:{
            data:user_attributes.given_name,
            p_type:"2"
        },
        lName:{
            data:user_attributes.family_name,
            p_type:"2"
        },
        number:{
            data:user_attributes.phone_number,
            p_type:"2"
        },
        email:{
            data:user_attributes.email,
            p_type:"2"
        }
    }
    await api.updateUser("basic",user);
}

export async function initialiseUserRole(role){
    if(applicants.includes(role)){
        await api.updateUser("status","STAGE_1_ONBOARDING");
    }else {
        await api.updateUser("status","TEAM_MEMBER");
    }
}

export async function changePassword(email,code=null,password = null){
    if(!password && !code){
        return await Auth.forgotPassword(email).then(data => console.log(data));
    }else {
        return await Auth.forgotPasswordSubmit(email,code,password);
    }
}

