import React, { Component } from "react";
import {API} from "aws-amplify/lib/index";
import {s3CustomUpload, s3Upload} from "../../libs/awsLib";
import {FormGroup, FormControl} from "react-bootstrap";
import {updateApplicant} from "../../actions/ApplicantActions";
import {connect} from "react-redux";
import {updateUser} from "../../actions/LambdaCalls";
import {getPersonalAttributes} from "../../actions/LambdaCalls";

//TODO BOOTSTRAP
const mapStateToProps = state => {
    return {user: state.authState, focusedUser : state.applicantsState.focusedUser };
};

const mapDispatchToProps = dispatch =>{
    return {
        updateApplicant : userId => {dispatch(updateApplicant(userId))}
    }
}

class ApplicantUploadAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: {
                idcheck: false,
                tfn: false,
                resume: false,
                reference: false,
                PC: false
            },
        };
    }

    handleUpload = async (identifier, attribute_type, event) => {
        event.preventDefault();
        let loading = this.state.loading;

        if (loading[identifier] === false) {
            loading[identifier] = true;
            this.setState(loading);
            let attachment;
            const sessionJWT =  this.props.user.JWT;

            if(this.props.focusedUser.cogId){
                attachment = await s3CustomUpload(event.target.files[0],null,this.props.focusedUser.cogId);
            }else{
                attachment = await s3Upload(event.target.files[0],null);
            }

            let upload = {
                token: sessionJWT,
                userId: this.props.focusedUser.userId,
                type: identifier,
                attribute_type: attribute_type,
                filename: attachment.fileName,
                attachment: attachment.fileName,
                uploaderId: this.props.user.username
            };

            //If the user is trying to upload a PC... set uploaderID to false (this caused it to always set the app_pc var in dynamo to false).
            //Now sets it to true
            if(attribute_type === "APP_PC"){
                upload.uploaderId = false
            }
            await API.post("users", "users/upload", {
                body: upload
            }).catch((e) => {
                loading[identifier] = false
                this.setState(loading)
            })


            this.props.updateApplicant(this.props.userId);
            loading[identifier] = false;
            this.setState(loading);
        }
    };

    render(){
        return(
            <div className={"upload-wrapper"}>
                <div className={"upload-list"}>
                    {(this.props.tfn !== 'true' && this.props.tfn !== "false")
                        ? <div className={"upload-item"}>
                            <FormGroup className={"file-container"} controlId="file">
                                <FormControl className="inputfile inputfile-2 full-width" onChange={(e) => this.handleUpload('tfn', "HR_TFN", e)} type="file" />
                                {(this.state.loading.tfn)
                                    ? <label htmlFor="file">

                                        <span>Uploading...</span>
                                    </label>
                                    : <label htmlFor="file">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17"
                                             viewBox="0 0 20 17">
                                            <path
                                                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                        </svg>
                                        <span>Upload TFN</span>
                                    </label>
                                }
                            </FormGroup>
                        </div>
                        : null
                    }
                    {(this.props.idcheck !== 'true' && this.props.idcheck !=='false')
                        ? <div className={"upload-item"}>
                            <FormGroup className={"file-container"} controlId="file">
                                <FormControl className="inputfile inputfile-2 full-width" onChange={(e) => this.handleUpload('idcheck', "HR_IDCHECK", e)} type="file" />
                                {(this.state.loading.idcheck)
                                    ? <label htmlFor="file">

                                        <span>Uploading...</span>
                                    </label>
                                    : <label htmlFor="file">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17"
                                             viewBox="0 0 20 17">
                                            <path
                                                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                        </svg>
                                        <span>Upload ID</span>
                                    </label>
                                }
                            </FormGroup>
                        </div>
                        : null
                    }
                    {(this.props.resume !== 'true' && this.props.resume !== 'false')
                        ? <div className={"upload-item"}>
                            <FormGroup className={"file-container"} controlId="file">
                                <FormControl className="inputfile inputfile-2 full-width" onChange={(e) => this.handleUpload('resume', "HR_RESUME", e)} type="file" />
                                {(this.state.loading.resume)
                                    ? <label htmlFor="file">

                                        <span>Uploading...</span>
                                    </label>
                                    : <label htmlFor="file">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17"
                                             viewBox="0 0 20 17">
                                            <path
                                                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                        </svg>
                                        <span>Upload Résumé</span>
                                    </label>
                                }
                            </FormGroup>
                        </div>
                        : null
                    }
                    {this.props.referencecheck !=='true' && this.props.referencecheck !== 'false'
                        ?<div className={"upload-item"}>
                            <FormGroup className={"file-container"} controlId="file">
                                <FormControl className="inputfile inputfile-2 full-width" onChange={ (e) => this.handleUpload('reference', "MAN_REFERENCECHECK",e)} type="file" />
                                {(this.state.loading.reference)
                                    ? <label htmlFor="file">

                                        <span>Uploading...</span>
                                    </label>
                                    : <label htmlFor="file">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17"
                                             viewBox="0 0 20 17">
                                            <path
                                                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                        </svg>
                                        <span>Upload Reference Check</span>
                                    </label>
                                }
                            </FormGroup>
                        </div>
                        : null
                    }

                    {this.props.app_pc !== 'na' && this.props.app_pc !== 'true'
                        ?<div className={"upload-item"}>
                            <FormGroup className={"file-container"} controlId="file">
                                <FormControl className="inputfile inputfile-2 full-width" onChange={ (e) => this.handleUpload('PC', "APP_PC",e)} type="file" />
                                {(this.state.loading.PC)
                                    ? <label htmlFor="file">

                                        <span>Uploading...</span>
                                    </label>
                                    : <label htmlFor="file">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17"
                                             viewBox="0 0 20 17">
                                            <path
                                                d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
                                        </svg>
                                        <span>Upload PC</span>
                                    </label>
                                }
                            </FormGroup>
                        </div>
                        : null
                    }

                    {/*{this.props.tfn === 'true' && this.props.idcheck === 'true' && this.props.resume === 'true'*/}
                        {/*? <div>There's nothing left to upload for this applicant!</div>*/}
                        {/*: null*/}

                </div>
            </div>
        )
    }


}


export default connect(mapStateToProps,mapDispatchToProps)(ApplicantUploadAction)