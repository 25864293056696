import React from 'react';
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

const nodes = [
    {
        value: 'onboarding',
        label: 'Onboarding',
        children: [
            {
                value: 'onboarding::add',
                label: 'Add',
                children: [
                    {
                        value: 'onboarding::add::manager',
                        label: 'Manager',
                    },
                    {
                        value: 'onboarding::add::team_member',
                        label: 'Team Member',
                    },
                ],
            },
            {
                value: 'onboarding::applicants',
                label: 'Applicants',
                children: [
                    {
                        value: 'onboarding::applicants::view_applicants',
                        label: 'View Applicants',
                    },
                    {
                        value: 'onboarding::applicants::edit_applicants',
                        label: 'Edit Applicants',
                        children: [
                            {
                                value: 'onboarding::applicants::edit_applicants::basic_uploads',
                                label: 'Basic Uploads',
                            },
                            {
                                value: 'onboarding::applicants::edit_applicants::hr_uploads',
                                label: 'HR Uploads',
                            },
                        ],
                    },],
            },
        ],
    },
    {
        value: 'admin',
        label: 'Admin',
        children: [
            {
                value: 'admin::user_management',
                label: 'User Management',
            },
            {
                value: 'admin::store_management',
                label: 'Store Management',
            },
            {
                value: 'admin::role_management',
                label: 'Role Management',
            }
        ],
    }
];

class PermissionsTree extends React.Component {
    state = {
        checked: [

        ],
        expanded: [

        ],
    };

    constructor(props) {
        super(props);
        this.onCheck = this.onCheck.bind(this);
        this.onExpand = this.onExpand.bind(this);
    }

    onCheck(checked) {
        this.setState({ checked });
        this.props.handlePermissionsChange(checked);
    }

    onExpand(expanded) {
        this.setState({ expanded });
    }

    render() {
        const { checked, expanded } = this.state;

        return (
            <CheckboxTree
                nodes={nodes}
                checkModel="all"
                checked={checked}
                expanded={expanded}
                iconsClass="fa5"
                onCheck={this.onCheck}
                onExpand={this.onExpand}
            />
        );
    }
}

export default PermissionsTree;