import * as type from "../constants/actions/ReportingActionTypes";
const initialState = {
    selectedStore: null,
    dateRange: "today",
    isLoading:false,
    shipmentData: {
        allStoreView: null,
        stateView: null,
        pendingOverview: null,
        pendingDetails: null,
        completeOverview:null,
        completeDetails:null,
        rejectedOverview:null,
        rejectedDetails:null
    },
    rejectedData:[],
    manifestData:[]
};
export default function reportingReducer (state = initialState , action)  {
    switch (action.type) {
        case type.GET_DEFAULT_DATA:
            return{...state, shipmentData: action.shipmentData, rejectedData:action.rejectedData, manifestData:action.manifestData};
        case type.GET_REJECTED_DATA:
            return{...state, rejectedData:action.rejectedData};
        case type.GET_MANIFEST_DATA:
            return{...state,manifestData: action.manifestData};
        case type.GET_ALL_STORE_VIEW:
            return{...state,shipmentData:{...state.shipmentData, allStoreView: action.storeView}};
        case type.GET_STATE_VIEW:
            return{...state,shipmentData:{...state.shipmentData,stateView:action.stateView}}
        case type.GET_PENDING_OVERVIEW:
            return {...state, shipmentData:{...state.shipmentData, pendingOverview: action.pendingOverview}};// reducing correctly
        case type.GET_PENDING_DETAILS:
            return {...state, shipmentData:{...state.shipmentData,pendingDetails: action.pendingDetails}};
        case type.GET_COMPLETE_OVERVIEW:
            return {...state, shipmentData:{...state.shipmentData, completeOverview: action.completeOverview}};
        case type.GET_COMPLETE_DETAILS:
            return {...state, shipmentData:{...state.shipmentData, completeDetails: action.completeDetails}};
        case type.GET_REJECTED_OVERVIEW:
            return {...state, shipmentData:{...state.shipmentData, rejectedOverview: action.rejectedOverview}};
        case type.GET_REJECTED_DETAILS:
            return {...state, shipmentData:{...state.shipmentData, rejectedDetails: action.rejectedDetails}};
        case type.RESET_SHIPMENT_VIEW:
            return {...state, shipmentData:{...state.shipmentData,
                    pendingOverview: initialState.shipmentData.pendingOverview,
                    pendingDetails: initialState.shipmentData.pendingDetails,
                    completeOverview: initialState.shipmentData.completeOverview,
                    completeDetails: initialState.shipmentData.completeDetails,
                    rejectedOverview:initialState.shipmentData.rejectedOverview,
                    rejectedDetails:initialState.shipmentData.rejectedDetails}};

        case type.LOADING_REPORTING_DATA:
            return {...state,isLoading:action.loading};
        default:
            return state;
    }
}