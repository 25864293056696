import {getShipments, getFocusedShipment, updateFulfiller, getPickslips} from "../../actions/ShippingActions";
import React, {Component} from "react";
import {connect} from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {Accordion, AccordionItem, AccordionItemTitle, AccordionItemBody} from "react-accessible-accordion";
import ShippingDropDownComponent from "./ShippingDropDownComponent";
import PrintIcon from "../../components/icons/icon-print"
import "./components/shippingaccordion.css"
import CollectionTimer from "./components/Timer"
import {Storage} from "aws-amplify/lib";

//Shipment Constants
import METHODS from "../../constants/shipping/MethodTypes";
import STATUS from "../../constants/shipping/StatusTypes";
import CARRIER from "../../constants/shipping/CarrierTypes";
import STORES from "../../constants/shipping/StoreTypes";

//Carrier Logos
import AramexLogo from "../../components/icons/carriers/aramex";
import AuspostLogo from "../../components/icons/carriers/auspost";
import SherpaLogo from "../../components/icons/carriers/sherpa";
import ClickCollectIcon from "../../components/icons/carriers/clickcollect";

//Store Logos
import UniversalStoreStackedLogo from "../../components/icons/stores/universal-store-stacked";
import PerfectStrangerStackedLogo from "../../components/icons/stores/perfect-stranger-stacked";

//Logos
import DownArrow from "../../images/arrow.png"
import PendingIcon from "../../images/pending-icon.svg"
import CompletedIcon from "../../images/completed-icon.svg"

var fileDownload = require('js-file-download');

const mapStateToProps = state => {
    return {
        user: state.authState, shipments: state.shippingState.shipments,
        searchedShipments: state.shippingState.searchedShipments,
        loading: state.shippingState.isLoading,
        focusedShipment: state.shippingState.focusedShipment
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getShipments: () => {
            dispatch(getShipments())
        },
        updateFulfiller: (fulfiller) => {
            dispatch(updateFulfiller(fulfiller))
        },
        getFocusedShipment: (shipment) => {
            dispatch(getFocusedShipment(shipment))
        },
        getPickslips: (shipmentId) => {
            dispatch(getPickslips(shipmentId))
        }
    }
};


class ShippingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipmentAlerts: [],
            stage: null
        }
    };

    componentDidMount() {
        this.props.getShipments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleLabelDownload = async (name) => {
        Storage.get('labels/' + name + '-label.pdf', {download: true})
            .then(result => fileDownload(result.Body, name + '-label.pdf'))
            .catch(err => console.log(err));
    };

    handleTimerAlert = async (shipmentId, alert) => {
        let index = this.state.shipmentAlerts.findIndex(x => x.id === shipmentId);
        if (index === -1) {
            let joined = this.state.shipmentAlerts.concat({id: shipmentId, alert: alert});
            this.setState({shipmentAlerts: joined})
        }
    };

    isDisabled(status) {
        let disabledStatus = [
            STATUS.COMPLETE,
            STATUS.REJECTED,
            STATUS.HOLD,
            STATUS.HOLD_WEIGHT,
            STATUS.PENDING_PICKUP
        ]

        return disabledStatus.includes(status);
    }

    /**
     * Determine which carrier logo to render based on the 'carrier' attached to the shipment. If the carrier
     * doesn't exist (legacy shipment) render the carrier logo based on the shipping method.
     * @param shipment
     * @returns {JSX.Element|string}
     */
    renderCarrierLogo(shipment) {
        let carrier = shipment.carrier
        switch (carrier) {
            case CARRIER.APS:
            case CARRIER.APX:
                return <AuspostLogo/>;
            case CARRIER.AX:
                return <AramexLogo/>;
            case CARRIER.SH:
                return <SherpaLogo/>;
            case CARRIER.CC:
                return <ClickCollectIcon/>;
        }

        //Legacy Fallback ('carrier doesn't exist) todo: remove once all shipments in TP have carrier key/value
        let method = shipment.method;
        switch (method) {
            case METHODS.STANDARD:
            case METHODS.EXPRESS:
                return <AuspostLogo/>;
            case METHODS.CLICK_COLLECT:
                return <ClickCollectIcon/>;
        }

        return 'Unknown Carrier';
    }

    /**
     * Determine if the shipment needs the 'urgent' class for the carrier column dependant on the carrier type
     *
     * @param carrier
     * @param method
     * @returns {string|string}
     */
    determineCarrierUrgency(carrier, method) {
        switch (carrier) {
            case CARRIER.SH:
            case CARRIER.CC:
            case CARRIER.APX:
                return 'urgent';
            case CARRIER.APS:
                return '';
            case CARRIER.AX:
                return method === METHODS.EXPRESS ? 'urgent' : '';
        }

        //Legacy Fallback ('carrier doesn't exist) todo: remove once all shipments in TP have carrier key/value
        switch (method) {
            case METHODS.EXPRESS:
            case METHODS.CLICK_COLLECT:
                return 'urgent';
            default:
                return '';
        }
    }

    /**
     * Determine if the shipment needs the 'urgent' class for the shipping method column based on the shipping method.
     * @param method
     * @returns {string}
     */
    determineShippingUrgency(method) {
        return (method === METHODS.CLICK_COLLECT || method === METHODS.SAME_DAY) ? 'urgent' : '';
    }

    /**
     * Return the title for the shipment based on the carrier.
     *
     * @param shipment
     * @returns {string|*}
     */
    determineCarrierTitle(shipment) {
        let carrier = shipment.carrier;
        let titles = {
            'AX': 'Aramex Shipping',
            'APS': 'Auspost Standard Shipping',
            'APX': 'Auspost Express Shipping',
            'SH': 'Same Day Shipping',
            'CC': 'Click And Collect'
        }

        if (titles[carrier]) {
            return titles[carrier];
        }

        //Legacy Fallback ('carrier' doesn't exist) todo: remove once all shipments in TP have carrier key/value
        let method = shipment.method;
        switch (method) {
            case METHODS.STANDARD:
                return titles.APS;
            case METHODS.EXPRESS:
                return titles.APX;
            case METHODS.CLICK_COLLECT:
                return titles.CC;
        }

        return 'Unknown Shipping Method ';
    }

    /**
     * Determine which store logo to render based on the given store.
     *
     * @param onlineStoreId
     * @returns {JSX.Element}
     */
    renderStoreLogo(onlineStoreId) {
        switch (onlineStoreId) {
            case STORES.US:
                return <UniversalStoreStackedLogo/>;
            case STORES.PS:
                return <PerfectStrangerStackedLogo/>;
            default:
                //Legacy Fallback ('onlineStoreId' doesn't exist)
                return <UniversalStoreStackedLogo/>;
        }
    }

    /**
     * Determine the status information to display in the status column.
     *
     * @param isLabelPrinted
     * @param status
     * @returns {JSX.Element}
     */
    determineStatus(isLabelPrinted, status) {
        if (isLabelPrinted && status !== STATUS.COMPLETE) {
            return <p style={{fontWeight: '700', textTransform: 'uppercase', textAlign: "center"}}>
                Label printed. Click 'Print Label' if you cannot find it</p>
        } else {
            let icon = (status === STATUS.COMPLETE || status === STATUS.PENDING_PICKUP) ? CompletedIcon : PendingIcon
            return <>
                <p style={{fontWeight: '700', textTransform: 'uppercase'}}>
                    {"   " + status === "HOLD"
                        ? "ON HOLD" : status === STATUS.PENDING_PICKUP
                            ? "WAITING FOR PICK-UP" : status ==="HOLD_WEIGHT"
                                ? "HOLD (Weight needed)" : status}
                </p>
                <img alt="status-icon" style={{width: "30px"}} src={icon}/>
            </>
        }
    }

    /**
     * Determine the print columns text and onclick functionality
     *
     * @param shipmentId
     * @param isLabelPrinted
     * @param status
     * @returns {JSX.Element}
     */
    determinePrintType(shipmentId, isLabelPrinted, status) {
        let labelExists = !!isLabelPrinted || status === STATUS.COMPLETE;
        let downloadHandler = labelExists ?
            (async (e) => {
                await this.handleLabelDownload(shipmentId);
                e.stopPropagation();
            }) :
            ((e) => {
                this.props.getPickslips(shipmentId);
                e.stopPropagation();
            });
        return (
            <div onClick={downloadHandler} className={"left-accordion-header download-pickslip"}>
                <span>{labelExists ? 'PRINT LABEL' : 'PRINT PICKSLIP'}</span>
                <PrintIcon className="icon"/>
            </div>
        )
    }

    /**
     * Return a CollectionTimer component dependant on the shipment shipping method. Returns null if not applicable
     *
     * @param method
     * @param status
     * @param createdAt
     * @param shipmentId
     * @returns {JSX.Element|null}
     */
    determineCountdown({method, status, createdAt, shipmentId}) {
        if ((method === METHODS.CLICK_COLLECT || method === METHODS.SAME_DAY) && status === STATUS.PENDING) {
            return <CollectionTimer createdAt={createdAt} onTimerAlert={(e) => this.handleTimerAlert(shipmentId, e)}/>
        }
        return null;
    }

    /**
     * Given a shipment build an object containing all the information needed to render the shipment.
     *
     * @param shipment
     * @returns {{carrierTitle: (string|*), print: JSX.Element, carrierLogo: (JSX.Element|string), countdown: (JSX.Element|null), storeLogo: JSX.Element, status: JSX.Element}}
     */
    buildShipmentInformation(shipment) {
        return {
            storeLogo: this.renderStoreLogo(shipment.onlineStoreId),
            carrierLogo: this.renderCarrierLogo(shipment),
            carrierTitle: this.determineCarrierTitle(shipment),
            status: this.determineStatus(shipment.labelsPrinted, shipment.status),
            print: this.determinePrintType(shipment.shipmentId, shipment.labelsPrinted, shipment.status),
            countdown: this.determineCountdown(shipment),
        }
    }

    renderShippingHeader(shipment) {
        let disabled = this.isDisabled(shipment.status ) ? "complete" : "";
        let printed = shipment.labelsPrinted && shipment.status !== STATUS.COMPLETE ? 'urgent' : '';
        let shipmentInformation = this.buildShipmentInformation(shipment);
        let alert = this.state.shipmentAlerts[this.state.shipmentAlerts.findIndex(x => x.id === shipment.shipmentId)];
        alert = (typeof alert === 'undefined') ? null : alert.alert;

        return (
            <div
                className={"accordion-header-wrapper shipping-accordion-header-wrapper " + disabled + " " + printed + " " + alert}>
                <Row>
                    <Col sm={1}
                         className={`carrier-column ${this.determineCarrierUrgency(shipment.carrier, shipment.method)}`}>
                        {shipmentInformation.carrierLogo}
                    </Col>
                    <Col sm={2} className={`store-column`}>
                        {shipmentInformation.storeLogo}
                    </Col>
                    <Col sm={2} className={"shipment-details-column"}>
                        <span className={`shipping-urgency ${this.determineShippingUrgency(shipment.method)}`} style={{fontWeight: '700', fontSize: '18px'}}>
                            {shipmentInformation.carrierTitle}
                        </span>
                        <span style={{fontWeight: '700', fontSize: '18px'}}>
                            SHIPMENT: {shipment.incrementId ? shipment.incrementId : shipment.shipmentId}
                        </span>
                    </Col>
                    <Col sm={2} className={"timer-column"}>
                        {shipmentInformation.countdown}
                    </Col>
                    <Col sm={2} className={"status-column"}>
                        {shipmentInformation.status}
                    </Col>
                    <Col sm={2} className={"print-column"}>
                        {shipmentInformation.print}
                    </Col>
                    <Col sm={1} className={"arrow-column"}>
                        <div className={"left-accordion-header"}>
                                <span className={"quarter arrow"}>
                                        <img style={{marginTop: '10px', marginBottom: '10px'}}
                                             className={"toggle-arrow"} src={DownArrow}/>
                                </span>
                        </div>
                    </Col>
                </Row>
                {this.props.focusedShipment === shipment.shipmentId && shipment.status === STATUS.PENDING ?
                    <Row className={"stages-wrapper"}>
                        <Col sm={4}>
                            <span style={shipment.stage ? shipment.stage !== "STAGE_ONE" ? {fontSize: 14 + 'px'} : {
                                fontWeight: 700,
                                fontSize: 14 + 'px'
                            } : {fontWeight: 700, fontSize: 14 + 'px'}}>
                                CONFIRM ORDER </span>
                        </Col>
                        <Col sm={4} style={shipment.stage ? shipment.stage === "STAGE_TWO" ? {
                            fontWeight: 700,
                            fontSize: 14 + 'px'
                        } : {fontSize: 14 + 'px'} : {fontSize: 14 + 'px'}}>
                            PACK ORDER
                        </Col>
                        <Col sm={4} style={shipment.stage ? shipment.stage === "STAGE_THREE" ? {
                            fontWeight: 700,
                            fontSize: 14 + 'px'
                        } : {fontSize: 14 + 'px'} : {fontSize: 14 + 'px'}}>
                            COMPLETE ORDER
                        </Col>
                    </Row>
                    : null
                }
            </div>
        )
    }

    render() {
        let shipmentList = this.props.shipments.map((shipment) =>
            <AccordionItem
                uuid={shipment.shipmentId} key={shipment.shipmentId} className={"accordion-node"} expanded={false}>
                <AccordionItemTitle style={{"padding": 0, "boxShadow": "none"}} className={"applicant-accordion-header"}>
                    {this.renderShippingHeader(shipment)}
                </AccordionItemTitle>
                <AccordionItemBody className={"accordion-panel"}>
                        <ShippingDropDownComponent disabled={this.isDisabled(shipment.status)} shipment={shipment}/>
                </AccordionItemBody>
            </AccordionItem>
        );
        return (
            (this.props.shipments.length > 0 ?
                <Accordion accordion={true} onChange={e => this.props.getFocusedShipment(e)}>
                    {shipmentList}
                </Accordion>
                : this.props.loading ? <div className="loading-spinner loading-spinner-blue" /> :
                    <p>No Shipments for your store</p>)

        )
    }
}

const Shipping = connect(mapStateToProps, mapDispatchToProps)(ShippingList);
export default Shipping;