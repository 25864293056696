import React from 'react';
import {Formik, Form, getIn} from 'formik';
import * as yup from 'yup';
import Input from "../form/inputs/Input";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoaderButton from "../LoaderButton";
import PropTypes from "prop-types";

import Alert from "react-bootstrap/Alert";
const errCodes = {
    NotAuthorizedException:"Incorrect password! Please try again",
    UserNotFoundException:"Email address not found, Please ensure the correct email was used"
}

class LoginForm extends React.Component {
    render() {
        return (
            <Formik
                validateOnChange={false}ß
                initialValues={{
                    email:'',
                    password:''
                }}
                validationSchema={this.buildLoginSchema}
                onSubmit={(values,{setStatus}) => {
                    this.props.submitDetails(values.email,values.password,setStatus)
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      status
                      /* and other goodies */
                  }) => (
                        <Col sm={12} md={3} lg={3} xl={3}>
                          <Form noValidate onSubmit={handleSubmit} className={"user-login-form"}>
                              {status?
                                  <Alert variant="danger">
                                      {errCodes[status]}!
                                  </Alert>
                                  : null}
                              <Row>
                                  <Col sm={12}>
                                      <Input
                                          gap = "mb-2"
                                          controlId={"email"}
                                          name={"email"}
                                          onChange={handleChange}
                                          value={values.email}
                                          placeholder={"Email"}
                                          isInvalid = {!!getIn(errors,"email")}
                                          required
                                      />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col sm={12}>
                                      <Input
                                          gap = "mb-4"
                                          controlId={"password"}
                                          name={"password"}
                                          type={"password"}
                                          onChange={handleChange}
                                          value={values.password}
                                          placeholder={"password"}
                                          isInvalid = {!!getIn(errors,"email")}
                                          required
                                      />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col sm={12}>
                                      <LoaderButton
                                          className={"btn-block"}
                                          variant="dark"
                                          type="submit"
                                          text={"LOGIN"}
                                          isLoading ={this.props.loading}
                                      />
                                  </Col>
                              </Row>
                          </Form>
                        </Col>
                )}
            </Formik>
        )
    }
    buildLoginSchema = () => {
        return yup.object().shape({
            email: yup.string().email().required("Please provide an email"),
            password: yup.string().min(6, 'Password must be at least 6 characters')
                .required('Password is required'),
        })

    }
}

LoginForm.propTypes = {
    submitDetails: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired
};

export default LoginForm ;