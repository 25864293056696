import React, {Component,useEffect, useState} from "react";
let moment = require('moment');
let momentz = require('moment-timezone');
export default class CollectionTimer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh: false,
            timeLeft: {},
            alert: null
        };
    }

    componentDidMount() {
        // creates an interval that runs every second.
        this.myInterval = setInterval(() => {
            const timeLeft = this.state.timeLeft;

            if (timeLeft.minutes > 0 || Object.keys(timeLeft).length === 0) {
                this.calculateTimeLeft(this.props.createdAt);
            }
            if (timeLeft.seconds <= 0) {
                if (timeLeft.minutes <= 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.calculateTimeLeft(this.props.createdAt);
                }
            }
        }, 1000)
    }

    /**
     * accepts moment object or string
     * @param createdAt
     */
    calculateTimeLeft = (createdAt) => {
        let createdAtTime = moment.utc(createdAt);
        let ausTime= moment(createdAtTime).local();
        let range = moment(ausTime).add(1,'hours');
        let now = moment();
        let diff = range.diff(now,"seconds");
        let timeLeft = {};
        if (diff > 0) {
           let minutes = Math.floor((diff /60 ) % 60);
           let seconds = Math.floor((diff) % 60 );
           if (minutes < 10) {
               minutes = "0"+minutes;
           }
            if (seconds < 10) {
                seconds = "0"+seconds;
            }
            timeLeft = {
                hours: Math.floor((diff / (3600)) % 24),
                minutes: minutes,
                seconds: seconds
            };
        }
        if(diff < 1){
            this.onTimerAlert('overdue-red');
            timeLeft= {
                overdue:true
            }
        }
        // if less than 10 minutes left
        if (diff < 300 ){
            timeLeft.urgent= true;
            this.onTimerAlert('five-yellow');
        }
        // if less than a minute left
        else if(diff < 60) {
            timeLeft.urgent= true;
        }
        this.setState({timeLeft});
    };

    onTimerAlert = (alert) => {
      this.props.onTimerAlert(alert)
    };


    render() {
        let timeLeft = this.state.timeLeft;

        return(
                timeLeft.minutes ?
                    <div>
                    <p style={{fontSize:"20px",fontWeight:"700",color:"red"}}>0{timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}</p>
                    </div>
                :<div>
                    <p style={{fontSize:"26px",fontWeight:"700",color:"red"}}>OVERDUE</p>
                </div>

        )
    }

}
