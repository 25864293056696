import React, {Component} from "react";
import {connect} from "react-redux";
import {getAllPickslips, getOrders, getPickslips, getShipments, updateFulfiller} from "../../actions/ShippingActions";
import {Row,Col} from "react-bootstrap";
import ReportingShipping from "./shipping/shippingReport";
import './reporting.css'
import {LinkContainer} from "react-router-bootstrap";
import ShippingIcon from "../../components/icons/icon-ship";
import OnboardingIcon from "../../components/icons/icon-tasks"
const mapStateToProps = state => {
};

const mapDispatchToProps = dispatch =>{
    return {
    }
};
class ReportingHome extends Component {
    constructor(props) {
        super(props);
    };

    render(){
        return(
         <Row className ="reporting-wrapper">
            <Col sm={12}>
                <LinkContainer to="/reporting/shipping">
                    <div className ="reporting-icon">
                        <ShippingIcon className="icon"/>
                        <a style={{fontSize:'14px'}} className="label-text">Ship from store</a>
                    </div>
                </LinkContainer>
            </Col>
         </Row>
        )
    }
}

const Reporting = connect(mapStateToProps, mapDispatchToProps)(ReportingHome);
export default Reporting;