import React, {Component} from 'react';
import DateInput from "./inputs/DateInput"
import PropTypes from 'prop-types';
import moment from "moment/moment";

/**
 *  Componenet is a reusable DateOfBirth that can be used anywhere with any data structure
 *
 */

class DateOfBirth extends Component {
    constructor(props) {
        super(props);
        this.handleChange = props.handleChange;
        this.handleErrors = props.handleErrors;
        this.onFocus = props.onFocus;
        this.state = {
            dob: "",
            dobError: ''
        }
    }


    /**
     * Handles validation locally at component level and then returns errors to parents
     *
     */
    handleValidation = (dob) => {

        if(dob){
            let date = moment(dob, 'DD/MM/YYYY',true);
            let endDate = moment(new Date);
            let startDate   = moment(new Date(1901, 1, 15));

            if(dob.length>3 && dob.length<10){
                this.setState({
                    dobError: 'Invalid Date Of Birth'
                });
                this.handleErrors('Invalid Date Of Birth',true);
            }else if(dob.length === 10 && date.isValid()){
                if(date.isBetween(startDate,endDate))
                {
                    this.handleErrors('Invalid Date Of Birth',false);
                    this.setState({
                        dobError: null
                    });
                }else {
                    this.handleErrors('Invalid Date Of Birth',true);
                    this.setState({
                        dobError: 'Invalid Date Of Birth'
                    });
                }

            } else if(dob.length === 10 && !date.isValid()){

                this.handleErrors('Invalid Date Of Birth',true);
                this.setState({
                    dobError: 'Invalid Date Of Birth'
                });
            }
            else return null
        }
    };


    getValidationState =() =>{

        let dob = this.state.dob
        if(dob){
            let date = moment(dob, 'DD/MM/YYYY',true);
            let endDate = moment(new Date);
            let startDate   = moment(new Date(1901, 1, 15));
            if(dob.length>3 && dob.length<10){
                return 'error';
            }else if(dob.length === 10 && date.isValid()){
                if(date.isBetween(startDate,endDate))
                {
                    return 'success'
                }else {
                    return 'error'
                }
            } else if(dob.length === 10 && !date.isValid()){
                return 'error'
            }
            else return null
        }

    }


    render(){
        let path  = this.props.path;
        let {dob,required}=this.props;
        return (
                        <DateInput
                            onFocus = {() => this.onFocus(path,"dob")}
                            id="date"
                            onValidate={this.getValidationState()}
                            onChange={e => {this.handleChange(path,"dob", e.target.value);
                                this.setState({dob:e.target.value});
                            }}
                            onBlur = { e => this.handleValidation(e.target.value)}
                            value={dob ? dob : this.state.dob}
                            label="Date of Birth"
                            required={required}
                        />
        )
    }
}

DateOfBirth.propTypes = {
    /** HandleChange is the parent logic to handle input and what to do with the input in terms of storing the data */
    handleChange: PropTypes.func.isRequired,
    /** if data is preloaded from backend pass in the following props with the corresponding data*/
    dob: PropTypes.string,
    path: PropTypes.array.isRequired

};
DateOfBirth.defaultProps = {
    //  street_one: "",
    path: [],
    dob : ""
};


export default DateOfBirth
